import React, { useState, useEffect, useRef, useContext } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ClickAwayListener from "@mui/material/ClickAwayListener";
// For the date range picker
import Datepicker from "../sections/DatePicker/Datepicker";
import Box from "@mui/material/Box";
import SortIcon from "@mui/icons-material/Sort";
import { useHistory } from "react-router-dom";
import Categories from "../sections/Checkbox/Categories";
import { filter_context } from "../searchbar/Searchbar";
import "../../../css/Filters/filters.css";

function Filters() {
  const {
    setShowFilters,
    teleSearch,
    setTeleSearch,
    telegramOption_user,
    setTelegramOption_user,
    user_group,
    set_user_group,
    telegramOption_post,
    setTelegramOption_post,
    telegramOption_channel,
    setTelegramOption_channel,
    userFilter,
    setUserFilter,
    postSearch,
    setPostSearch,
    postSearchSub,
    setPostSearchSub,
    keyword,
    setKeyword,
    postCategory,
    setPostCategory,
    multimedia,
    setMultimedia,
    setDateInput,
    date_from,
    set_date_from,
    date_to,
    set_date_to,
    postNo,
    setPostNo,
    dataDisplay,
    setDataDisplay,
    dataOrder,
    setDataOrder,
    spamFilter,
    setSpamFilter,
    proximity,
    set_proximity,
    re_render,
    set_re_render,
  } = useContext(filter_context)["telegram_filters"];
  // Calendar part
  const date_start_ref = useRef();
  const date_to_ref = useState();
  const [session_status, set_session_status] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setDateInput([date_from, date_to]);
  }, [date_from, date_to]);

  useEffect(() => {
    if (
      teleSearch !== "post" &&
      teleSearch !== "file" &&
      postSearch === "logical"
    ) {
      setPostSearch("None");
    } else if (teleSearch !== "post" && postSearch !== "logical") {
      setPostSearch(postSearch);
    }
    if (
      (teleSearch === "user" || teleSearch === "channel") &&
      (postSearch === "bulk" || postSearch === "individual")
    ) {
      setPostSearch("None");
    }
  }, [teleSearch]);

  // When return back from other page
  useEffect(() => {
    let carry_loop = false;

    if (history.action === "POP" && re_render === false) {
      if (session_status === false) {
        if (
          history.location.pathname === "/dashboard" ||
          history.location.pathname === "/beta"
        ) {
          carry_loop = true;
        }
      }
    }
    try {
      if (carry_loop === true) {
        const filters_array = JSON.parse(localStorage.getItem("filters_array"));
        const {
          new_tele,
          date_data,
          telegramOption_post,
          telegramOption_user,
          user_group,
          userFilter,
          postSearch,
          postSearchSub,
          keyword,
          postCategory,
          multimedia,
          postNo,
          dataDisplay,
          dataOrder,
          spamFilter,
          proximity,
          telegramOption_channel,
        } = filters_array;
        setTeleSearch(new_tele);
        setUserFilter(userFilter);
        setPostSearch(postSearch);
        setPostSearchSub(postSearchSub);
        setKeyword(keyword);
        setMultimedia(multimedia);
        setDataDisplay(dataDisplay);
        setDataOrder(dataOrder);
        setPostNo(postNo);
        setDateInput(date_data);
        set_date_from(date_data[0]);
        set_date_to(date_data[1]);
        setTelegramOption_post(telegramOption_post);
        setTelegramOption_user(telegramOption_user);
        setTelegramOption_channel(telegramOption_channel);
        set_user_group(user_group);
        setPostCategory(postCategory);
        setSpamFilter(spamFilter);
        set_proximity(proximity);
        set_session_status(true);
        set_re_render(true);
      }
    } catch {
      set_re_render(true);
    }
  }, [re_render]);

  try {
    window.onbeforeunload = function () {
      sessionStorage.removeItem("filters_array");
      localStorage.removeItem("filters_array");
      localStorage.removeItem("filters_array_tag");
      localStorage.removeItem("filters_array_stealer");
    };
  } catch {
    //Do nothing
  }

  // TELEGEAM Search
  const handleChangeTeleSearch = (event) => {
    setTeleSearch(event.target.value);
    if (event.target.value === "channel") {
      setPostNo(25);
    } else {
      setPostNo(100);
    }
    setPostSearch((prev) => {
      if (prev !== "None") {
        return "None";
      } else {
        return prev;
      }
    });
    setTelegramOption_post((prev) =>
      event.target.value === "file" ? "None" : prev,
    );
  };

  // telegram option for post
  const handleChangeTelegramOption_post = (event) => {
    setTelegramOption_post(event.target.value);
  };

  // telegram option for user
  const handleChangeTelegramOption_user = (event) => {
    setTelegramOption_user(event.target.value);
    if (event.target.value === "group") {
      setKeyword("exact");
    } else {
      setKeyword("contains");
    }
  };

  const handleChangeTelegramOption_channel = (event) => {
    setTelegramOption_channel(event.target.value);
  };

  const handleChangeUserGroup = (event) => {
    set_user_group(event.target.value);
  };

  //   POST Search
  const handleChangePostSearch = (event) => {
    setPostSearch(event.target.value);
    if (event.target.value === "logical") {
      setTelegramOption_post("message");
    } else if (
      event.target.value === "None" ||
      event.target.value === "individual"
    ) {
      setTelegramOption_post("None");
    }
  };

  //   POST Search Sub
  const handleChangePostSearchSub = (event) => {
    setPostSearchSub(event.target.value);
  };

  //   Keyword Search
  const handleChangeKeyword = (event) => {
    setKeyword(event.target.value);
  };

  const handleChangeProximity = (event) => {
    set_proximity(event.target.value);
  };

  //   Multimedia Display
  const handleChangeMultimedia = (event) => {
    setMultimedia(event.target.value);
  };

  // USER filter
  const handleChangeUserFilter = (event) => {
    setUserFilter(event.target.value);
  };
  // No of posts
  const handleChangePostNo = (event) => {
    setPostNo(event.target.value);
  };

  //   Data Display
  const handleChangeDataDisplay = (event) => {
    setDataDisplay(event.target.value);
  };

  //   Data Order
  const handleChangeDataOrder = (event) => {
    setDataOrder(event.target.value);
  };

  const handleChangeSpamFilter = (event) => {
    setSpamFilter(event.target.value);
  };

  // Click outside function
  const handleClickAway = () => {
    setShowFilters(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="filters__main filters_main_telegram">
          {/* TeleSearch part */}

          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Telegram
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={teleSearch}
              onChange={handleChangeTeleSearch}
            >
              <FormControlLabel
                value="post"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Post"
              />
              <FormControlLabel
                value="user"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="User"
              />
              <FormControlLabel
                value="file"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="File"
              />
              <FormControlLabel
                value="channel"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Profile"
              />
            </RadioGroup>
          </FormControl>

          {/* Telegram option named filters for post */}
          {teleSearch === "post" && postSearch !== "individual" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Filters
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={telegramOption_post}
                onChange={handleChangeTelegramOption_post}
              >
                {postSearch !== "logical" ? (
                  <FormControlLabel
                    value="None"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="All"
                  />
                ) : null}

                <FormControlLabel
                  value="conv_name"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Title"
                />
                <FormControlLabel
                  value="message"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Post"
                />
              </RadioGroup>
            </FormControl>
          )}

          {/* Telegram option named filters for user */}
          {teleSearch === "user" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Filters
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={telegramOption_user}
                onChange={handleChangeTelegramOption_user}
              >
                <FormControlLabel
                  value="username"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Username"
                />

                <FormControlLabel
                  value="phone"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Phone"
                />

                <FormControlLabel
                  value="group"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Group"
                />
                <FormControlLabel
                  value="user_id"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="User Id"
                />
              </RadioGroup>
            </FormControl>
          )}

          {/* Telegram option named filters for channel */}
          {teleSearch === "channel" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Filters
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={telegramOption_channel}
                onChange={handleChangeTelegramOption_channel}
              >
                <FormControlLabel
                  value="conv_name"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Name"
                />

                <FormControlLabel
                  value="link"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Link"
                />
              </RadioGroup>
            </FormControl>
          )}

          {/* User Group */}
          {teleSearch === "user" && telegramOption_user === "group" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Group Filter
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={user_group}
                onChange={handleChangeUserGroup}
              >
                <FormControlLabel
                  value="groupid"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Group Id"
                />
                <FormControlLabel
                  value="group_channel_name"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Group Name"
                />
                <FormControlLabel
                  value="group_username"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Group Username"
                />
              </RadioGroup>
            </FormControl>
          )}

          {/* Spam filter */}
          {teleSearch === "post" && postSearch === "None" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Spam
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={spamFilter}
                onChange={handleChangeSpamFilter}
              >
                <FormControlLabel
                  value="False"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="OFF"
                />
                <FormControlLabel
                  value="True"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="ON"
                />
              </RadioGroup>
            </FormControl>
          )}

          {/* User search Filter */}
          {teleSearch === "user" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Contains
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={userFilter}
                onChange={handleChangeUserFilter}
              >
                <FormControlLabel
                  value="phone"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Phone only"
                />
                <FormControlLabel
                  value="all"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="All"
                />
              </RadioGroup>
            </FormControl>
          )}

          {/* Post Search type  */}
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Search by
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={postSearch}
              onChange={handleChangePostSearch}
            >
              <FormControlLabel
                value="None" // selected keyword
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Keyword"
              />
              <FormControlLabel
                value="regex"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Regex"
              />
              {(teleSearch === "post" || teleSearch === "file") && (
                <FormControlLabel
                  value="logical"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Logical"
                />
              )}

              {(teleSearch === "post" || teleSearch === "file") && (
                <FormControlLabel
                  value="bulk"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Bulk"
                />
              )}

              {(teleSearch === "post" || teleSearch === "file") && (
                <FormControlLabel
                  value="individual"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Indv channel"
                />
              )}
            </RadioGroup>
          </FormControl>
          {/* Post Search Sub Part */}
          {teleSearch !== "user" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={postSearchSub}
                onChange={handleChangePostSearchSub}
              >
                <FormControlLabel
                  value="channel"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Channel"
                />
                <FormControlLabel
                  value="group"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Group"
                />
                <FormControlLabel
                  value="Channel/Group"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Both"
                />
              </RadioGroup>
            </FormControl>
          )}

          {/* Keyword search */}
          {postSearch === "None" && telegramOption_user !== "group" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Keyword
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={keyword}
                onChange={handleChangeKeyword}
              >
                <FormControlLabel
                  value="contains"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Contains"
                />
                <FormControlLabel
                  value="exact"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Exact"
                />
              </RadioGroup>
            </FormControl>
          )}

          {/* Proximity */}
          {(teleSearch === "file" || teleSearch === "post") &&
            postSearch === "None" &&
            keyword === "exact" && (
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Proximity
                </FormLabel>
                <form>
                  <input
                    type="number"
                    value={proximity}
                    onChange={handleChangeProximity}
                    className="post_no_input"
                    min="0"
                    max="100"
                  />
                </form>
              </FormControl>
            )}

          {/* Post Category Search */}
          {teleSearch !== "user" && (
            <Categories
              postCategory={postCategory}
              setPostCategory={setPostCategory}
              container_type={"column"}
            />
          )}

          {/* Multimedia Search */}
          {teleSearch === "post" && postSearch === "None" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Multimedia
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={multimedia}
                onChange={handleChangeMultimedia}
              >
                <FormControlLabel
                  value="enabled"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Enable"
                />
                <FormControlLabel
                  value="disabled"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Disable"
                />
              </RadioGroup>
            </FormControl>
          )}

          {/* Date */}
          {(teleSearch === "post" || teleSearch === "file") && (
            <FormControl>
              <div className="dateRangePicker">
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Date
                </FormLabel>
                <Datepicker
                  date_from={date_from}
                  set_date_from={set_date_from}
                  date_to={date_to}
                  set_date_to={set_date_to}
                />
                {teleSearch === "filter" ? (
                  <div className="dateRangePicker_input">
                    <input
                      type="text"
                      placeholder="start"
                      value={date_from !== "None" ? date_from : "start"}
                      ref={date_start_ref}
                      onClick={() => (date_start_ref.current.type = "date")}
                      onChange={(event) =>
                        set_date_from(
                          event.target.value ? event.target.value : "None",
                        )
                      }
                      max={date_to !== "None" ? date_to : null}
                    />
                    <Box sx={{ mx: 1 }}> to </Box>
                    <input
                      type="text"
                      placeholder="end"
                      value={date_to !== "None" ? date_to : "end"}
                      ref={date_to_ref}
                      onClick={() => (date_to_ref.current.type = "date")}
                      onChange={(event) =>
                        set_date_to(
                          event.target.value ? event.target.value : "None",
                        )
                      }
                      min={date_from !== "None" ? date_from : null}
                    />
                  </div>
                ) : null}
              </div>
            </FormControl>
          )}

          {/* Data display */}
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Display
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={dataDisplay}
              onChange={handleChangeDataDisplay}
            >
              <FormControlLabel
                value="grid"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Table"
              />
              {teleSearch !== "user" && teleSearch !== "channel" && (
                <FormControlLabel
                  value="graph"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Graph"
                />
              )}
              <FormControlLabel
                value="json"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="JSON"
              />
            </RadioGroup>
          </FormControl>

          {/* Post Number */}
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Post No
            </FormLabel>
            {teleSearch === "channel" ? (
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={postNo}
                onChange={handleChangePostNo}
              >
                <FormControlLabel
                  value={10}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label={10}
                />
                <FormControlLabel
                  value={15}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label={15}
                />
                <FormControlLabel
                  value={20}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label={20}
                />
                <FormControlLabel
                  value={25}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label={25}
                />
              </RadioGroup>
            ) : (
              <input
                type="number"
                value={postNo}
                onChange={handleChangePostNo}
                className="post_no_input"
              />
            )}
          </FormControl>

          {/* Data Order */}
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Order
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={dataOrder}
              onChange={handleChangeDataOrder}
            >
              <FormControlLabel
                value="desc"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label={<SortIcon />}
              />
              <FormControlLabel
                value="asc"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label={<SortIcon sx={{ transform: "rotate(180deg)" }} />}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </ClickAwayListener>
    </>
  );
}

export default Filters;
