import React, { useState, useEffect } from "react";
import Sidenav from "../../TableUi/components/Navbar/Sidenav";
import Adminmainnav from "./Adminmainnav";
import FiltersAlertAdmin from "../../TableUi/components/filters/FiltersAlertAdmin";
import { Grid, Stack } from "@mui/material";
import { Formik } from "formik";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import GridViewIcon from "@mui/icons-material/GridView";
import { IconContext } from "react-icons";
import HelpIcon from "@mui/icons-material/Help";
import TranslateIcon from "@mui/icons-material/Translate";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FaInfoCircle } from "react-icons/fa";
import { GlobalStyles } from "@mui/material";
import { stealer_logs_auto_notification } from "../../utils/Globals/global_functions";
import { ToastContainer } from "react-toastify";
import {
  bank_card_validator,
  ip_address_validator,
  phone_validator,
  crypto_validator,
  email_validator,
  bin_validator,
} from "../../utils/Validation/Tag/tag_validator";
import { TranslateApiUpdate } from "../../TableUi/components/modalContainer/TranslateApi";
import { handle_session_get } from "../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../utils/Validation/Token/token_checker";
import axios from "axios";
import TranslateApi from "../../TableUi/components/modalContainer/TranslateApi";
import { handle_check_category_length } from "../../Assets/globalfunctions";
import {
  handle_success_message,
  response_message_custom,
  handle_error_message,
} from "../../utils/ResponseMessage/response_message";
import CircularProgress from "@mui/material/CircularProgress";
import { options_post_category } from "../../Assets/globalfunctions";
import UpdateAdminAlertFilters from "../../TableUi/components/filters/UpdateAdminAlertFilters";
import BulkModal from "../../TableUi/components/modalContainer/BulkModal";
import { RollerAnimation } from "../../TableUi/components/loader/Loader";
import { handle_string_to_array } from "./handle_admin_function";
import ChannelLogicalAlert from "../../TableUi/components/modalContainer/ChannelLogicalAlert";
import CustomDropdown from "../../TableUi/components/sections/CustomDropdown";
import "../../css/Admin/admin.css";
function AddCredentialAlert() {
  const token = handle_session_get("Token");
  const [admin_notification_data, set_admin_notification_data] = useState([]);
  const [option_dropdown, set_option_dropdown] = useState("keyword");
  const [search_val, set_search_val] = useState("");
  const [notification_filters, set_notification_filters] = useState("Default");
  const [show_filters, set_show_filters] = useState(false);
  const [postCategory, setPostCategory] = useState([]);
  const [keyword, setKeyword] = useState("contains");
  const [interval_type_filter, set_interval_type_filter] = useState("day");
  const [interval_number_filter, set_interval_number_filter] = useState(1);
  const [notification_filters_breach, set_notificaton_filters_breach] =
    useState("domain");
  const [notification_filters_tag, set_notification_filters_tag] =
    useState("bank_card");
  const [alert_type, set_alert_type] = useState("email");
  const [filter_type, set_filter_type] = useState("default");
  const exp_year_options = [
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
  ];
  const [exp_year, set_exp_year] = useState(exp_year_options);

  const exp_month_options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [exp_month, set_exp_month] = useState(exp_month_options);
  const card_type_options = [
    "amex_card",
    "bc_global",
    "carte_blanche_card",
    "diners_club_card",
    "insta_payment_card",
    "jcb_card",
    "korean_local_card",
    "laser_card",
    "maestro_card",
    "mastercard",
    "solo_card",
    "switch_card",
    "visa_master_card",
    "union_pay_card",
    "visa_card",
    "interpayment_card",
    "dankort_card",
    "universal_air_travel_plan_card",
  ];
  const [card_type, set_card_type] = useState(card_type_options);

  const crypto_options = ["bitcoin", "ethereum", "monero", "tron", "xrp"];
  const [crypto_provider, set_crypto_provider] = useState(crypto_options);

  const email_options = [
    "gmail",
    "outlook",
    "aol",
    "yahoo",
    "icloud",
    "proton",
    "zoho",
    "yandex",
    "gmx",
  ];
  const [email_provider, set_email_provider] = useState(email_options);
  const [translate_report, set_translate_report] = useState("deactivate");
  const [alert_file_type, set_alert_file_type] = useState("csv");
  const [alert_post_no, set_alert_post_no] = useState(1);
  const [multiple_email_type, set_multiple_email_type] = useState("cc");

  const [
    open_channel_logical_alert_modal,
    set_open_channel_logical_alert_modal,
  ] = useState(false);
  const [channels_list, set_channels_list] = useState([]);

  const [notification_filters_stealer, set_notificaton_filters_stealer] =
    useState("email");
  const [keyword_stealer, setKeyword_stealer] = useState("contains");
  const [post_category_stealer, set_post_category_stealer] = useState("off");
  const [open_translate, set_open_translate] = useState(false);
  const [language_array, set_language_array] = useState([]);
  const [checked_exclude, set_checked_exclude] = useState(false);
  const [update_loader, set_update_loader] = useState(false);
  const [session_category_array, set_session_category_array] = useState([]);
  const [modal_data, set_modal_data] = useState(null);
  const [query_type, set_query_type] = useState("Default");
  const [open_bulk_modal, set_open_bulk_modal] = useState(false);
  const [stat_loading, set_stat_loading] = useState(false);
  const [search_error, set_search_error] = useState("");
  const [search_data, set_search_data] = useState([]);
  const [all_clients, set_all_clients] = useState([]);
  const [selected_user, set_selected_user] = useState("");

  useEffect(() => {
    if (token !== null) {
      const session_categories = handle_session_get("enabled_categories");
      if (session_categories !== "all") {
        const array_data = session_categories.split(",");
        setPostCategory(array_data);
        set_session_category_array(array_data);
      } else {
        setPostCategory(options_post_category);
      }
      handle_data_getter();
    }
  }, []);
  useEffect(() => {
    if (option_dropdown === "channel") {
      if (filter_type === "individual") {
        set_open_channel_logical_alert_modal(true);
      }
    }
  }, [filter_type]);
  const handle_change_option = (event) => {
    const selected_value = event.target.value;
    set_option_dropdown(selected_value);
    if (
      ["breach", "brand_protection", "stealer_logs"].includes(
        selected_value,
      ) === true
    ) {
      set_notification_filters("Default");
      set_interval_number_filter(1);
      if (selected_value === "breach") {
        set_interval_type_filter("week");
      } else {
        set_interval_type_filter("day");
      }
    } else if (selected_value === "channel") {
      set_notification_filters("Default");
    }
    if (selected_value !== "keyword" && selected_value !== "channel") {
      set_translate_report("deactivate");
    }
    if (
      selected_value === "breach" ||
      selected_value === "tag" ||
      selected_value === "stealer_logs"
    ) {
      set_alert_file_type("csv");
    }
    if (selected_value !== "stealer_logs") {
      set_query_type("Default");
    }
  };

  // Modal state
  const [keyword_modal, setKeyword_modal] = useState(null);
  const [postCategory_modal, setPostCategory_modal] = useState([]);
  const [interval_type, set_interval_type] = useState("day");
  const [interval_number, set_interval_number] = useState(1);
  const [notification_filters_modal, set_notificaton_filters_modal] =
    useState("Default");
  const [alert_type_modal, set_alert_type_modal] = useState("email");
  const [language_array_modal, set_language_array_modal] = useState([]);
  const [checked_exclude_modal, set_checked_exclude_modal] = useState(false);
  const [open_translate_modal, set_open_translate_modal] = useState(false);
  const [select_top_lang_modal, set_select_top_lang_modal] = useState(false);

  const select_default_languages = (obj) => {
    // For the language at inital selection
    try {
      if (obj) {
        if (obj.notification_type === "keyword") {
          if (obj.lang_obj) {
            const filter_lang = obj.lang_obj.map(
              (item) => item.translated_lang,
            );
            set_language_array_modal(filter_lang);
            set_checked_exclude_modal(obj.exclude_source === "True");
          }
        }
      }
    } catch {
      //Do nothing
    }
  };
  const handle_filter_user_id = () => {
    try {
      if (all_clients.length > 0 && selected_user) {
        const filter_user = all_clients.find(
          (item) => item.username === selected_user,
        );
        return filter_user.userid;
      } else return null;
    } catch {
      return null;
    }
  };

  let isSubmitBlocked = false;
  // Function onSubmit
  const post_data = (val, notification_type, channel_list) => {
    const data_individual_channel = {
      keyword_to_watch: val,
      is_protected: true,
      notification_sub_filter: "Default",
      user_id: handle_filter_user_id(),
      channel_name: channel_list,
      interval_type: interval_type_filter,
      interval_number: interval_number_filter,
      notification_type,
      selectCategory:
        option_dropdown === "channel" || option_dropdown === "breach"
          ? "all"
          : handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory,
      search_type: keyword,
      notification_filter: "nested",
      endpoint_type: alert_type,
      email_type: alert_type === "multiple_email" ? multiple_email_type : null,
      alert_file: alert_file_type,
      no_of_posts: alert_file_type === "html" ? Number(alert_post_no) : "None",
      tag_name: "None",
    };
    const data = {
      keyword_to_watch: val,
      is_protected: true,
      notification_sub_filter: "Default",
      user_id: handle_filter_user_id(),
      interval_type: interval_type_filter,
      interval_number: interval_number_filter,
      notification_type,
      selectCategory:
        option_dropdown === "channel" || option_dropdown === "breach"
          ? "all"
          : handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory,
      search_type:
        (option_dropdown === "keyword" ||
          option_dropdown === "file" ||
          option_dropdown === "file_ext") &&
          notification_filters === "Default"
          ? keyword
          : "exact",
      notification_filter:
        option_dropdown === "channel"
          ? "Default"
          : option_dropdown === "breach"
            ? notification_filters_breach
            : notification_filters,
      endpoint_type: alert_type,
      email_type: alert_type === "multiple_email" ? multiple_email_type : null,
      target_lang:
        option_dropdown === "keyword" &&
          notification_filters === "Default" &&
          language_array.length !== 0
          ? language_array
          : "None",
      exclude_source:
        option_dropdown === "keyword" && notification_filters === "Default"
          ? checked_exclude === true
            ? "True"
            : "False"
          : "None",
      alert_file: option_dropdown !== "breach" ? alert_file_type : "csv",
      no_of_posts:
        option_dropdown !== "breach" && alert_file_type === "html"
          ? Number(alert_post_no)
          : "None",
      tag_name: "None",
    };
    const data_stealer = {
      keyword_to_watch:
        query_type === "Default" ? val : handle_string_to_array(val),
      is_protected: true,
      notification_sub_filter: query_type,
      user_id: handle_filter_user_id(),
      interval_type: interval_type_filter,
      interval_number: interval_number_filter,
      notification_type,
      selectCategory: "all",
      search_type: keyword_stealer,
      notification_filter: notification_filters_stealer,
      endpoint_type: alert_type,
      email_type: alert_type === "multiple_email" ? multiple_email_type : null,
      tag_name: "None",
      stealer_category:
        post_category_stealer !== "off" ? post_category_stealer : null,
    };
    const data_tag = {
      keyword_to_watch: val,
      is_protected: true,
      notification_sub_filter: "Default",
      user_id: handle_filter_user_id(),
      selectCategory:
        handle_check_category_length(postCategory) === true
          ? "all"
          : postCategory,
      interval_type: interval_type_filter,
      interval_number: interval_number_filter,
      notification_type,
      notification_filter: notification_filters_tag,
      endpoint_type: alert_type,
      email_type: alert_type === "multiple_email" ? multiple_email_type : null,
      expiry_year:
        notification_filters_tag === "bank_card" ||
          notification_filters_tag === "bin"
          ? exp_year.length === 11
            ? "all"
            : exp_year
          : null,
      expiry_month:
        notification_filters_tag === "bank_card" ||
          notification_filters_tag === "bin"
          ? exp_month.length === 12
            ? "all"
            : exp_month
          : null,
      bank_card_type:
        notification_filters_tag === "bank_card" ||
          notification_filters_tag === "bin"
          ? card_type.length === 18
            ? "all"
            : card_type
          : null,
      email_provider:
        notification_filters_tag === "email"
          ? email_provider.length === 9
            ? "all"
            : email_provider
          : null,
      crypto_type:
        notification_filters_tag === "crypto"
          ? crypto_provider.length === 5
            ? "all"
            : crypto_provider
          : null,
      alert_file: "csv",
      no_of_posts: "None",
      tag_name: "None",
    };
    const data_brand_protection = {
      keyword_to_watch: val,
      is_protected: true,
      notification_sub_filter: "Default",
      user_id: handle_filter_user_id(),
      interval_type: interval_type_filter,
      interval_number: interval_number_filter,
      notification_type,
      endpoint_type: alert_type,
      email_type: alert_type === "multiple_email" ? multiple_email_type : null,
      alert_file: alert_file_type,
      tag_name: "None",
    };
    let sanitize_data = {};
    if (option_dropdown === "channel" && filter_type === "individual") {
      sanitize_data = { ...data_individual_channel };
    } else if (option_dropdown === "tag") {
      sanitize_data = { ...data_tag };
    } else if (option_dropdown === "stealer_logs") {
      sanitize_data = { ...data_stealer };
    } else if (option_dropdown === "brand_protection") {
      sanitize_data = { ...data_brand_protection };
    } else {
      sanitize_data = { ...data };
    }
    set_update_loader(true);
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .post(
          "https://api.recordedtelegram.com/v2/create_notifications",
          sanitize_data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          handle_success_message(res.data.message);
          setTimeout(() => {
            set_admin_notification_data(res.data.data);
          }, 200);
          isSubmitBlocked = false;
          set_update_loader(false);
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          response_message_custom(err.response.data.errormsg, "error");
          isSubmitBlocked = false;
          set_update_loader(false);
        });
    }
  };

  const post_data_modal = (
    val,
    notification_type,
    regex_status,
    id,
    channels,
  ) => {
    const data = {
      keyword_to_watch:
        modal_data.notification_sub_filter === "bulk"
          ? Array.isArray(val)
            ? val
            : handle_string_to_array(val)
          : val,
      is_protected: true,
      notification_sub_filter: modal_data.notification_sub_filter,
      user_id: modal_data.user_obj?.userid ?? null,
      channel_name:
        modal_data.notification_filter === "nested" ? channels : null,
      interval_type,
      interval_number,
      notification_type,
      search_type: keyword_modal,
      selectCategory:
        modal_data.notification_type === "channel" ||
          modal_data.notification_type === "breach" ||
          modal_data.notification_type === "tag"
          ? "all"
          : handle_check_category_length(postCategory_modal) === true
            ? "all"
            : postCategory_modal,
      notification_filter: notification_filters_modal,
      endpoint_type: alert_type_modal,
      alert_id: id,
      email_type: modal_data.email_type
        ? alert_type_modal === "multiple_email"
          ? modal_data.email_type
          : null
        : null,
      target_lang:
        modal_data.notification_type === "keyword" &&
          modal_data.lang_obj &&
          language_array_modal.length !== 0
          ? language_array_modal
          : "None",
      exclude_source:
        modal_data.notification_type === "keyword" &&
          modal_data.lang_obj &&
          language_array_modal.length !== 0
          ? checked_exclude_modal === true
            ? "True"
            : "False"
          : "None",
      alert_file: modal_data.alert_file ? modal_data.alert_file : "csv",
      no_of_posts:
        modal_data.no_of_posts && modal_data.alert_file === "html"
          ? Number(modal_data.no_of_posts)
          : "None",
      tag_name: modal_data.tag_name ? modal_data.tag_name : null,
      stealer_category:
        modal_data.notification_type === "stealer_logs" &&
          modal_data.stealer_category !== "off"
          ? modal_data.stealer_category
          : null,
    };
    const data_tag = modal_data.notification_type === "tag" && {
      keyword_to_watch: val,
      is_protected: true,
      notification_sub_filter: "Default",
      user_id: modal_data.user_obj?.userid ?? null,
      channel_name:
        modal_data.notification_filter === "nested" ? channels : null,
      interval_type,
      interval_number,
      notification_type,
      search_type: keyword_modal,
      selectCategory:
        handle_check_category_length(postCategory_modal) === true
          ? "all"
          : postCategory_modal,
      notification_filter: notification_filters_modal,
      endpoint_type: alert_type_modal,
      alert_id: id,
      target_lang:
        modal_data.notification_type === "keyword" &&
          modal_data.lang_obj &&
          language_array_modal.length !== 0
          ? language_array_modal
          : "None",
      exclude_source:
        modal_data.notification_type === "keyword" && modal_data.exclude_source
          ? checked_exclude_modal === true
            ? "True"
            : "False"
          : "None",
      expiry_year:
        modal_data.notification_filter === "bank_card" ||
          modal_data.notification_filter === "bin"
          ? modal_data.sub_value.exp_year.length === 11
            ? "all"
            : modal_data.sub_value.exp_year
          : null,
      expiry_month:
        modal_data.notification_filter === "bank_card" ||
          modal_data.notification_filter === "bin"
          ? modal_data.sub_value.exp_month.length === 12
            ? "all"
            : modal_data.sub_value.exp_month
          : null,
      bank_card_type:
        modal_data.notification_filter === "bank_card" ||
          modal_data.notification_filter === "bin"
          ? modal_data.sub_value.bank_card_type.length === 18
            ? "all"
            : modal_data.sub_value.bank_card_type
          : null,
      email_provider:
        modal_data.notification_filter === "email"
          ? modal_data.sub_value.email.length === 9
            ? "all"
            : modal_data.sub_value.email
          : null,
      crypto_type:
        modal_data.notification_filter === "crypto"
          ? modal_data.sub_value.crypto_type.length === 5
            ? "all"
            : modal_data.sub_value.crypto_type
          : null,
      email_type: modal_data.email_type
        ? alert_type_modal === "multiple_email"
          ? modal_data.email_type
          : null
        : null,
      alert_file: "csv",
      no_of_posts: "None",
      tag_name: modal_data.tag_name ? modal_data.tag_name : null,
    };
    const data_brand_protection = {
      keyword_to_watch: val,
      is_protected: true,
      notification_sub_filter: "Default",
      user_id: modal_data.user_obj?.userid ?? null,
      interval_type,
      interval_number,
      notification_type,
      endpoint_type: alert_type_modal,
      alert_id: id,
      email_type: modal_data.email_type
        ? alert_type_modal === "multiple_email"
          ? modal_data.email_type
          : null
        : null,
      alert_file: modal_data.alert_file ? modal_data.alert_file : "csv",
      tag_name: modal_data.tag_name ? modal_data.tag_name : null,
    };
    set_update_loader(true);
    axios
      .post(
        "https://api.recordedtelegram.com/v2/create_notifications",
        modal_data.notification_type === "tag"
          ? data_tag
          : modal_data.notification_type === "brand_protection"
            ? data_brand_protection
            : data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        setTimeout(() => {
          set_admin_notification_data(res.data.data);
        }, 300);
        handle_success_message("Updated Sucessfully");
        set_update_loader(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        response_message_custom(err.response.data.errormsg, "error");
        set_update_loader(false);
      });
  };
  const handle_data_getter = () => {
    axios
      .get("https://api.recordedtelegram.com/v2/admin_notification", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        set_admin_notification_data(res.data);
      })
      .catch((err) => {
        try {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
        } catch {
          //Do nothing
        }
      });
  };
  const data_delete = (delete_id) => {
    const data = { hash_id: delete_id };
    axios
      .post(
        "https://api.recordedtelegram.com/v2/delete_user_notification",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        handle_success_message("Deleted Sucessfully");
        setTimeout(() => {
          set_admin_notification_data(res.data.data);
        }, 300);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };
  const handleClickSearchbar = () => {
    if (option_dropdown === "stealer_logs" && query_type === "bulk") {
      set_open_bulk_modal(true);
    }
    if (option_dropdown === "channel" && filter_type === "individual") {
      set_open_channel_logical_alert_modal(true);
    }
  };

  const handle_setting_modal_data = (val) => {
    if (val.notification_type !== "tag") {
      set_modal_data({
        ...val,
        tag_name: val.tag_name && val.tag_name !== "None" ? val.tag_name : "",
        channel_name:
          val.channel_obj && val.channel_obj !== "None"
            ? val.channel_obj.map((item) => item.channel_name)
            : [],
        email_type:
          val.email_type && val.email_type !== "None" ? val.email_type : "cc",
        stealer_category:
          val.notification_type === "stealer_logs"
            ? val.stealer_category !== null
              ? val.stealer_category
              : "off"
            : null,
      });
    } else if (
      val.notification_filter === "bank_card" ||
      val.notification_filter === "bin"
    ) {
      set_modal_data({
        ...val,
        tag_name: val.tag_name && val.tag_name !== "None" ? val.tag_name : "",
        email_type:
          val.email_type && val.email_type !== "None" ? val.email_type : "cc",
        sub_value: {
          bank_card_type:
            val.tag_obj[0].bank_card_type === "all"
              ? card_type_options
              : val.tag_obj[0].bank_card_type,
          exp_month:
            val.tag_obj[0].exp_month === "all"
              ? exp_month_options
              : val.tag_obj[0].exp_month,
          exp_year:
            val.tag_obj[0].exp_year === "all"
              ? exp_year_options
              : val.tag_obj[0].exp_year,
        },
      });
    } else if (val.notification_filter === "email") {
      set_modal_data({
        ...val,
        tag_name: val.tag_name && val.tag_name !== "None" ? val.tag_name : "",
        email_type:
          val.email_type && val.email_type !== "None" ? val.email_type : "cc",
        sub_value: {
          email:
            val.tag_obj[0].email_type === "all"
              ? email_options
              : val.tag_obj[0].email_type,
        },
      });
    } else if (val.notification_filter === "crypto") {
      set_modal_data({
        ...val,
        tag_name: val.tag_name && val.tag_name !== "None" ? val.tag_name : "",
        email_type:
          val.email_type && val.email_type !== "None" ? val.email_type : "cc",
        sub_value: {
          crypto_type:
            val.tag_obj[0].crypto_type === "all"
              ? crypto_options
              : val.tag_obj[0].crypto_type,
        },
      });
    } else {
      set_modal_data({
        ...val,
        tag_name: val.tag_name && val.tag_name !== "None" ? val.tag_name : "",
        email_type:
          val.email_type && val.email_type !== "None" ? val.email_type : "cc",
      });
    }
  };

  const val_change = (e) => {
    if (filter_type === "individual") {
      document.getElementById("srch_tab").value = "";
      set_search_val("");
      return;
    }
    set_search_val(e.target.value);
    if (e.target.value.length >= 2) {
      set_stat_loading(true);
      const data = { qtext: e.target.value, search_type: "alert" };
      axios
        .post("https://api.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const filter_data = res.data.filter((item) => item.link !== "None");
          set_search_data(filter_data);
          set_stat_loading(false);
        })
        .catch((err) => {
          try {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            set_stat_loading(false);
            set_search_error(err.response.data.errormsg);
          } catch {
            //Do nothing
          }
        });
    } else {
      set_search_data(null);
    }
  };
  const detail_row_data = (row) => {
    let category_length;
    if (
      ["file", "file_ext", "tag", "keyword"].includes(row.notification_type)
    ) {
      if (row.category_access === "all") {
        category_length = "all";
      } else {
        category_length = row.category_access.length;
      }
      return (
        <div>
          <span>{category_length}categories</span>
          <span>,search_type:{row.search_type}</span>{" "}
          <span>,alert_file:{row.alert_file}</span>
          {row.lang_obj && row.lang_obj.length > 0 ? (
            <span>,Translated : {row.lang_obj.length} language</span>
          ) : null}
        </div>
      );
    } else {
      return (
        <div>
          <span>search_type:{row.search_type}</span>{" "}
          <span>,alert_file:{row.alert_file}</span>
        </div>
      );
    }
  };

  const handle_query_row = (row) => {
    if (
      row.notification_type === "stealer_logs" &&
      row.stealer_category &&
      !row.keyword_entered
    ) {
      return `stealer category : ${row.stealer_category}`;
    } else if (Array.isArray(row.keyword_entered)) {
      return row.keyword_entered.toString();
    } else {
      return row.keyword_entered;
    }
  };
  return (
    <div className="wrapper">
      <GlobalStyles
        styles={{
          ".MuiInput-root svg": {
            color: "#1fcecb",
          },
        }}
      />
      <Grid container>
        <Grid item xs={0.5}>
          <Sidenav />
        </Grid>
        <Grid item xs={11.5}>
          <div id="content" className={"active"}>
            <section>
              <Adminmainnav />
              <div className="admin_notificaton_container">
                <Formik
                  initialValues={{ search: "" }}
                  validate={(values) => {
                    const errors = {};
                    const { search } = values;
                    const regexExp =
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
                    // For checking valid regular expression
                    const isRegExp = (string) => {
                      try {
                        new RegExp(string);
                      } catch (e) {
                        return false;
                      }
                      return true;
                    };
                    if (
                      stealer_logs_auto_notification(
                        option_dropdown,
                        post_category_stealer,
                        search,
                      ) === true
                    )
                      return;
                    if (search.length < 2) {
                      errors.search = "Keyword must be longer than 1 character";
                    }
                    if (
                      notification_filters !== "logical" &&
                      option_dropdown !== "channel"
                    ) {
                      const reg = /\s{2,}/g;
                      const verifier_conv = search.replaceAll(reg, " ");
                      const verifier = /\s(\b(and|or|AND|OR)\b)\s/g.exec(
                        verifier_conv,
                      );
                      if (verifier !== null && verifier.length > 0) {
                        errors.search =
                          "AND/OR operator are only allowed on logical feature";
                      }
                    }
                    if (
                      option_dropdown === "file_extension" &&
                      notification_filters === "Default"
                    ) {
                      if (search.startsWith(".") === false) {
                        errors.search =
                          "File extension should start with (.) character";
                      }
                      if (search.length > 8) {
                        errors.search =
                          "File extension should not exceed 8 characters";
                      }
                    }
                    if (
                      option_dropdown === "breach" &&
                      notification_filters_breach === "email"
                    ) {
                      if (regexExp.test(search) === false) {
                        errors.search = "please enter valid email";
                      }
                    }
                    if (
                      (option_dropdown === "keyword" ||
                        option_dropdown === "file" ||
                        option_dropdown === "file_extension") &&
                      notification_filters === "regex"
                    ) {
                      if (isRegExp(search) === false) {
                        errors.search = "Please enter valid Regular expression";
                      }
                    }

                    // validation for the tag alert
                    if (option_dropdown === "tag") {
                      if (notification_filters_tag === "bank_card") {
                        const error_msg = bank_card_validator(values.search);
                        if (error_msg) {
                          errors.search = error_msg;
                        }
                      } else if (notification_filters_tag === "ip_address") {
                        const error_msg = ip_address_validator(values.search);
                        if (error_msg) {
                          errors.search = error_msg;
                        }
                      } else if (notification_filters_tag === "phone") {
                        const error_msg = phone_validator(values.search);
                        if (error_msg) {
                          errors.search = error_msg;
                        }
                      } else if (notification_filters_tag === "crypto") {
                        const error_msg = crypto_validator(values.search);
                        if (error_msg) {
                          errors.search = error_msg;
                        }
                      } else if (notification_filters_tag === "email") {
                        const error_msg = email_validator(values.search);
                        if (error_msg) {
                          errors.search = error_msg;
                        }
                      } else if (notification_filters_tag === "bin") {
                        const error_msg = bin_validator(values.search);
                        if (error_msg) {
                          errors.search = error_msg;
                        }
                      }
                    }

                    return errors;
                  }}
                  onSubmit={(values, { resetForm }) => {
                    const { search } = values;
                    if (option_dropdown !== "channel") {
                      if (
                        stealer_logs_auto_notification(
                          option_dropdown,
                          post_category_stealer,
                          search,
                        ) === true
                      ) {
                        post_data("", "stealer_logs");
                      } else {
                        post_data(search.trim(), option_dropdown);
                      }
                    }
                    setTimeout(() => resetForm());
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched,
                  }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <div
                        className="notification_input"
                        style={{
                          columnGap: "10px",
                          display: "flex",
                          alignItems: "center",
                          width: "75%",
                          margin: "auto",
                        }}
                      >
                        <Stack direction="row" sx={{ mt: "-2%" }}>
                          <div
                            className="dropdown_notification"
                            style={{ border: "none" }}
                          >
                            <p className="alert_pref_select_label">
                              Choose User
                            </p>
                            <CustomDropdown
                              all_clients={all_clients}
                              set_all_clients={set_all_clients}
                              selected_user={selected_user}
                              set_selected_user={set_selected_user}
                            />
                          </div>
                          <div
                            className="dropdown_notification"
                            style={{ border: "none" }}
                          >
                            <p className="alert_pref_select_label">Filters</p>
                            <FormControl
                              sx={{
                                minWidth: 180,
                                border: "1px solid #e7e4e463",
                                borderRadius: "50px",
                              }}
                            >
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={option_dropdown}
                                onChange={handle_change_option}
                                sx={{ width: "100%", mt: 1 }}
                                disableUnderline
                                variant="standard"
                              >
                                <MenuItem value="keyword">Keyword</MenuItem>
                                <MenuItem value="channel">Channel</MenuItem>
                                <MenuItem value="file">File</MenuItem>
                                <MenuItem value="file_extension">
                                  File ext
                                </MenuItem>
                                <MenuItem value="tag">Tag</MenuItem>
                                <MenuItem value="breach">Breach</MenuItem>
                                <MenuItem value="stealer_logs">
                                  Stealer Logs
                                </MenuItem>
                                <MenuItem value="brand_protection">
                                  Brand Protection
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </Stack>
                        <div
                          className=" rounded input-group rounded-pill  shadow-sm mb-1 notification_search_box"
                          style={{
                            padding: "0.1rem 0.4rem",
                            width: "100%",
                            alignSelf: "flex-start",
                          }}
                        >
                          <input
                            id="srch_tab"
                            type="search"
                            placeholder={
                              notification_filters === "logical"
                                ? "e.g: test1 and test2 or (test3 and test4)"
                                : stealer_logs_auto_notification(
                                  option_dropdown,
                                  post_category_stealer,
                                  values.search,
                                ) === false
                                  ? option_dropdown === "brand_protection"
                                    ? "Please separate the queries with commas for bulk insertion."
                                    : "Please type your Query."
                                  : "Please type your Query or press enter for all alert."
                            }
                            name="search"
                            value={values.search}
                            onChange={(e) => {
                              handleChange(e);
                              option_dropdown === "channel" && val_change(e);
                            }}
                            onClick={() => handleClickSearchbar(values.search)}
                            onBlur={handleBlur}
                            className="form-control border-0 new-dk"
                            style={{
                              border: "none",
                              outline: "none",
                              boxShadow: "none",
                            }}
                          />

                          {(option_dropdown === "keyword" ||
                            option_dropdown === "file" ||
                            option_dropdown === "file_extension") &&
                            notification_filters === "logical" ? (
                            <span
                              data-toggle="modal"
                              data-target="#help_logical"
                            >
                              <Tooltip title="Logical Input Guidance">
                                <HelpIcon
                                  sx={{
                                    color: "white",
                                    mt: 0.7,
                                    cursor: "pointer",
                                  }}
                                />
                              </Tooltip>
                            </span>
                          ) : null}
                          {option_dropdown === "keyword" &&
                            notification_filters === "Default" ? (
                            <Tooltip title="choose language">
                              <TranslateIcon
                                onClick={() => set_open_translate(true)}
                                sx={{
                                  color: "rgb(56 211 175)",
                                  mr: 0.3,
                                  mt: 0.7,
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          ) : null}
                          <button
                            id="button-addon1"
                            type="submit"
                            style={{
                              outline: "none",
                              outlineWidth: 0,
                              boxShadow: "none",
                            }}
                            className="btn btn-link"
                            disabled={
                              stealer_logs_auto_notification(
                                option_dropdown,
                                post_category_stealer,
                                values.search,
                              ) === false
                                ? !(values.search.length > 1)
                                : false
                            }
                          >
                            <FontAwesomeIcon
                              style={{ color: "#1FCECB" }}
                              icon={faPlus}
                              size="1x"
                            />
                          </button>
                        </div>
                        <button
                          className="searchbar-btn searchbar-btn-main"
                          onClick={() => set_show_filters(!show_filters)}
                          type="button"
                        >
                          {" "}
                          <Tooltip title="Filters">
                            <GridViewIcon
                              sx={{ color: "#dcf3ff", marginBottom: "0.5rem" }}
                            />
                          </Tooltip>{" "}
                        </button>
                      </div>
                      {show_filters === false &&
                        errors.search &&
                        touched.search ? (
                        <div className="user_search_validation">
                          {errors.search}
                        </div>
                      ) : null}

                      {search_val.length < 2 &&
                        show_filters === false &&
                        !errors.search &&
                        !touched.search ? (
                        <span style={{ color: "#dcf3ff", fontSize: "14px" }}>
                          {" "}
                          <IconContext.Provider
                            value={{ ize: 18, color: "#f15e5e" }}
                          >
                            <FaInfoCircle style={{ marginRight: "6px" }} />
                          </IconContext.Provider>
                          {`Insert the ${option_dropdown === "channel"
                            ? "channel"
                            : "keyword"
                            } on the search box and press enter for  the notification. ${option_dropdown === "tag"
                              ? "For regex, enclose query within two forward slashes / /."
                              : ""
                            }`}
                        </span>
                      ) : null}

                      <div style={{ margin: "auto" }}>
                        {update_loader === true ? <CircularProgress /> : null}
                      </div>
                    </form>
                  )}
                </Formik>
                {admin_notification_data.length > 0 && (
                  <div className="alert_pref_table_container credentail_insertion_table_container">
                    <table>
                      <thead>
                        <tr>
                          <th style={{ width: "15%" }}>Query</th>
                          <th style={{ width: "10%" }}>Notification Type</th>
                          <th style={{ width: "10%" }}>User</th>
                          <th style={{ width: "5%" }}>Interval Type</th>
                          <th style={{ width: "5%" }}>Interval Duration</th>
                          <th style={{ width: "5%" }}>Alert Type</th>
                          <th style={{ width: "5%" }}>File Type</th>
                          <th style={{ width: "20%" }}>Details</th>
                          <th style={{ width: "5%" }}>Edit</th>
                          <th style={{ width: "6%" }}>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {admin_notification_data.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td>{handle_query_row(val)}</td>
                              <td>{val.notification_type}</td>
                              <td>{val.user_obj?.username ?? "None"}</td>
                              <td>{val.interval_type}</td>
                              <td>{val.interval_number}</td>
                              <td>{val.endpoint_type}</td>
                              <td>{val.alert_file}</td>
                              <td>{detail_row_data(val)}</td>
                              <td>
                                <button
                                  className="credential_btn credential_btn_edit"
                                  data-toggle="modal"
                                  data-target="#updatefilters"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handle_setting_modal_data(val);
                                    setKeyword_modal(val.search_type);
                                    setPostCategory_modal(
                                      val.category_access === "all"
                                        ? session_category_array
                                        : val.category_access,
                                    );
                                    set_interval_type(val.interval_type);
                                    set_interval_number(val.interval_number);
                                    set_notificaton_filters_modal(
                                      val.notification_filter,
                                    );
                                    set_alert_type_modal(val.endpoint_type);
                                    select_default_languages(val);
                                  }}
                                >
                                  Edit
                                </button>
                              </td>
                              <td>
                                <button
                                  className="credential_btn credential_btn_delete"
                                  onClick={() => {
                                    data_delete(val.id);
                                  }}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                {search_val.length >= 2 &&
                  option_dropdown === "channel" &&
                  show_filters === false ? (
                  <div
                    className="autocom-box channel_list_container m-auto"
                    style={{ width: "62%", position: "static" }}
                  >
                    {stat_loading ? (
                      <RollerAnimation />
                    ) : search_error ? (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "larger",
                          color: "white",
                          marginTop: "10%",
                        }}
                      >
                        {search_error}
                      </div>
                    ) : search_data !== null && search_data.length === 0 ? (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "larger",
                          color: "white",
                          marginTop: "10%",
                        }}
                      >
                        No Data to display
                      </div>
                    ) : (
                      <>
                        <h4
                          style={{
                            paddingLeft: "1rem",
                            marginLeft: "15px",
                            marginTop: "5px",
                            fontSize: "18px",
                            color: "whitesmoke",
                          }}
                        >
                          List Of Channels
                        </h4>
                        <ul>
                          {search_data !== null &&
                            search_data.map((val, id) => (
                              <li key={id}>
                                <div className="row">
                                  <div
                                    className="col-md-8"
                                    style={{ textAlign: "left" }}
                                  >
                                    {" "}
                                    <img
                                      style={{
                                        width: "36px",
                                        height: "36px",
                                        padding: "2px",
                                        border: "2px solid #f4f4f4",
                                      }}
                                      className="img-fluid rounded-circle"
                                      src={
                                        val.channel_id !== "None"
                                          ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                      }
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                      }}
                                      alt="User"
                                    />
                                    <span
                                      className="channels_list_map"
                                      style={{
                                        marginLeft: "10px",
                                        color: "#dcf3ff",
                                      }}
                                    >
                                      {val.channel_name}
                                    </span>
                                  </div>
                                  <div
                                    className="col-md-4"
                                    style={{
                                      textAlign: "right",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <button
                                      style={{
                                        borderRadius: "40px",
                                        background: "#d03940",
                                        color: "white",
                                        lineHeight: "1",
                                      }}
                                      className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                      onClick={() => {
                                        post_data(
                                          val.channel_name !== "None"
                                            ? val.channel_name
                                            : val.link !== "None"
                                              ? val.link
                                              : val.channel_id,
                                          "channel",
                                        );
                                        set_search_val("");
                                      }}
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </>
                    )}
                  </div>
                ) : null}
                {show_filters ? (
                  <div className="notification_filters">
                    <FiltersAlertAdmin
                      set_show_filters={set_show_filters}
                      postCategory={postCategory}
                      setPostCategory={setPostCategory}
                      keyword={keyword}
                      setKeyword={setKeyword}
                      interval_type_filter={interval_type_filter}
                      set_interval_type_filter={set_interval_type_filter}
                      set_interval_number_filter={set_interval_number_filter}
                      interval_number_filter={interval_number_filter}
                      option_dropdown={option_dropdown}
                      notification_filters={notification_filters}
                      set_notification_filters={set_notification_filters}
                      notification_filters_breach={notification_filters_breach}
                      set_notification_filters_breach={
                        set_notificaton_filters_breach
                      }
                      notification_filters_tag={notification_filters_tag}
                      set_notification_filters_tag={
                        set_notification_filters_tag
                      }
                      alert_type={alert_type}
                      set_alert_type={set_alert_type}
                      filter_type={filter_type}
                      set_filter_type={set_filter_type}
                      exp_year={exp_year}
                      set_exp_year={set_exp_year}
                      exp_year_options={exp_year_options}
                      exp_month={exp_month}
                      set_exp_month={set_exp_month}
                      exp_month_options={exp_month_options}
                      card_type={card_type}
                      set_card_type={set_card_type}
                      card_type_options={card_type_options}
                      crypto_provider={crypto_provider}
                      set_crypto_provider={set_crypto_provider}
                      crypto_options={crypto_options}
                      email_provider={email_provider}
                      set_email_provider={set_email_provider}
                      email_options={email_options}
                      translate_report={translate_report}
                      set_translate_report={set_translate_report}
                      alert_file_type={alert_file_type}
                      set_alert_file_type={set_alert_file_type}
                      alert_post_no={alert_post_no}
                      set_alert_post_no={set_alert_post_no}
                      multiple_email_type={multiple_email_type}
                      set_multiple_email_type={set_multiple_email_type}
                      notification_filters_stealer={
                        notification_filters_stealer
                      }
                      set_notificaton_filters_stealer={
                        set_notificaton_filters_stealer
                      }
                      keyword_stealer={keyword_stealer}
                      setKeyword_stealer={setKeyword_stealer}
                      post_category_stealer={post_category_stealer}
                      set_post_category_stealer={set_post_category_stealer}
                      query_type={query_type}
                      set_query_type={set_query_type}
                      set_open_bulk_modal={set_open_bulk_modal}
                    />
                  </div>
                ) : null}
              </div>
            </section>
          </div>
        </Grid>
      </Grid>
      {open_translate === true ? (
        <TranslateApi
          open_translate={open_translate}
          set_open_translate={set_open_translate}
          language_array={language_array}
          set_language_array={set_language_array}
          checked_exclude={checked_exclude}
          set_checked_exclude={set_checked_exclude}
        />
      ) : null}
      {open_bulk_modal ? (
        <BulkModal
          open_bulk_modal={open_bulk_modal}
          set_open_bulk_modal={set_open_bulk_modal}
          post_data={post_data}
          option_dropdown={option_dropdown}
          alert_status="admin_alert"
        />
      ) : null}
      {open_channel_logical_alert_modal === true ? (
        <ChannelLogicalAlert
          open_channel_logical_alert_modal={open_channel_logical_alert_modal}
          set_open_channel_logical_alert_modal={
            set_open_channel_logical_alert_modal
          }
          keyword={keyword}
          setKeyword={setKeyword}
          interval_type_filter={interval_type_filter}
          set_interval_type_filter={set_interval_type_filter}
          interval_number_filter={interval_number_filter}
          set_interval_number_filter={set_interval_number_filter}
          alert_type={alert_type}
          set_alert_type={set_alert_type}
          channels_list={channels_list}
          set_channels_list={set_channels_list}
          post_data={post_data}
          alert_file_type={alert_file_type}
          set_alert_file_type={set_alert_file_type}
          alert_post_no={alert_post_no}
          set_alert_post_no={set_alert_post_no}
          multiple_email_type={multiple_email_type}
          set_multiple_email_type={set_multiple_email_type}
          alert_status="admin_alert"
        />
      ) : null}
      {open_translate_modal === true ? (
        <TranslateApiUpdate
          open_translate={open_translate_modal}
          set_open_translate={set_open_translate_modal}
          language_array={language_array_modal}
          set_language_array={set_language_array_modal}
          checked_exclude={checked_exclude_modal}
          set_checked_exclude={set_checked_exclude_modal}
          modal_data={modal_data}
          set_modal_data={set_modal_data}
          select_top_lang={select_top_lang_modal}
          set_select_top_lang={set_select_top_lang_modal}
        />
      ) : null}
      <UpdateAdminAlertFilters
        modal_data={modal_data}
        set_modal_data={set_modal_data}
        keyword_modal={keyword_modal}
        setKeyword_modal={setKeyword_modal}
        postCategory_modal={postCategory_modal}
        setPostCategory_modal={setPostCategory_modal}
        interval_type={interval_type}
        set_interval_type={set_interval_type}
        interval_number={interval_number}
        set_interval_number={set_interval_number}
        notification_filters_modal={notification_filters_modal}
        set_notificaton_filters_modal={set_notificaton_filters_modal}
        alert_type_modal={alert_type_modal}
        set_alert_type_modal={set_alert_type_modal}
        language_array_modal={language_array_modal}
        set_language_array_modal={set_language_array_modal}
        checked_exclude_modal={checked_exclude_modal}
        set_checked_exclude_modal={set_checked_exclude_modal}
        post_data_modal={post_data_modal}
        set_open_translate_modal={set_open_translate_modal}
      />
      <ToastContainer />
    </div>
  );
}

export default AddCredentialAlert;
