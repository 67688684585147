import PropTypes from "prop-types";
import React from "react";
import TransparentContainer from "../sections/Charts/TransparentContainer";
import "../../../css/Loader/loader.css";

export function DotsLoading() {
  return (
    <div className="keyword_stats_loading">
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
    </div>
  );
}
export function BallLoader() {
  return (
    <div className="card-body scroll-custom" style={{ height: "325px" }}>
      {" "}
      <div className="blocks list-tab">
        <div className="block orange" />
        <div className="block blue" />
      </div>
      <h6 style={{ marginTop: "-25px", color: "white" }}>
        Data may take time to load
      </h6>
    </div>
  );
}

export function NoData() {
  return (
    <div className="card-body" style={{ height: "450px" }}>
      <h2 style={{ marginTop: "150px", color: "#9bbcd1" }}>
        No Data to Display
      </h2>
    </div>
  );
}

export function RoundAnimationLoader() {
  return (
    <div className="round_loader_div">
      <span className="round_loader" />
    </div>
  );
}
export function RollerAnimation() {
  return (
    <div className="container" style={{ marginTop: "2rem" }}>
      <div className="row">
        <div className="col-md-12 text-center text-muted">
          <div className="lds-roller">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
          <h4 className="roller_animation_text">Please Wait ...</h4>
        </div>
      </div>
    </div>
  );
}
export function ErrorMsg({ error }) {
  return (
    <div className="error_msg_component">
      <h4 style={{ color: "#9bbcd1" }}>{error}</h4>
    </div>
  );
}
ErrorMsg.propTypes = {
  error: PropTypes.string,
};

export function StatsErrorMsg({ error }) {
  return (
    <TransparentContainer
      container={
        <div>
          <div className="widget-chart p-0">
            <div
              className="stats_error_msg_container"
              style={{ height: "450px" }}
            >
              <h4 className="stats_error_msg_container_text">{error}</h4>
            </div>
          </div>
        </div>
      }
    />
  );
}
StatsErrorMsg.propTypes = {
  error: PropTypes.string,
};
