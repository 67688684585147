import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import ModalFlex from "../display_flex/ModalFlex";
import { Stack, Grid } from "@mui/material";
import { participants, link_website } from "../../../Assets/globalfunctions";
import "../../../css/Modal/modal.css";

function ModalContainer({ openModal, setOpenModal, selectedRow }) {
  return (
    <div>
      {selectedRow !== null ? (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="seemore_container_modal">
            <Box className="container_modal_child">
              <div className="modal-heading-parent-closeBtn">
                <button
                  className="modal-heading-closeBtn"
                  onClick={() => setOpenModal(false)}
                >
                  <CancelIcon
                    sx={{ color: "#eb0000 ", cursor: "pointer", mr: 1 }}
                  />
                </button>
              </div>
              <Grid
                container
                sx={{ width: "95%", margin: "auto" }}
                justifyContent="space-between"
              >
                <Grid item xs={3}>
                  <Stack alignItems="flex-start">
                    <Typography
                      id="modal-modal-title-seemore"
                      variant="h6"
                      component="h2"
                      sx={{ color: "yellow", textAlign: "justify" }}
                    >
                      {selectedRow.conv_name}
                    </Typography>
                    {selectedRow.link !== "None" &&
                    selectedRow.link !== null ? (
                      <button
                        className="link_post_seemore_modal"
                        onClick={() => link_website(selectedRow.link)}
                      >
                        {selectedRow.link}
                      </button>
                    ) : null}
                  </Stack>
                </Grid>
                {selectedRow.about ? (
                  <Grid item xs={4}>
                    <div className="">
                      <Typography
                        className="modal_desc_box_heading"
                        variant="body2"
                      >
                        Description
                      </Typography>
                      <Typography
                        className="modal_desc_box_about_content"
                        variant="body2"
                      >
                        {selectedRow.about.slice(0, 300)}
                      </Typography>
                    </div>
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  <div className="modal-desc-userDetail">
                    <div>
                      <Typography
                        className="modal_desc_box_heading"
                        variant="body2"
                      >
                        Name
                      </Typography>
                      <Typography
                        variant="body2"
                        className="modal_desc_box_content"
                      >
                        {selectedRow.conv_name}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        className="modal_desc_box_heading"
                        variant="body2"
                      >
                        ID
                      </Typography>
                      <Typography
                        className="modal_desc_box_content"
                        variant="body2"
                      >
                        {selectedRow.id}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        className="modal_desc_box_heading"
                        variant="body2"
                      >
                        Channel ID
                      </Typography>
                      <Typography
                        className="modal_desc_box_content"
                        variant="body2"
                      >
                        {selectedRow.to_id}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        className="modal_desc_box_heading"
                        variant="body2"
                      >
                        Message ID
                      </Typography>
                      <Typography
                        className="modal_desc_box_content"
                        variant="body2"
                      >
                        {selectedRow.msgid}
                      </Typography>
                    </div>
                    {selectedRow.filename && selectedRow.filename !== "None" ? (
                      <div>
                        <Typography
                          className="modal_desc_box_heading"
                          variant="body2"
                        >
                          filename
                        </Typography>
                        <Typography
                          className="modal_desc_box_content"
                          variant="body2"
                        >
                          {selectedRow.filename.replace(
                            "SIZE_EXCEEDED_NOT_DOWNLOADED_",
                            "",
                          )}
                        </Typography>
                      </div>
                    ) : null}
                    <div>
                      <Typography
                        className="modal_desc_box_heading"
                        variant="body2"
                      >
                        Subscribers
                      </Typography>
                      <Typography
                        className="modal_desc_box_content"
                        variant="body2"
                      >
                        {participants(selectedRow.participants_count).subs}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        className="modal_desc_box_heading"
                        variant="body2"
                      >
                        Online members
                      </Typography>
                      <Typography
                        className="modal_desc_box_content"
                        variant="body2"
                      >
                        {selectedRow.online_count}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        className="modal_desc_box_heading"
                        variant="body2"
                      >
                        Forwarder ID
                      </Typography>
                      <Typography
                        className="modal_desc_box_content"
                        variant="body2"
                      >
                        {selectedRow.forwarderid}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div id="modal-modal-description" className="modal-description">
                <ModalFlex Data={selectedRow} />
              </div>
            </Box>
          </Box>
        </Modal>
      ) : null}
    </div>
  );
}
ModalContainer.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  selectedRow: PropTypes.object,
};

export default ModalContainer;
