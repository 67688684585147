import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconContext } from "react-icons";
import { FaBookReader, FaBoxes, FaMailBulk, FaMask } from "react-icons/fa";
import { participants } from "../Assets/globalfunctions";
import { connect } from "react-redux";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
// import ModalContainer from '../modalContainer/ModalContainer';
import Highlighter from "react-highlight-words";
import { GlobalStyles, Stack } from "@mui/material";
import { get_search_data, change_inv_func } from "../actions/userAction";
import { get_audience } from "../actions/statsAction";
import Userunfixednavbar from "./Userunfixednavbar";
import Usersidenav from "./Usersidenav";
import { handle_session_get } from "../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../utils/Validation/Token/token_checker";
function Userchannel({ users, stats, get_audience }) {
  const token = handle_session_get("Token");
  const [paginationValue, setPaginationValue] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [user_channel_data, set_user_channel_data] = useState([]);
  const getSearchedData = "python";

  useEffect(() => {
    const url = window.location.href;
    const new_url = containsEncodedComponents(url);
    const channel_name = new_url.split("?")[1].split("page=")[1];
    set_channel_name(channel_name);
    get_audience(channel_name);
    setTimeout(() => {
      axios
        .post(
          "https://api.recordedtelegram.com/individual_channel_posts",
          { qtext: channel_name },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          const { data } = response;
          set_user_channel_data(data);
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
        });
    }, 1000);
  }, []);

  // function for the date
  const date_fm = (dt) => {
    if (dt !== undefined) {
      const new_dt = dt.split("T");
      const sp_dt = new_dt[0].split("-");
      const new_sp_month = `0${parseInt(sp_dt[1]) - 1}`;

      const date = new Date(sp_dt[0], new_sp_month, sp_dt[2]); // 2009-11-10

      const month = date.toLocaleString("default", { month: "long" });
      const mod_month = month.substring(0, 3);
      const ret_dt = `${mod_month}-${sp_dt[2]}-${sp_dt[0]}`;
      return ret_dt;
    }
    return null;
  };

  // Function for the type is group or category
  const channel_type = (typ) => {
    if (typ !== undefined) {
      const channel_type = typ === "True" ? "Group" : "Channel";
      return channel_type;
    }
    return null;
  };

  // Highlight message for the post and file
  const highlight_message = (words) => words;
  const columns_post_file = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (cellValues) => (
        <Highlighter
          highlightClassName="highlightclass"
          searchWords={[getSearchedData]}
          autoEscape
          textToHighlight={highlight_message(cellValues.row.name)}
          // textToHighlight={(cellValues.row.message)}
          className="text_hidden"
        />
      ),
    },

    { field: "date", headerName: "Date", width: 110 },
    {
      field: "subscribers",
      headerName: "Subs",
      width: 80,
    },

    {
      field: "user_id",
      headerName: "User Id",
      width: 120,

      renderCell: (cellValues) => (
        <>
          <Highlighter
            highlightClassName="highlightclass"
            searchWords={[getSearchedData]}
            autoEscape
            textToHighlight={highlight_message(cellValues.row.user_id)}
            // textToHighlight={(cellValues.row.message)}
            className="text_hidden"
          />
          {/* <p className='text_hidden'>{cellValues.row.message}</p> */}
        </>
      ),
    },
    {
      field: "user_name",
      headerName: "Username",
      width: 120,

      renderCell: (cellValues) => (
        <>
          <Highlighter
            highlightClassName="highlightclass"
            searchWords={[getSearchedData]}
            autoEscape
            textToHighlight={highlight_message(cellValues.row.user_name)}
            // textToHighlight={(cellValues.row.message)}
            className="text_hidden"
          />
          {/* <p className='text_hidden'>{cellValues.row.message}</p> */}
        </>
      ),
    },
    {
      field: "seemore",
      headerName: "Message",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (cellValues) => (
        <Highlighter
          highlightClassName="highlightclass"
          searchWords={[getSearchedData]}
          autoEscape
          textToHighlight={highlight_message(cellValues.row.message)}
          // textToHighlight={(cellValues.row.message)}
          className="text_hidden"
        />
      ),
    },
    {
      field: "views",
      headerName: "Views",
      width: 80,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: false,
      filterable: false,
      width: 80,
      renderCell: (cellValues) => <Stack>{cellValues.row.type}</Stack>,
    },
  ];

  const filterData_post_file =
    user_channel_data.length > 0
      ? user_channel_data.map((item, index) => ({
          id: index,
          name: item.conv_name,
          link: item.link,
          date: date_fm(item.date),
          subscribers: participants(item.participants_count).subs,
          user_id: item.id,
          user_name: item.userdetails,
          message: item.message,
          views: item.views ? item.views : 0,
          type: channel_type(item.is_group),
          fileextension: item.fileext,
          filename: item.filename,
          all_data: item,
        }))
      : null;

  const rows = filterData_post_file;
  const cloneData = Array.isArray(rows) ? [...rows] : [];
  const new_state = cloneData.slice(
    (parseInt(paginationValue) - 1) * pageSize,
    parseInt(paginationValue) * pageSize,
  );
  const new_total_page = Math.ceil(rows.length / pageSize);

  const handlePaginationChange = (event, value) => {
    setPaginationValue(value);
  };
  // Select the required page size
  const page_size_fixer = (newPageSize) => {
    setPageSize(newPageSize);
    setPaginationValue(Math.ceil(rows.length / newPageSize));
  };
  const [channel_name, set_channel_name] = useState(null);
  const containsEncodedComponents = (x) => {
    try {
      return decodeURI(x);
    } catch {
      return x;
    }
  };

  const numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
      return `${(num / 1000).toFixed(1)}K`; // convert to K for number from > 1000 < 1 million
    }
    if (num > 1000000) {
      return `${(num / 1000000).toFixed(1)}M`; // convert to M for number from > 1 million
    }
    if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  };
  return (
    <div className="wrapper">
      <GlobalStyles
        styles={{
          ".MuiDataGrid-menuList": {
            backgroundColor: "#394e5a",
          },
          ".MuiMenu-list ": {
            backgroundColor: "#394e5a",
          },
          ".MuiDataGrid-menuList li": {
            color: "#dcf3ff",
          },
          ".MuiMenu-list li": {
            color: "#dcf3ff",
          },
          ".MuiPaginationItem-root": {
            color: "#9bbcd1",
            backgroundColor: "#1e2a31",
          },
        }}
      />
      <Usersidenav />
      {/* Page Content  */}
      <div id="content" className={users.sidebar_activ ? "active" : null}>
        <section>
          <Userunfixednavbar />
          <div className="container">
            <div
              className={users.dark_theme ? "mb-3 card dk-mode" : "mb-3 card"}
              style={{ marginTop: "50px" }}
            >
              <div
                style={
                  users.dark_theme
                    ? { background: "#2b3c46", color: "#dcf3ff" }
                    : { background: "white" }
                }
                className="card-header-tab card-header main-tab"
              >
                <div
                  style={{ marginLeft: "35px" }}
                  className="card-header-title font-size-lg text-capitalize font-weight-normal"
                >
                  {channel_name !== null ? channel_name : "..."}
                </div>
              </div>

              <div className="no-gutters row" style={{ marginLeft: "35px" }}>
                <div className="col-sm-6 col-md-3 col-xl-3">
                  <div
                    style={{
                      padding: "1rem",
                      marginBottom: "0px",
                      boxShadow: "none",
                    }}
                    className="card no-shadow rm-border bg-transparent widget-chart text-left"
                  >
                    <div className="icon-wrapper rounded-circle">
                      <div
                        style={{ background: "#d92550" }}
                        className="icon-wrapper-bg opacity-10 "
                      />
                      <i style={{ zIndex: "10", marginLeft: "12px" }}>
                        <IconContext.Provider
                          value={{
                            size: 30,
                            marginTop: "10px",
                            marginLeft: "12px",
                            color: "white",
                          }}
                        >
                          <FaMailBulk />
                        </IconContext.Provider>
                      </i>
                    </div>

                    <div className="widget-chart-content">
                      <div className="widget-subheading">Total Post</div>
                      <div className="widget-numbers">
                        {stats.total_post !== null ? stats.total_post : "..."}
                      </div>
                    </div>
                  </div>

                  <div className="divider m-0 d-md-none d-sm-block" />
                </div>
                <div className="col-sm-6 col-md-3 col-xl-3">
                  <div
                    style={{
                      padding: "1rem",
                      marginBottom: "0px",
                      boxShadow: "none",
                    }}
                    className="card no-shadow rm-border bg-transparent widget-chart text-left"
                  >
                    <div className="icon-wrapper rounded-circle">
                      <div
                        style={{ background: "#58D68D" }}
                        className="icon-wrapper-bg opacity-10 "
                      />
                      <i style={{ zIndex: "10", marginLeft: "12px" }}>
                        <IconContext.Provider
                          value={{
                            size: 30,
                            marginTop: "10px",
                            marginLeft: "12px",
                            color: "white",
                          }}
                        >
                          <FaBoxes />
                        </IconContext.Provider>
                      </i>
                    </div>

                    <div className="widget-chart-content">
                      <div className="widget-subheading">Total Post Views</div>
                      <div className="widget-numbers">
                        {stats.total_views !== null
                          ? numFormatter(stats.total_views)
                          : "..."}
                      </div>
                    </div>
                  </div>

                  <div className="divider m-0 d-md-none d-sm-block" />
                </div>

                <div className="col-sm-6 col-md-3 col-xl-3">
                  <div
                    style={{
                      padding: "1rem",
                      marginBottom: "0px",
                      boxShadow: "none",
                    }}
                    className="card no-shadow rm-border bg-transparent widget-chart text-left"
                  >
                    <div className="icon-wrapper rounded-circle">
                      <div
                        style={{ background: "#2980B9" }}
                        className="icon-wrapper-bg opacity-10 "
                      />
                      <i style={{ zIndex: "10", marginLeft: "12px" }}>
                        <IconContext.Provider
                          value={{
                            size: 30,
                            marginTop: "10px",
                            marginLeft: "12px",
                            color: "white",
                          }}
                        >
                          <FaBookReader />
                        </IconContext.Provider>
                      </i>
                    </div>

                    <div className="widget-chart-content">
                      <div className="widget-subheading">Total Subs</div>
                      <div className="widget-numbers">
                        {stats.audience !== null
                          ? stats.audience !== "None"
                            ? stats.audience.subs
                            : "..."
                          : "..."}
                      </div>
                    </div>
                  </div>

                  <div className="divider m-0 d-md-none d-sm-block" />
                </div>
                <div className="col-sm-6 col-md-3 col-xl-3">
                  <div
                    style={{
                      padding: "1rem",
                      marginBottom: "0px",
                      boxShadow: "none",
                    }}
                    className="card no-shadow rm-border bg-transparent widget-chart text-left"
                  >
                    <div className="icon-wrapper rounded-circle">
                      <div
                        style={{ background: "#34495E" }}
                        className="icon-wrapper-bg opacity-10 "
                      />
                      <i style={{ zIndex: "10", marginLeft: "12px" }}>
                        <IconContext.Provider
                          value={{
                            size: 30,
                            marginTop: "10px",
                            color: "white",
                          }}
                        >
                          <FaMask />
                        </IconContext.Provider>
                      </i>
                    </div>

                    <div className="widget-chart-content">
                      <div className="widget-subheading">Total Files Post</div>
                      <div className="widget-numbers">
                        {stats.audience !== null
                          ? stats.audience.files === "None"
                            ? 0
                            : stats.audience.files
                          : "..."}
                      </div>
                    </div>
                  </div>

                  <div className="divider m-0 d-md-none d-sm-block" />
                </div>
              </div>
            </div>
            {user_channel_data !== null ? (
              user_channel_data.length > 0 ? (
                <>
                  <span
                    className={
                      users.dark_theme ? "dk-mode line-headmid" : "line-headmid"
                    }
                  >
                    {" "}
                    <h2
                      style={{ fontSize: "20px" }}
                      className={
                        users.dark_theme ? "dk-mode txt-line" : "txt-line"
                      }
                    >
                      {channel_name} Posts
                    </h2>
                  </span>
                  <div className="main__table">
                    <GlobalStyles
                      styles={{
                        ".MuiDataGrid-menuList": {
                          backgroundColor: "#394e5a",
                        },
                        ".MuiMenu-list ": {
                          backgroundColor: "#394e5a",
                        },
                        ".MuiDataGrid-menuList li": {
                          color: "#dcf3ff",
                        },
                        ".MuiMenu-list li": {
                          color: "#dcf3ff",
                        },
                        ".MuiPaginationItem-root": {
                          color: "#9bbcd1",
                          backgroundColor: "#1e2a31",
                        },
                      }}
                    />

                    {/* <div className='divider_line'></div> */}
                    <DataGrid
                      rows={new_state}
                      columns={columns_post_file}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        page_size_fixer(newPageSize)
                      }
                      rowsPerPageOptions={[25, 50, 75, 100]}
                      autoHeight
                      columnVisibilityModel={{
                        id: false,
                      }}
                      rowHeight={65}
                    />
                    <Pagination
                      count={new_total_page}
                      color="primary"
                      page={paginationValue}
                      onChange={handlePaginationChange}
                      size="small"
                    />
                  </div>
                </>
              ) : users.rate_limit_status ? (
                <div className="row" style={{ marginTop: "12%" }}>
                  <div className="col-md-12 text-center text-muted">
                    <h2>You have reached your Daily Limit</h2>
                    <h4>Please Contact the Service Provider</h4>
                  </div>
                </div>
              ) : (
                <div className="row" style={{ marginTop: "12%" }}>
                  <div className="col-md-12 text-center text-muted">
                    <h2>No Data to Display</h2>
                  </div>
                </div>
              )
            ) : null}
          </div>
        </section>
      </div>
      <ToastContainer />
    </div>
  );
}
Userchannel.propTypes = {
  users: PropTypes.object,
  stats: PropTypes.object,
  get_audience: PropTypes.func,
};
const mapStateToProps = (state) => ({
  users: state.users,
  stats: state.stats,
});

export default connect(mapStateToProps, {
  get_audience,
  get_search_data,
  change_inv_func,
})(Userchannel);
