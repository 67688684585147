import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import axios from "axios";
import Adminsidenav from "./Adminsidenav";
import Adminmainnav from "./Adminmainnav";
import { handle_token_expire } from "../../utils/Validation/Token/token_checker";
import {
  handle_success_message,
  handle_error_message,
} from "../../utils/ResponseMessage/response_message";
function AutoCredintial({ admin_reducer }) {
  const [email, set_email] = useState("");
  const send_cred = () => {
    toast.configure();
    const token = localStorage.getItem("Token");
    axios
      .post(
        "https://api.recordedtelegram.com/v2/autocredentials",
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        handle_success_message("Email sucessfully sent");
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };
  return (
    <div className="wrapper">
      <Adminsidenav />
      <div id="content" className={admin_reducer.boot_active ? "active" : null}>
        <section>
          <Adminmainnav />
          <div>
            <main className="d-flex align-items-center min-vh-100 py-3 py-md-0">
              <div className="container " style={{ marginTop: "-160px" }}>
                <div className="login-card ">
                  <ToastContainer />
                  <div className="col-md-12">
                    <div className="card-body">
                      <p className="login_form_heading">
                        Setup New User Account
                      </p>
                      <Formik
                        initialValues={{ email: "" }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string()
                            .email("Please enter valid email")
                            .required("Email is required for registration"),
                        })}
                        onSubmit={(values) => {
                          const new_email = values.email;
                          set_email(new_email);
                          document.getElementById(
                            "showdeletebutton",
                          ).className = "modal fade show";
                          document.getElementById(
                            "showdeletebutton",
                          ).style.display = "block";
                        }}
                      >
                        {({
                          values,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="regex_add_div">
                              <label
                                htmlFor="email"
                                className="login_form_label"
                              >
                                Email
                              </label>
                              <input
                                type="text"
                                name="email"
                                id="email"
                                className="global_input_box w-100"
                                placeholder="Email Address Here"
                                required
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete="off"
                              />
                              <ErrorMessage
                                component="div"
                                name="email"
                                className="login_form_error_msg"
                              />
                            </div>

                            <input
                              name="login"
                              id="login"
                              className="login-form-control-btn"
                              type="submit"
                              value="Setup"
                              defaultValue="Setup"
                            />
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <div
            className="modal fade"
            id="showdeletebutton"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="showdeletebutton"
            aria-hidden="true"
            style={{ background: "rgba(29, 40, 53, .95)" }}
          >
            <div
              className="modal-dialog modal-confirm dk-mode"
              style={{ background: "#2b3c46" }}
            >
              <div className="modal-content dk-mode">
                <div className="modal-header flex-column">
                  <h4 className="modal-title w-100">Are you sure?</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    style={{ color: "red" }}
                    onClick={(e) => {
                      e.preventDefault();

                      document.getElementById(
                        "showdeletebutton",
                      ).style.display = "none";
                      document.getElementById("showdeletebutton").className =
                        "modal fade";
                    }}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Do you really want to email the credentials to{" "}
                    <span style={{ color: "#f15e5e" }}>{email}</span> ? This
                    process cannot be undone.
                  </p>
                </div>
                <div className="modal-footer justify-content-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={(e) => {
                      e.preventDefault();

                      document.getElementById(
                        "showdeletebutton",
                      ).style.display = "none";
                      document.getElementById("showdeletebutton").className =
                        "modal fade";
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      send_cred();
                      document.getElementById(
                        "showdeletebutton",
                      ).style.display = "none";
                      document.getElementById("showdeletebutton").className =
                        "modal fade";
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  admin_reducer: state.admin_reducer,
});
AutoCredintial.propTypes = {
  admin_reducer: PropTypes.object,
};
export default connect(mapStateToProps)(AutoCredintial);
