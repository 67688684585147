import React, { useState, memo, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import Highlighter from "react-highlight-words";
import { Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import StealerLogsModal from "../modalContainer/StealerLogsModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import BugReportIcon from "@mui/icons-material/BugReport";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LanguageIcon from "@mui/icons-material/Language";
import Tooltip from "@mui/material/Tooltip";
import fileDownload from "js-file-download";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import "../../../css/Stealerlogs/stealerlogs.css";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_error_message } from "../../../utils/ResponseMessage/response_message";
import { date_fm } from "../../../Assets/globalfunctions";

const format_string = (str) => {
  try {
    const formattedString = str.split("\n");
    return formattedString;
  } catch {
    return str;
  }
};
function StealerLogsTable({
  getSearchedData,
  stealer_search_data,
  template_stealer_filters,
  rate_limit_stealer,
  total_db_data_stealer,
  loadmore_loading,
  template_search_type_stealer,
  get_stealer_data,
  template_date,
  template_stealer_filter_type,
  stealer_multiple_obj,
  template_date_log,
  template_stealer_auto_fields,
}) {
  const pageSize = 25;
  const user_table_ref = useRef("details");
  const [paginationValue, setPaginationValue] = useState(1);
  const [open_stealer_modal, set_open_stealer_modal] = useState(false);
  const [selected_row, set_selected_row] = useState();
  const filter_array = stealer_search_data.map((item) => ({
    country_code: item.country_code,
    system_information: item.system_information,
    domains: item.domains_detected,
    passwords: item.passwords,
    autofill_value: item.autofill_value,
    all_data: item,
  }));

  const cloneData = [...filter_array];
  const new_state = cloneData.slice(
    (parseInt(paginationValue) - 1) * pageSize,
    parseInt(paginationValue) * pageSize,
  );
  const new_total_page = Math.ceil(stealer_search_data.length / pageSize);

  const handlePaginationChange = (event, value) => {
    setPaginationValue(value);
  };

  const load_more = () => {
    try {
      const filters_array = JSON.parse(
        handle_session_get("filters_array_stealer"),
      );
      const {
        searchedData,
        stealer_filters,
        search_type_stealer,
        display_type_stealer,
        max_result_stealer,
        stealer_date_from,
        stealer_date_to,
        stealer_filter_type,
        stealer_multiple_obj,
        stealer_date_log,
        stealer_auto_fields,
        post_category_stealer,
      } = filters_array;
      get_stealer_data(
        searchedData,
        stealer_filters,
        search_type_stealer,
        display_type_stealer,
        max_result_stealer,
        stealer_date_from,
        stealer_date_to,
        stealer_filter_type,
        stealer_multiple_obj,
        stealer_date_log,
        stealer_auto_fields,
        post_category_stealer,
      );
    } catch {
      //Do nothing
    }
  };

  const check_stealer_multiple_obj = (stealer) => {
    try {
      if (stealer_multiple_obj[stealer]) {
        if (stealer_multiple_obj[stealer].length > 0) {
          return true;
        }
      } else {
        return false;
      }
    } catch {
      return false;
    }
  };
  const model_popup_loader = () => {
    document.getElementById("downloadmodal").className = "modal fade show";
    document.getElementById("downloadmodal").style.display = "block";
  };
  const model_hide_loader = () => {
    document.getElementById("downloadmodal").className = "modal fade";
    document.getElementById("downloadmodal").style.display = "none";
  };

  const check_date_text = (data_array, date_text_array) => {
    try {
      for (const date_text of date_text_array) {
        const containsWorld = data_array.some((item) =>
          item.includes(date_text),
        );
        if (containsWorld === true) {
          return date_text;
        }
      }
    } catch {
      return "Log date";
    }
  };
  const handle_domain_detected = (domain_array, row) => {
    try {
      if (row.domains) {
        if (row.domains.length > 0) {
          if (
            (template_stealer_filter_type === "default" &&
              template_stealer_filters === "domain") ||
            (template_stealer_filter_type === "individual" &&
              check_stealer_multiple_obj("domain") === true)
          ) {
            const first_item = domain_array[0];
            if (
              template_stealer_filter_type === "default" &&
              ((template_search_type_stealer === "regex" &&
                new RegExp(getSearchedData, "i").test(first_item) === true) ||
                first_item.includes(getSearchedData))
            ) {
              return true;
            } else if (
              template_stealer_filter_type === "individual" &&
              ((template_search_type_stealer === "regex" &&
                new RegExp(stealer_multiple_obj.domain, "i").test(
                  first_item,
                ) === true) ||
                first_item.includes(stealer_multiple_obj.domain))
            ) {
              return true;
            }
          }
        }
      }
      return false;
    } catch {
      return false;
    }
  };
  const format_string_user_systeminfo = (str) => {
    try {
      const remove_words_arr = [
        "Intel",
        "FileLocation",
        "AMD Radeon",
        "AMD",
        "Display Resolution",
        "screensize",
        "screen size",
        "Cores",
        "Threads",
        "RAM",
        "NVIDIA GeForce",
        "NVIDIA",
        "Radeon",
        "Ryzen",
        "CPU:",
        "GPU:",
        "атак: правила",
        "Отработка криптокошельков",
        "Выведу скрытые токены,",
        "Возьму любое количество",
        "Отрабатываю все виды десктопных",
        "Контакты",
      ];
      const formattedString = str.split("\n");
      const filter_array = formattedString.filter(
        (item) =>
          !remove_words_arr.some((text) => new RegExp(text, "i").test(item)),
      );
      const sort_autofill_array = [
        "country",
        "username",
        "user name",
        `user(\\s)?[a-z]{0,6}:`,
        "location",
        "city",
        "ip",
        "hwid",
        "guid",
        "machineid",
        "log date",
        "date",
        "last seen",
        "local time",
        "launch time",
        "Time",
        "Timezone",
        "zip",
        "zip code",
        "machinename",
      ];
      const sanitized_array = [];
      const clone_array = [...filter_array];
      for (let words of sort_autofill_array) {
        const find_item = clone_array.find(
          (item) =>
            new RegExp(words, "i").test(item) === true &&
            !sanitized_array.includes(item),
        );
        if (find_item) {
          sanitized_array.push(find_item);
        }
      }
      const merged_array = [...sanitized_array, ...filter_array];
      const remove_dublicate = [...new Set(merged_array)];
      return remove_dublicate;
    } catch {
      return str.split("\n");
    }
  };
  const handle_highlight_column = useCallback(
    (column, row) => {
      try {
        let column_type;
        let get_single_custom_key;
        let index_of_word;
        let is_length_exceed;
        if (
          (template_stealer_filter_type === "default" &&
            (template_stealer_filters === "domain" ||
              template_stealer_filters === "email")) ||
          (template_stealer_filter_type === "individual" &&
            (check_stealer_multiple_obj("domain") === true ||
              check_stealer_multiple_obj("email") === true))
        ) {
          if (column === "url") {
            column_type = "url_column";
          } else if (column === "username") {
            column_type = "username_column";
          } else if (column === "name") {
            column_type = "name_column";
          } else if (column === "value") {
            column_type = "value_column";
          }
        } else if (
          (template_stealer_filter_type === "default" &&
            template_stealer_filters === "password") ||
          (template_stealer_filter_type === "individual" &&
            check_stealer_multiple_obj("password") === true)
        ) {
          if (column === "password") {
            column_type = "password_column";
          }
        }
        if (template_stealer_filter_type === "individual") {
          if (
            check_stealer_multiple_obj("password") === true &&
            column === "password"
          ) {
            get_single_custom_key = stealer_multiple_obj["password"];
          } else if (
            check_stealer_multiple_obj("email") === true ||
            check_stealer_multiple_obj("domain") === true
          ) {
            get_single_custom_key = new RegExp(
              stealer_multiple_obj["email"],
              "i",
            ).test(row)
              ? stealer_multiple_obj["email"]
              : stealer_multiple_obj["domain"];
          }
        }
        const searched_query =
          template_stealer_filter_type === "default"
            ? getSearchedData
            : get_single_custom_key;
        const static_length =
          column === "url"
            ? 32
            : column === "username"
            ? 12
            : column === "password"
            ? 8
            : column === "name"
            ? 18
            : 24;
        const row_length = row.length;
        const half_length = Math.floor(static_length / 2) - 1;
        if (template_search_type_stealer !== "regex") {
          index_of_word =
            row.toLowerCase().indexOf(searched_query.toLowerCase()) ?? -1;
        } else {
          const regex = new RegExp(searched_query, "i");
          index_of_word = row.search(regex);
        }
        if (column_type === "url_column") {
          is_length_exceed = index_of_word >= 26;
        } else if (column_type === "username_column") {
          is_length_exceed = index_of_word >= 5;
        } else if (column_type === "name_column") {
          is_length_exceed = index_of_word >= 11;
        } else if (column_type === "value_column") {
          is_length_exceed = index_of_word >= 18;
        } else if (column_type === "password_column") {
          is_length_exceed = index_of_word >= 1;
        } else {
          is_length_exceed = false;
        }

        if (is_length_exceed) {
          const filter_row = row.slice(index_of_word - half_length, row_length);
          return filter_row;
        } else {
          return row;
        }
      } catch {
        return row;
      }
    },
    [
      template_stealer_filter_type,
      template_stealer_filters,
      stealer_multiple_obj,
      template_search_type_stealer,
    ],
  );
  const handle_enable_auto_fills_highlight = () => {
    try {
      if (
        template_stealer_filters === "email" &&
        template_stealer_filter_type === "default"
      ) {
        return (
          template_stealer_auto_fields === "true" ||
          template_stealer_auto_fields === true
        );
      } else if (
        template_stealer_filter_type === "individual" &&
        check_stealer_multiple_obj("email") === true
      ) {
        return (
          template_stealer_auto_fields === "true" ||
          template_stealer_auto_fields === true
        );
      } else return true;
    } catch {
      return true;
    }
  };
  return (
    <div className="main__table stealer_logs_table_main">
      <div className="total_data_number">
        <h6>
          Results shown/found :{" "}
          <span>
            {stealer_search_data.length}/{total_db_data_stealer}
          </span>
        </h6>
        <div className="total_data_number_child">
          <h6>
            Credits remaining :{" "}
            <span className="credit_limit_colors">{rate_limit_stealer}</span>
          </h6>
        </div>
      </div>
      <TableContainer sx={{ border: "1px solid #585d618f" }}>
        <Table
          sx={{
            background: "transparent",
            tableLayout: "fixed",
            width: "100%",
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow sx={{ borderBottom: "0.1px solid #585d618f" }}>
              <TableCell
                align="left"
                width="42%"
                sx={{
                  color: "#dcf3ff",
                  borderBottom: "1px solid rgba(255,255,255,0.3)",
                }}
              >
                User Accounts
              </TableCell>
              <TableCell
                align="left"
                width="26%"
                sx={{
                  color: "#dcf3ff",
                  borderBottom: "1px solid rgba(255,255,255,0.3)",
                }}
              >
                Auto Fill
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#dcf3ff",
                  flex: 1,
                  borderBottom: "1px solid rgba(255,255,255,0.3)",
                }}
              >
                User System Info
              </TableCell>
              {/* <TableCell align="left" width="8%" sx={{ color: "#dcf3ff" }}>
                Domains
              </TableCell> */}
              <TableCell
                align="left"
                width="9%"
                sx={{
                  color: "#dcf3ff",
                  borderBottom: "1px solid rgba(255,255,255,0.3)",
                }}
              >
                Details
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {new_state.map((row, index) => {
              const getFlagImageUrl =
                row.country_code !== "None"
                  ? row.country_code === "AE"
                    ? // eslint-disable-next-line no-undef
                      `${process.env.PUBLIC_URL}/static/images/AE.png`
                    : `https://flagcdn.com/w20/${row[
                        "country_code"
                      ].toLowerCase()}.png`
                  : "";
              return (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    width: "100%",
                  }}
                  className="table_body_row_stealer"
                >
                  <TableCell className="table_body_stealer_td">
                    <>
                      <div className="stealer_table_user_accounts_col">
                        {row.passwords ? (
                          row.passwords.length > 0 ? (
                            <table
                              style={{ tableLayout: "fixed", width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th width="50%">URL</th>
                                  <th width="30%">Username</th>
                                  <th width="20%">Password</th>
                                </tr>
                              </thead>
                              <tbody>
                                {row.passwords.map((item, index) => {
                                  if (index < 6) {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {(template_stealer_filter_type ===
                                            "default" &&
                                            (template_stealer_filters ===
                                              "email" ||
                                              template_stealer_filters ===
                                                "domain")) ||
                                          (template_stealer_filter_type ===
                                            "individual" &&
                                            (check_stealer_multiple_obj(
                                              "email",
                                            ) === true ||
                                              check_stealer_multiple_obj(
                                                "domain",
                                              ) === true)) ? (
                                            <Highlighter
                                              highlightClassName="highlightclass"
                                              searchWords={
                                                template_stealer_filter_type ===
                                                "default"
                                                  ? [getSearchedData]
                                                  : [
                                                      stealer_multiple_obj.email,
                                                      stealer_multiple_obj.domain,
                                                    ]
                                              }
                                              autoEscape={
                                                template_search_type_stealer !==
                                                "regex"
                                              }
                                              textToHighlight={handle_highlight_column(
                                                "url",
                                                item.url,
                                              )}
                                              className="user_account_col_text user_account_col_text_nowrap"
                                            />
                                          ) : (
                                            <p className="user_account_col_text user_account_col_text_nowrap">
                                              {item.url}
                                            </p>
                                          )}
                                        </td>

                                        <td>
                                          {(template_stealer_filter_type ===
                                            "default" &&
                                            (template_stealer_filters ===
                                              "email" ||
                                              template_stealer_filters ===
                                                "domain")) ||
                                          (template_stealer_filter_type ===
                                            "individual" &&
                                            (check_stealer_multiple_obj(
                                              "email",
                                            ) === true ||
                                              check_stealer_multiple_obj(
                                                "domain",
                                              ) === true)) ? (
                                            <Highlighter
                                              highlightClassName="highlightclass"
                                              searchWords={
                                                template_stealer_filter_type ===
                                                "default"
                                                  ? [getSearchedData]
                                                  : [
                                                      stealer_multiple_obj.email,
                                                      stealer_multiple_obj.domain,
                                                    ]
                                              }
                                              autoEscape={
                                                template_search_type_stealer !==
                                                "regex"
                                              }
                                              textToHighlight={handle_highlight_column(
                                                "username",
                                                item.username,
                                              )}
                                              className="user_account_col_text user_account_col_text_nowrap"
                                            />
                                          ) : (
                                            <p className="user_account_col_text user_account_col_text_nowrap">
                                              {item.username}
                                            </p>
                                          )}
                                        </td>
                                        <td>
                                          {(template_stealer_filter_type ===
                                            "default" &&
                                            template_stealer_filters ===
                                              "password") ||
                                          (template_stealer_filter_type ===
                                            "individual" &&
                                            check_stealer_multiple_obj(
                                              "password",
                                            ) === true) ? (
                                            <Highlighter
                                              highlightClassName="highlightclass"
                                              searchWords={
                                                template_stealer_filter_type ===
                                                "default"
                                                  ? [getSearchedData]
                                                  : [
                                                      stealer_multiple_obj.password,
                                                    ]
                                              }
                                              autoEscape={
                                                template_search_type_stealer !==
                                                "regex"
                                              }
                                              textToHighlight={handle_highlight_column(
                                                "password",
                                                item.password,
                                              )}
                                              className="user_account_col_text user_account_col_text_nowrap"
                                            />
                                          ) : (
                                            <p className="user_account_col_text user_account_col_text_nowrap">
                                              {item.password}
                                            </p>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return null;
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <p className="stealer_row_text_none">
                              No data to display
                            </p>
                          )
                        ) : (
                          <p className="stealer_row_text_none">
                            No data to display
                          </p>
                        )}
                        {row.passwords ? (
                          row.passwords.length > 6 ? (
                            <div className="stealer_logs_btn_count">
                              <Tooltip title="see more" disableInteractive>
                                <button
                                  onClick={() => {
                                    set_open_stealer_modal(true);
                                    set_selected_row(row);
                                    user_table_ref.current = "user_accounts";
                                  }}
                                >
                                  {`6/${row.all_data.total_passwords}`}
                                </button>
                              </Tooltip>
                            </div>
                          ) : null
                        ) : null}
                      </div>
                    </>
                  </TableCell>
                  <TableCell className="table_body_stealer_td">
                    <>
                      <div className="stealer_table_user_accounts_col">
                        {row.autofill_value ? (
                          row.autofill_value.length > 0 ? (
                            <table
                              style={{ tableLayout: "fixed", width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th width="40%">Name</th>
                                  <th width="60%">Value</th>
                                </tr>
                              </thead>
                              <tbody>
                                {row.autofill_value.map((item, index) => {
                                  if (index < 6) {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {handle_enable_auto_fills_highlight() ===
                                            true &&
                                          ((template_stealer_filter_type ===
                                            "default" &&
                                            (template_stealer_filters ===
                                              "email" ||
                                              template_stealer_filters ===
                                                "domain")) ||
                                            (template_stealer_filter_type ===
                                              "individual" &&
                                              (check_stealer_multiple_obj(
                                                "email",
                                              ) === true ||
                                                check_stealer_multiple_obj(
                                                  "domain",
                                                ) === true))) ? (
                                            <Highlighter
                                              highlightClassName="highlightclass"
                                              searchWords={
                                                template_stealer_filter_type ===
                                                "default"
                                                  ? [getSearchedData]
                                                  : [
                                                      stealer_multiple_obj.email,
                                                      stealer_multiple_obj.domain,
                                                    ]
                                              }
                                              autoEscape={
                                                template_search_type_stealer !==
                                                "regex"
                                              }
                                              textToHighlight={
                                                item.name
                                                  ? handle_highlight_column(
                                                      "name",
                                                      item.name,
                                                    )
                                                  : "None"
                                              }
                                              className="user_account_col_text user_account_col_text_nowrap"
                                            />
                                          ) : (
                                            <p className="user_account_col_text user_account_col_text_nowrap">
                                              {item.name ? item.name : "None"}
                                            </p>
                                          )}
                                        </td>

                                        <td>
                                          {handle_enable_auto_fills_highlight() ===
                                            true &&
                                          ((template_stealer_filter_type ===
                                            "default" &&
                                            (template_stealer_filters ===
                                              "email" ||
                                              template_stealer_filters ===
                                                "domain")) ||
                                            (template_stealer_filter_type ===
                                              "individual" &&
                                              (check_stealer_multiple_obj(
                                                "email",
                                              ) === true ||
                                                check_stealer_multiple_obj(
                                                  "domain",
                                                ) === true))) ? (
                                            <Highlighter
                                              highlightClassName="highlightclass"
                                              searchWords={
                                                template_stealer_filter_type ===
                                                "default"
                                                  ? [getSearchedData]
                                                  : [
                                                      stealer_multiple_obj.email,
                                                      stealer_multiple_obj.domain,
                                                    ]
                                              }
                                              autoEscape={
                                                template_search_type_stealer !==
                                                "regex"
                                              }
                                              textToHighlight={
                                                item.value
                                                  ? handle_highlight_column(
                                                      "value",
                                                      item.value,
                                                    )
                                                  : "None"
                                              }
                                              className="user_account_col_text user_account_col_text_nowrap"
                                            />
                                          ) : (
                                            <p className="user_account_col_text user_account_col_text_nowrap">
                                              {item.value
                                                ? item.value.slice(0, 60)
                                                : "None"}
                                            </p>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }
                                  return null;
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <p className="stealer_row_text_none">
                              No data to display
                            </p>
                          )
                        ) : (
                          <p className="stealer_row_text_none">
                            No data to display
                          </p>
                        )}
                        {row.autofill_value ? (
                          row.autofill_value.length > 6 ? (
                            <div className="stealer_logs_btn_count">
                              <Tooltip title="see more" disableInteractive>
                                <button
                                  onClick={() => {
                                    set_open_stealer_modal(true);
                                    set_selected_row(row);
                                    user_table_ref.current = "auto_fill";
                                  }}
                                >
                                  {`6/${row.all_data.total_autofill_value}`}
                                </button>
                              </Tooltip>
                            </div>
                          ) : null
                        ) : null}
                      </div>
                    </>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "start" }}
                    className="table_body_stealer_td"
                  >
                    {format_string_user_systeminfo(row.system_information)
                      ? format_string_user_systeminfo(row.system_information)
                          .length > 0
                        ? format_string_user_systeminfo(row.system_information)
                            .slice(0, 10)
                            .map((item, id) => {
                              if (
                                ["Zip Code", "Zip Country"].some((word) =>
                                  item.includes(word),
                                ) === true &&
                                ((template_stealer_filter_type === "default" &&
                                  template_stealer_filters === "zip") ||
                                  (template_stealer_filter_type ===
                                    "individual" &&
                                    check_stealer_multiple_obj("zip") === true))
                              ) {
                                return (
                                  <p
                                    style={{
                                      marginBottom: "0",
                                      marginBlockStart: "0",
                                      marginBlockEnd: "0",
                                    }}
                                    key={id}
                                  >
                                    <Highlighter
                                      highlightClassName="highlightclass"
                                      searchWords={
                                        template_stealer_filter_type ===
                                        "default"
                                          ? [getSearchedData]
                                          : [stealer_multiple_obj.zip]
                                      }
                                      autoEscape={
                                        template_search_type_stealer !== "regex"
                                      }
                                      textToHighlight={item}
                                      className="user_account_col_text"
                                    />
                                  </p>
                                );
                              } else if (
                                ["Ip", "IP"].some((word) =>
                                  item.includes(word),
                                ) &&
                                ((template_stealer_filter_type === "default" &&
                                  template_stealer_filters === "ip_address") ||
                                  (template_stealer_filter_type ===
                                    "individual" &&
                                    check_stealer_multiple_obj("ip_address") ===
                                      true))
                              ) {
                                return (
                                  <p
                                    style={{
                                      marginBottom: "0",
                                      marginBlockStart: "0",
                                      marginBlockEnd: "0",
                                    }}
                                    key={id}
                                  >
                                    <Highlighter
                                      highlightClassName="highlightclass"
                                      searchWords={
                                        template_stealer_filter_type ===
                                        "default"
                                          ? [getSearchedData]
                                          : [stealer_multiple_obj.ip_address]
                                      }
                                      autoEscape={
                                        template_search_type_stealer !== "regex"
                                      }
                                      textToHighlight={item}
                                      className="user_account_col_text"
                                    />
                                  </p>
                                );
                              } else if (
                                item.includes("Location") === true &&
                                ((template_stealer_filter_type === "default" &&
                                  template_stealer_filters === "location") ||
                                  (template_stealer_filter_type ===
                                    "individual" &&
                                    check_stealer_multiple_obj("location") ===
                                      true))
                              ) {
                                return (
                                  <p
                                    style={{
                                      marginBottom: "0",
                                      marginBlockStart: "0",
                                      marginBlockEnd: "0",
                                    }}
                                    key={id}
                                  >
                                    <Highlighter
                                      highlightClassName="highlightclass"
                                      searchWords={
                                        template_stealer_filter_type ===
                                        "default"
                                          ? [getSearchedData]
                                          : [stealer_multiple_obj.location]
                                      }
                                      autoEscape={
                                        template_search_type_stealer !== "regex"
                                      }
                                      textToHighlight={item}
                                      className="user_account_col_text"
                                    />
                                  </p>
                                );
                              } else if (
                                ["HWID", "GUID", "MachineID"].some((word) =>
                                  item.includes(word),
                                ) === true &&
                                ((template_stealer_filter_type === "default" &&
                                  template_stealer_filters === "device_id") ||
                                  (template_stealer_filter_type ===
                                    "individual" &&
                                    check_stealer_multiple_obj("device_id") ===
                                      true))
                              ) {
                                return (
                                  <p
                                    style={{
                                      marginBottom: "0",
                                      marginBlockStart: "0",
                                      marginBlockEnd: "0",
                                    }}
                                    key={id}
                                  >
                                    <Highlighter
                                      highlightClassName="highlightclass"
                                      searchWords={
                                        template_stealer_filter_type ===
                                        "default"
                                          ? [getSearchedData]
                                          : [stealer_multiple_obj.device_id]
                                      }
                                      autoEscape={
                                        template_search_type_stealer !== "regex"
                                      }
                                      textToHighlight={item}
                                      className="user_account_col_text"
                                    />
                                  </p>
                                );
                              } else if (
                                item.includes(
                                  check_date_text(
                                    format_string(row.system_information),
                                    [
                                      "Log date",
                                      "Date",
                                      "Last seen",
                                      "Local Time",
                                      "Launch time",
                                      "Time",
                                    ],
                                  ),
                                ) === true &&
                                item.includes("Install Date") === false &&
                                row.all_data.log_date
                              ) {
                                return (
                                  <p
                                    style={{
                                      marginBottom: "0",
                                      marginBlockStart: "0",
                                      marginBlockEnd: "0",
                                    }}
                                    key={id}
                                  >
                                    <span
                                      className={
                                        template_date.start !== "None" ||
                                        template_date.end !== "None" ||
                                        template_date_log === "true"
                                          ? "user_account_col_text stealer_highlight_date"
                                          : "user_account_col_text"
                                      }
                                    >
                                      {`Log Date : ${
                                        row.all_data?.log_date ?? ""
                                      }`}
                                    </span>
                                  </p>
                                );
                              } else {
                                return (
                                  <p
                                    style={{
                                      marginBottom: "0",
                                      marginBlockStart: "0",
                                      marginBlockEnd: "0",
                                    }}
                                    key={id}
                                  >
                                    <span className="user_account_col_text">
                                      {item}
                                    </span>
                                  </p>
                                );
                              }
                            })
                        : "None"
                      : "None"}
                  </TableCell>
                  {/* <TableCell
                  sx={{ textAlign: "start" }}
                  className="table_body_stealer_td"
                >
                  {row.domains ? (
                    row.domains.length > 0 ? (
                      <div>
                        {[...new Set(row.all_data.domains_detected)].map(
                          (item, id) => (
                            <>
                              {(template_stealer_filter_type === "default" &&
                                template_stealer_filters === "domain") ||
                                (template_stealer_filter_type === "individual" &&
                                  check_stealer_multiple_obj("domain") ===
                                  true) ? (
                                <p
                                  className="stealer_table_domain_col"
                                  key={id}
                                >
                                  <Highlighter
                                    highlightClassName="highlightclass"
                                    searchWords={
                                      template_stealer_filter_type === "default"
                                        ? [getSearchedData]
                                        : [stealer_multiple_obj.domain]
                                    }
                                    autoEscape={
                                      template_search_type_stealer !== "regex"
                                    }
                                    textToHighlight={item}
                                    className="user_account_col_text"
                                  />
                                </p>
                              ) : (
                                <p className="user_account_col_text" key={id}>
                                  {item}
                                </p>
                              )}
                            </>
                          ),
                        )}
                      </div>
                    ) : (
                      "None"
                    )
                  ) : (
                    "None"
                  )}
                </TableCell> */}

                  <TableCell
                    sx={{ textAlign: "start" }}
                    className="table_body_stealer_td"
                  >
                    <Stack rowGap={2}>
                      <Stack direction="row" columnGap={2} alignItems="center">
                        <button
                          className="alert_bell_icon_btn stealer_logs_action_btns"
                          onClick={() => {
                            set_open_stealer_modal(true);
                            set_selected_row(row);
                            user_table_ref.current = "details";
                          }}
                        >
                          <Tooltip title="see more" disableInteractive>
                            <VisibilityIcon sx={{ color: "#e19813" }} />
                          </Tooltip>
                        </button>
                        <DownloadOptons
                          file_id={row.all_data.file_id}
                          model_popup_loader={model_popup_loader}
                          model_hide_loader={model_hide_loader}
                          getSearchedData={getSearchedData}
                          row_item={row}
                          set_selected_row={set_selected_row}
                        />
                      </Stack>
                      <Stack direction="row" columnGap={0.5}>
                        <Tooltip title="Date of indexing" disableInteractive>
                          <CalendarMonthIcon
                            fontSize="small"
                            sx={{ color: "#999" }}
                          />
                        </Tooltip>
                        <p className="stealerl_action_content stealer_action_value text-nowrap">
                          {date_fm(row.all_data.dateofindexing)}
                        </p>
                      </Stack>

                      {row.all_data.stealer_info ? (
                        <>
                          <Stack direction="row" columnGap={0.5}>
                            <Tooltip title="Family" disableInteractive>
                              <BugReportIcon
                                fontSize="small"
                                sx={{ color: "#999" }}
                              />
                            </Tooltip>
                            <p className="stealerl_action_content stealer_action_value">
                              {row.all_data.stealer_info}
                            </p>
                          </Stack>
                        </>
                      ) : null}
                      {row.country_code && row.country_code !== "None" ? (
                        <Stack
                          direction="row"
                          columnGap={1}
                          alignItems="center"
                        >
                          <img
                            width="25"
                            height="20"
                            src={getFlagImageUrl}
                            style={{ objectFit: "contain" }}
                            alt="flag"
                          />
                          <p className="stealerl_action_content stealer_action_value">
                            {" "}
                            {(template_stealer_filter_type === "default" &&
                              template_stealer_filters === "country_code") ||
                            (template_stealer_filter_type === "individual" &&
                              check_stealer_multiple_obj("country_code") ===
                                true) ? (
                              <Highlighter
                                highlightClassName="highlightclass"
                                searchWords={
                                  template_stealer_filter_type === "default"
                                    ? [getSearchedData]
                                    : [stealer_multiple_obj.country_code]
                                }
                                autoEscape={
                                  template_search_type_stealer !== "regex"
                                }
                                textToHighlight={row.country_code}
                                className="user_account_col_text"
                              />
                            ) : (
                              row.country_code
                            )}{" "}
                          </p>
                        </Stack>
                      ) : null}
                      {handle_domain_detected(
                        [...new Set(row.all_data.domains_detected)],
                        row,
                      ) === true ? (
                        <Stack direction="row" columnGap={0.5}>
                          <Tooltip title="Domain detected" disableInteractive>
                            <LanguageIcon
                              fontSize="small"
                              sx={{ color: "#999" }}
                            />
                          </Tooltip>
                          <p className="stealerl_action_content stealer_action_value stealer_action_value_domain">
                            <Highlighter
                              highlightClassName="highlightclass"
                              searchWords={
                                template_stealer_filter_type === "default"
                                  ? [getSearchedData]
                                  : [stealer_multiple_obj.domain]
                              }
                              autoEscape={false}
                              textToHighlight={
                                [...new Set(row.all_data.domains_detected)][0]
                              }
                              className="user_account_col_text"
                            />
                          </p>
                        </Stack>
                      ) : null}
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={new_total_page}
        color="primary"
        page={paginationValue}
        onChange={handlePaginationChange}
        size="small"
      />
      {total_db_data_stealer &&
      Number(total_db_data_stealer) > stealer_search_data.length ? (
        <Stack
          direction="row"
          alignItem="center"
          justifyContent="center"
          spacing={2}
          mt={1}
        >
          {" "}
          {new_total_page === paginationValue ? (
            loadmore_loading === true ? (
              <CircularProgress fontSize="small" sx={{ color: "#28cab3" }} />
            ) : (
              <button onClick={load_more} className="load_more_btn">
                Load more
              </button>
            )
          ) : null}
        </Stack>
      ) : null}
      {open_stealer_modal === true ? (
        <StealerLogsModal
          getSearchedData={getSearchedData}
          open_stealer_modal={open_stealer_modal}
          set_open_stealer_modal={set_open_stealer_modal}
          selected_row={selected_row}
          user_table_ref={user_table_ref}
        />
      ) : null}

      {/* Download loading */}
      <div
        className="modal fade"
        id="downloadmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="downloadmodal"
        aria-hidden="true"
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div
          className="modal-dialog modal-confirm"
          style={{ background: "#2b3c46", marginTop: "13rem" }}
        >
          <div className="modal-content dk-mode">
            <div className="modal-header flex-column">
              <h4
                className="modal-title w-100"
                style={{ fontSize: "18px", color: "#359daf" }}
              >
                Please wait for a moment...
              </h4>
            </div>
            <div className="modal-body" style={{ marginTop: "50px" }}>
              <div className="load-wrapper" style={{ marginLeft: "200px" }}>
                <div
                  className="load-circle"
                  style={{ background: "#359daf" }}
                />
                <div
                  className="load-circle"
                  style={{ background: "#359daf" }}
                />
                <div className="load-circle" style={{ background: "#359daf" }}>
                  {" "}
                </div>
                <div className="load-shadow" />
                <div className="load-shadow" />
                <div className="load-shadow" />
                <span style={{ left: "2%", color: "#359daf" }}>
                  Downloading
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
function DownloadOptons({
  file_id,
  model_popup_loader,
  model_hide_loader,
  getSearchedData,
  row_item,
  set_selected_row,
}) {
  // Download file
  const file_download = (id, file_type) => {
    model_popup_loader();
    const token = handle_session_get("Token");
    const data = { file_hash: id, file_type };
    try {
      axios
        .post("https://api.recordedtelegram.com/stealer_logs_download", data, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const new_file_name = `${getSearchedData}_${new Date()}.${file_type}`;
          fileDownload(res.data, new_file_name);
          model_hide_loader();
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(
            err.response.data,
            err.response.status,
          );
          if (token_expire_status === true) return;
          model_hide_loader();
          let errorMessage = "";
          const reader = new FileReader();
          reader.onload = () => {
            errorMessage = reader.result;
            handle_error_message(JSON.parse(errorMessage).errormsg);
          };
          reader.readAsText(err.response.data);
        });
    } catch (err) {
      model_hide_loader();
      handle_error_message("Sorry could not download file");
    }
  };
  // Download menu function
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="alert_bell_icon_btn stealer_logs_action_btns"
      >
        <Tooltip title="Download">
          <CloudDownloadIcon sx={{ cursor: "pointer", color: "#e19813" }} />
        </Tooltip>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            file_download(file_id, "json");
            handleClose();
          }}
        >
          Download as JSON
        </MenuItem>
        <MenuItem
          onClick={() => {
            file_download(file_id, "csv");
            handleClose();
          }}
        >
          Download as CSV
        </MenuItem>
        <MenuItem
          onClick={() => {
            file_download(file_id, "pdf");
            handleClose();
          }}
        >
          Download as PDF
        </MenuItem>
        <MenuItem
          onClick={() => {
            set_selected_row(row_item);
            file_download(file_id, "html");
            handleClose();
          }}
        >
          Download as HTML
        </MenuItem>
      </Menu>
    </>
  );
}
DownloadOptons.propTypes = {
  file_id: PropTypes.string,
  model_popup_loader: PropTypes.func,
  model_hide_loader: PropTypes.func,
  getSearchedData: PropTypes.string,
  row_item: PropTypes.object,
  set_selected_row: PropTypes.func,
};
StealerLogsTable.propTypes = {
  getSearchedData: PropTypes.string,
  stealer_search_data: PropTypes.array,
  template_stealer_filters: PropTypes.string,
  rate_limit_stealer: PropTypes.number,
  total_db_data_stealer: PropTypes.number,
  loadmore_loading: PropTypes.bool,
  template_search_type_stealer: PropTypes.string,
  get_stealer_data: PropTypes.func,
  template_date: PropTypes.string,
  template_stealer_filter_type: PropTypes.string,
  stealer_multiple_obj: PropTypes.object,
  template_date_log: PropTypes.any,
  template_stealer_auto_fields: PropTypes.any,
};
export default memo(StealerLogsTable);
