import React, { useState } from "react";
import AddCredentialIndustry from "./AddCredentialIndustry";
import axios from "axios";
import { handle_session_get } from "../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../utils/Validation/Token/token_checker";
import {
  handle_error_message,
  handle_success_message,
  handle_warning_message,
} from "../../utils/ResponseMessage/response_message";

function AddCredentialinsustryFunc() {
  const [industry_detail, set_industry_detail] = useState({
    loading: false,
    error: "",
    data: [],
  });
  const token = handle_session_get("Token");
  const handle_get_industry_credential = () => {
    set_industry_detail((prev) => ({ ...prev, loading: true }));
    axios
      .get("https://api.recordedtelegram.com/industry_preference", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const response = res.data.data;
        set_industry_detail((prev) => ({
          ...prev,
          loading: false,
          error: "",
          data: response,
        }));
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_industry_detail((prev) => ({
          ...prev,
          loading: false,
          error: err.response.data.errormsg,
        }));
      });
  };
  const handle_add_industry_credential = (
    name,
    product_list,
    domain_list,
    channel_list,
    tag_list,
    add_status,
    row_id,
  ) => {
    const data = {
      name,
      product: product_list,
      domain: domain_list,
      channel: channel_list,
      tag: tag_list,
    };
    const data_update = {
      name,
      product: product_list,
      domain: domain_list,
      channel: channel_list,
      tag: tag_list,
      id: row_id,
    };
    if (
      product_list.length > 100 ||
      domain_list.length > 100 ||
      channel_list.length > 100 ||
      tag_list.length > 100
    ) {
      handle_warning_message(
        "None of the Parameter length must exceed 100 items.",
      );
      return;
    }
    axios
      .post(
        "https://api.recordedtelegram.com/industry_preference",
        add_status === "update" && row_id ? data_update : data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const filter_res = industry_detail["data"].filter(
          (item) => item.id !== res.data.data.id,
        );
        if (add_status === "update") {
          set_industry_detail((prev) => ({
            ...prev,
            data: [...filter_res, res.data.data],
          }));
        } else {
          set_industry_detail((prev) => ({
            ...prev,
            data: [...prev.data, res.data.data],
          }));
        }
        handle_success_message(res.data.message);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };
  const handle_delete_industry_credential = (row_id) => {
    const data = {
      id: row_id,
    };
    axios
      .post("https://api.recordedtelegram.com/remove_industry", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const response = res.data.data;
        set_industry_detail((prev) => ({
          ...prev,
          loading: false,
          error: "",
          data: response,
        }));
        handle_success_message(res.data.message);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };

  return (
    <div>
      <AddCredentialIndustry
        handle_get_industry_credential={handle_get_industry_credential}
        handle_add_industry_credential={handle_add_industry_credential}
        handle_delete_industry_credential={handle_delete_industry_credential}
        industry_detail={industry_detail}
      />
    </div>
  );
}

export default AddCredentialinsustryFunc;
