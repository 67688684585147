import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../../styles2.css";
import "../../css/Admin/admin.css";
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { Grid, GlobalStyles, Tooltip as tooltip_box } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeCircles } from "react-loader-spinner";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { connect } from "react-redux";
// Data Grid
import { Formik } from "formik";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
// For re-charts
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { RoundAnimationLoader } from "../../TableUi/components/loader/Loader";
import { handle_tooltip_date } from "../../Assets/globalfunctions";
import GridTable from "./GridTable";
import { date_fm } from "../../actions/userAction";
import Sidenav from "../../TableUi/components/Navbar/Sidenav";
import Adminmainnav from "./Adminmainnav";
import { categories_array_admin_scraper } from "../../utils/Globals/global_variables";
import { handle_session_get } from "../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../utils/Validation/Token/token_checker";
function Adminscraper({ users, admin_reducer }) {
  const token = handle_session_get("Token");
  const [scraper_data, set_scraper_data] = useState([]);
  const [scrapper_data_search, set_scrapper_data_search] = useState([]);
  const [scrapper_data_search_status, set_scrapper_data_search_status] =
    useState({
      loading: false,
      error_status: false,
      error: "",
      fetch_status: false,
    });
  const [total_no_of_posts_scraper, set_total_no_of_posts_scraper] =
    useState(0);

  const [deployed_scrappers, set_deployed_scrappers] = useState({
    data: [],
    loading: false,
    error_status: false,
    error: "",
  });
  const [vpn_tracker, set_vpn_tracker] = useState({
    data: [],
    loading: false,
    error: "",
  });

  const [scrapper_stats, set_scrapper_stats] = useState({});
  const [scrapper_stats_status, set_scrapper_stats_status] = useState({
    loading: false,
    error_status: false,
    error: "",
  });

  // State for the search
  const [search_filter, set_search_filter] = useState("channel_name");
  const [dropdown, set_drop_down] = useState(false);
  const [search_value, set_search_value] = useState("");

  // const [scraper_loading, set_scraper_loading] = useState(false);
  // const bg_color = [
  //   "#fc4b6c",
  //   "#26c6da",
  //   "	#8A2BE2",
  //   "#00c292",
  //   "#1e88e5",
  //   "#7c277d",
  //   "rgb(31, 40, 51)",
  //   "rgb(16, 82, 98)",
  //   "rgb(203, 141, 78)",
  //   "rgb(148, 169, 176)",

  //   "#fc4b6c",
  //   "#26c6da",
  //   "#A0522D ",
  //   "#00c292",
  //   "#1e88e5",
  //   "#7c277d",
  //   "#6A5ACD",
  //   "rgb(16, 82, 98)",
  //   "rgb(203, 141, 78)",
  //   "rgb(148, 169, 176)",

  //   "#74A6BD",
  //   "#AB988B",
  //   "#3da553",
  //   "#bc9126",
  //   "#2651bc",
  //   "	#A0522D"
  // ];

  // const [main_data, set_main_data] = useState(null);

  useEffect(() => {
    if (token !== null) {
      get_scraper_data();
      get_vpn_tracker_data();
      get_deployed_scraper_data();
      get_chart_scrapper_stats();
      document.getElementById("downloadmodal").className = "modal fade show";
      document.getElementById("downloadmodal").style.display = "block";
    }
  }, []);

  // const fetch_posts = () => {
  //   let token = localStorage.getItem("Token");
  //   set_scraper_loading(true)
  //   axios.get("https://api.recordedtelegram.com/v2/data_count", {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //       keyword: "True",
  //     },
  //   })
  //     .then((res) => {
  //       set_main_data(res.data.data);
  //       set_scraper_loading(false)

  //     })
  //     .catch((err) => {
  //       try {
  //         let errors = Object.keys(err.response.data)
  //         let token_error_status = errors.includes('tokenerror')
  //         if (token_error_status === true) {
  //           let error_check = error_checker(true)
  //           if (error_check === true) {
  //             toast.warn("Your session has expired. Redirecting you to Login page.", {
  //               position: toast.POSITION.TOP_CENTER,
  //               autoClose: 2000,

  //             })
  //             setTimeout(() => {
  //               sessionStorage.clear(); window.location.href = '/'
  //             }, 2500)

  //             return
  //           }
  //         } else {
  //           set_scraper_loading(false)
  //         }

  //       } catch {

  //       }

  //     });
  // };

  const get_scraper_data_search = (qtext, filter, filter_name) => {
    set_search_value(filter_name);
    set_drop_down(false);
    const data = { qtext, search_type: filter };
    set_scrapper_data_search_status((prev) => ({
      ...prev,
      loading: true,
      error_status: false,
      error: "",
      fetch_status: false,
    }));
    axios
      .post("https://api.recordedtelegram.com/scraper_search", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const posts_array = res.data.data
          .map((item) => item.number_of_posts)
          .reduce((sum, item) => sum + item, 0);
        set_total_no_of_posts_scraper(posts_array);
        set_scrapper_data_search(res.data.data);
        set_scrapper_data_search_status((prev) => ({
          ...prev,
          loading: false,
          error_status: false,
          error: "",
          fetch_status: true,
        }));
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_scrapper_data_search_status((prev) => ({
          ...prev,
          loading: false,
          error_status: true,
          error: err.response.data.message,
        }));
      });
  };

  const get_scraper_data = () => {
    const data = { passcode: "@lazlxEtIcS3A9rchIydzXiqg2u21fr0m" };
    axios
      .post("https://api.recordedtelegram.com/get_all_scraper_tracker", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const new_data = res.data.data.map((val) => val._source);
        set_scraper_data(new_data);
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
      });
  };
  const get_vpn_tracker_data = () => {
    set_vpn_tracker((prev) => ({ ...prev, loading: true }));
    axios
      .get("https://api.recordedtelegram.com/get_vpninfo", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const response_data = res.data;
        response_data.sort((a, b) =>
          a["mullvad_user"].localeCompare(b["mullvad_user"]),
        );
        set_vpn_tracker((prev) => ({
          ...prev,
          loading: false,
          data: response_data,
          error: "",
        }));
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_vpn_tracker((prev) => ({
          ...prev,
          loading: false,
          error: err.response.data.errormsg,
        }));
      });
  };
  const get_deployed_scraper_data = () => {
    set_deployed_scrappers((prev) => ({ ...prev, loading: true }));
    axios
      .get("https://api.recordedtelegram.com/deployed_scrapers", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        set_deployed_scrappers((prev) => ({
          ...prev,
          loading: false,
          data: res.data.total_data,
          error_status: false,
          error: "",
        }));
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_deployed_scrappers((prev) => ({
          ...prev,
          loading: false,
          error_status: true,
          error: err.response.data.errormsg,
        }));
      });
  };

  // Get Scrapper chart data
  const get_chart_scrapper_stats = () => {
    let isSubmitBlocked = false;
    set_scrapper_stats_status((prev) => ({
      ...prev,
      loading: true,
      error_status: false,
      error: "",
    }));
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .get("https://api.recordedtelegram.com/scraper_stats", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          isSubmitBlocked = false;
          const all_chart_res = res.data.statistics;
          const filter_obj = {};
          for (const key in all_chart_res) {
            const categories_obj = all_chart_res[key];
            // Filter the empty object
            const sanitized_obj = Object.fromEntries(
              Object.entries(categories_obj).filter(
                // eslint-disable-next-line no-unused-vars
                ([key, value]) => Array.isArray(value) === true,
              ),
            );
            if (Object.keys(sanitized_obj).length === 0) {
              continue;
            }
            const filter_array = sanitized_obj[Object.keys(sanitized_obj)[0]];
            const restructuredData = filter_array.map((item, index) => {
              const dateTime = new Date(
                sanitized_obj[Object.keys(sanitized_obj)[0]][
                  index
                ].key_as_string,
              );
              const hours = dateTime.getHours();
              const minutes = dateTime.getMinutes();
              const newObj = {
                name: `${hours.toString().padStart(2, "0")}:${minutes
                  .toString()
                  .padStart(2, "0")}`,
              };
              for (const val in sanitized_obj) {
                try {
                  newObj[val] = sanitized_obj[val][index].doc_count;
                } catch {
                  //Do nothing
                }
              }
              return newObj;
            });
            filter_obj[key] = {
              data: restructuredData,
            };
          }
          set_scrapper_stats(filter_obj);
          set_scrapper_stats_status((prev) => ({
            ...prev,
            loading: false,
            error_status: false,
            error: "",
          }));
        })
        .catch((err) => {
          isSubmitBlocked = false;
          set_scrapper_stats_status((prev) => ({
            ...prev,
            loading: false,
            error_status: true,
            error: err.response.data.errormsg,
          }));
        });
    }
  };

  const colorArrayScarperChart = [
    "#9FD03E",
    "#63C2CB",
    "#6E7582",
    "#996600",
    "#B58900",
    "#D25A6E",
    "#358397",
    "#CE6FCE",
    "#BFBFBF",
    "#D77C6C",
    "#9F60A3",
    "#E17D73",
    "#62A87C",
    "#F7AE4E",
    "#6C9E9E",
    "#8E7AA3",
    "#D4A76A",
    "#5DA4E6",
    "#D08770",
    "#7AA43C",
    "#A67D97",
    "#6E8D71",
    "#D9675F",
    "#4285B0",
    "#9A736E",
    "#63A69F",
    "#AA8F65",
    "#7C8B99",
    "#8a8a5c",
  ];
  return (
    <div className="wrapper">
      <GlobalStyles
        styles={{
          ".MuiDataGrid-menuList": {
            backgroundColor: "#fff",
          },
        }}
      />

      <Grid container>
        <Grid item sm={0.7}>
          <Sidenav />
        </Grid>
        <Grid item sm={11.2}>
          <div
            id="content"
            className={admin_reducer.boot_active ? "active" : null}
          >
            <section>
              <Adminmainnav />
              <div
                id="content"
                className={admin_reducer.boot_active ? "active" : null}
              >
                <section>
                  <section id="boxes" className="">
                    <div className="scrapper_container_main">
                      {/* Commit the Load Scraper Stats */}

                      {/*
                      {scraper_loading === true ? <section style={{ marginTop: '60px' }}>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 text-center text-muted">
                              <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                              <h4>Please wait for some minutes. It may take 2-5 minutes</h4>
                            </div>
                          </div>
                        </div>
                      </section>
                        :
                        <div className="row justify-content-center">
                          {main_data !== null
                            ? Object.keys(main_data)
                              .sort(function (a, b) {
                                return (
                                  main_data[b]["No.of total channel posts avaialable"] -
                                  main_data[a]["No.of total channel posts avaialable"]
                                );
                              })
                              .map((val, i) => (
                                <React.Fragment>
                                  <div key={i} className="col-md-4 col-lg-4 col-xl-4">
                                    <div
                                      className="box"
                                      style={{
                                        background: bg_color[i],
                                        marginTop: "50px",
                                        paddingBottom: "15px",
                                      }}
                                    >
                                      <div className="box-heder stats-header">
                                        <h4 style={{ textTransform: 'capitalize' }}>{val} </h4>
                                      </div>
                                      <h5 style={{ color: "white" }}>Channel </h5>
                                      <div className="box-body p-0">
                                        <div className="stats-body">
                                          <div style={{ display: "inline-block" }}>
                                            <span style={{ float: "left" }}>
                                              <IconContext.Provider value={{}}>
                                                <FaIdBadge />
                                              </IconContext.Provider>
                                            </span>
                                            <span style={{ marginLeft: "10px" }}>
                                              {" "}
                                              Total Channel posts
                                            </span>
                                          </div>
                                          <span
                                            className="stats-right"
                                            style={{ float: "right" }}
                                          >
                                            {
                                              main_data[val][
                                              "No.of total channel posts avaialable"
                                              ]
                                            }
                                          </span>
                                        </div>
                                        <div className="stats-body">
                                          <div style={{ display: "inline-block" }}>
                                            <span style={{ float: "left" }}>
                                              <IconContext.Provider value={{}}>
                                                <FaMemory />
                                              </IconContext.Provider>
                                            </span>
                                            <span style={{ marginLeft: "10px" }}>
                                              {" "}
                                              Total Channel File Posts
                                            </span>
                                          </div>
                                          <span
                                            className="stats-right"
                                            style={{ float: "right" }}
                                          >
                                            {
                                              main_data[val][
                                              "No.of total channel file posts avaialable"
                                              ]
                                            }
                                          </span>
                                        </div>
                                        <div className="stats-body">
                                          <div style={{ display: "inline-block" }}>
                                            <span style={{ float: "left" }}>
                                              <IconContext.Provider value={{}}>
                                                <FaIdBadge />
                                              </IconContext.Provider>
                                            </span>
                                            <span style={{ marginLeft: "10px" }}>
                                              {" "}
                                              Total Channel Forwarded Posts
                                            </span>
                                          </div>
                                          <span
                                            className="stats-right"
                                            style={{ float: "right" }}
                                          >
                                            {
                                              main_data[val][
                                              "No.of total channel forwarded posts avaialable"
                                              ]
                                            }
                                          </span>
                                        </div>
                                        <div className="stats-body">
                                          <div style={{ display: "inline-block" }}>
                                            <span style={{ float: "left" }}>
                                              <IconContext.Provider value={{}}>
                                                <FaPeopleCarry />
                                              </IconContext.Provider>
                                            </span>
                                            <span style={{ marginLeft: "10px" }}>
                                              {" "}
                                              Total Unique Channel Scraped
                                            </span>
                                          </div>
                                          <span
                                            className="stats-right"
                                            style={{ float: "right" }}
                                          >
                                            {
                                              main_data[val][
                                              "No.of total unique channel "
                                              ]
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <h5 style={{ color: "white" }}>Group </h5>
                                      <div className="box-body p-0">
                                        <div className="stats-body">
                                          <div style={{ display: "inline-block" }}>
                                            <span style={{ float: "left" }}>
                                              <IconContext.Provider value={{}}>
                                                <FaIdBadge />
                                              </IconContext.Provider>
                                            </span>
                                            <span style={{ marginLeft: "10px" }}>
                                              {" "}
                                              Total Group posts
                                            </span>
                                          </div>
                                          <span
                                            className="stats-right"
                                            style={{ float: "right" }}
                                          >
                                            {
                                              main_data[val][
                                              "No.of total group posts avaialable"
                                              ]
                                            }
                                          </span>
                                        </div>
                                        <div className="stats-body">
                                          <div style={{ display: "inline-block" }}>
                                            <span style={{ float: "left" }}>
                                              <IconContext.Provider value={{}}>
                                                <FaMemory />
                                              </IconContext.Provider>
                                            </span>
                                            <span style={{ marginLeft: "10px" }}>
                                              {" "}
                                              Total Group File Posts
                                            </span>
                                          </div>
                                          <span
                                            className="stats-right"
                                            style={{ float: "right" }}
                                          >
                                            {
                                              main_data[val][
                                              "No.of total group file posts avaialable"
                                              ]
                                            }
                                          </span>
                                        </div>
                                        <div className="stats-body">
                                          <div style={{ display: "inline-block" }}>
                                            <span style={{ float: "left" }}>
                                              <IconContext.Provider value={{}}>
                                                <FaIdBadge />
                                              </IconContext.Provider>
                                            </span>
                                            <span style={{ marginLeft: "10px" }}>
                                              {" "}
                                              Total Group Forwarded Posts
                                            </span>
                                          </div>
                                          <span
                                            className="stats-right"
                                            style={{ float: "right" }}
                                          >
                                            {
                                              main_data[val][
                                              "No.of total group forwarded posts avaialable"
                                              ]
                                            }
                                          </span>
                                        </div>
                                        <div className="stats-body">
                                          <div style={{ display: "inline-block" }}>
                                            <span style={{ float: "left" }}>
                                              <IconContext.Provider value={{}}>
                                                <FaPeopleCarry />
                                              </IconContext.Provider>
                                            </span>
                                            <span style={{ marginLeft: "10px" }}>
                                              {" "}
                                              Total Unique Group Scraped
                                            </span>
                                          </div>
                                          <span
                                            className="stats-right"
                                            style={{ float: "right" }}
                                          >
                                            {
                                              main_data[val][
                                              "No.of total unique group "
                                              ]
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <h5 className="mt-3" style={{ color: "white" }}>Total Aggregation </h5>
                                      <div className="box-body p-0 mb-3">
                                        <div className="stats-body">
                                          <div style={{ display: "inline-block" }}>
                                            <span style={{ float: "left" }}>
                                              <IconContext.Provider value={{}}>
                                                <FaIdBadge />
                                              </IconContext.Provider>
                                            </span>
                                            <span style={{ marginLeft: "10px" }}>
                                              {" "}
                                              Total Combined Posts Ingested
                                            </span>
                                          </div>
                                          <span
                                            className="stats-right"
                                            style={{ float: "right" }}
                                          >
                                            {
                                              main_data[val][
                                              "No.of all total posts"
                                              ]
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ))
                            : null}
                        </div>
                      } */}
                      {/* {main_data === null ? <button onClick={fetch_posts} className="load_scraper_btn">Load scraper stats</button> : null} */}

                      {/* Search Scrapper */}
                      <div
                        className="container"
                        style={{ position: "relative" }}
                      >
                        <div
                          className="row"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="col-lg-7 mb-5">
                            <div
                              className="  rounded rounded-pill  shadow-sm mb-4"
                              style={{
                                border: ".5px solid #1FCECB",
                                padding: "0.4rem",
                              }}
                            >
                              <Formik
                                initialValues={{ search: "" }}
                                validate={() => {
                                  const errors = {};
                                  return errors;
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                  setTimeout(() => {
                                    get_scraper_data_search(
                                      values.search,
                                      search_filter,
                                    );
                                    setSubmitting(false);
                                  });
                                }}
                              >
                                {({
                                  values,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                }) => (
                                  <form
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                  >
                                    <div className="input-group">
                                      <div className="dropdown_search_scrapper">
                                        <FormControl
                                          sx={{
                                            minWidth: 120,
                                            borderRight: "2px solid whitesmoke",
                                            mt: 0.5,
                                          }}
                                          size="small"
                                        >
                                          <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={search_filter}
                                            onChange={(e) => {
                                              set_search_filter(e.target.value);
                                              if (
                                                e.target.value === "category"
                                              ) {
                                                set_drop_down(true);
                                                set_search_value("");
                                              } else {
                                                if (dropdown) {
                                                  set_drop_down(false);
                                                }
                                                values.search = "";
                                              }
                                            }}
                                            sx={{ width: "100%", mt: 1 }}
                                            disableUnderline
                                            variant="standard"
                                          >
                                            <MenuItem value="channel_name">
                                              Channel Name
                                            </MenuItem>
                                            <MenuItem value="channel_link">
                                              Channel Link
                                            </MenuItem>
                                            <MenuItem value="category">
                                              Category
                                            </MenuItem>
                                            <MenuItem value="phone">
                                              Phone
                                            </MenuItem>
                                            <MenuItem value="server_ip">
                                              Server IP
                                            </MenuItem>
                                            <MenuItem value="scraper_name">
                                              Scraper Name
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </div>
                                      <input
                                        id="srch_tab"
                                        onChange={(e) => {
                                          if (search_filter === "category")
                                            return;
                                          handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        onClick={() => {
                                          if (search_filter === "category") {
                                            set_drop_down(true);
                                          }
                                        }}
                                        type="search"
                                        placeholder="Search here ..."
                                        name="search"
                                        value={
                                          search_filter !== "category"
                                            ? values.search
                                            : search_value
                                        }
                                        className="form-control border-0 new-dk"
                                      />
                                      <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        style={{ display: "none" }}
                                      />
                                    </div>
                                  </form>
                                )}
                              </Formik>
                            </div>
                            {scrapper_data_search_status.loading === false &&
                            dropdown === false &&
                            scrapper_data_search.length > 0 &&
                            !scrapper_data_search_status.error ? (
                              <p className="info_text_scrapper">
                                The Data shown are after 24, july, 2023
                              </p>
                            ) : null}
                          </div>
                        </div>
                        {dropdown === true ? (
                          <ClickAwayListener
                            onClickAway={() => set_drop_down(false)}
                          >
                            <div
                              className="autocom-box w-50 m-auto position-absolute"
                              style={{ zIndex: "50", left: "25%", top: "60%" }}
                            >
                              <h4
                                style={{
                                  paddingLeft: "1rem",
                                  marginLeft: "15px",
                                  marginTop: "5px",
                                  fontSize: "18px",
                                  color: "whitesmoke",
                                }}
                              >
                                Choose a category
                              </h4>
                              <ul>
                                {categories_array_admin_scraper.map(
                                  (val, id) => {
                                    let filter_name = "";
                                    if (val.includes("_")) {
                                      filter_name = val.replaceAll("_", " ");
                                    } else if (val === "telegram2") {
                                      filter_name = "telegram";
                                    } else if (val === "extremepolitical2") {
                                      filter_name = "political";
                                    } else filter_name = val;
                                    return (
                                      <li key={id}>
                                        <div
                                          className="row category_row_scraper"
                                          onClick={() =>
                                            get_scraper_data_search(
                                              val,
                                              "category",
                                              filter_name,
                                            )
                                          }
                                        >
                                          <div
                                            className="col-md-8"
                                            style={{ textAlign: "left" }}
                                          >
                                            {" "}
                                            <img
                                              style={{
                                                width: "36px",
                                                height: "36px",
                                                padding: "2px",
                                                border: "2px solid #f4f4f4",
                                              }}
                                              className="img-fluid rounded-circle"
                                              src={
                                                val.channel_id !== "None"
                                                  ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                                  : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                              }
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src =
                                                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                              }}
                                              alt="User"
                                            />
                                            <span
                                              className="channels_list_map"
                                              style={{
                                                marginLeft: "10px",
                                                color: "#dcf3ff",
                                              }}
                                            >
                                              {filter_name}
                                            </span>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  },
                                )}
                              </ul>
                            </div>
                          </ClickAwayListener>
                        ) : null}
                      </div>

                      {/* Scrapper Search Table */}
                      {scrapper_data_search_status.loading === false ? (
                        scrapper_data_search_status.error_status === true ||
                        scrapper_data_search_status.error ? (
                          <div className="main__table no_data">
                            {scrapper_data_search_status.error}
                          </div>
                        ) : scrapper_data_search_status.fetch_status === true &&
                          scrapper_data_search.length === 0 ? (
                          <div className="main__table no_data">
                            No Data to Display
                          </div>
                        ) : scrapper_data_search_status.fetch_status === true &&
                          scrapper_data_search.length > 0 ? (
                          <div className="deployed_scrapper_container">
                            <div className="total_data_number">
                              <h6>
                                Results shown:{" "}
                                <span>{scrapper_data_search.length}</span>
                              </h6>
                              <div className="total_data_number_child">
                                <h6>
                                  Total No of Posts :{" "}
                                  <span className="credit_limit_colors">
                                    {total_no_of_posts_scraper}
                                  </span>
                                </h6>
                              </div>
                            </div>
                            <GridTable
                              scraper_data={scrapper_data_search}
                              table_type="scraper_search_table"
                            />
                          </div>
                        ) : null
                      ) : (
                        <div className="spinner" style={{ height: "30vh" }}>
                          <ThreeCircles
                            color="#28cab3"
                            height={60}
                            width={60}
                            ariaLabel="three-circles-rotating"
                          />
                        </div>
                      )}
                      {/* Scrapper Stats Chart */}
                      <span
                        style={{ marginTop: "20px" }}
                        className={
                          users.dark_theme
                            ? "dk-mode line-headsmall"
                            : "line-headsmall"
                        }
                      >
                        <h2
                          style={{ fontSize: "20px" }}
                          className={
                            users.dark_theme ? "dk-mode txt-line" : "txt-line"
                          }
                        >
                          Scraper Stats
                        </h2>
                      </span>

                      <div className="scrapper_stats_container">
                        {scrapper_stats_status.loading === false ? (
                          <div className="scrapper_stats_chart_parent_div">
                            {Object.keys(scrapper_stats).length > 0 &&
                              Object.keys(scrapper_stats).map((item, index) => (
                                <div
                                  className="scrapper_stats_chart_child_div"
                                  key={index}
                                >
                                  <div className="comp_stats_heading">
                                    <p>{item}</p>
                                  </div>
                                  <div className="scrapper_stats_chart">
                                    <ResponsiveContainer
                                      width="100%"
                                      height="100%"
                                    >
                                      <LineChart
                                        data={scrapper_stats[item].data}
                                        margin={{
                                          top: 5,
                                          right: 10,
                                          left: 0,
                                          bottom: 5,
                                        }}
                                        isAnimationActive={false}
                                      >
                                        <CartesianGrid
                                          strokeDasharray="0"
                                          fillOpacity={0.1}
                                          strokeWidth={0.2}
                                        />
                                        <XAxis
                                          dataKey="name"
                                          interval={0}
                                          tick={{
                                            fontSize: 10,
                                            fill: "white",
                                          }}
                                          angle={-45} // Adjust the angle of the labels
                                          textAnchor="end" // Align the labels to the end
                                          height={50}
                                        />
                                        <YAxis
                                          tick={{
                                            fontSize: 10,
                                            fill: "white",
                                          }}
                                        />
                                        <Legend
                                          iconType="circle"
                                          width="100%"
                                        />
                                        <Tooltip
                                          cursor={false}
                                          position={{ x: 20, y: 15 }}
                                          contentStyle={{
                                            background: "#0C151A",
                                            border: "none",
                                            textAlign: "start",
                                          }}
                                          wrapperClassName={
                                            item === "83.229.71.89"
                                              ? "custom_tooltip_styling"
                                              : "default_tooltip_styling"
                                          }
                                          isAnimationActive
                                        />
                                        {Object.keys(
                                          scrapper_stats[item].data[0],
                                        ).map((val, index) => {
                                          if (val === "name") {
                                            return null;
                                          }
                                          return (
                                            <Line
                                              type="monotone"
                                              dataKey={val}
                                              stroke={
                                                colorArrayScarperChart[index]
                                              }
                                              activeDot={{ r: 5 }}
                                              key={index}
                                            />
                                          );
                                        })}
                                      </LineChart>
                                    </ResponsiveContainer>
                                  </div>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <RoundAnimationLoader />
                        )}
                      </div>
                      <span
                        style={{ marginTop: "20px" }}
                        className={
                          users.dark_theme
                            ? "dk-mode line-headsmall"
                            : "line-headsmall"
                        }
                      >
                        <h2
                          style={{ fontSize: "20px" }}
                          className={
                            users.dark_theme ? "dk-mode txt-line" : "txt-line"
                          }
                        >
                          VPN Tracker System
                        </h2>
                      </span>
                      {vpn_tracker.loading === true ? (
                        <RoundAnimationLoader />
                      ) : vpn_tracker.error || vpn_tracker.data.length === 0 ? (
                        <div className="main__table no_data">
                          {vpn_tracker.error || "No Data to Display"}
                        </div>
                      ) : (
                        <div className="deployed_scrapper_container">
                          <GridTable
                            scraper_data={vpn_tracker["data"]}
                            table_type="vpn_tracker_table"
                          />
                        </div>
                      )}

                      {/* Deployed Scrapper Table */}
                      <span
                        style={{ marginTop: "20px" }}
                        className={
                          users.dark_theme
                            ? "dk-mode line-headsmall"
                            : "line-headsmall"
                        }
                      >
                        <h2
                          style={{ fontSize: "20px" }}
                          className={
                            users.dark_theme ? "dk-mode txt-line" : "txt-line"
                          }
                        >
                          Deployed Scrapers Info
                        </h2>
                      </span>
                      <div className="deployed_scrapper_container">
                        {deployed_scrappers.loading === false ? (
                          <table>
                            <thead>
                              <tr>
                                <th>Scrapper Name</th>
                                <th style={{ flex: 1 }}>Scrapper IP</th>
                                <th>Phone</th>
                                <th>Date</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {deployed_scrappers.data.length > 0 &&
                                deployed_scrappers.data.map((item, id) => (
                                  <tr key={id}>
                                    <td>{item.scraper_name}</td>
                                    <td>{item.server_ip}</td>
                                    <td>{item.phone}</td>
                                    <td>
                                      {handle_tooltip_date(
                                        item.last_updated,
                                        "daily",
                                      )}
                                    </td>
                                    <td>
                                      <tooltip_box
                                        title={
                                          item.active === "True"
                                            ? "Active"
                                            : "Inactive"
                                        }
                                        // eslint-disable-next-line react/no-unknown-property
                                        disableInteractive
                                      >
                                        <span
                                          className={
                                            item.active === "True"
                                              ? "deployed_scrapper_status_sign sign_color_active"
                                              : "deployed_scrapper_status_sign sign_color_inactive"
                                          }
                                        />
                                      </tooltip_box>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          <RoundAnimationLoader />
                        )}
                      </div>

                      <span
                        style={{ marginTop: "20px" }}
                        className={
                          users.dark_theme
                            ? "dk-mode line-headsmall"
                            : "line-headsmall"
                        }
                      >
                        {" "}
                        <h2
                          style={{ fontSize: "20px" }}
                          className={
                            users.dark_theme ? "dk-mode txt-line" : "txt-line"
                          }
                        >
                          Sim Scrapers Info
                        </h2>
                      </span>
                      <GridTable
                        scraper_data={scraper_data}
                        table_type="scraper_info_table"
                      />
                    </div>
                  </section>
                  <div
                    className="modal fade"
                    id="downloadmodal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="downloadmodal"
                    aria-hidden="true"
                    style={{ background: "rgba(0, 0, 0, 0.5)" }}
                  >
                    <div
                      className="modal-dialog modal-confirm"
                      style={{ background: "white", marginTop: "13rem" }}
                    >
                      <div className="modal-content dk-mode">
                        <div className="modal-header flex-column">
                          <h4
                            className="modal-title w-100"
                            style={{ fontSize: "18px", color: "#359daf" }}
                          >
                            Please Wait, The Stats are being calculated ...
                          </h4>
                        </div>
                        <div
                          className="modal-body"
                          style={{ marginTop: "50px" }}
                        >
                          <div
                            className="load-wrapper"
                            style={{ marginLeft: "200px" }}
                          >
                            <div
                              className="load-circle"
                              style={{ background: "#359daf" }}
                            />
                            <div
                              className="load-circle"
                              style={{ background: "#359daf" }}
                            />
                            <div
                              className="load-circle"
                              style={{ background: "#359daf" }}
                            >
                              {" "}
                            </div>
                            <div className="load-shadow" />
                            <div className="load-shadow" />
                            <div className="load-shadow" />
                            <span style={{ left: "2%", color: "#359daf" }}>
                              Calculating
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </Grid>
      </Grid>
      <ToastContainer />
    </div>
  );
}
const mapStateToProps = (state) => ({
  users: state.users,
  admin_reducer: state.admin_reducer,
});
Adminscraper.propTypes = {
  users: PropTypes.object,
  admin_reducer: PropTypes.object,
};
export default connect(mapStateToProps, { date_fm })(Adminscraper);
