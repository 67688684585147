import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Stack } from "@mui/material";
import Sidenav from "../../TableUi/components/Navbar/Sidenav";
import Adminmainnav from "./Adminmainnav";
import { RollerAnimation } from "../../TableUi/components/loader/Loader";
import { handle_session_get } from "../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../utils/Validation/Token/token_checker";
import { Formik, ErrorMessage } from "formik";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { handle_string_to_array } from "./handle_admin_function";
import "../../css/Admin/admin.css";

function AddCredentialIndustry({
  handle_get_industry_credential,
  handle_add_industry_credential,
  handle_delete_industry_credential,
  industry_detail,
}) {
  const [channel_query, set_channel_query] = useState("");
  const [channel_list, set_channel__list] = useState([[]]);
  const [added_channel_list, set_added_channel_list] = useState([]);
  const [card_filters, set_card_filters] = useState("bank_card");
  const [industry_name, set_industry_name] = useState("");
  const [product, set_product] = useState("");
  const [domain, set_domain] = useState("");
  const [tag, set_tag] = useState({
    bank_card: "",
    email: "",
    ip_address: "",
    crypto: "",
    phone: "",
    bin: "",
  });
  const [stat_loading, set_stat_loading] = useState(false);
  const [channel_error, set_channel_error] = useState("");
  const [add_status, set_add_status] = useState("create_new");
  const [row_id, set_row_id] = useState();

  useEffect(() => {
    handle_get_industry_credential();
  }, []);

  const token = handle_session_get("Token");

  const handle_channel_suggestion = (e) => {
    set_channel_query(e.target.value);
    if (e.target.value.length >= 2) {
      set_stat_loading(true);
      const data = { qtext: e.target.value };
      axios
        .post("https://api.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const filter_data = res.data.filter((item) => item.link !== "None");
          set_channel__list(filter_data);
          set_stat_loading(false);
        })
        .catch((err) => {
          try {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            set_stat_loading(false);
            set_channel_error(err.response.data.errormsg);
          } catch {
            //Do nothing
          }
        });
    } else {
      set_channel__list(null);
    }
  };
  const handle_delete_channel = (channel) => {
    const dublicate_channel = [...added_channel_list];
    const filter_channel = dublicate_channel.filter((item) => item !== channel);
    set_added_channel_list(filter_channel);
  };

  const channels_list_chips = added_channel_list.map((item, index) => (
    <Chip
      label={item}
      onDelete={() => handle_delete_channel(item)}
      color="success"
      key={index}
      sx={{ m: "2px" }}
    />
  ));

  const handle_validate_item = (field, type) => {
    try {
      if (field !== null && field) {
        if (Array.isArray(field) && field.length > 0) {
          if (type !== "tag") {
            return field.toString();
          } else {
            return field.map((item) => JSON.stringify(item)).join(", ");
          }
        } else return "None";
      } else return "None";
    } catch {
      return "None";
    }
  };

  const handle_set_data_update = (row) => {
    try {
      const filter_product =
        handle_validate_item(row.product, "product") !== "None"
          ? row.product.join("\n")
          : "";
      const filter_domain =
        handle_validate_item(row.domain, "domain") !== "None"
          ? row.domain.join("\n")
          : "";
      const filter_channel =
        handle_validate_item(row.channel, "domain") !== "None"
          ? row.channel
          : [];
      const check_tag =
        handle_validate_item(row.tag, "tag") !== "None" ? row.tag : [];
      const parsed_check_tag = check_tag.map((item) =>
        JSON.parse(item.replace(/'/g, '"')),
      );

      const filter_tag = {
        bank_card: "",
        email: "",
        ip_address: "",
        crypto: "",
        phone: "",
        bin: "",
      };
      parsed_check_tag.forEach((item, index) => {
        const key_item = Object.keys(item)[0];
        const isLast =
          index === parsed_check_tag.length - 1 ||
          Object.keys(parsed_check_tag[index + 1])[0] !== key_item;
        filter_tag[key_item] += item[key_item] + (isLast ? "" : "\n");
      });
      set_product(filter_product);
      set_domain(filter_domain);
      set_added_channel_list(filter_channel);
      set_tag(filter_tag);
      set_industry_name(row.name || "");
    } catch {
      //Do nothing
    }
  };
  const handle_reset_inputs = () => {
    set_product("");
    set_domain("");
    set_tag({
      bank_card: "",
      email: "",
      ip_address: "",
      crypto: "",
      phone: "",
      bin: "",
    });
    set_added_channel_list([]);
    set_industry_name("");
    set_add_status("create_new");
  };
  return (
    <div>
      <div className="wrapper">
        <Grid container>
          <Grid item xs={0.5}>
            <Sidenav />
          </Grid>
          <Grid item xs={11.5}>
            <div id="content" className={"active"}>
              <section>
                <Adminmainnav />
                <div className="add_credential_industry_container">
                  <main className="d-flex align-items-center">
                    <div className="container">
                      <div className="login-card add_credential_industry_container_login-card">
                        <ToastContainer />
                        <div className="col-md-12">
                          <div className="card-body">
                            <p className="login_form_heading">
                              Industry Preference
                            </p>
                            <Stack
                              direction="row"
                              style={{ width: "92%", marginTop: "-32px" }}
                              justifyContent="flex-end"
                            >
                              <button
                                className="clear_all_marked_channels_btn"
                                onClick={() => {
                                  handle_reset_inputs();
                                }}
                              >
                                Reset
                              </button>
                            </Stack>
                            <Formik
                              initialValues={{
                                industry_name: "",
                                product_list: "",
                                domain_list: "",
                                tag_list: "",
                                channel_group: "",
                              }}
                              validate={() => {
                                const errors = {};
                                return errors;
                              }}
                              onSubmit={() => {
                                const product_list =
                                  handle_string_to_array(product);
                                const domain_list =
                                  handle_string_to_array(domain);
                                const tag_list = [];
                                for (let key in tag) {
                                  const tag_item = handle_string_to_array(
                                    tag[key],
                                  );
                                  if (Array.isArray(tag_item)) {
                                    tag_item.forEach((item) => {
                                      tag_list.push({ [key]: item });
                                    });
                                  }
                                }
                                handle_add_industry_credential(
                                  industry_name,
                                  product_list,
                                  domain_list,
                                  added_channel_list,
                                  tag_list,
                                  add_status,
                                  row_id,
                                );

                                setTimeout(() => handle_reset_inputs());
                              }}
                            >
                              {({ handleChange, handleBlur, handleSubmit }) => (
                                <form
                                  onSubmit={handleSubmit}
                                  autoComplete="off"
                                >
                                  <div className="regex_add_div">
                                    <label
                                      htmlFor="query"
                                      className="login_form_label"
                                    >
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      name="industry_name"
                                      id="industry_name"
                                      className="global_input_box w-100"
                                      placeholder="enter industry name"
                                      required
                                      value={industry_name}
                                      onChange={(event) => {
                                        handleChange(event);
                                        set_industry_name(event.target.value);
                                      }}
                                      onBlur={handleBlur}
                                      autoComplete="off"
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="industry_name"
                                      className="login_form_error_msg"
                                    />
                                  </div>
                                  <div className="regex_add_div">
                                    <label
                                      htmlFor="name"
                                      className="login_form_label"
                                    >
                                      Enter Products
                                    </label>
                                    <textarea
                                      type="text"
                                      name="product_list"
                                      id="product_list"
                                      className="global_input_box w-100"
                                      placeholder="enter products"
                                      rows="5"
                                      cols="5"
                                      value={product}
                                      onChange={(event) => {
                                        handleChange(event);
                                        set_product(event.target.value);
                                      }}
                                      onBlur={handleBlur}
                                      autoComplete="off"
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="product_list"
                                      className="login_form_error_msg"
                                    />
                                  </div>
                                  <div className="regex_add_div">
                                    <label
                                      htmlFor="name"
                                      className="login_form_label"
                                    >
                                      Enter Domains
                                    </label>
                                    <textarea
                                      type="text"
                                      name="domain_list"
                                      id="domain_list"
                                      className="global_input_box w-100"
                                      placeholder="enter domains"
                                      rows="5"
                                      cols="5"
                                      value={domain}
                                      onChange={(event) => {
                                        handleChange(event);
                                        set_domain(event.target.value);
                                      }}
                                      onBlur={handleBlur}
                                      autoComplete="off"
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="domain_list"
                                      className="login_form_error_msg"
                                    />
                                  </div>

                                  <div className="regex_add_div">
                                    <label
                                      htmlFor="name"
                                      className="login_form_label"
                                    >
                                      Enter Tag
                                    </label>
                                    <div className="industry_pref_tag_input_box">
                                      <div className="comp_dash_tag_dropdown">
                                        <FormControl
                                          sx={{
                                            minWidth: 120,
                                            border:
                                              "1px solid rgba(207, 205, 205, 0.39)",
                                            borderRadius: "8px",
                                            mt: 0.5,
                                          }}
                                        >
                                          <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={card_filters}
                                            onChange={(e) => {
                                              set_card_filters(e.target.value);
                                            }}
                                            sx={{
                                              width: "98%",
                                              mt: 0.6,
                                              color: "#dcf3ff",
                                            }}
                                            disableUnderline
                                            variant="standard"
                                          >
                                            <MenuItem value="bank_card">
                                              Bank card
                                            </MenuItem>
                                            <MenuItem value="email">
                                              Email
                                            </MenuItem>
                                            <MenuItem value="ip_address">
                                              Ip address
                                            </MenuItem>
                                            <MenuItem value="crypto">
                                              Crypto
                                            </MenuItem>
                                            <MenuItem value="phone">
                                              Phone
                                            </MenuItem>
                                            <MenuItem value="bin">BIN</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </div>
                                      <textarea
                                        type="text"
                                        name="tag_list"
                                        id="tag_list"
                                        className="global_input_box w-100"
                                        placeholder="enter tags"
                                        value={tag[card_filters]}
                                        onChange={(event) => {
                                          handleChange(event);
                                          set_tag((prev) => ({
                                            ...prev,
                                            [card_filters]: event.target.value,
                                          }));
                                        }}
                                        onBlur={handleBlur}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>

                                  <div className="industry_pref_channel_container">
                                    <div className="regex_add_div">
                                      <label
                                        htmlFor="channel_group"
                                        className="login_form_label"
                                      >
                                        Channel/Group
                                      </label>
                                      <input
                                        type="text"
                                        name="channel_group"
                                        id="channel_group"
                                        className="global_input_box w-100"
                                        placeholder="enter channel/group"
                                        value={channel_query}
                                        onChange={(event) => {
                                          handle_channel_suggestion(event);
                                        }}
                                        onBlur={handleBlur}
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div>{channels_list_chips}</div>
                                    {/* list of channels */}
                                    {channel_query.length > 2 && (
                                      <div className="autocom-box w-80">
                                        {stat_loading ? (
                                          <RollerAnimation />
                                        ) : channel_error ? (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              fontSize: "larger",
                                              color: "white",
                                              marginTop: "10%",
                                            }}
                                          >
                                            {channel_error}
                                          </div>
                                        ) : channel_list !== null &&
                                          channel_list.length === 0 ? (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              fontSize: "larger",
                                              color: "white",
                                              marginTop: "10%",
                                            }}
                                          >
                                            No Data to display
                                          </div>
                                        ) : (
                                          <>
                                            <h4
                                              style={{
                                                paddingLeft: "1rem",
                                                marginLeft: "15px",
                                                marginTop: "5px",
                                                fontSize: "18px",
                                                color: "whitesmoke",
                                              }}
                                            >
                                              List Of Channels
                                            </h4>
                                            <ul>
                                              {channel_list !== null &&
                                                channel_list.map(
                                                  (val, index) => (
                                                    <li key={index}>
                                                      <div className="row">
                                                        <div
                                                          className="col-md-8"
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          {" "}
                                                          <img
                                                            style={{
                                                              width: "36px",
                                                              height: "36px",
                                                              padding: "2px",
                                                              border:
                                                                "2px solid #f4f4f4",
                                                            }}
                                                            className="img-fluid rounded-circle"
                                                            src={
                                                              val.channel_id !==
                                                              "None"
                                                                ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                                            }
                                                            onError={(e) => {
                                                              e.target.onerror =
                                                                null;
                                                              e.target.src =
                                                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                                            }}
                                                            alt="User"
                                                          />
                                                          <span
                                                            className="channels_list_map"
                                                            style={{
                                                              marginLeft:
                                                                "10px",
                                                              color: "#dcf3ff",
                                                            }}
                                                          >
                                                            {val.channel_name}
                                                          </span>
                                                        </div>
                                                        <div
                                                          className="col-md-4"
                                                          style={{
                                                            textAlign: "right",
                                                            marginTop: "5px",
                                                          }}
                                                        >
                                                          {added_channel_list.includes(
                                                            val.channel_name,
                                                          ) === false ? (
                                                            <button
                                                              type="button"
                                                              style={{
                                                                borderRadius:
                                                                  "40px",
                                                                background:
                                                                  "#d03940",
                                                                color: "white",
                                                                lineHeight: "1",
                                                              }}
                                                              className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                                              onClick={() => {
                                                                set_added_channel_list(
                                                                  (prev) => [
                                                                    ...prev,
                                                                    val.channel_name,
                                                                  ],
                                                                );
                                                                set_channel_query(
                                                                  "",
                                                                );
                                                              }}
                                                            >
                                                              Add
                                                            </button>
                                                          ) : (
                                                            <button
                                                              type="button"
                                                              disabled
                                                              style={{
                                                                borderRadius:
                                                                  "40px",
                                                                background:
                                                                  "#4cabd9",
                                                                color: "white",
                                                                lineHeight: "1",
                                                              }}
                                                              className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                                            >
                                                              Added
                                                            </button>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </li>
                                                  ),
                                                )}
                                            </ul>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <input
                                    name="add"
                                    id="add"
                                    className="login-form-control-btn"
                                    type="submit"
                                    value="Add"
                                    defaultValue="Add"
                                  />
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
                {industry_detail["loading"] === false ? (
                  industry_detail["error"] ||
                  industry_detail["data"].length === 0 ? (
                    <div className="main__table no_data">
                      {industry_detail["error"] || "No data to display"}
                    </div>
                  ) : (
                    <div className="credentail_insertion_table_container">
                      <table>
                        <thead>
                          <tr>
                            <th style={{ width: "5%" }}>Name</th>
                            <th style={{ width: "20%" }}>Products</th>
                            <th style={{ width: "20%" }}>Domains</th>
                            <th style={{ width: "15%" }}>channels</th>
                            <th style={{ width: "15%" }}>Tags</th>
                            <th style={{ width: "5%" }}>Edit</th>
                            <th style={{ width: "5%" }}>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {industry_detail["data"].length > 0 &&
                            industry_detail["data"].map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.name ?? "None"}</td>
                                  <td>
                                    {handle_validate_item(
                                      item.product,
                                      "product",
                                    )}
                                  </td>
                                  <td>
                                    {handle_validate_item(
                                      item.domain,
                                      "domain",
                                    )}
                                  </td>
                                  <td>
                                    {handle_validate_item(
                                      item.channel,
                                      "channel",
                                    )}
                                  </td>
                                  <td>
                                    {handle_validate_item(item.tag, "tag")}
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => {
                                        handle_set_data_update(item);
                                        set_add_status("update");
                                        set_row_id(item.id);
                                      }}
                                      className="credential_btn credential_btn_edit"
                                    >
                                      Edit
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      className="credential_btn credential_btn_delete"
                                      onClick={() => {
                                        handle_delete_industry_credential(
                                          item.id,
                                        );
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )
                ) : (
                  <RollerAnimation />
                )}
              </section>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
AddCredentialIndustry.propTypes = {
  handle_get_industry_credential: PropTypes.func,
  handle_add_industry_credential: PropTypes.func,
  handle_delete_industry_credential: PropTypes.func,
  industry_detail: PropTypes.object,
};
export default AddCredentialIndustry;
