import React, { memo, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Formik } from "formik";
import Badge from "@mui/material/Badge";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { handle_disable_radio_btn } from "../../../utils/SessionStorage/handle_session";
import { handle_warning_message } from "../../../utils/ResponseMessage/response_message";
import { filter_context } from "../searchbar/Searchbar";
import Datepicker from "../sections/DatePicker/Datepicker";
import "../../../css/Stealerlogs/stealerlogs.css";
function StealerLogsMultipleSearch({ open_modal, set_open_modal }) {
  const {
    display_type_stealer,
    set_display_type_stealer,
    max_result_stealer,
    set_max_result_stealer,
    search_type_stealer,
    set_search_type_stealer,
    stealer_date_from,
    set_stealer_date_from,
    stealer_date_to,
    set_stealer_date_to,
    stealer_date_log,
    set_stealer_date_log,
    stealer_multiple_obj,
    set_stealer_multiple_obj,
    search_sender,
    stealer_auto_fields,
    set_stealer_auto_fields,
    post_category_stealer,
    set_post_category_stealer,
    is_auto_search,
  } = useContext(filter_context)["stealer_filters"];
  useEffect(() => {
    set_stealer_multiple_obj({});
  }, []);
  const handle_stealer_search_type = (event) => {
    set_search_type_stealer(event.target.value);
  };
  const handle_stealer_display_type = (event) => {
    set_display_type_stealer(event.target.value);
  };
  const handle_max_result_stealer = (event) => {
    set_max_result_stealer(event.target.value);
  };
  const handle_change_stealer_input = (event) => {
    const { id } = event.target;
    const { value } = event.target;
    set_stealer_multiple_obj((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <div>
      <Modal
        open={open_modal}
        onClose={() => set_open_modal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="container_modal_nested_channel scroll-custom"
          id="container_modal_nested_channel"
        >
          <div className="modal_email_alert_close">
            <button
              className="modal-heading-closeBtn"
              onClick={() => set_open_modal(false)}
            >
              <CancelIcon sx={{ color: "#eb0000 ", cursor: "pointer" }} />
            </button>
          </div>
          <div className="modal_nested_channel_desc modal_stealer_logs_multiple_search_formik">
            <Formik
              initialValues={{
                email: "",
                domain: "",
                password: "",
                country_code: "",
                ip_address: "",
                zip: "",
                location: "",
              }}
              validate={() => {
                const errors = {};
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (is_auto_search === true) {
                  search_sender();
                  set_open_modal(false);
                  setSubmitting(false);
                  return;
                } else if (Object.keys(stealer_multiple_obj).length > 0) {
                  if (Object.values(stealer_multiple_obj).length > 0) {
                    const filter_array = Object.values(
                      stealer_multiple_obj,
                    ).filter((item) => item !== "");
                    if (filter_array.length === 0) {
                      handle_warning_message(
                        "Please enter atleast one input field",
                      );
                      return;
                    }
                  }
                } else if (Object.keys(stealer_multiple_obj).length === 0) {
                  handle_warning_message(
                    "Please enter atleast one input field",
                  );
                  return;
                }
                setTimeout(() => {
                  search_sender();
                  set_open_modal(false);
                  setSubmitting(false);
                });
              }}
            >
              {({ values, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <h5>Multi Search</h5>
                  <div
                    style={{
                      borderBottom: "0.3px solid #f5f5f55e",
                      width: "80%",
                    }}
                  />
                  <div className="modal_stealer_logs_multiple_search_input_container">
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="email">
                        Email / Username
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        className="global_input_box w-100"
                        value={values.email}
                        placeholder="enter email/username"
                      />
                    </div>
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="domain">
                        Domain
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="domain"
                        name="domain"
                        className="global_input_box w-100"
                        value={values.domain}
                        placeholder="enter domain"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="password"
                        name="password"
                        className="global_input_box w-100"
                        value={values.password}
                        placeholder="enter password"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label
                        className="global_input_label"
                        htmlFor="country_code"
                      >
                        Country Code
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="country_code"
                        name="country_code"
                        className="global_input_box w-100"
                        value={values.country_code}
                        placeholder="enter country code"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label
                        className="global_input_label"
                        htmlFor="ip_address"
                      >
                        IP Address
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="ip_address"
                        name="ip_address"
                        className="global_input_box w-100"
                        value={values.ip_address}
                        placeholder="ente ip address"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="zip">
                        Zip
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="zip"
                        name="zip"
                        className="global_input_box w-100"
                        value={values.zip}
                        placeholder="enter zip"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="location">
                        Location
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="location"
                        name="location"
                        className="global_input_box w-100"
                        value={values.location}
                        placeholder="enter location"
                      />
                    </div>
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="device_id">
                        MID/HWID/GUID
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="device_id"
                        name="device_id"
                        className="global_input_box w-100"
                        value={values.device_id}
                        placeholder="enter MID/HWID/GUID"
                      />
                    </div>
                  </div>
                  <h5
                    style={{
                      color: "var(--headingColor)",
                      marginBottom: 0,
                      marginTop: "2rem",
                    }}
                  >
                    Filters
                  </h5>
                  <div
                    style={{
                      borderBottom: "0.3px solid #f5f5f55e",
                      width: "80%",
                    }}
                  />
                  <div className="nested_channel_filters">
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Search Type
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={search_type_stealer}
                        onChange={handle_stealer_search_type}
                        sx={{ columnGap: 2 }}
                      >
                        <FormControlLabel
                          value="contains"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Contains"
                        />
                        <FormControlLabel
                          value="exact"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Exact"
                        />
                        <FormControlLabel
                          value="regex"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Regex"
                        />
                      </RadioGroup>
                    </FormControl>

                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        View Type
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={display_type_stealer}
                        onChange={handle_stealer_display_type}
                        sx={{ columnGap: 2 }}
                      >
                        <FormControlLabel
                          value="table"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Table"
                        />
                        <FormControlLabel
                          value="json"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="JSON"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Category
                      </FormLabel>
                      <Box
                        className="filter__category"
                        sx={{ height: "66%", paddingLeft: "1px" }}
                      >
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={post_category_stealer}
                          onChange={(e) =>
                            set_post_category_stealer(e.target.value)
                          }
                          sx={{ columnGap: 2 }}
                        >
                          <FormControlLabel
                            value={"hacking"}
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="Hacking"
                          />
                          <FormControlLabel
                            value={"pedophiles"}
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="Pedophiles"
                          />
                          <FormControlLabel
                            value={"russian defense"}
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="Russian"
                          />
                          <FormControlLabel
                            value={"terrorism"}
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="Terrorism"
                          />
                          <FormControlLabel
                            value={"off"}
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="OFF"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl>
                      <div className="dateRangePicker">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title"
                        >
                          Date
                        </FormLabel>
                        <Datepicker
                          date_from={stealer_date_from}
                          set_date_from={set_stealer_date_from}
                          date_to={stealer_date_to}
                          set_date_to={set_stealer_date_to}
                        />
                      </div>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Log Date
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={stealer_date_log}
                        onChange={(e) => set_stealer_date_log(e.target.value)}
                        sx={{ columnGap: 2 }}
                      >
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="OFF"
                        />
                        <FormControlLabel
                          value
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="ON"
                        />
                      </RadioGroup>
                    </FormControl>
                    {stealer_multiple_obj["email"] &&
                    stealer_multiple_obj["email"].length > 0 ? (
                      <FormControl>
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title"
                        >
                          Auto Fills
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={stealer_auto_fields}
                          onChange={(e) =>
                            set_stealer_auto_fields(e.target.value)
                          }
                          sx={{ columnGap: 2 }}
                        >
                          <FormControlLabel
                            value={false}
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="Exclude"
                          />
                          <FormControlLabel
                            value
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="Include"
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : null}
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Max result
                      </FormLabel>
                      <Box>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={max_result_stealer}
                          onChange={handle_max_result_stealer}
                          sx={{ columnGap: 2 }}
                        >
                          <FormControlLabel
                            value={50}
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="50"
                          />

                          <FormControlLabel
                            value={100}
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="100"
                          />

                          <FormControlLabel
                            value={500}
                            control={
                              <Radio
                                disabled={handle_disable_radio_btn()}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={
                              <Badge color="secondary" badgeContent="premium">
                                <span>500</span>
                              </Badge>
                            }
                          />

                          <FormControlLabel
                            value={1000}
                            control={
                              <Radio
                                disabled={handle_disable_radio_btn()}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={
                              <Badge color="secondary" badgeContent="premium">
                                <span>1000</span>
                              </Badge>
                            }
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                  </div>
                  <button type="submit" className="button_global_style">
                    Search
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
StealerLogsMultipleSearch.propTypes = {
  open_modal: PropTypes.bool,
  set_open_modal: PropTypes.func,
};

export default memo(StealerLogsMultipleSearch);
