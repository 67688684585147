import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { MyContext } from "../tabledata/TableData";
import {
  handle_session_get,
  handle_session_set,
} from "../../../utils/SessionStorage/handle_session";
import "../../../css/Display_flex/displayflex.css";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import TransparentContainer from "../sections/Charts/TransparentContainer";
import ChannelSeeMoreInfo from "../sections/Card/ChannelSeeMoreInfo";

function ModalFlex({ Data }) {
  const token = handle_session_get("Token");
  const [modal_flex_data, set_modal_flex_data] = useState([]);
  const [flex_loading, set_flex_loading] = useState(true);
  const [flex_error_status, set_flex_error_status] = useState(false);

  const [msg_id, set_msg_id] = useState(Data.msgid);
  const [scrollbar_top, set_scrollbar_top] = useState(true);
  const [scrollbar_bottom, set_scrollbar_bottom] = useState(false);
  const [msgid_first, set_msgid_first] = useState(null);
  const [msgid_last, set_msgid_last] = useState(null);

  const [is_reached_top, set_is_reached_top] = useState();
  const [is_reached_bottom, set_is_reached_bottom] = useState();

  const { set_telegram_limit } = useContext(MyContext);

  // function for the Show More text
  // function executeOnClick() { }
  const split_link = (
    links = Data.link,
    id = Data.to_id,
    name = Data.conv_name,
  ) => {
    if (name !== null && name !== "None") {
      return name;
    }
    if (links !== null && links !== "None") {
      if (links.includes("t.me")) {
        const new_link = links.replace("s/", "");
        const open_link = new_link.split("/");
        const final_link = open_link[open_link.length - 1];
        return final_link;
      }
      const new_link = links;
      return new_link;
    }
    return id;
  };

  useEffect(() => {
    if (scrollbar_top === true) {
      if (msgid_first !== null) {
        if (msgid_first.length === 0) {
          set_scrollbar_top(false);
          return;
        }
      }
      if (msg_id === Data.msgid) {
        get_modal_flex_data(msg_id, "default");
      } else {
        get_modal_flex_data(msg_id, "before");
      }
    } else if (scrollbar_bottom === true) {
      if (msgid_last !== null) {
        if (msgid_last.length === 0) {
          set_scrollbar_bottom(false);
          return;
        }
      }
      get_modal_flex_data_scrollend(msg_id, "after");
    }
  }, [msg_id]);

  let isSubmitBlocked = false;
  const get_modal_flex_data = (msg_id, sort_type) => {
    set_flex_loading(true);
    const data = {
      qtext: split_link(),
      search_type:
        Data.conv_name !== "None" && Data.conv_name !== null
          ? "channel name"
          : Data.link !== "None" && Data.link !== null
          ? "link"
          : "id",
      msgid: msg_id,
      sort_type,
    };
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .post("https://api.recordedtelegram.com/msgid_posts", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const array = [...res.data.data, ...modal_flex_data]
            .sort((a, b) => a.msgid - b.msgid)
            .slice(0, 200);
          set_modal_flex_data(array);
          set_msgid_first([...res.data.data]);
          set_flex_loading(false);
          set_flex_error_status(false);
          set_scrollbar_bottom(false);
          set_scrollbar_top(false);
          set_telegram_limit(res.data.ratelimit);
          handle_session_set("Ratelimits", JSON.stringify(res.data.ratelimit));
          if ([...res.data.data].length > 0) {
            scrollToHalf();
          }
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_flex_loading(false);
          set_flex_error_status(true);
          set_scrollbar_bottom(false);
          set_scrollbar_top(false);
          isSubmitBlocked = false;
        });
    }
  };

  const get_modal_flex_data_scrollend = (msg_id, sort_type) => {
    set_flex_loading(true);
    const data = {
      qtext: split_link(),
      search_type:
        Data.conv_name !== "None" && Data.conv_name !== null
          ? "channel name"
          : Data.link !== "None" && Data.link !== null
          ? "link"
          : "id",
      msgid: msg_id,
      sort_type,
    };
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .post("https://api.recordedtelegram.com/msgid_posts", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const array_len = [...res.data.data, ...modal_flex_data].length - 1;
          const array = [...res.data.data, ...modal_flex_data].sort(
            (a, b) => a.msgid - b.msgid,
          );
          if (array.length > 200) {
            const slice_array = array.slice(array_len - 200, array_len);
            set_modal_flex_data(slice_array);
          } else {
            set_modal_flex_data(array);
          }
          set_msgid_last([...res.data.data]);
          set_flex_loading(false);
          set_flex_error_status(false);
          set_scrollbar_bottom(false);
          set_scrollbar_top(false);
          set_telegram_limit(res.data.ratelimit);
          handle_session_set("Ratelimits", JSON.stringify(res.data.ratelimit));
          if ([...res.data.data].length > 0) {
            scrollToHalf();
          }
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_flex_loading(false);
          set_flex_error_status(true);
          set_scrollbar_bottom(false);
          set_scrollbar_top(false);
          isSubmitBlocked = false;
        });
    }
  };
  const scrollToHalf = () => {
    const elem = document.getElementById("flex_modal");
    elem.scrollTop = (elem.scrollHeight - elem.clientHeight) / 2;
  };
  const detect_scrollbar = () => {
    const main_div = document.getElementById("flex_modal");
    const sh = main_div.scrollHeight;
    const st = main_div.scrollTop;
    // var ht = main_div.offsetHeight;
    const ct = main_div.clientHeight;
    const scroll_pos = Math.floor(sh - st - ct);

    if (scroll_pos <= 1) {
      set_is_reached_bottom(true);
      set_scrollbar_bottom(true);
      setTimeout(() => {
        const last_msgid = modal_flex_data[modal_flex_data.length - 1].msgid;
        set_msg_id(last_msgid || msg_id);
      }, 1000);
    } else {
      set_is_reached_bottom(false);
    }

    if (st === 0) {
      set_is_reached_top(true);
      set_scrollbar_top(true);
      setTimeout(() => {
        const first_msgid = modal_flex_data[0].msgid;
        set_msg_id(first_msgid || msg_id);
      }, 1000);
    } else {
      set_is_reached_top(false);
    }
  };

  return (
    <>
      <div>
        <div className="flex_modal_loading">
          {flex_loading === true && scrollbar_top === true ? (
            <CircularProgress sx={{ color: "#28cab3" }} />
          ) : null}
          {msgid_first !== null ? (
            (flex_error_status === true &&
              scrollbar_top === false &&
              is_reached_top === true) ||
            (is_reached_top === true && msgid_first.length === 0) ? (
              <div id="no_data_top">No more data to load</div>
            ) : null
          ) : null}
        </div>
        {modal_flex_data.length > 0 && (
          <div className="main__table_flex_modal ">
            <div
              className="flex_first flex_container_tweet"
              id="flex_modal"
              onScroll={detect_scrollbar}
            >
              {modal_flex_data.length > 0 &&
                modal_flex_data.map((item, index) => {
                  return (
                    <div
                      className={
                        item.msgid === Data.msgid
                          ? "flex_first_child_selected"
                          : "flex_first_child_tweet"
                      }
                      id={
                        item.msgid === Data.msgid
                          ? "selected_data"
                          : "tweet_data"
                      }
                      key={index}
                    >
                      <TransparentContainer
                        container={<ChannelSeeMoreInfo item={item} />}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <div className="flex_modal_loading">
          {flex_loading === true && scrollbar_bottom === true ? (
            <CircularProgress sx={{ color: "#28cab3" }} />
          ) : null}
          {msgid_last !== null ? (
            (flex_error_status === true &&
              scrollbar_bottom === false &&
              is_reached_bottom === true) ||
            (is_reached_bottom === true && msgid_last.length === 0) ? (
              <div id="no_data_top">No more data to load</div>
            ) : null
          ) : null}
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

ModalFlex.propTypes = {
  Data: PropTypes.object,
};

export default ModalFlex;
