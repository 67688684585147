import PropTypes from "prop-types";
import React, { useState } from "react";
import { Stack, Grid } from "@mui/material";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { all_user_data } from "../../actions/adminAction";
import MultipleAcctJson from "../../TableUi/components/display_json/MultipleAcctJson";
import Adminmainnav from "./Adminmainnav";
import Sidenav from "../../TableUi/components/Navbar/Sidenav";
import "react-toastify/dist/ReactToastify.css";
import { options_post_category } from "../../Assets/globalfunctions";
import "../../css/Admin/admin.css";
import { handle_session_get } from "../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../utils/Validation/Token/token_checker";
import {
  handle_success_message,
  handle_error_message,
  handle_warning_message,
} from "../../utils/ResponseMessage/response_message";
import { useDispatch } from "react-redux";
import { handle_store_url_location } from "../../actions/filterAction";

function AddMultipleAcct({ all_user_data }) {
  const token = handle_session_get("Token");
  const dispatch = useDispatch();
  const [postCategory, setPostCategory] = useState(options_post_category);
  const [user_details, set_user_details] = useState([]);
  const [company_details, set_company_details] = useState({});
  const [input_field_change, set_input_field_change] = useState(false);
  const [registered_accounts, set_registered_accounts] = useState([]);

  const [inputCount, setInputCount] = useState(1);
  const handle_add_user = (username, password, email, auth) => {
    if (!username || !password || !email) {
      handle_warning_message("Invalid username or password or email");
      return;
    }
    if (email) {
      const re =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(email).toLowerCase()) === false) {
        handle_warning_message("Please enter valid email");
        return;
      }
    }
    if (!auth) {
      handle_warning_message("Please enter the valid auth");
      return;
    }
    const data = {
      access_code: auth,
      account_details: {
        username,
        password,
        email,
        status: "validate",
      },
    };
    axios
      .post("https://api.recordedtelegram.com/v2/signup_bulk", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        handle_success_message(res.data.message);
        setInputCount(inputCount + 1);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };
  const handle_delete_user = (username) => {
    if (inputCount === 1) return;
    setInputCount(inputCount - 1);
    const filter_array = user_details.filter(
      (item) => item.username !== username,
    );
    set_user_details(filter_array);
  };
  const isAllselected =
    options_post_category.length > 0 &&
    postCategory.length === options_post_category.length;

  const handleChangePostCategory = (event) => {
    const { value } = event.target;
    if (value === "all") {
      setPostCategory(
        options_post_category.length === postCategory.length
          ? []
          : options_post_category,
      );
      set_input_field_change(true);
      return;
    }
    const list_post_category = [...postCategory];
    const index_post_category = list_post_category.indexOf(value);
    index_post_category === -1
      ? list_post_category.push(value)
      : list_post_category.splice(index_post_category, 1);
    setPostCategory(list_post_category);
    set_input_field_change(true);
  };
  const submit_form = (values) => {
    if (!values.auth) {
      handle_warning_message("Please enter the valid auth");
      return;
    }
    const data = {
      access_code: values.auth,
      account_details: user_details,
      company_details,
      status: "insert",
    };
    axios
      .post("https://api.recordedtelegram.com/v2/signup_bulk", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const sort_array = res.data.data.map((item) => ({
          username: item.username,
          password: item.password,
          email: item.email,
        }));
        set_registered_accounts(sort_array);
        handle_success_message(res.data.message);
        setTimeout(() => all_user_data(), 1000);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };

  const check_company = (name, auth) => {
    if (!auth) {
      handle_warning_message("Please enter the valid auth");
      return;
    }
    const data = { access_code: auth, company: name };
    axios
      .post("https://api.recordedtelegram.com/v2/signup_bulk", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        handle_success_message("Company doesn't exist on the Database");
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message("Company already existed");
      });
  };
  const confirm_user_details = (values) => {
    const array = [];
    for (let i = 0; i < inputCount; i++) {
      if (
        values[`username_${i}`] &&
        document.getElementById(`password_${i}`).value &&
        values[`email_${i}`] &&
        values.company &&
        values.monthly_pagination &&
        values.ratelimit &&
        values.bonus &&
        values.channel_search_limit &&
        values.breach_limit &&
        values.forum_limit &&
        values.report_generator
      ) {
        const obj = {
          username: values[`username_${i}`],
          password: document.getElementById(`password_${i}`).value,
          email: values[`email_${i}`],
          monthly_pagination_limit: values.monthly_pagination,
          channelsearch_ratelimit: values.channel_search_limit,
          reportgenerator_ratelimit: values.report_generator,
          breachlimit: values.breach_limit,
          forums_limits: values.forum_limit,
        };
        array.push(obj);
      } else {
        handle_warning_message("Please enter all input fields.");
        return;
      }
    }
    if (postCategory.length === 0) {
      handle_warning_message("Please select atleast one category.");
      return;
    }
    const company_obj = {
      company_name: values.company,
      total_ratelimit_offer: values.ratelimit,
      bonus: values.bonus,
      category_access: postCategory,
    };
    set_user_details(array);
    set_company_details(company_obj);
    set_input_field_change(false);
  };

  const auto_generate_password = (id) => {
    const possibleChars =
      "abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVXYZ";
    const sample = possibleChars
      .split("")
      .sort(() => 0.5 - Math.random())
      .slice(0, 12)
      .join("");
    document.getElementById(id).value = sample;
    set_input_field_change(true);
  };

  if (localStorage.getItem("Token") == null) {
    dispatch(handle_store_url_location(window.location.href));
    return <Redirect to="/" />;
  }
  return (
    <div>
      <Grid container>
        <Grid item sm={0.7}>
          <Sidenav />
        </Grid>
        <Grid item sm={11.2}>
          <div>
            <section>
              <Adminmainnav />
              <div
                className="multiple_account_setup"
                style={{ color: "white" }}
              >
                <Formik
                  initialValues={{ company: "" }}
                  validate={(values) => {
                    const errors = {};

                    if (values.company.length === 0) {
                      errors.company = "";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      setSubmitting(false);
                      submit_form(values);
                    }, 400);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container justifyContent="space-evenly">
                        <Grid item xs={5}>
                          <Stack alignItems="flex-start">
                            <label htmlFor="company">Company</label>
                            <Stack
                              direction="row"
                              columnGap={1}
                              sx={{ width: "100%" }}
                            >
                              <input
                                id="company"
                                type="text"
                                name="company"
                                autoComplete="off"
                                className="admin_input company"
                                onChange={(event) => {
                                  handleChange(event);
                                  set_input_field_change(true);
                                }}
                                onBlur={handleBlur}
                                placeholder="company"
                              />
                              <button
                                type="button"
                                disabled={!values.company}
                                className="btn_admin btn_confirm"
                                onClick={() =>
                                  check_company(values.company, values.auth)
                                }
                              >
                                Check
                              </button>
                            </Stack>
                          </Stack>
                          <Stack alignItems="flex-start">
                            <label htmlFor="ratelimit">Ratelimit</label>
                            <Stack
                              direction="row"
                              columnGap={1}
                              sx={{ width: "100%" }}
                            >
                              <input
                                id="ratelimit"
                                type="number"
                                name="ratelimit"
                                autoComplete="off"
                                className="admin_input ratelimit"
                                onChange={(event) => {
                                  handleChange(event);
                                  set_input_field_change(true);
                                }}
                                onBlur={handleBlur}
                                placeholder="ratelimit"
                              />
                            </Stack>
                          </Stack>
                          <Stack alignItems="flex-start">
                            <label htmlFor="bonus">Bonus</label>
                            <Stack
                              direction="row"
                              columnGap={1}
                              sx={{ width: "100%" }}
                            >
                              <input
                                id="bonus"
                                type="number"
                                name="bonus"
                                autoComplete="off"
                                className="admin_input bonus"
                                onChange={(event) => {
                                  handleChange(event);
                                  set_input_field_change(true);
                                }}
                                onBlur={handleBlur}
                                placeholder="bonus"
                              />
                            </Stack>
                          </Stack>
                          <Stack alignItems="flex-start">
                            <label htmlFor="category">Category</label>
                            <Box
                              className="filter_category_multipleaccounts"
                              id="scroll_filter"
                            >
                              {options_post_category.map((item, index) => {
                                const split_card_name = item.replaceAll(
                                  "_",
                                  " ",
                                );
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={item}
                                        onChange={handleChangePostCategory}
                                        checked={postCategory.includes(item)}
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <span className="card_name">
                                        {split_card_name}
                                      </span>
                                    }
                                    key={index}
                                  />
                                );
                              })}

                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value="all"
                                    onChange={handleChangePostCategory}
                                    checked={isAllselected}
                                    size="small"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "#28cab3",
                                      },
                                    }}
                                  />
                                }
                                label="All"
                              />
                            </Box>
                          </Stack>
                        </Grid>
                        <Grid item xs={5}>
                          <Stack alignItems="flex-start">
                            <label htmlFor="monthly_pagination">
                              Monthly Pagination
                            </label>
                            <Stack
                              direction="row"
                              columnGap={1}
                              sx={{ width: "100%" }}
                            >
                              <input
                                id="monthly_pagination"
                                type="number"
                                name="monthly_pagination"
                                autoComplete="off"
                                className="admin_input monthly_pagination"
                                onChange={(event) => {
                                  handleChange(event);
                                  set_input_field_change(true);
                                }}
                                onBlur={handleBlur}
                                placeholder="monthly pagination"
                              />
                            </Stack>
                          </Stack>
                          <Stack alignItems="flex-start">
                            <label htmlFor="report_generator">
                              Report Generator
                            </label>
                            <Stack
                              direction="row"
                              columnGap={1}
                              sx={{ width: "100%" }}
                            >
                              <input
                                id="report_generator"
                                type="number"
                                name="report_generator"
                                autoComplete="off"
                                className="admin_input report_generator"
                                onChange={(event) => {
                                  handleChange(event);
                                  set_input_field_change(true);
                                }}
                                onBlur={handleBlur}
                                placeholder="report generator"
                              />
                            </Stack>
                          </Stack>

                          <Stack alignItems="flex-start">
                            <label htmlFor="breach_limit">Breach Limit</label>
                            <Stack
                              direction="row"
                              columnGap={1}
                              sx={{ width: "100%" }}
                            >
                              <input
                                id="breach_limit"
                                type="number"
                                name="breach_limit"
                                autoComplete="off"
                                className="admin_input breach_limit"
                                onChange={(event) => {
                                  handleChange(event);
                                  set_input_field_change(true);
                                }}
                                onBlur={handleBlur}
                                placeholder="breach limit"
                              />
                            </Stack>
                          </Stack>

                          <Stack alignItems="flex-start">
                            <label htmlFor="forum_limit">Forum Limit</label>
                            <Stack
                              direction="row"
                              columnGap={1}
                              sx={{ width: "100%" }}
                            >
                              <input
                                id="forum_limit"
                                type="number"
                                name="forum_limit"
                                autoComplete="off"
                                className="admin_input forum_limit"
                                onChange={(event) => {
                                  handleChange(event);
                                  set_input_field_change(true);
                                }}
                                onBlur={handleBlur}
                                placeholder="forum limit"
                              />
                            </Stack>
                          </Stack>
                          <Stack alignItems="flex-start">
                            <label htmlFor="channel_search_limit">
                              Channel Search Limit
                            </label>
                            <Stack
                              direction="row"
                              columnGap={1}
                              sx={{ width: "100%" }}
                            >
                              <input
                                id="channel_search_limit"
                                type="number"
                                name="channel_search_limit"
                                autoComplete="off"
                                className="admin_input channel_search_limit"
                                onChange={(event) => {
                                  handleChange(event);
                                  set_input_field_change(true);
                                }}
                                onBlur={handleBlur}
                                placeholder="channel search limit"
                              />
                            </Stack>
                          </Stack>
                          <Stack alignItems="flex-start">
                            <label htmlFor="auth">Auth</label>
                            <Stack
                              direction="row"
                              columnGap={1}
                              sx={{ width: "100%" }}
                            >
                              <input
                                id="auth"
                                type="text"
                                name="auth"
                                autoComplete="off"
                                className="admin_input auth"
                                onChange={(event) => {
                                  handleChange(event);
                                  set_input_field_change(true);
                                }}
                                onBlur={handleBlur}
                                placeholder="auth"
                                required
                              />
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                      <div className="user_detail_admin_main">
                        {Array.from({ length: inputCount }, (_, i) => (
                          <div key={i} className="user_detail_admin">
                            <Stack alignItems="flex-start">
                              <label htmlFor="channel_search_limit">SN</label>
                              <Stack direction="row" columnGap={1}>
                                <p>{i + 1}</p>
                              </Stack>
                            </Stack>
                            <Stack alignItems="flex-start">
                              <label htmlFor={`username_${i}`}>Username</label>
                              <Stack direction="row" columnGap={1}>
                                <input
                                  id={`username_${i}`}
                                  type="text"
                                  name={`username_${i}`}
                                  autoComplete="off"
                                  className="admin_input_user username"
                                  onChange={(event) => {
                                    handleChange(event);
                                    set_input_field_change(true);
                                  }}
                                  onBlur={handleBlur}
                                  placeholder="username"
                                />
                              </Stack>
                            </Stack>
                            <Stack
                              alignItems="flex-start"
                              sx={{ width: "25%" }}
                            >
                              <label htmlFor={`password_${i}`}>Password</label>
                              <Stack
                                direction="row"
                                columnGap={1}
                                sx={{ width: "100%" }}
                              >
                                <input
                                  id={`password_${i}`}
                                  disabled
                                  type="text"
                                  name={`password_${i}`}
                                  autoComplete="off"
                                  className="admin_input_user password"
                                  onChange={(event) => {
                                    handleChange(event);
                                    set_input_field_change(true);
                                  }}
                                  onBlur={handleBlur}
                                  placeholder="password"
                                />
                                <button
                                  type="button"
                                  onClick={() =>
                                    auto_generate_password(`password_${i}`)
                                  }
                                  className="btn_admin btn_confirm"
                                >
                                  Generate
                                </button>
                              </Stack>
                            </Stack>

                            <Stack
                              alignItems="flex-start"
                              sx={{ width: "30%" }}
                            >
                              <label htmlFor={`email_${i}`}>Email</label>
                              <Stack
                                direction="row"
                                columnGap={1}
                                sx={{ width: "100%" }}
                              >
                                <input
                                  id={`email_${i}`}
                                  type="email"
                                  name={`email_${i}`}
                                  autoComplete="off"
                                  className="admin_input_user email"
                                  onChange={(event) => {
                                    handleChange(event);
                                    set_input_field_change(true);
                                  }}
                                  onBlur={handleBlur}
                                  placeholder="email"
                                />
                              </Stack>
                            </Stack>
                          </div>
                        ))}
                        <button
                          type="button"
                          className="btn_add_admin"
                          onClick={() =>
                            handle_add_user(
                              values[`username_${inputCount - 1}`],
                              document.getElementById(
                                `password_${inputCount - 1}`,
                              ).value,
                              values[`email_${inputCount - 1}`],
                              values.auth,
                            )
                          }
                        >
                          <AddCircleOutlineIcon
                            className="admin_icon"
                            sx={{ color: "white", fontSize: 30 }}
                          />
                        </button>
                        {inputCount !== 1 ? (
                          <button
                            type="button"
                            className="btn_delete_admin"
                            onClick={() =>
                              handle_delete_user(
                                values[`username_${inputCount - 1}`],
                              )
                            }
                          >
                            <RemoveCircleOutlineIcon
                              sx={{ color: "white", fontSize: 30 }}
                            />
                          </button>
                        ) : null}
                      </div>
                      <button
                        type="button"
                        onClick={() => confirm_user_details(values)}
                        style={{ marginTop: "10px", marginRight: "10px" }}
                        className="bulk_search_submit"
                      >
                        Confirm
                      </button>

                      {user_details.length > 0 &&
                      input_field_change === false ? (
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          style={{ marginTop: "10px" }}
                          className="bulk_search_submit"
                        >
                          Submit
                        </button>
                      ) : null}
                    </form>
                  )}
                </Formik>
              </div>
            </section>
            <ToastContainer />
          </div>
          {registered_accounts.length > 0 ? (
            <MultipleAcctJson Data={registered_accounts} />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}

AddMultipleAcct.propTypes = {
  all_user_data: PropTypes.func,
};
const mapStateToProps = (state) => ({
  admin_reducer: state.admin_reducer,
});

export default connect(mapStateToProps, { all_user_data })(AddMultipleAcct);
