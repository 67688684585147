import PropTypes from "prop-types";
import React, { useState } from "react";
import { Stack, Avatar, Typography, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import fileDownload from "js-file-download";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Badge from "@mui/material/Badge";
// Import icons
import VideocamIcon from "@mui/icons-material/Videocam";
import ClearIcon from "@mui/icons-material/Clear";
import GroupsIcon from "@mui/icons-material/Groups";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import UpdateIcon from "@mui/icons-material/Update";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Link } from "react-router-dom";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ShowMoreText from "react-show-more-text";
import { ToastContainer } from "react-toastify";
import { check_filename } from "../../../Assets/globalfunctions";
import "react-toastify/dist/ReactToastify.css";
import { participants } from "../../../Assets/globalfunctions";
import {
  handle_disable_radio_btn,
  handle_session_get,
} from "../../../utils/SessionStorage/handle_session";
import { handle_error_message } from "../../../utils/ResponseMessage/response_message";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import TransparentContainer from "../sections/Charts/TransparentContainer";
import { RollerAnimation } from "../loader/Loader";
import {
  handleOpenNewTab,
  check_author_channel_or_user,
} from "../../../utils/Globals/global_functions";
import "../../../css/Display_flex/displayflex.css";

function TweetdeckDisplay({
  Data,
  flex_loading,
  flex_loadmore_loading,
  hasMore,
  get_flex_data,
  channelName,
  flex_error_status,
  showTable,
  flex_error,
  search_type,
}) {
  const token = handle_session_get("Token");
  // function for the Show More text
  function executeOnClick() {
    // Do nothing
  }

  const [is_reached_bottom, set_is_reached_bottom] = useState();
  const [max_download, set_max_download] = useState(50);
  const [download_type, set_download_type] = useState("csv");

  const detect_scrollbar = () => {
    const main_div = document.getElementById("flex_modal");
    const sh = main_div.scrollHeight;
    const st = main_div.scrollTop;
    // var ht = main_div.offsetHeight;
    const ct = main_div.clientHeight;
    const scroll_pos = Math.floor(sh - st - ct);

    if (scroll_pos <= 1) {
      set_is_reached_bottom(true);
      if (hasMore) {
        setTimeout(() => {
          get_flex_data(channelName, search_type, "loadmore_loading");
        }, 1000);
      }
    } else {
      set_is_reached_bottom(false);
    }
  };

  // function for the date
  const date_fm = (dt) => {
    if (dt !== undefined) {
      const new_dt = dt.split("T");
      const sp_dt = new_dt[0].split("-");
      const new_sp_month = `0${parseInt(sp_dt[1]) - 1}`;

      const date = new Date(sp_dt[0], new_sp_month, sp_dt[2]); // 2009-11-10

      const month = date.toLocaleString("default", { month: "long" });
      const mod_month = month.substring(0, 3);
      const ret_dt = `${mod_month}-${sp_dt[2]}-${sp_dt[0]}`;
      return ret_dt;
    }
    return null;
  };
  // link the file
  const link_adder = (links) => {
    if (links !== null && links !== "None") {
      if (links.includes("t.me")) {
        const new_link = links.split("/");
        return new_link[new_link.length - 1];
      }
      const new_link = links.split("/");
      return new_link[new_link.length - 1];
    }
  };

  // Download file
  const file_download = (filename, file_type, search_type_link) => {
    model_popup();
    const data = {
      qtext: filename,
      max: Number(max_download),
      start_date: "None",
      end_date: "None",
      sort_order: "desc",
      search_type: search_type_link,
      filter: "None",
      search_after_id: "None",
      file_type,
    };
    try {
      axios
        .post(
          "https://api.recordedtelegram.com/individual_posts_download",
          data,
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          const new_file_name = `${channelName}_${new Date()}.${file_type}`;
          fileDownload(res.data, new_file_name);
          document.getElementById("downloadmodal").className = "modal fade";
          document.getElementById("downloadmodal").style.display = "none";
        })
        .catch((error) => {
          const token_expire_status = handle_token_expire(
            error.response.data,
            error.response.status,
          );
          if (token_expire_status === true) return;
          document.getElementById("downloadmodal").className = "modal fade";
          document.getElementById("downloadmodal").style.display = "none";
          let errorMessage = "";
          const reader = new FileReader();
          reader.onload = () => {
            errorMessage = reader.result;
            handle_error_message(JSON.parse(errorMessage).errormsg);
          };
          reader.readAsText(error.response.data);
        });
    } catch (error) {
      document.getElementById("downloadmodal").className = "modal fade";
      document.getElementById("downloadmodal").style.display = "none";
      handle_error_message("Sorry could not download file");
    }
  };
  const model_popup = () => {
    document.getElementById("downloadmodal").className = "modal fade show";
    document.getElementById("downloadmodal").style.display = "block";
  };

  // link the file
  const file_link_adder = (links, msgid) => {
    if (links !== null && links !== "None") {
      if (links.includes("t.me")) {
        const new_link = links.replace("s/", "");
        const open_link = `${new_link}/${msgid}`;

        window.open(
          open_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      } else {
        const new_link = `https://t.me/${links}/${msgid}`;
        window.open(
          new_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      }
    }
  };

  return (
    <>
      {showTable != null &&
        (flex_error_status === true ? (
          <div className="main__table no_data">{flex_error}</div>
        ) : Data.includes("No results. Please try again after some time.") ? (
          <div className="main__table no_data">No data to display</div>
        ) : showTable === true && Data.length === 0 ? (
          <div className="main__table no_data">No data to display</div>
        ) : (
          <div>
            {Data.length > 0 && (
              <div className="main__table_flex_tweet " id="tweet_deck">
                <div
                  className="flex_first flex_container_tweet_channel"
                  id="flex_modal"
                  onScroll={detect_scrollbar}
                >
                  {Data.length > 0 &&
                    Data.map((item, index) => {
                      const author_info = check_author_channel_or_user(item);
                      return (
                        <div
                          className="flex_first_child_tweet_channel"
                          key={index}
                        >
                          <TransparentContainer
                            container={
                              <>
                                <Grid container alignItems="flex-start">
                                  <Grid item xs={12}>
                                    <div className="flex_first_child_heading flex_child_heading">
                                      <Avatar
                                        sx={{
                                          width: 52,
                                          height: 52,
                                          bgcolor: "#64b5ef",
                                        }}
                                      >
                                        {item.conv_name[0].toUpperCase()}
                                      </Avatar>
                                      <Stack direction="column" sx={{ pt: 1 }}>
                                        <h5 className="flex_child_heading_text">
                                          <span
                                            style={{
                                              color: "#fdff32",
                                            }}
                                          >
                                            (
                                            {item.is_group === "True"
                                              ? "Group"
                                              : "Channel"}
                                            )
                                          </span>{" "}
                                          <span
                                            onClick={(e) =>
                                              handleOpenNewTab(
                                                `/page?page=${item.conv_name}&type=channel name`,
                                                e,
                                              )
                                            }
                                            className="group_channel_profile_link_text"
                                          >
                                            {item.conv_name}
                                          </span>
                                        </h5>
                                        {item.is_group === "True" &&
                                        item.id !== "None" ? (
                                          <Link
                                            to={
                                              author_info.author_type === "user"
                                                ? `/users?page=${author_info.user_id}`
                                                : `/page?page=${author_info.channel}&type=${author_info.navigate_type}`
                                            }
                                          >
                                            <p className="author_link">
                                              {author_info.author_type ===
                                              "user"
                                                ? `Author ${
                                                    author_info.navigate_type ===
                                                    "id"
                                                      ? "ID"
                                                      : ""
                                                  }  : ${author_info.user}`
                                                : `${author_info.navigate_type} : ${author_info.channel}`}
                                            </p>
                                          </Link>
                                        ) : null}
                                      </Stack>
                                    </div>
                                  </Grid>
                                </Grid>
                                {item.filename !== "None" ||
                                item.fileext !== "None" ? (
                                  <button
                                    className="filter_file_link"
                                    disabled={
                                      !(
                                        item.link !== null &&
                                        item.link !== "None"
                                      )
                                    }
                                    onClick={() =>
                                      file_link_adder(item.link, item.msgid)
                                    }
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      columnGap={0.5}
                                    >
                                      <InsertDriveFileIcon fontSize="smaller" />
                                      <span className="link_filename_modal">
                                        {check_filename(
                                          item.filename,
                                          "filename",
                                        ) === true
                                          ? item.filename
                                          : "No File Name"}
                                        {check_filename(
                                          item.filename,
                                          "fileext",
                                        ) === true
                                          ? `(${item.fileext})`
                                          : null}
                                      </span>
                                    </Stack>
                                  </button>
                                ) : null}
                                {item.message ? (
                                  <div className="flex_first_child_body">
                                    <ShowMoreText
                                      /* Default options */
                                      lines={4}
                                      more="Show more"
                                      less="Show less"
                                      className="content-css"
                                      anchorClass="my-anchor-css-class"
                                      onClick={executeOnClick}
                                      expanded={false}
                                    >
                                      <Typography>{item.message}</Typography>
                                    </ShowMoreText>
                                  </div>
                                ) : null}

                                {/* <div className='table_divider_flex' /> */}
                                <Stack
                                  direction="row"
                                  justifyContent="flex-end"
                                  columnGap={2}
                                  className="flex_child_footer"
                                >
                                  <Stack direction="row" columnGap={1}>
                                    <UpdateIcon
                                      fontSize="small"
                                      sx={{ color: "#999" }}
                                    />
                                    <p className="icon_text">
                                      {date_fm(item.date)}
                                    </p>
                                  </Stack>
                                  {item.views ? (
                                    <Stack direction="row" columnGap={1}>
                                      <RemoveRedEyeIcon
                                        fontSize="small"
                                        sx={{ color: "#999" }}
                                      />
                                      <p className="icon_text">{item.views}</p>
                                    </Stack>
                                  ) : null}

                                  <Stack direction="row" columnGap={1}>
                                    <GroupsIcon
                                      fontSize="small"
                                      sx={{ color: "#999" }}
                                    />
                                    <p
                                      className="icon_text"
                                      style={{
                                        width: "100%",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      {
                                        participants(item.participants_count)
                                          .subs
                                      }
                                    </p>
                                  </Stack>
                                </Stack>
                              </>
                            }
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

            {Data.length > 0 ? (
              <div className="fixed_channel">
                <div className="flex_first_child_tweet_fixed">
                  <TransparentContainer
                    container={
                      <>
                        <div className="flex_first_child_heading flex_child_heading">
                          <Avatar
                            sx={{ width: 60, height: 60 }}
                            alt="Remy Sharp"
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                          />
                          <Stack direction="column" sx={{ pt: 1 }}>
                            <h5 className="fixed_chanel_title">
                              <span
                                onClick={(e) =>
                                  handleOpenNewTab(
                                    `/page?page=${Data[0].conv_name}&type=channel name`,
                                    e,
                                  )
                                }
                                className="group_channel_profile_link_text"
                              >
                                {Data[0].conv_name}
                              </span>
                            </h5>
                            <p className="fixed_channel_link">
                              {`@${link_adder(Data[0].link, Data[0].msgid)}`}
                            </p>
                          </Stack>
                        </div>
                        <button
                          className="download_individual_user_btn"
                          data-toggle="modal"
                          data-target="#download_individual_user"
                        >
                          Download
                        </button>
                        <Stack direction="row" columnGap={2}>
                          <Stack direction="row" columnGap={1}>
                            <GroupsIcon sx={{ color: "#999" }} />
                            <p className="icon_text_channel">
                              {participants(Data[0].participants_count).subs}
                            </p>
                          </Stack>
                          {participants(Data[0].participants_count).files !==
                            "None" && (
                            <Stack direction="row" columnGap={1}>
                              <VideocamIcon sx={{ color: "#999" }} />
                              <p className="icon_text_channel">
                                {participants(Data[0].participants_count).files}
                              </p>
                            </Stack>
                          )}

                          {participants(Data[0].participants_count).photos !==
                            "None" && (
                            <Stack direction="row" columnGap={1}>
                              <PermMediaIcon sx={{ color: "#999" }} />
                              <p className="icon_text_channel">
                                {
                                  participants(Data[0].participants_count)
                                    .photos
                                }
                              </p>
                            </Stack>
                          )}

                          {participants(Data[0].participants_count).links !==
                            "None" && (
                            <Stack direction="row" columnGap={1}>
                              <InsertLinkIcon sx={{ color: "#999" }} />
                              <p className="icon_text_channel">
                                {participants(Data[0].participants_count).links}
                              </p>
                            </Stack>
                          )}
                        </Stack>

                        <div className="flex_first_child_body">
                          <ShowMoreText
                            lines={4}
                            more="Show more"
                            less="Show less"
                            className="content-css"
                            anchorClass="my-anchor-css-class"
                            onClick={executeOnClick}
                            expanded={false}
                          >
                            <Typography>{Data[0].about}</Typography>
                          </ShowMoreText>
                        </div>
                      </>
                    }
                  />
                </div>
              </div>
            ) : null}

            <div>
              {flex_loading === true ? <RollerAnimation /> : null}
              {flex_loadmore_loading === true ? (
                <CircularProgress sx={{ color: "#28cab3" }} />
              ) : null}
              {hasMore === false && is_reached_bottom === true ? (
                <div id="no_data_bottom">No more data to load</div>
              ) : null}
            </div>
          </div>
        ))}

      {/* Modal pop up for the  */}
      <div
        className="modal fade"
        id="download_individual_user"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="download_individual_user"
        aria-hidden="true"
        style={{ background: "rgba(29, 40, 53, .95)" }}
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content dk-mode"
            style={{ top: "51px", width: "580px" }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="userModalLongTitle">
                Download Options
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <ClearIcon sx={{ color: "#fb1616" }} />
              </button>
            </div>
            <div className="modal-body">
              <Stack>
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Max
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={max_download}
                    onChange={(e) => {
                      set_max_download(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={50}
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="50"
                    />
                    <FormControlLabel
                      value={100}
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="100"
                    />
                    <FormControlLabel
                      value={1000}
                      control={
                        <Radio
                          disabled={handle_disable_radio_btn()}
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label={
                        <Badge color="secondary" badgeContent="premium">
                          <span>1000</span>
                        </Badge>
                      }
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Download As
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={download_type}
                    onChange={(e) => {
                      set_download_type(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="csv"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="CSV"
                    />
                    <FormControlLabel
                      value="html"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="HTML"
                    />
                    <FormControlLabel
                      value="json"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="JSON"
                    />
                  </RadioGroup>
                </FormControl>
                <button
                  onClick={() => {
                    file_download(channelName, download_type, search_type);
                  }}
                  className="button_global_style"
                  data-toggle="modal"
                  data-target="#download_individual_user"
                >
                  Download
                </button>
              </Stack>
            </div>
          </div>
        </div>
      </div>

      {/* download file loader */}
      <div
        className="modal fade"
        id="downloadmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="downloadmodal"
        aria-hidden="true"
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div
          className="modal-dialog modal-confirm"
          style={{ background: "#2b3c46", marginTop: "13rem" }}
        >
          <div className="modal-content dk-mode">
            <div className="modal-header flex-column">
              <h4
                className="modal-title w-100"
                style={{ fontSize: "18px", color: "#359daf" }}
              >
                Please wait for a moment...
              </h4>
            </div>
            <div className="modal-body" style={{ marginTop: "50px" }}>
              <div className="load-wrapper" style={{ marginLeft: "200px" }}>
                <div
                  className="load-circle"
                  style={{ background: "#359daf" }}
                />
                <div
                  className="load-circle"
                  style={{ background: "#359daf" }}
                />
                <div className="load-circle" style={{ background: "#359daf" }}>
                  {" "}
                </div>
                <div className="load-shadow" />
                <div className="load-shadow" />
                <div className="load-shadow" />
                <span style={{ left: "2%", color: "#359daf" }}>
                  Downloading
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

TweetdeckDisplay.propTypes = {
  Data: PropTypes.array,
  channelName: PropTypes.string,
  flex_error: PropTypes.string,
  flex_error_status: PropTypes.bool,
  flex_loading: PropTypes.bool,
  flex_loadmore_loading: PropTypes.bool,
  get_flex_data: PropTypes.func,
  hasMore: PropTypes.bool,
  search_type: PropTypes.string,
  showTable: PropTypes.bool,
};

export default TweetdeckDisplay;
