import update from "immutability-helper";
import axios from "axios";
import {
  UPDATE_ADMIN_PAGE,
  LOGOUT,
  NAV_CHANGER,
  GET_ALL_USER,
  ADMIN_NOTIFICATION,
  ADMIN_CHART_DATA,
  EMAIL_SENT,
} from "./types";
import { handle_clear_localStorage } from "../utils/SessionStorage/handle_session";

export const updt_admin_pg = (val) => () => (dispatch) => {
  dispatch({ type: UPDATE_ADMIN_PAGE, value: val });
};

export const token_checker = () => (dispatch) => {
  const copiedDate = new Date(Date.now() + 6 * (60 * 60 * 1000));
  new Date();
  const low_date = new Date(Date.now() - 6 * (60 * 60 * 1000));
  new Date();
  let tok_date = null;
  const user_name = localStorage.getItem("User");
  try {
    tok_date = new Date(localStorage.getItem("Expdate"));
  } catch (e) {
    // Do nothing
  }
  if (tok_date === null) {
    dispatch({ type: ADMIN_NOTIFICATION, redirect: true, user_name: "" });
  }

  if (tok_date <= copiedDate && tok_date >= low_date) {
    dispatch({
      type: ADMIN_NOTIFICATION,
      redirect: false,
      user_name,
    });
  } else {
    dispatch({ type: ADMIN_NOTIFICATION, redirect: true, user_name: "" });
  }
  const auth = localStorage.getItem("Type");
  if (auth !== "Auth") {
    dispatch({ type: ADMIN_NOTIFICATION, redirect: true, user_name: "" });
  }
};
export const date_space_fm = (dt) => () => {
  if (dt !== undefined) {
    const new_dt = dt.split(" ");
    const sp_dt = new_dt[0].split("-");
    const new_sp_month = `0${parseInt(sp_dt[1]) - 1}`;

    const date = new Date(sp_dt[0], new_sp_month, sp_dt[2]); // 2009-11-10

    const month = date.toLocaleString("default", { month: "long" });
    const mod_month = month.substring(0, 3);
    const ret_dt = `${sp_dt[2]}-${mod_month}-${sp_dt[0]}`;
    return ret_dt;
  }
  return false;
};

export const all_user_data = () => (dispatch) => {
  const token = localStorage.getItem("Token");
  axios
    .post(
      "https://api.recordedtelegram.com/v2/admin/getallclients",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const new_frmt = response.data.sort(
        (a, b) => parseInt(a.userid) - parseInt(b.userid),
      );

      const new_data = new_frmt.map((val) => {
        val.userid = parseInt(val.userid);
        val.ratelimit = parseInt(val.ratelimit);
        if (val.lastloginip !== "None") {
          val.lastloginip = val.lastloginip.split(",")[0];
        }
        if (val.signupip !== "None") {
          val.signupip = val.signupip.split(",")[0];
        }
        return val;
      });
      dispatch({ type: GET_ALL_USER, all_data: new_data });
    });
};

export const email_sent = () => (dispatch) => {
  const token = localStorage.getItem("Token");
  axios
    .get("https://api.recordedtelegram.com/get_bot_email", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      dispatch({ type: EMAIL_SENT, data: response.data });
    })
    .catch(() => {});
};

export const nav_style_changer = (val) => (dispatch) => {
  dispatch({ type: NAV_CHANGER, value: val });
};

export const admin_chart = () => (dispatch, getState) => {
  const state = getState().admin_reducer;

  const token = localStorage.getItem("Token");
  axios
    .post(
      "https://api.recordedtelegram.com/v2/admin/getallclients",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const new_data = response.data.map((val) => {
        if (val.tokensgenerated === "None") {
          val.tokensgenerated = 0;
        } else {
          val.tokensgenerated = parseInt(val.tokensgenerated);
        }
        return val;
      });

      let insert_data = null;
      let disp_data = null;
      if (new_data.length > 10) {
        const sortable = new_data.sort((a, b) =>
          b.tokensgenerated > a.tokensgenerated ? 1 : -1,
        );
        insert_data = sortable.slice(0, 10);
        disp_data = sortable;
      } else {
        insert_data = new_data;
        const sortable = new_data.sort((a, b) =>
          b.tokensgenerated > a.tokensgenerated ? 1 : -1,
        );
        disp_data = sortable;
      }

      const new_key = insert_data.map((val) => val.username);
      const new_value = insert_data.map((val) => val.tokensgenerated);
      const state_key = disp_data.map((val) => val.username);
      const state_value = disp_data.map((val) => val.tokensgenerated);
      const old_series = state.series;
      const old_state = state.options;
      const new_state = update(old_state, {
        xaxis: { categories: { $set: new_key } },
      });
      if (old_series.length > 1) {
        old_series.pop();
      }
      const new_series = update(old_series, {
        0: { data: { $set: new_value } },
      });
      const tn_data = response.data.sort(
        (a, b) =>
          new Date(b.dateofregistration) - new Date(a.dateofregistration),
      );
      const filt_arr = response.data.filter(
        (val) => val.authorization === "False",
      );
      dispatch({
        type: ADMIN_CHART_DATA,
        all_data: response.data,
        series: new_series,
        options: new_state,
        key: state_key,
        data: state_value,
        tb_data: tn_data.slice(0, 7),
        unauth: filt_arr.length,
      });
    });
};
export const arr_calc = (arr) => () => {
  const new_val = arr.reduce((a, b) => a + b);
  return new_val;
};
export const logout = () => (dispatch) => {
  sessionStorage.clear();
  handle_clear_localStorage();
  dispatch({ type: LOGOUT, redirect: true });
};
