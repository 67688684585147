import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import axios from "axios";
import {
  FaCog,
  FaInfoCircle,
  FaStarOfLife,
  FaToggleOff,
  FaToggleOn,
  FaTrashAlt,
} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import Slider, { SliderTooltip } from "rc-slider";
import { connect } from "react-redux";
import "rc-slider/assets/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Chip from "@mui/material/Chip";
import GridViewIcon from "@mui/icons-material/GridView";
import LabelIcon from "@mui/icons-material/Label";
import SettingsIcon from "@mui/icons-material/Settings";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import ClearIcon from "@mui/icons-material/Clear";
import Badge from "@mui/material/Badge";
import { GlobalStyles } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import TelegramIcon from "@mui/icons-material/Telegram";
import TranslateIcon from "@mui/icons-material/Translate";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CancelIcon from "@mui/icons-material/Cancel";
import { Redirect } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TranslateApi, {
  TranslateApiUpdate,
} from "../TableUi/components/modalContainer/TranslateApi";
import ChannelLogicalAlert from "../TableUi/components/modalContainer/ChannelLogicalAlert";
import EmailAlert from "../TableUi/components/modalContainer/EmailAlert";
import {
  options_post_category,
  handle_check_category_length,
} from "../Assets/globalfunctions";
import {
  bank_card_validator,
  ip_address_validator,
  phone_validator,
  crypto_validator,
  email_validator,
  bin_validator,
} from "../utils/Validation/Tag/tag_validator";
import { change_inv_func } from "../actions/userAction";
import FiltersNotification from "../TableUi/components/filters/FiltersNotification";
import BulkModal from "../TableUi/components/modalContainer/BulkModal";
import {
  handle_session_get,
  handle_disable_radio_btn,
} from "../utils/SessionStorage/handle_session";
import { RollerAnimation } from "../TableUi/components/loader/Loader";
import { handle_token_expire } from "../utils/Validation/Token/token_checker";
import { styles_background_img } from "../utils/Globals/global_functions";
import {
  handle_success_message,
  handle_error_message,
  response_message_custom,
  handle_warning_message,
} from "../utils/ResponseMessage/response_message";
import { chips_background_color } from "../utils/Globals/global_variables";
import TransparentContainer from "../TableUi/components/sections/Charts/TransparentContainer";
import Categories from "../TableUi/components/sections/Checkbox/Categories";
import { useDispatch } from "react-redux";
import { handle_store_url_location } from "../actions/filterAction";
import {
  txt_remove_underscore,
  stealer_logs_auto_notification,
  handle_filter_brand_protection_keyword,
  handle_validation_brand_protection_query,
} from "../utils/Globals/global_functions";
function Customernotification({ users }) {
  const token = handle_session_get("Token");
  const dispatch = useDispatch();
  const [search_val, set_search_val] = useState("");
  const [search_val_modal, set_search_val_modal] = useState("");
  const [search_data, set_search_data] = useState(null); // Get the list of channels
  const [search_data_modal, set_search_data_modal] = useState(null);
  const [stat_loading, set_stat_loading] = useState(false);
  const [keyword_data, set_keyword_data] = useState([]);
  const [channel_data, set_channel_data] = useState([]);
  const [brand_protection_data, set_brand_protection_data] = useState([]);
  const [interval_type_filter, set_interval_type_filter] = useState("day");
  const [interval_number_filter, set_interval_number_filter] = useState(1);
  const [modal_data, set_modal_data] = useState(null);
  const [delete_id, set_delete_id] = useState(null);
  const [show_filters, set_show_filters] = useState(false);
  const [search_error, set_search_error] = useState(null);
  const [update_loader, set_update_loader] = useState(false);

  const { Handle } = Slider;
  const wrapperStyle = { width: 250, marginLeft: "25px" };

  // Dropdown option
  const [option_dropdown, set_option_dropdown] = useState("keyword");
  const handle_change_option = (event) => {
    const selected_value = event.target.value;
    set_option_dropdown(selected_value);
    if (
      ["breach", "brand_protection", "stealer_logs"].includes(
        selected_value,
      ) === true
    ) {
      set_notification_filters("Default");
      set_interval_number_filter(1);
      if (selected_value === "breach") {
        set_interval_type_filter("week");
      } else {
        set_interval_type_filter("day");
      }
    } else if (selected_value === "channel") {
      set_notification_filters("Default");
    }
    if (selected_value !== "keyword" && selected_value !== "channel") {
      set_translate_report("deactivate");
    }
    if (
      selected_value === "breach" ||
      selected_value === "tag" ||
      selected_value === "stealer_logs"
    ) {
      set_alert_file_type("csv");
    }
  };

  // State for the email alert modal form parent component
  const [open_email_alert, set_open_email_alert] = useState(false);

  // State to store the current date for the NEW text
  const [current_date, set_current_date] = useState(new Date());

  // Filters for the notification , this is parent component
  const [interval_type, set_interval_type] = useState("day");
  const [interval_number, set_interval_number] = useState(1);
  const [postCategory, setPostCategory] = useState([]);
  const [notification_filters, set_notification_filters] = useState("Default");
  const [notification_filters_breach, set_notificaton_filters_breach] =
    useState("domain");
  const [notification_filters_tag, set_notification_filters_tag] =
    useState("bank_card");
  const [notification_filters_stealer, set_notificaton_filters_stealer] =
    useState("email");
  const [alert_type, set_alert_type] = useState("email");
  const [filter_type, set_filter_type] = useState("default");
  const [translate_report, set_translate_report] = useState("deactivate");
  const [alert_file_type, set_alert_file_type] = useState("csv");
  const [alert_post_no, set_alert_post_no] = useState(1);
  const [multiple_email_type, set_multiple_email_type] = useState("cc");

  const [postCategory_modal, setPostCategory_modal] = useState([]);
  const [keyword, setKeyword] = useState("contains");
  const [keyword_stealer, setKeyword_stealer] = useState("contains");
  const [post_category_stealer, set_post_category_stealer] = useState("off");
  const [keyword_modal, setKeyword_modal] = useState(null);
  const [notification_filters_modal, set_notificaton_filters_modal] =
    useState("Default");
  const [alert_type_modal, set_alert_type_modal] = useState("email");

  const [channel_name_clicked, set_channel_name_clicked] = useState("");

  // State for the channel logical modal
  const [
    open_channel_logical_alert_modal,
    set_open_channel_logical_alert_modal,
  ] = useState(false);
  const [channels_list, set_channels_list] = useState([]);

  // State for the keyword translation alert
  const [checked_exclude, set_checked_exclude] = useState(false);
  const [open_translate, set_open_translate] = useState(false);
  const [language_array, set_language_array] = useState([]);

  // State for the keyword tranalstion from modal
  const [language_array_modal, set_language_array_modal] = useState([]);
  const [open_translate_modal, set_open_translate_modal] = useState(false);
  const [select_top_lang_modal, set_select_top_lang_modal] = useState(false);
  const [checked_exclude_modal, set_checked_exclude_modal] = useState(false);

  // State for the tag_name_list
  const [tag_name_list, set_tag_name_list] = useState(null);
  const [show_tag_list_div, set_show_tag_list_div] = useState(false);

  // State for the Tag alert filters from parent
  const exp_year_options = [
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
  ];
  const [exp_year, set_exp_year] = useState(exp_year_options);

  const exp_month_options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [exp_month, set_exp_month] = useState(exp_month_options);

  const crypto_options = ["bitcoin", "ethereum", "monero", "tron", "xrp"];
  const [crypto_provider, set_crypto_provider] = useState(crypto_options);

  const email_options = [
    "gmail",
    "outlook",
    "aol",
    "yahoo",
    "icloud",
    "proton",
    "zoho",
    "yandex",
    "gmx",
  ];
  const [email_provider, set_email_provider] = useState(email_options);

  const card_type_options = [
    "amex_card",
    "bc_global",
    "carte_blanche_card",
    "diners_club_card",
    "insta_payment_card",
    "jcb_card",
    "korean_local_card",
    "laser_card",
    "maestro_card",
    "mastercard",
    "solo_card",
    "switch_card",
    "visa_master_card",
    "union_pay_card",
    "visa_card",
    "interpayment_card",
    "dankort_card",
    "universal_air_travel_plan_card",
  ];

  const [card_type, set_card_type] = useState(card_type_options);

  // State to store the endpoint
  const [end_point, set_end_point] = useState(null);
  const [endpoint_loading, set_end_point_loading] = useState(false);
  const [header_obj, set_header_obj] = useState({});

  // State to store the multiple emails
  const [multiple_emails_list, set_multiple_emails_list] = useState({
    emails: [],
    user_email: "",
    previous_emails: [],
  });
  const [open_brand_protection_modal, set_open_brand_protection_modal] =
    useState(false);

  const handleChangeKeywordModal = (event) => {
    setKeyword_modal(event.target.value);
  };
  const [session_category_array, set_session_category_array] = useState([]);

  const handle = (props) => {
    const {
      // eslint-disable-next-line react/prop-types
      value,
      // eslint-disable-next-line react/prop-types
      dragging,
      // eslint-disable-next-line react/prop-types
      index,
      ...restProps
    } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} `}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  const handleClickSearchbar = () => {
    if (option_dropdown === "channel" && filter_type === "individual") {
      set_open_channel_logical_alert_modal(true);
    }
  };

  useEffect(() => {
    if (token !== null) {
      toast.configure();
      data_getter();
      const session_categories = handle_session_get("enabled_categories");
      if (session_categories !== "all") {
        const array_data = session_categories.split(",");
        setPostCategory(array_data);
        set_session_category_array(array_data);
      } else {
        setPostCategory(options_post_category);
      }

      // Getting end point
      get_endpoint();
      // Getting multiple emails
      get_multiple_emails();
    }
  }, []);

  const select_default_languages = (obj) => {
    // For the language at inital selection
    try {
      if (obj) {
        if (obj.notification_type === "keyword") {
          if (obj.lang_obj) {
            const filter_lang = obj.lang_obj.map(
              (item) => item.translated_lang,
            );
            set_language_array_modal(filter_lang);
            set_checked_exclude_modal(obj.exclude_source === "True");
          }
        }
      }
    } catch {
      //Do nothing
    }
  };

  useEffect(() => {
    if (option_dropdown === "channel") {
      if (filter_type === "individual") {
        set_open_channel_logical_alert_modal(true);
      }
    }
  }, [filter_type]);

  useEffect(() => {
    if (modal_data) {
      if (
        modal_data.notification_type === "channel" &&
        modal_data.notification_filter === "Default"
      ) {
        try {
          if (modal_data.keyword_entered.includes("https://t.me/s") === false) {
            set_channel_name_clicked(modal_data.keyword_entered);
          }
        } catch {
          //Do nothing
        }
      }
    }
  }, [modal_data]);

  const data_delete = () => {
    const data = { hash_id: delete_id };
    axios
      .post(
        "https://api.recordedtelegram.com/v2/delete_user_notification",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        handle_warning_message("Deleted Sucessfully");
        setTimeout(() => {
          handle_set_notification_data(
            res.data.data,
            set_keyword_data,
            set_channel_data,
            set_brand_protection_data,
            set_current_date,
          );
        }, 300);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };
  const data_getter = () => {
    axios
      .post(
        "https://api.recordedtelegram.com/v2/get_notification_data",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        handle_set_notification_data(
          res.data,
          set_keyword_data,
          set_channel_data,
          set_brand_protection_data,
          set_current_date,
        );
      })
      .catch((err) => {
        try {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
        } catch {
          //Do nothing
        }
      });
  };

  const handle_set_notification_data = (
    response,
    set_keyword_data,
    set_channel_data,
    set_brand_protection_data,
    set_current_date,
  ) => {
    const new_keyword_data = response.filter((item) => {
      const all_keys = Object.keys(item);
      if (
        all_keys.includes("notification_type") === false ||
        (item.notification_type !== "channel" &&
          item.notification_type !== "brand_protection")
      ) {
        return item;
      }
      return null;
    });

    const clone_keyword = [...new_keyword_data];
    clone_keyword.sort(
      (a, b) => new Date(b.date_of_submission) - new Date(a.date_of_submission),
    );
    set_keyword_data(clone_keyword);
    const new_channel_data = response.filter(
      (item) => item.notification_type && item.notification_type === "channel",
    );
    const clone_channel_data = [...new_channel_data];
    clone_channel_data.sort(
      (a, b) => new Date(b.date_of_submission) - new Date(a.date_of_submission),
    );
    set_channel_data(clone_channel_data);
    const new_brand_protection_data = response.filter(
      (item) =>
        item.notification_type && item.notification_type === "brand_protection",
    );
    const clone_brand_protection_data = [...new_brand_protection_data];
    clone_brand_protection_data.sort(
      (a, b) => new Date(b.date_of_submission) - new Date(a.date_of_submission),
    );
    set_brand_protection_data(clone_brand_protection_data);
    set_current_date(new Date());
    setTimeout(() => {
      set_current_date(new Date());
    }, 10000);
  };

  const val_change = (e) => {
    if (filter_type === "individual") {
      document.getElementById("srch_tab").value = "";
      set_search_val("");
      return;
    }
    set_search_val(e.target.value);
    if (e.target.value.length >= 2) {
      set_stat_loading(true);
      const data = { qtext: e.target.value, search_type: "alert" };
      axios
        .post("https://api.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const filter_data = res.data.filter((item) => item.link !== "None");
          set_search_data(filter_data);
          set_stat_loading(false);
        })
        .catch((err) => {
          try {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            set_stat_loading(false);
            set_search_error(err.response.data.errormsg);
          } catch {
            //Do nothing
          }
        });
    } else {
      set_search_data(null);
    }
  };

  const val_change_modal = (e) => {
    set_search_val_modal(e.target.value);
    if (e.target.value.length > 2) {
      set_stat_loading(true);
      const data = { qtext: e.target.value, search_type: "alert" };
      axios
        .post("https://api.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const filter_data = res.data.filter((item) => item.link !== "None");
          set_search_data_modal(filter_data);
          set_stat_loading(false);
        })
        .catch((err) => {
          try {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            set_stat_loading(false);
            set_search_error(err.response.data.errormsg);
          } catch {
            //Do nothing
          }
        });
    } else {
      set_search_data_modal(null);
    }
  };

  const get_tag_name_list = (e) => {
    set_modal_data({ ...modal_data, tag_name: e.target.value });
    set_show_tag_list_div(true);
    if (e.target.value.length > 1) {
      set_stat_loading(true);
      const data = { qtext: e.target.value };
      axios
        .post(
          "https://api.recordedtelegram.com/unique_notification_tag",
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          set_tag_name_list(res.data.data);
          set_stat_loading(false);
        })
        .catch((err) => {
          try {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            set_stat_loading(false);
          } catch {
            //Do nothing
          }
        });
    }
  };

  // To stop multiple fecthing of data
  let isSubmitBlocked = false;

  // Function onSubmit
  const post_data = (val, notification_type, channel_list) => {
    if (option_dropdown === "channel" && filter_type === "individual") {
      const data = {
        keyword_to_watch: val,
        channel_name: channel_list,
        interval_type: interval_type_filter,
        interval_number: interval_number_filter,
        notification_type,
        selectCategory:
          option_dropdown === "channel" || option_dropdown === "breach"
            ? "all"
            : handle_check_category_length(postCategory) === true
              ? "all"
              : postCategory,
        search_type: keyword,
        notification_filter: "nested",
        endpoint_type: alert_type,
        email_type:
          alert_type === "multiple_email" ? multiple_email_type : null,
        alert_file: alert_file_type,
        no_of_posts:
          alert_file_type === "html" ? Number(alert_post_no) : "None",
        tag_name: "None",
      };
      if (!isSubmitBlocked) {
        isSubmitBlocked = true;
        axios
          .post(
            "https://api.recordedtelegram.com/v2/create_notifications",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then((res) => {
            handle_success_message(res.data.message);
            setTimeout(() => {
              handle_set_notification_data(
                res.data.data,
                set_keyword_data,
                set_channel_data,
                set_brand_protection_data,
                set_current_date,
              );
            }, 300);
            isSubmitBlocked = false;
          })
          .catch((err) => {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            response_message_custom(err.response.data.errormsg, "error");
            isSubmitBlocked = false;
          });
      }
    } else {
      const data = {
        keyword_to_watch: val,
        interval_type: interval_type_filter,
        interval_number: interval_number_filter,
        notification_type,
        selectCategory:
          option_dropdown === "channel" || option_dropdown === "breach"
            ? "all"
            : handle_check_category_length(postCategory) === true
              ? "all"
              : postCategory,
        search_type:
          (option_dropdown === "keyword" ||
            option_dropdown === "file" ||
            option_dropdown === "file_ext") &&
            notification_filters === "Default"
            ? keyword
            : "exact",
        notification_filter:
          option_dropdown === "channel"
            ? "Default"
            : option_dropdown === "breach"
              ? notification_filters_breach
              : notification_filters,
        endpoint_type: alert_type,
        email_type:
          alert_type === "multiple_email" ? multiple_email_type : null,
        target_lang:
          option_dropdown === "keyword" &&
            notification_filters === "Default" &&
            language_array.length !== 0
            ? language_array
            : "None",
        exclude_source:
          option_dropdown === "keyword" && notification_filters === "Default"
            ? checked_exclude === true
              ? "True"
              : "False"
            : "None",
        alert_file: option_dropdown !== "breach" ? alert_file_type : "csv",
        no_of_posts:
          option_dropdown !== "breach" && alert_file_type === "html"
            ? Number(alert_post_no)
            : "None",
        tag_name: "None",
      };
      const data_stealer = {
        keyword_to_watch: val,
        interval_type: interval_type_filter,
        interval_number: interval_number_filter,
        notification_type,
        selectCategory: "all",
        search_type: keyword_stealer,
        notification_filter: notification_filters_stealer,
        endpoint_type: alert_type,
        email_type:
          alert_type === "multiple_email" ? multiple_email_type : null,
        tag_name: "None",
        stealer_category:
          post_category_stealer !== "off" ? post_category_stealer : null,
      };
      const data_tag = {
        keyword_to_watch: val,
        selectCategory:
          handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory,
        interval_type: interval_type_filter,
        interval_number: interval_number_filter,
        notification_type,
        notification_filter: notification_filters_tag,
        endpoint_type: alert_type,
        email_type:
          alert_type === "multiple_email" ? multiple_email_type : null,
        expiry_year:
          notification_filters_tag === "bank_card" ||
            notification_filters_tag === "bin"
            ? exp_year.length === 11
              ? "all"
              : exp_year
            : null,
        expiry_month:
          notification_filters_tag === "bank_card" ||
            notification_filters_tag === "bin"
            ? exp_month.length === 12
              ? "all"
              : exp_month
            : null,
        bank_card_type:
          notification_filters_tag === "bank_card" ||
            notification_filters_tag === "bin"
            ? card_type.length === 18
              ? "all"
              : card_type
            : null,
        email_provider:
          notification_filters_tag === "email"
            ? email_provider.length === 9
              ? "all"
              : email_provider
            : null,
        crypto_type:
          notification_filters_tag === "crypto"
            ? crypto_provider.length === 5
              ? "all"
              : crypto_provider
            : null,
        alert_file: "csv",
        no_of_posts: "None",
        tag_name: "None",
      };
      const data_brand_protection = {
        keyword_to_watch: handle_filter_brand_protection_keyword(val),
        interval_type: interval_type_filter,
        interval_number: interval_number_filter,
        notification_type,
        endpoint_type: alert_type,
        email_type:
          alert_type === "multiple_email" ? multiple_email_type : null,
        alert_file: alert_file_type,
        tag_name: "None",
      };
      set_update_loader(true);
      if (!isSubmitBlocked) {
        isSubmitBlocked = true;
        axios
          .post(
            "https://api.recordedtelegram.com/v2/create_notifications",
            option_dropdown === "tag"
              ? data_tag
              : option_dropdown === "stealer_logs"
                ? data_stealer
                : option_dropdown === "brand_protection"
                  ? data_brand_protection
                  : data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then((res) => {
            handle_success_message(res.data.message);
            setTimeout(() => {
              handle_set_notification_data(
                res.data.data,
                set_keyword_data,
                set_channel_data,
                set_brand_protection_data,
                set_current_date,
              );
            }, 300);
            isSubmitBlocked = false;
            set_update_loader(false);
          })
          .catch((err) => {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            response_message_custom(err.response.data.errormsg, "error");
            isSubmitBlocked = false;
            set_update_loader(false);
          });
      }
    }
  };

  // update when UPDATE button of the modal is clicked
  const post_data_modal = (
    val,
    notification_type,
    regex_status,
    id,
    channels,
  ) => {
    const data = {
      keyword_to_watch: val,
      channel_name:
        modal_data.notification_filter === "nested" ? channels : null,
      interval_type,
      interval_number,
      notification_type,
      search_type: keyword_modal,
      selectCategory:
        modal_data.notification_type === "channel" ||
          modal_data.notification_type === "breach" ||
          modal_data.notification_type === "tag"
          ? "all"
          : handle_check_category_length(postCategory_modal) === true
            ? "all"
            : postCategory_modal,
      notification_filter: notification_filters_modal,
      endpoint_type: alert_type_modal,
      alert_id: id,
      email_type: modal_data.email_type
        ? alert_type_modal === "multiple_email"
          ? modal_data.email_type
          : null
        : null,
      target_lang:
        modal_data.notification_type === "keyword" &&
          modal_data.lang_obj &&
          language_array_modal.length !== 0
          ? language_array_modal
          : "None",
      exclude_source:
        modal_data.notification_type === "keyword" &&
          modal_data.lang_obj &&
          language_array_modal.length !== 0
          ? checked_exclude_modal === true
            ? "True"
            : "False"
          : "None",
      alert_file: modal_data.alert_file ? modal_data.alert_file : "csv",
      no_of_posts:
        modal_data.no_of_posts && modal_data.alert_file === "html"
          ? Number(modal_data.no_of_posts)
          : "None",
      tag_name: modal_data.tag_name ? modal_data.tag_name : null,
      stealer_category:
        modal_data.notification_type === "stealer_logs" &&
          modal_data.stealer_category !== "off"
          ? modal_data.stealer_category
          : null,
    };
    const data_tag = modal_data.notification_type === "tag" && {
      keyword_to_watch: val,
      channel_name:
        modal_data.notification_filter === "nested" ? channels : null,
      interval_type,
      interval_number,
      notification_type,
      search_type: keyword_modal,
      selectCategory:
        handle_check_category_length(postCategory_modal) === true
          ? "all"
          : postCategory_modal,
      notification_filter: notification_filters_modal,
      endpoint_type: alert_type_modal,
      alert_id: id,
      target_lang:
        modal_data.notification_type === "keyword" &&
          modal_data.lang_obj &&
          language_array_modal.length !== 0
          ? language_array_modal
          : "None",
      exclude_source:
        modal_data.notification_type === "keyword" && modal_data.exclude_source
          ? checked_exclude_modal === true
            ? "True"
            : "False"
          : "None",
      expiry_year:
        modal_data.notification_filter === "bank_card" ||
          modal_data.notification_filter === "bin"
          ? modal_data.sub_value.exp_year.length === 11
            ? "all"
            : modal_data.sub_value.exp_year
          : null,
      expiry_month:
        modal_data.notification_filter === "bank_card" ||
          modal_data.notification_filter === "bin"
          ? modal_data.sub_value.exp_month.length === 12
            ? "all"
            : modal_data.sub_value.exp_month
          : null,
      bank_card_type:
        modal_data.notification_filter === "bank_card" ||
          modal_data.notification_filter === "bin"
          ? modal_data.sub_value.bank_card_type.length === 18
            ? "all"
            : modal_data.sub_value.bank_card_type
          : null,
      email_provider:
        modal_data.notification_filter === "email"
          ? modal_data.sub_value.email.length === 9
            ? "all"
            : modal_data.sub_value.email
          : null,
      crypto_type:
        modal_data.notification_filter === "crypto"
          ? modal_data.sub_value.crypto_type.length === 5
            ? "all"
            : modal_data.sub_value.crypto_type
          : null,
      email_type: modal_data.email_type
        ? alert_type_modal === "multiple_email"
          ? modal_data.email_type
          : null
        : null,
      alert_file: "csv",
      no_of_posts: "None",
      tag_name: modal_data.tag_name ? modal_data.tag_name : null,
    };
    const data_brand_protection = {
      keyword_to_watch: val,
      interval_type,
      interval_number,
      notification_type,
      endpoint_type: alert_type_modal,
      alert_id: id,
      email_type: modal_data.email_type
        ? alert_type_modal === "multiple_email"
          ? modal_data.email_type
          : null
        : null,
      alert_file: modal_data.alert_file ? modal_data.alert_file : "csv",
      tag_name: modal_data.tag_name ? modal_data.tag_name : null,
    };
    set_update_loader(true);
    axios
      .post(
        "https://api.recordedtelegram.com/v2/create_notifications",
        modal_data.notification_type === "tag"
          ? data_tag
          : modal_data.notification_type === "brand_protection"
            ? data_brand_protection
            : data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        setTimeout(() => {
          handle_set_notification_data(
            res.data.data,
            set_keyword_data,
            set_channel_data,
            set_brand_protection_data,
            set_current_date,
          );
        }, 300);
        handle_success_message("Updated Sucessfully");
        set_update_loader(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        response_message_custom(err.response.data.errormsg, "error");
        set_update_loader(false);
      });
  };

  const update_notification_status = (id, val) => {
    const data = {
      status: !val,
      id,
    };
    axios
      .post(
        "https://api.recordedtelegram.com/v2/update_notification_status",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        setTimeout(() => {
          handle_set_notification_data(
            res.data.data,
            set_keyword_data,
            set_channel_data,
            set_brand_protection_data,
            set_current_date,
          );
        }, 200);
        handle_success_message("Updated Sucessfully");
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        response_message_custom(err.response.data.errormsg, "error");
      });
  };

  const handle_onchange_update = (event) => {
    if (modal_data.notification_filter !== "nested") {
      set_modal_data({ ...modal_data, keyword_entered: event.target.value });
    } else {
      set_search_val_modal(event.target.value);
    }
    if (
      modal_data.notification_type === "channel" &&
      modal_data.notification_filter === "Default"
    ) {
      set_channel_name_clicked(event.target.value);
    }
    if (modal_data.notification_type === "channel") {
      val_change_modal(event);
    }
  };

  // Funttion for the channel update from the modal
  const add_channels_list = (item) => {
    if (modal_data.channel_name.includes(item) === false) {
      set_modal_data({
        ...modal_data,
        channel_name: [...modal_data.channel_name, item],
      });
    } else {
      set_modal_data({ ...modal_data, channel_name: modal_data.channel_name });
    }
    set_search_val_modal("");
  };

  const handle_delete_channels = (clicked) => {
    const filter_channels = modal_data.channel_name.filter(
      (item) => item !== clicked,
    );
    set_modal_data({ ...modal_data, channel_name: filter_channels });
  };
  const channels_list_chips = (
    modal_data !== null && modal_data.channel_name
      ? modal_data.channel_name
      : []
  ).map((item, index) => (
    <Chip
      label={item}
      onDelete={() => handle_delete_channels(item)}
      color="success"
      key={index}
    />
  ));

  // Function for updating the tag alert filters from the modal
  const handle_change_exp_year = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_modal_data(
        modal_data.sub_value.exp_year.length === exp_year_options.length
          ? {
            ...modal_data,
            sub_value: { ...modal_data.sub_value, exp_year: [] },
          }
          : {
            ...modal_data,
            sub_value: {
              ...modal_data.sub_value,
              exp_year: exp_year_options,
            },
          },
      );
    } else {
      const num_value = Number(value);
      const list_exp_year = [...modal_data.sub_value.exp_year];
      const index_exp_year = list_exp_year.indexOf(num_value);
      index_exp_year === -1
        ? list_exp_year.push(num_value)
        : list_exp_year.splice(index_exp_year, 1);
      set_modal_data({
        ...modal_data,
        sub_value: { ...modal_data.sub_value, exp_year: list_exp_year },
      });
    }
  };
  const handle_change_exp_month = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_modal_data(
        modal_data.sub_value.exp_month.length === exp_month_options.length
          ? {
            ...modal_data,
            sub_value: { ...modal_data.sub_value, exp_month: [] },
          }
          : {
            ...modal_data,
            sub_value: {
              ...modal_data.sub_value,
              exp_month: exp_month_options,
            },
          },
      );
    } else {
      const num_value = Number(value);
      const list_exp_month = [...modal_data.sub_value.exp_month];
      const index_exp_month = list_exp_month.indexOf(num_value);
      index_exp_month === -1
        ? list_exp_month.push(num_value)
        : list_exp_month.splice(index_exp_month, 1);
      set_modal_data({
        ...modal_data,
        sub_value: { ...modal_data.sub_value, exp_month: list_exp_month },
      });
    }
  };
  const handle_change_card_type = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_modal_data(
        modal_data.sub_value.bank_card_type.length === card_type_options.length
          ? {
            ...modal_data,
            sub_value: { ...modal_data.sub_value, bank_card_type: [] },
          }
          : {
            ...modal_data,
            sub_value: {
              ...modal_data.sub_value,
              bank_card_type: card_type_options,
            },
          },
      );
    } else {
      const list_card_type = [...modal_data.sub_value.bank_card_type];
      const index_card_type = list_card_type.indexOf(value);
      index_card_type === -1
        ? list_card_type.push(value)
        : list_card_type.splice(index_card_type, 1);
      set_modal_data({
        ...modal_data,
        sub_value: { ...modal_data.sub_value, bank_card_type: list_card_type },
      });
    }
  };
  const handle_change_crypto_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_modal_data(
        modal_data.sub_value.crypto_type.length === crypto_options.length
          ? {
            ...modal_data,
            sub_value: { ...modal_data.sub_value, crypto_type: [] },
          }
          : {
            ...modal_data,
            sub_value: {
              ...modal_data.sub_value,
              crypto_type: crypto_options,
            },
          },
      );
    } else {
      const list_crypto_provider = [...modal_data.sub_value.crypto_type];
      const index_crypto_provider = list_crypto_provider.indexOf(value);
      index_crypto_provider === -1
        ? list_crypto_provider.push(value)
        : list_crypto_provider.splice(index_crypto_provider, 1);
      set_modal_data({
        ...modal_data,
        sub_value: {
          ...modal_data.sub_value,
          crypto_type: list_crypto_provider,
        },
      });
    }
  };
  const handle_change_email_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_modal_data(
        modal_data.sub_value.email.length === email_options.length
          ? { ...modal_data, sub_value: { ...modal_data.sub_value, email: [] } }
          : {
            ...modal_data,
            sub_value: { ...modal_data.sub_value, email: email_options },
          },
      );
    } else {
      const list_email_provider = [...modal_data.sub_value.email];
      const index_email_provider = list_email_provider.indexOf(value);
      index_email_provider === -1
        ? list_email_provider.push(value)
        : list_email_provider.splice(index_email_provider, 1);
      set_modal_data({
        ...modal_data,
        sub_value: { ...modal_data.sub_value, email: list_email_provider },
      });
    }
  };

  // Function for setting the modal_data
  const handle_setting_modal_data = (val) => {
    set_show_tag_list_div(false);
    if (val.notification_type !== "tag") {
      set_modal_data({
        ...val,
        tag_name: val.tag_name && val.tag_name !== "None" ? val.tag_name : "",
        email_type:
          val.email_type && val.email_type !== "None" ? val.email_type : "cc",
        stealer_category:
          val.notification_type === "stealer_logs"
            ? val.stealer_category !== null
              ? val.stealer_category
              : "off"
            : null,
      });
    } else if (
      val.notification_filter === "bank_card" ||
      val.notification_filter === "bin"
    ) {
      set_modal_data({
        ...val,
        tag_name: val.tag_name && val.tag_name !== "None" ? val.tag_name : "",
        email_type:
          val.email_type && val.email_type !== "None" ? val.email_type : "cc",
        sub_value: {
          bank_card_type:
            val.tag_obj[0].bank_card_type === "all"
              ? card_type_options
              : val.tag_obj[0].bank_card_type,
          exp_month:
            val.tag_obj[0].exp_month === "all"
              ? exp_month_options
              : val.tag_obj[0].exp_month,
          exp_year:
            val.tag_obj[0].exp_year === "all"
              ? exp_year_options
              : val.tag_obj[0].exp_year,
        },
      });
    } else if (val.notification_filter === "email") {
      set_modal_data({
        ...val,
        tag_name: val.tag_name && val.tag_name !== "None" ? val.tag_name : "",
        email_type:
          val.email_type && val.email_type !== "None" ? val.email_type : "cc",
        sub_value: {
          email:
            val.tag_obj[0].email_type === "all"
              ? email_options
              : val.tag_obj[0].email_type,
        },
      });
    } else if (val.notification_filter === "crypto") {
      set_modal_data({
        ...val,
        tag_name: val.tag_name && val.tag_name !== "None" ? val.tag_name : "",
        email_type:
          val.email_type && val.email_type !== "None" ? val.email_type : "cc",
        sub_value: {
          crypto_type:
            val.tag_obj[0].crypto_type === "all"
              ? crypto_options
              : val.tag_obj[0].crypto_type,
        },
      });
    } else {
      set_modal_data({
        ...val,
        tag_name: val.tag_name && val.tag_name !== "None" ? val.tag_name : "",
        email_type:
          val.email_type && val.email_type !== "None" ? val.email_type : "cc",
      });
    }
  };

  // Function to get the custom endpoint
  const get_endpoint = () => {
    set_end_point_loading(true);
    axios
      .get("https://api.recordedtelegram.com/v2/get_endpoints", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        set_end_point(res.data[0].endpoint_url);
        set_end_point_loading(false);
        set_header_obj(res.data[0].headers_key_value);
      })
      .catch((err) => {
        try {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_end_point(null);
          set_end_point_loading(false);
        } catch {
          //Do nothing
        }
      });
  };

  // Function to get the multiple emails
  const get_multiple_emails = () => {
    axios
      .get("https://api.recordedtelegram.com/v2/get_multiple_email", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        set_multiple_emails_list(() => ({
          emails: res.data.emails,
          user_email: res.data.user_email,
          previous_emails: res.data.emails,
        }));
      })
      .catch((err) => {
        try {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_multiple_emails_list({ emails: [], user_email: "" });
        } catch {
          //Do nothing
        }
      });
  };

  if (users.redirect) {
    dispatch(handle_store_url_location(window.location.href));
    return <Redirect to="/" />;
  }
  return (
    <section
      className="company_stats_main_container"
      style={styles_background_img}
    >
      <GlobalStyles
        styles={{
          ".MuiInput-root svg": {
            color: "#1fcecb",
          },
        }}
      />
      <div
        className={
          users.dark_theme ? "container-fluid dk-mode" : "container-fluid "
        }
        style={{ marginTop: "50px", position: "relative", height: "100vh" }}
      >
        <div className="row mb-1 " style={{ justifyContent: "center" }}>
          <div className="col-sm-8">
            <Formik
              initialValues={{ search: "" }}
              validate={(values) => {
                const errors = {};
                const { search } = values;
                const regexExp =
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
                // For checking valid regular expression
                const isRegExp = (string) => {
                  try {
                    new RegExp(string);
                  } catch (e) {
                    return false;
                  }
                  return true;
                };
                if (
                  stealer_logs_auto_notification(
                    option_dropdown,
                    post_category_stealer,
                    search,
                  ) === true
                )
                  return;
                if (search.length < 2) {
                  errors.search = "Keyword must be longer than 1 character";
                }
                if (
                  notification_filters !== "logical" &&
                  option_dropdown !== "channel"
                ) {
                  const reg = /\s{2,}/g;
                  const verifier_conv = search.replaceAll(reg, " ");
                  const verifier = /\s(\b(and|or|AND|OR)\b)\s/g.exec(
                    verifier_conv,
                  );
                  if (verifier !== null && verifier.length > 0) {
                    errors.search =
                      "AND/OR operator are only allowed on logical feature";
                  }
                }
                if (
                  option_dropdown === "file_extension" &&
                  notification_filters === "Default"
                ) {
                  if (search.startsWith(".") === false) {
                    errors.search =
                      "File extension should start with (.) character";
                  }
                  if (search.length > 8) {
                    errors.search =
                      "File extension should not exceed 8 characters";
                  }
                }
                if (
                  option_dropdown === "breach" &&
                  notification_filters_breach === "email"
                ) {
                  if (regexExp.test(search) === false) {
                    errors.search = "please enter valid email";
                  }
                }
                if (
                  (option_dropdown === "keyword" ||
                    option_dropdown === "file" ||
                    option_dropdown === "file_extension") &&
                  notification_filters === "regex"
                ) {
                  if (isRegExp(search) === false) {
                    errors.search = "Please enter valid Regular expression";
                  }
                }

                // validation for the tag alert
                if (option_dropdown === "tag") {
                  if (notification_filters_tag === "bank_card") {
                    const error_msg = bank_card_validator(values.search);
                    if (error_msg) {
                      errors.search = error_msg;
                    }
                  } else if (notification_filters_tag === "ip_address") {
                    const error_msg = ip_address_validator(values.search);
                    if (error_msg) {
                      errors.search = error_msg;
                    }
                  } else if (notification_filters_tag === "phone") {
                    const error_msg = phone_validator(values.search);
                    if (error_msg) {
                      errors.search = error_msg;
                    }
                  } else if (notification_filters_tag === "crypto") {
                    const error_msg = crypto_validator(values.search);
                    if (error_msg) {
                      errors.search = error_msg;
                    }
                  } else if (notification_filters_tag === "email") {
                    const error_msg = email_validator(values.search);
                    if (error_msg) {
                      errors.search = error_msg;
                    }
                  } else if (notification_filters_tag === "bin") {
                    const error_msg = bin_validator(values.search);
                    if (error_msg) {
                      errors.search = error_msg;
                    }
                  }
                }

                return errors;
              }}
              onSubmit={(values, { resetForm }) => {
                const { search } = values;
                if (option_dropdown !== "channel") {
                  if (
                    stealer_logs_auto_notification(
                      option_dropdown,
                      post_category_stealer,
                      search,
                    ) === true
                  ) {
                    post_data("", "stealer_logs");
                  } else {
                    if (option_dropdown === "brand_protection") {
                      const validtion_text =
                        handle_validation_brand_protection_query(
                          brand_protection_data,
                          search,
                          "comma",
                        );
                      if (validtion_text !== "submit form") {
                        handle_error_message(validtion_text);
                        return;
                      }
                    }
                    post_data(search.trim(), option_dropdown);
                  }
                }
                setTimeout(() => resetForm());
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div
                    className="d-flex align-items-center notification_input"
                    style={{ columnGap: "10px" }}
                  >
                    <div
                      className=" rounded input-group rounded-pill  shadow-sm mb-1 notification_search_box"
                      style={{
                        padding: "0.4rem",
                        width: "100%",
                      }}
                    >
                      <div className="dropdown_notification">
                        <FormControl sx={{ minWidth: 150 }} size="small">
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={option_dropdown}
                            onChange={handle_change_option}
                            sx={{ width: "100%", mt: 1 }}
                            disableUnderline
                            variant="standard"
                          >
                            <MenuItem value="keyword">Keyword</MenuItem>
                            <MenuItem value="channel">Channel</MenuItem>
                            <MenuItem value="file">File</MenuItem>
                            <MenuItem value="file_extension">File ext</MenuItem>
                            <MenuItem value="tag">Tag</MenuItem>
                            {handle_session_get("Acc_typ") ===
                              "PAID_CUSTOMER" ||
                              handle_session_get("User") === "administrator" ? (
                              <MenuItem value="breach">Breach</MenuItem>
                            ) : null}
                            {handle_session_get("Acc_typ") ===
                              "PAID_CUSTOMER" ||
                              handle_session_get("User") === "administrator" ? (
                              <MenuItem value="stealer_logs">
                                Stealer Logs
                              </MenuItem>
                            ) : null}
                            {handle_session_get("Acc_typ") ===
                              "PAID_CUSTOMER" ||
                              handle_session_get("User") === "administrator" ? (
                              <MenuItem value="brand_protection">
                                Brand Protection
                              </MenuItem>
                            ) : null}
                          </Select>
                        </FormControl>
                      </div>
                      <input
                        id="srch_tab"
                        type="search"
                        placeholder={
                          notification_filters === "logical"
                            ? "e.g: test1 and test2 or (test3 and test4)"
                            : stealer_logs_auto_notification(
                              option_dropdown,
                              post_category_stealer,
                              values.search,
                            ) === false
                              ? option_dropdown === "brand_protection"
                                ? "Please separate the queries with commas for bulk insertion."
                                : "Please type your Query."
                              : "Please type your Query or press enter for all alert."
                        }
                        name="search"
                        value={values.search}
                        onChange={(e) => {
                          handleChange(e);
                          option_dropdown === "channel" && val_change(e);
                        }}
                        onClick={() => handleClickSearchbar(values.search)}
                        onBlur={handleBlur}
                        className="form-control border-0 new-dk"
                      />

                      {(option_dropdown === "keyword" ||
                        option_dropdown === "file" ||
                        option_dropdown === "file_extension") &&
                        notification_filters === "logical" ? (
                        <span data-toggle="modal" data-target="#help_logical">
                          <Tooltip title="Logical Input Guidance">
                            <HelpIcon
                              sx={{
                                color: "white",
                                mt: 0.7,
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                        </span>
                      ) : null}
                      {option_dropdown === "brand_protection" ? (
                        <button
                          className="brand_protection_bulk_query"
                          type="button"
                          onClick={() => set_open_brand_protection_modal(true)}
                        >
                          <span className="brand_protection_bulk_query_text">
                            Bulk Query
                          </span>
                        </button>
                      ) : null}
                      {option_dropdown === "keyword" &&
                        notification_filters === "Default" ? (
                        <Tooltip title="choose language">
                          <TranslateIcon
                            onClick={() => set_open_translate(true)}
                            sx={{
                              color: "rgb(56 211 175)",
                              mr: 0.3,
                              mt: 0.7,
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      ) : null}
                      <button
                        id="button-addon1"
                        type="submit"
                        style={{
                          outline: "none",
                          outlineWidth: 0,
                          boxShadow: "none",
                        }}
                        className="btn btn-link"
                        disabled={
                          stealer_logs_auto_notification(
                            option_dropdown,
                            post_category_stealer,
                            values.search,
                          ) === false
                            ? !(values.search.length > 1)
                            : false
                        }
                      >
                        <FontAwesomeIcon
                          style={{ color: "#1FCECB" }}
                          icon={faPlus}
                          size="1x"
                        />
                      </button>
                    </div>
                    <button
                      type="button"
                      className="searchbar-btn searchbar-btn-main"
                      onClick={() => set_show_filters(!show_filters)}
                    >
                      {" "}
                      <Tooltip title="Filters">
                        <GridViewIcon sx={{ color: "#dcf3ff" }} />
                      </Tooltip>{" "}
                    </button>
                    {handle_session_get("Acc_typ") === "PAID_CUSTOMER" ||
                      handle_session_get("User") === "administrator" ? (
                      <button
                        type="button"
                        className="searchbar-btn searchbar-btn-main"
                        onClick={() => set_open_email_alert(true)}
                      >
                        {" "}
                        <Tooltip title="Endpoint/Emails setting">
                          <Badge color="secondary" badgeContent="premium">
                            <SettingsIcon sx={{ color: "#dcf3ff" }} />
                          </Badge>
                        </Tooltip>{" "}
                      </button>
                    ) : (
                      <button
                        type="button"
                        disabled
                        className="searchbar-btn-email-alert"
                      >
                        <Tooltip title="Endpoint/Emails setting">
                          <Badge color="secondary" badgeContent="premium">
                            <SettingsIcon sx={{ color: "gray" }} />
                          </Badge>
                        </Tooltip>
                      </button>
                    )}
                  </div>
                  {show_filters === false && errors.search && touched.search ? (
                    <div className="user_search_validation">
                      {errors.search}
                    </div>
                  ) : null}

                  {search_val.length < 2 &&
                    show_filters === false &&
                    !errors.search &&
                    !touched.search ? (
                    <span style={{ color: "#dcf3ff", fontSize: "14px" }}>
                      {" "}
                      <IconContext.Provider
                        value={{ ize: 18, color: "#f15e5e" }}
                      >
                        <FaInfoCircle style={{ marginRight: "6px" }} />
                      </IconContext.Provider>
                      {`Insert the ${option_dropdown === "channel" ? "channel" : "keyword"
                        } on the search box and press enter for  the notification. ${option_dropdown === "tag"
                          ? "For regex, enclose query within two forward slashes / /."
                          : ""
                        }`}
                    </span>
                  ) : null}

                  <div style={{ margin: "auto" }}>
                    {update_loader === true ? <CircularProgress /> : null}
                  </div>
                </form>
              )}
            </Formik>
          </div>
          {/* list of channels */}
          {search_val.length >= 2 &&
            option_dropdown === "channel" &&
            show_filters === false ? (
            <div
              className="autocom-box channel_list_container mt-3"
              style={{ width: "62%" }}
            >
              {stat_loading ? (
                <RollerAnimation />
              ) : search_error !== null ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "larger",
                    color: "white",
                    marginTop: "10%",
                  }}
                >
                  {search_error}
                </div>
              ) : search_data !== null && search_data.length === 0 ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "larger",
                    color: "white",
                    marginTop: "10%",
                  }}
                >
                  No Data to display
                </div>
              ) : (
                <>
                  <h4
                    style={{
                      paddingLeft: "1rem",
                      marginLeft: "15px",
                      marginTop: "5px",
                      fontSize: "18px",
                      color: "whitesmoke",
                    }}
                  >
                    List Of Channels
                  </h4>
                  <ul>
                    {search_data !== null &&
                      search_data.map((val, id) => (
                        <li key={id}>
                          <div className="row">
                            <div
                              className="col-md-8"
                              style={{ textAlign: "left" }}
                            >
                              {" "}
                              <img
                                style={{
                                  width: "36px",
                                  height: "36px",
                                  padding: "2px",
                                  border: "2px solid #f4f4f4",
                                }}
                                className="img-fluid rounded-circle"
                                src={
                                  val.channel_id !== "None"
                                    ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                }}
                                alt="User"
                              />
                              <span
                                className="channels_list_map"
                                style={{
                                  marginLeft: "10px",
                                  color: "#dcf3ff",
                                }}
                              >
                                {val.channel_name}
                              </span>
                            </div>
                            <div
                              className="col-md-4"
                              style={{ textAlign: "right", marginTop: "5px" }}
                            >
                              <button
                                style={{
                                  borderRadius: "40px",
                                  background: "#d03940",
                                  color: "white",
                                  lineHeight: "1",
                                }}
                                className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                onClick={() => {
                                  post_data(
                                    val.channel_name !== "None"
                                      ? val.channel_name
                                      : val.link !== "None"
                                        ? val.link
                                        : val.channel_id,
                                    "channel",
                                  );
                                  set_search_val("");
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </>
              )}
            </div>
          ) : null}
        </div>
        <div
          className="position-absolute "
          style={{ marginLeft: "1%", width: "95%" }}
        >
          <div
            className="row"
            style={{ justifyContent: "center", marginTop: "30px" }}
          >
            <div className="col-lg-8">
              <TransparentContainer
                container={
                  <div>
                    <div className="card-header ">
                      <h5 className="stats_title_color">
                        Keyword Email Notification
                      </h5>
                    </div>
                    <div
                      className="card-body  scroll-custom"
                      style={{ height: "50vh" }}
                    >
                      <div style={{ textAlign: "start" }}>
                        <p>
                          {" "}
                          Alert reports are sent to customers in the form of
                          .CSV file for posts in channels/groups on Telegram.{" "}
                        </p>
                      </div>
                      {keyword_data.length > 0
                        ? keyword_data.map((val, id) => {
                          const keyword_time = new Date(
                            val.date_of_submission,
                          ).getTime();
                          const stealer_auto_query =
                            stealer_logs_auto_notification(
                              val.notification_type,
                              val.stealer_category,
                              val.keyword_entered,
                            ) === true
                              ? `stealer category : ${val.stealer_category}`
                              : "None";
                          return (
                            <>
                              <div
                                className={
                                  current_date.getTime() - keyword_time < 8000
                                    ? "new_alert_background file-tab"
                                    : "file-tab"
                                }
                                key={id}
                              >
                                <div
                                  className="media"
                                  style={{ width: "80%" }}
                                >
                                  <div className="media-aside mr-1 align-self-start">
                                    <i
                                      className="file-icon lbg-0"
                                      style={{
                                        fontFamily: "none",
                                        fontSize: "30px",
                                        color: "white",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {val.regex_status === "True" ? (
                                        <IconContext.Provider
                                          value={{
                                            size: 18,
                                            marginTop: "10px",
                                            marginLeft: "12px",
                                            color: "white",
                                          }}
                                        >
                                          <FaStarOfLife />
                                        </IconContext.Provider>
                                      ) : (
                                        val.keyword_entered.substring(0, 1) ||
                                        "#"
                                      )}
                                    </i>
                                  </div>
                                  <div
                                    className="media-body my-auto keyword_name_alert_body "
                                    style={{ width: "97%" }}
                                  >
                                    <div className="file-name keyword_name_alert ">
                                      {current_date.getTime() - keyword_time <
                                        8000 ? (
                                        <span className="new_txt_alert">
                                          NEW
                                        </span>
                                      ) : null}
                                      <span style={{ marginLeft: "10px" }}>
                                        {stealer_auto_query === "None"
                                          ? val.keyword_entered
                                          : stealer_auto_query}
                                      </span>
                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["interval_type"]}`,
                                          color:
                                            chips_background_color[
                                            "interval_type"
                                            ],
                                        }}
                                        className="filters-text"
                                      >
                                        {val.interval_type === "week" ||
                                          val.interval_type === "weekly"
                                          ? "Week"
                                          : val.interval_type === "day"
                                            ? "Day"
                                            : val.interval_type === "minutes"
                                              ? "Minutes"
                                              : "Month"}
                                      </span>
                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["interval_number"]}`,
                                          color:
                                            chips_background_color[
                                            "interval_number"
                                            ],
                                        }}
                                        className="filters-text"
                                      >
                                        {val.interval_number}{" "}
                                        {val.interval_type === "week" ||
                                          val.interval_type === "weekly"
                                          ? "Week"
                                          : val.interval_type === "day"
                                            ? "Day"
                                            : val.interval_type === "minutes"
                                              ? "Minutes"
                                              : "Month"}
                                      </span>

                                      {(val.notification_type !== "channel" ||
                                        val.notification_type !== "breach") &&
                                        val.notification_filter ===
                                        "Default" ? (
                                        <span
                                          style={{
                                            border: `2px solid ${chips_background_color["search_type"]}`,
                                            color:
                                              chips_background_color[
                                              "search_type"
                                              ],
                                          }}
                                          className="filters-text filters-text_keyword"
                                        >
                                          {val.search_type}
                                        </span>
                                      ) : null}
                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["notification_type"]}`,
                                          color:
                                            chips_background_color[
                                            "notification_type"
                                            ],
                                        }}
                                        className="filters-text filters-text_keyword"
                                      >
                                        {val.notification_type ===
                                          "keyword" &&
                                          val.regex_status === "False"
                                          ? "Keyword"
                                          : val.notification_type ===
                                            "keyword" &&
                                            val.regex_status === "True"
                                            ? "Regex"
                                            : txt_remove_underscore(
                                              val.notification_type,
                                            )}
                                      </span>
                                      {[val.notification_type].some(
                                        (type) =>
                                          type === "keyword" ||
                                          type === "file" ||
                                          type === "file_extension" ||
                                          type === "tag",
                                      ) ? (
                                        <span
                                          style={{
                                            border: `2px solid ${chips_background_color["categories"]}`,
                                            color:
                                              chips_background_color[
                                              "categories"
                                              ],
                                          }}
                                          className="filters-text filters-text_keyword"
                                        >
                                          {handle_check_category_length(
                                            val.category_access,
                                          ) === true ||
                                            val.category_access === "all"
                                            ? "all categories"
                                            : val.category_access.length === 1
                                              ? `${val.category_access.length} category`
                                              : `${val.category_access.length} categories`}
                                        </span>
                                      ) : null}
                                      {val.notification_type ===
                                        "stealer_logs" &&
                                        val.keyword_entered.length > 0 &&
                                        val.stealer_category !== null ? (
                                        <span
                                          style={{
                                            border: `2px solid ${chips_background_color["categories"]}`,
                                            color:
                                              chips_background_color[
                                              "categories"
                                              ],
                                          }}
                                          className="filters-text filters-text_keyword"
                                        >
                                          {val.stealer_category}
                                        </span>
                                      ) : null}
                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["notification_filter"]}`,
                                          color:
                                            chips_background_color[
                                            "notification_filter"
                                            ],
                                        }}
                                        className="filters-text"
                                      >
                                        {txt_remove_underscore(
                                          val.notification_filter,
                                        )}
                                      </span>
                                      {val.endpoint_type ? (
                                        <span
                                          style={{
                                            border: `2px solid ${chips_background_color["endpoint_type"]}`,
                                            color:
                                              chips_background_color[
                                              "endpoint_type"
                                              ],
                                          }}
                                          className="filters-text"
                                        >
                                          {txt_remove_underscore(
                                            val.endpoint_type,
                                          )}
                                        </span>
                                      ) : null}
                                      {val.email_type &&
                                        val.email_type !== "None" ? (
                                        <span
                                          style={{
                                            border: `2px solid ${chips_background_color["email_type"]}`,
                                            color:
                                              chips_background_color[
                                              "email_type"
                                              ],
                                          }}
                                          className="filters-text"
                                        >
                                          {val.email_type}
                                        </span>
                                      ) : null}

                                      {val.tag_name ? (
                                        val.tag_name !== "None" ? (
                                          <span
                                            style={{
                                              border: `2px solid ${chips_background_color["tag_name"]}`,
                                              color:
                                                chips_background_color[
                                                "tag_name"
                                                ],
                                            }}
                                            className="filters-text"
                                          >
                                            {val.tag_name}
                                          </span>
                                        ) : null
                                      ) : null}
                                      {val.alert_file &&
                                        val.endpoint_type &&
                                        val.endpoint_type !==
                                        "custom_endpoint" ? (
                                        <span
                                          style={{
                                            border: `2px solid ${chips_background_color["alert_file"]}`,
                                            color:
                                              chips_background_color[
                                              "alert_file"
                                              ],
                                          }}
                                          className="filters-text"
                                        >
                                          {val.alert_file}
                                        </span>
                                      ) : null}
                                      {val.translate_alert ? (
                                        val.translate_alert === "activate" ? (
                                          <span
                                            style={{
                                              border: `2px solid ${chips_background_color["translated"]}`,
                                              color:
                                                chips_background_color[
                                                "translated"
                                                ],
                                            }}
                                            className="filters-text"
                                          >
                                            Translated
                                          </span>
                                        ) : null
                                      ) : null}

                                      {val.lang_obj ? (
                                        val.lang_obj !== null ? (
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{ pt: 1 }}
                                          >
                                            {val.lang_obj.map(
                                              (item, index) => {
                                                if (index <= 5) {
                                                  return (
                                                    <Tooltip
                                                      placement="bottom"
                                                      title={
                                                        item.translated_lang_name
                                                      }
                                                      key={index}
                                                    >
                                                      <img
                                                        width="20.5411"
                                                        height="20"
                                                        // eslint-disable-next-line no-undef
                                                        src={`${process.env.PUBLIC_URL}/static/images/flags/${item.translated_lang}.png`}
                                                        alt="flag"
                                                      />
                                                    </Tooltip>
                                                  );
                                                }
                                                if (index === 6) {
                                                  return (
                                                    <p
                                                      key={index}
                                                    >{`......`}</p>
                                                  );
                                                }
                                                return null;
                                              },
                                            )}
                                          </Stack>
                                        ) : null
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <Tooltip title="Update">
                                    <span
                                      data-toggle="modal"
                                      data-target="#updatefilters"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handle_setting_modal_data(val);
                                        setKeyword_modal(val.search_type);
                                        setPostCategory_modal(
                                          val.category_access === "all"
                                            ? session_category_array
                                            : val.category_access,
                                        );
                                        set_interval_type(val.interval_type);
                                        set_interval_number(
                                          val.interval_number,
                                        );
                                        set_notificaton_filters_modal(
                                          val.notification_filter,
                                        );
                                        set_alert_type_modal(
                                          val.endpoint_type,
                                        );
                                        select_default_languages(val);
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          size: 28,
                                          marginTop: "10px",
                                          color: "rgb(6, 110, 128)",
                                        }}
                                      >
                                        <FaCog />
                                      </IconContext.Provider>
                                    </span>
                                  </Tooltip>

                                  <Tooltip title="ON / OFF">
                                    <span
                                      style={{ marginLeft: "25px" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        update_notification_status(
                                          val.id,
                                          val.notification_status,
                                        );
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={
                                          val.notification_status === true
                                            ? {
                                              size: 32,
                                              marginTop: "10px",
                                              marginLeft: "12px",
                                              color: "rgb(41, 128, 185)",
                                            }
                                            : {
                                              size: 32,
                                              marginTop: "10px",
                                              marginLeft: "12px",
                                              color: "#5e5873",
                                            }
                                        }
                                      >
                                        {val.notification_status === true ? (
                                          <FaToggleOn />
                                        ) : (
                                          <FaToggleOff />
                                        )}
                                      </IconContext.Provider>
                                    </span>
                                  </Tooltip>

                                  <Tooltip title="Delete">
                                    <span
                                      style={{
                                        marginLeft: "25px",
                                        cursor: "pointer",
                                      }}
                                      data-toggle="modal"
                                      data-target="#showdeletebutton"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        set_delete_id(val.id);
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          size: 28,
                                          marginTop: "10px",
                                          color: "rgb(217, 37, 80)",
                                        }}
                                      >
                                        <FaTrashAlt />
                                      </IconContext.Provider>
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="divider_alert" />
                            </>
                          );
                        })
                        : null}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
          <div
            className="row"
            style={{ justifyContent: "center", marginTop: "30px" }}
          >
            <div className="col-lg-8">
              <TransparentContainer
                container={
                  <div>
                    <div className="card-header ">
                      <h5 className="stats_title_color">
                        Channel Notifications
                      </h5>
                    </div>
                    <div
                      className="card-body scroll-custom"
                      style={{ height: "50vh" }}
                    >
                      <div style={{ textAlign: "start" }}>
                        <p>
                          Alert reports are sent to customers in the form of
                          .CSV file for new posts in a particular channel on
                          Telegram.
                        </p>
                      </div>
                      {channel_data.length > 0
                        ? channel_data.map((val, id) => {
                          const channel_time = new Date(
                            val.date_of_submission,
                          ).getTime();
                          return (
                            <>
                              <div
                                className={
                                  current_date.getTime() - channel_time < 8000
                                    ? "new_alert_background file-tab"
                                    : "file-tab"
                                }
                                key={id}
                              >
                                <div className="media">
                                  <div className="media-aside mr-1 align-self-start">
                                    <i
                                      className="file-icon lbg-0"
                                      style={{
                                        fontFamily: "none",
                                        fontSize: "30px",
                                        color: "white",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {val.regex_status === "True" ? (
                                        <IconContext.Provider
                                          value={{
                                            size: 18,
                                            marginTop: "10px",
                                            marginLeft: "12px",
                                            color: "white",
                                          }}
                                        >
                                          <FaStarOfLife />
                                        </IconContext.Provider>
                                      ) : (
                                        val.keyword_entered.substring(0, 1)
                                      )}
                                    </i>
                                  </div>
                                  <div className="media-body my-auto  keyword_name_alert_body ">
                                    <div className="file-name  keyword_name_alert">
                                      {current_date.getTime() - channel_time <
                                        8000 ? (
                                        <span
                                          className={
                                            val.notification_filter ===
                                              "nested"
                                              ? "new_txt_alert_array"
                                              : "new_txt_alert"
                                          }
                                        >
                                          NEW
                                        </span>
                                      ) : null}
                                      {val.channel_obj ? (
                                        val.channel_obj.length > 0 &&
                                          val.notification_filter ===
                                          "nested" ? (
                                          <Stack direction="row">
                                            <span
                                              style={{ marginLeft: "10px" }}
                                            >
                                              {val.keyword_entered}{" "}
                                            </span>
                                            <EastIcon
                                              sx={{ marginRight: "10px" }}
                                            />
                                            <div
                                              style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              {val.channel_obj.map(
                                                (item, i) => (
                                                  <div
                                                    style={{
                                                      marginLeft: "10px",
                                                      display: "flex",
                                                    }}
                                                    key={i}
                                                  >
                                                    {i !== 0 ? "," : ""}
                                                    <TelegramIcon
                                                      fontSize="small"
                                                      sx={{
                                                        color: "#58d8ff",
                                                        mr: 0.5,
                                                      }}
                                                    />
                                                    {item.channel_name}{" "}
                                                  </div>
                                                ),
                                              )}
                                            </div>
                                          </Stack>
                                        ) : (
                                          <span
                                            style={{ marginLeft: "10px" }}
                                          >
                                            <TelegramIcon
                                              fontSize="small"
                                              sx={{
                                                color: "#58d8ff",
                                                mr: 0.5,
                                              }}
                                            />
                                            {val.keyword_entered}
                                          </span>
                                        )
                                      ) : (
                                        <span style={{ marginLeft: "10px" }}>
                                          <TelegramIcon
                                            fontSize="small"
                                            sx={{ color: "#58d8ff", mr: 0.5 }}
                                          />
                                          {val.keyword_entered}
                                        </span>
                                      )}

                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["interval_type"]}`,
                                          color:
                                            chips_background_color[
                                            "interval_type"
                                            ],
                                        }}
                                        className="filters-text"
                                      >
                                        {val.interval_type === "week" ||
                                          val.interval_type === "weekly"
                                          ? "Week"
                                          : val.interval_type === "day"
                                            ? "Day"
                                            : val.interval_type === "minutes"
                                              ? "Minutes"
                                              : "Month"}
                                      </span>
                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["interval_number"]}`,
                                          color:
                                            chips_background_color[
                                            "interval_number"
                                            ],
                                        }}
                                        className="filters-text"
                                      >
                                        {val.interval_number}{" "}
                                        {val.interval_type === "week" ||
                                          val.interval_type === "weekly"
                                          ? "Week"
                                          : val.interval_type === "day"
                                            ? "Day"
                                            : val.interval_type === "minutes"
                                              ? "Minutes"
                                              : "Month"}
                                      </span>
                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["endpoint_type"]}`,
                                          color:
                                            chips_background_color[
                                            "endpoint_type"
                                            ],
                                        }}
                                        className="filters-text"
                                      >
                                        {txt_remove_underscore(
                                          val.endpoint_type,
                                        )}
                                      </span>
                                      {val.email_type &&
                                        val.email_type !== "None" ? (
                                        <span
                                          style={{
                                            border: `2px solid ${chips_background_color["email_type"]}`,
                                            color:
                                              chips_background_color[
                                              "email_type"
                                              ],
                                          }}
                                          className="filters-text"
                                        >
                                          {val.email_type}
                                        </span>
                                      ) : null}
                                      {val.tag_name ? (
                                        val.tag_name !== "None" ? (
                                          <span
                                            style={{
                                              border: `2px solid ${chips_background_color["tag_name"]}`,
                                              color:
                                                chips_background_color[
                                                "tag_name"
                                                ],
                                            }}
                                            className="filters-text"
                                          >
                                            {val.tag_name}
                                          </span>
                                        ) : null
                                      ) : null}
                                      {val.alert_file &&
                                        val.endpoint_type &&
                                        val.endpoint_type !==
                                        "custom_endpoint" ? (
                                        <span
                                          style={{
                                            border: `2px solid ${chips_background_color["alert_file"]}`,
                                            color:
                                              chips_background_color[
                                              "alert_file"
                                              ],
                                          }}
                                          className="filters-text"
                                        >
                                          {val.alert_file}
                                        </span>
                                      ) : null}
                                      {val.translate_alert ? (
                                        val.translate_alert === "activate" ? (
                                          <span
                                            style={{
                                              border: `2px solid ${chips_background_color["translated"]}`,
                                              color:
                                                chips_background_color[
                                                "translated"
                                                ],
                                            }}
                                            className="filters-text"
                                          >
                                            Translated
                                          </span>
                                        ) : null
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <Tooltip title="Update">
                                    <span
                                      data-toggle="modal"
                                      data-target="#updatefilters"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        set_modal_data({
                                          ...val,
                                          tag_name:
                                            val.tag_name &&
                                              val.tag_name !== "None"
                                              ? val.tag_name
                                              : "",
                                          channel_name:
                                            val.channel_obj &&
                                              val.channel_obj !== "None"
                                              ? val.channel_obj.map(
                                                (item) => item.channel_name,
                                              )
                                              : [],
                                          email_type:
                                            val.email_type &&
                                              val.email_type !== "None"
                                              ? val.email_type
                                              : "cc",
                                        });
                                        setKeyword_modal(val.search_type);
                                        set_interval_type(val.interval_type);
                                        set_interval_number(
                                          val.interval_number,
                                        );
                                        set_notificaton_filters_modal(
                                          val.notification_filter,
                                        );
                                        set_alert_type_modal(
                                          val.endpoint_type,
                                        );
                                        set_show_tag_list_div(false);
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          size: 28,
                                          marginTop: "10px",
                                          color: "rgb(6, 110, 128)",
                                        }}
                                      >
                                        <FaCog />
                                      </IconContext.Provider>
                                    </span>
                                  </Tooltip>

                                  <Tooltip title="ON / OFF">
                                    <span
                                      style={{ marginLeft: "25px" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        update_notification_status(
                                          val.id,
                                          val.notification_status,
                                        );
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={
                                          val.notification_status === true
                                            ? {
                                              size: 32,
                                              marginTop: "10px",
                                              marginLeft: "12px",
                                              color: "rgb(41, 128, 185)",
                                            }
                                            : {
                                              size: 32,
                                              marginTop: "10px",
                                              marginLeft: "12px",
                                              color: "#5e5873",
                                            }
                                        }
                                      >
                                        {val.notification_status === true ? (
                                          <FaToggleOn />
                                        ) : (
                                          <FaToggleOff />
                                        )}
                                      </IconContext.Provider>
                                    </span>
                                  </Tooltip>

                                  <Tooltip title="Delete">
                                    <span
                                      style={{
                                        marginLeft: "25px",
                                        cursor: "pointer",
                                      }}
                                      data-toggle="modal"
                                      data-target="#showdeletebutton"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        set_delete_id(val.id);
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          size: 28,
                                          marginTop: "10px",
                                          color: "rgb(217, 37, 80)",
                                        }}
                                      >
                                        <FaTrashAlt />
                                      </IconContext.Provider>
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="divider_alert" />
                            </>
                          );
                        })
                        : null}
                    </div>
                  </div>
                }
              />
            </div>
          </div>

          <div
            className="row"
            style={{ justifyContent: "center", marginTop: "30px" }}
          >
            <div className="col-lg-8">
              <TransparentContainer
                container={
                  <div>
                    <div className="card-header ">
                      <h5 className="stats_title_color">
                        Brand Protection Notifications
                      </h5>
                    </div>
                    <div className="card-body scroll-custom">
                      {brand_protection_data.length > 0
                        ? brand_protection_data.map((val, id) => {
                          const channel_time = new Date(
                            val.date_of_submission,
                          ).getTime();
                          return (
                            <>
                              <div
                                className={
                                  current_date.getTime() - channel_time < 8000
                                    ? "new_alert_background file-tab"
                                    : "file-tab"
                                }
                                key={id}
                              >
                                <div className="media">
                                  <div className="media-aside mr-1 align-self-start">
                                    <i
                                      className="file-icon lbg-0"
                                      style={{
                                        fontFamily: "none",
                                        fontSize: "30px",
                                        color: "white",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {val.regex_status === "True" ? (
                                        <IconContext.Provider
                                          value={{
                                            size: 18,
                                            marginTop: "10px",
                                            marginLeft: "12px",
                                            color: "white",
                                          }}
                                        >
                                          <FaStarOfLife />
                                        </IconContext.Provider>
                                      ) : (
                                        val.keyword_entered.substring(0, 1)
                                      )}
                                    </i>
                                  </div>
                                  <div className="media-body my-auto  keyword_name_alert_body ">
                                    <div className="file-name  keyword_name_alert">
                                      {current_date.getTime() - channel_time <
                                        8000 ? (
                                        <span
                                          className={
                                            val.notification_filter ===
                                              "nested"
                                              ? "new_txt_alert_array"
                                              : "new_txt_alert"
                                          }
                                        >
                                          NEW
                                        </span>
                                      ) : null}
                                      <span style={{ marginLeft: "10px" }}>
                                        {val.keyword_entered}
                                      </span>
                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["interval_type"]}`,
                                          color:
                                            chips_background_color[
                                            "interval_type"
                                            ],
                                        }}
                                        className="filters-text"
                                      >
                                        {val.interval_type === "week" ||
                                          val.interval_type === "weekly"
                                          ? "Week"
                                          : val.interval_type === "day"
                                            ? "Day"
                                            : val.interval_type === "minutes"
                                              ? "Minutes"
                                              : "Month"}
                                      </span>
                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["interval_number"]}`,
                                          color:
                                            chips_background_color[
                                            "interval_number"
                                            ],
                                        }}
                                        className="filters-text"
                                      >
                                        {val.interval_number}{" "}
                                        {val.interval_type === "week" ||
                                          val.interval_type === "weekly"
                                          ? "Week"
                                          : val.interval_type === "day"
                                            ? "Day"
                                            : val.interval_type === "minutes"
                                              ? "Minutes"
                                              : "Month"}
                                      </span>
                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["endpoint_type"]}`,
                                          color:
                                            chips_background_color[
                                            "endpoint_type"
                                            ],
                                        }}
                                        className="filters-text"
                                      >
                                        {txt_remove_underscore(
                                          val.endpoint_type,
                                        )}
                                      </span>
                                      {val.email_type &&
                                        val.email_type !== "None" ? (
                                        <span
                                          style={{
                                            border: `2px solid ${chips_background_color["email_type"]}`,
                                            color:
                                              chips_background_color[
                                              "email_type"
                                              ],
                                          }}
                                          className="filters-text"
                                        >
                                          {val.email_type}
                                        </span>
                                      ) : null}
                                      {val.tag_name ? (
                                        val.tag_name !== "None" ? (
                                          <span
                                            style={{
                                              border: `2px solid ${chips_background_color["tag_name"]}`,
                                              color:
                                                chips_background_color[
                                                "tag_name"
                                                ],
                                            }}
                                            className="filters-text"
                                          >
                                            {val.tag_name}
                                          </span>
                                        ) : null
                                      ) : null}
                                      {val.alert_file &&
                                        val.endpoint_type &&
                                        val.endpoint_type !==
                                        "custom_endpoint" ? (
                                        <span
                                          style={{
                                            border: `2px solid ${chips_background_color["alert_file"]}`,
                                            color:
                                              chips_background_color[
                                              "alert_file"
                                              ],
                                          }}
                                          className="filters-text"
                                        >
                                          {val.alert_file}
                                        </span>
                                      ) : null}
                                      {val.translate_alert ? (
                                        val.translate_alert === "activate" ? (
                                          <span
                                            style={{
                                              border: `2px solid ${chips_background_color["translated"]}`,
                                              color:
                                                chips_background_color[
                                                "translated"
                                                ],
                                            }}
                                            className="filters-text"
                                          >
                                            Translated
                                          </span>
                                        ) : null
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <Tooltip title="Update">
                                    <span
                                      data-toggle="modal"
                                      data-target="#updatefilters"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        set_modal_data({
                                          ...val,
                                          tag_name:
                                            val.tag_name &&
                                              val.tag_name !== "None"
                                              ? val.tag_name
                                              : "",
                                          channel_name:
                                            val.channel_obj &&
                                              val.channel_obj !== "None"
                                              ? val.channel_obj.map(
                                                (item) => item.channel_name,
                                              )
                                              : [],
                                          email_type:
                                            val.email_type &&
                                              val.email_type !== "None"
                                              ? val.email_type
                                              : "cc",
                                        });
                                        setKeyword_modal(val.search_type);
                                        set_interval_type(val.interval_type);
                                        set_interval_number(
                                          val.interval_number,
                                        );
                                        set_notificaton_filters_modal(
                                          val.notification_filter,
                                        );
                                        set_alert_type_modal(
                                          val.endpoint_type,
                                        );
                                        set_show_tag_list_div(false);
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          size: 28,
                                          marginTop: "10px",
                                          color: "rgb(6, 110, 128)",
                                        }}
                                      >
                                        <FaCog />
                                      </IconContext.Provider>
                                    </span>
                                  </Tooltip>

                                  <Tooltip title="ON / OFF">
                                    <span
                                      style={{ marginLeft: "25px" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        update_notification_status(
                                          val.id,
                                          val.notification_status,
                                        );
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={
                                          val.notification_status === true
                                            ? {
                                              size: 32,
                                              marginTop: "10px",
                                              marginLeft: "12px",
                                              color: "rgb(41, 128, 185)",
                                            }
                                            : {
                                              size: 32,
                                              marginTop: "10px",
                                              marginLeft: "12px",
                                              color: "#5e5873",
                                            }
                                        }
                                      >
                                        {val.notification_status === true ? (
                                          <FaToggleOn />
                                        ) : (
                                          <FaToggleOff />
                                        )}
                                      </IconContext.Provider>
                                    </span>
                                  </Tooltip>

                                  <Tooltip title="Delete">
                                    <span
                                      style={{
                                        marginLeft: "25px",
                                        cursor: "pointer",
                                      }}
                                      data-toggle="modal"
                                      data-target="#showdeletebutton"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        set_delete_id(val.id);
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          size: 28,
                                          marginTop: "10px",
                                          color: "rgb(217, 37, 80)",
                                        }}
                                      >
                                        <FaTrashAlt />
                                      </IconContext.Provider>
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="divider_alert" />
                            </>
                          );
                        })
                        : null}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>

        {show_filters ? (
          <div className="notification_filters">
            <FiltersNotification
              set_show_filters={set_show_filters}
              postCategory={postCategory}
              setPostCategory={setPostCategory}
              keyword={keyword}
              setKeyword={setKeyword}
              interval_type_filter={interval_type_filter}
              set_interval_type_filter={set_interval_type_filter}
              set_interval_number_filter={set_interval_number_filter}
              interval_number_filter={interval_number_filter}
              option_dropdown={option_dropdown}
              notification_filters={notification_filters}
              set_notification_filters={set_notification_filters}
              notification_filters_breach={notification_filters_breach}
              set_notification_filters_breach={set_notificaton_filters_breach}
              notification_filters_tag={notification_filters_tag}
              set_notification_filters_tag={set_notification_filters_tag}
              alert_type={alert_type}
              set_alert_type={set_alert_type}
              filter_type={filter_type}
              set_filter_type={set_filter_type}
              exp_year={exp_year}
              set_exp_year={set_exp_year}
              exp_year_options={exp_year_options}
              exp_month={exp_month}
              set_exp_month={set_exp_month}
              exp_month_options={exp_month_options}
              card_type={card_type}
              set_card_type={set_card_type}
              card_type_options={card_type_options}
              crypto_provider={crypto_provider}
              set_crypto_provider={set_crypto_provider}
              crypto_options={crypto_options}
              email_provider={email_provider}
              set_email_provider={set_email_provider}
              email_options={email_options}
              translate_report={translate_report}
              set_translate_report={set_translate_report}
              alert_file_type={alert_file_type}
              set_alert_file_type={set_alert_file_type}
              alert_post_no={alert_post_no}
              set_alert_post_no={set_alert_post_no}
              multiple_email_type={multiple_email_type}
              set_multiple_email_type={set_multiple_email_type}
              end_point={end_point}
              set_open_email_alert={set_open_email_alert}
              multiple_emails_list={multiple_emails_list}
              notification_filters_stealer={notification_filters_stealer}
              set_notificaton_filters_stealer={set_notificaton_filters_stealer}
              keyword_stealer={keyword_stealer}
              setKeyword_stealer={setKeyword_stealer}
              post_category_stealer={post_category_stealer}
              set_post_category_stealer={set_post_category_stealer}
            />
          </div>
        ) : null}
      </div>

      <div
        className="modal fade"
        id="updatefilters"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="updatefilters"
        aria-hidden="true"
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog" role="document">
          {/* {users.modal_data.length !== 0 ? */}
          <div
            className="modal-content dk-mode scroll-custom "
            style={{ top: "20px", width: "580px", height: "90vh" }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="userModalLongTitle">
                Post Filters
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <CancelIcon
                  sx={{ color: "#ff1f1f ", cursor: "pointer", m: 0.5 }}
                />
              </button>
            </div>
            <div className="modal-body">
              <div className="cardbox-item">
                <div className="row">
                  <div className="col text-left">
                    <Formik
                      initialValues={{ qtext: "" }}
                      validate={(values) => {
                        const errors = {};
                        if (values.qtext.length === 0) {
                          errors.qtext = "";
                        }

                        return errors;
                      }}
                      onSubmit={() => {
                        setTimeout(() => { }, 400);
                      }}
                    >
                      {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        /* and other goodies */
                      }) =>
                        modal_data ? (
                          <form onSubmit={handleSubmit} autoComplete="off">
                            <FormLabel
                              id="demo-controlled-radio-buttons-group"
                              className="label__title"
                            >
                              {modal_data.notification_type === "channel"
                                ? "Channel/Group"
                                : "Search"}
                            </FormLabel>
                            <br />
                            <input
                              type="text"
                              className="global_input_box w-75"
                              name="qtext"
                              onChange={(e) => {
                                handleChange(e);
                                handle_onchange_update(e);
                              }}
                              onBlur={handleBlur}
                              value={
                                modal_data.notification_type === "channel" &&
                                  modal_data.notification_filter === "nested"
                                  ? search_val_modal
                                  : modal_data.notification_type ===
                                    "channel" &&
                                    modal_data.notification_filter === "Default"
                                    ? channel_name_clicked
                                    : modal_data.keyword_entered
                              }
                            />
                            {modal_data.notification_type === "channel" &&
                              modal_data.notification_filter === "nested" ? (
                              <div style={{ marginTop: "5px" }}>
                                {channels_list_chips}
                              </div>
                            ) : null}

                            {/* list of channels */}
                            {search_val_modal.length > 2 &&
                              modal_data.notification_type === "channel" &&
                              show_filters === false ? (
                              <div className="autocom-box w-80 mt-3">
                                {stat_loading ? (
                                  <RollerAnimation />
                                ) : search_error !== null ? (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      fontSize: "larger",
                                      color: "white",
                                      marginTop: "10%",
                                    }}
                                  >
                                    {search_error}
                                  </div>
                                ) : search_data_modal !== null &&
                                  search_data_modal.length === 0 ? (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      fontSize: "larger",
                                      color: "white",
                                      marginTop: "10%",
                                    }}
                                  >
                                    No Data to display
                                  </div>
                                ) : (
                                  <>
                                    <h4
                                      style={{
                                        paddingLeft: "1rem",
                                        marginLeft: "15px",
                                        marginTop: "5px",
                                        fontSize: "18px",
                                        color: "whitesmoke",
                                      }}
                                    >
                                      List Of Channels
                                    </h4>
                                    <ul>
                                      {search_data_modal !== null &&
                                        search_data_modal.map((val, index) => (
                                          <li key={index}>
                                            <div className="row">
                                              <div
                                                className="col-md-8"
                                                style={{
                                                  textAlign: "left",
                                                }}
                                              >
                                                {" "}
                                                <img
                                                  style={{
                                                    width: "36px",
                                                    height: "36px",
                                                    padding: "2px",
                                                    border: "2px solid #f4f4f4",
                                                  }}
                                                  className="img-fluid rounded-circle"
                                                  src={
                                                    val.channel_id !== "None"
                                                      ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                                  }
                                                  onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src =
                                                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                                  }}
                                                  alt="User"
                                                />
                                                <span
                                                  className="channels_list_map"
                                                  style={{
                                                    marginLeft: "10px",
                                                    color: "#dcf3ff",
                                                  }}
                                                >
                                                  {val.channel_name}
                                                </span>
                                              </div>
                                              <div
                                                className="col-md-4"
                                                style={{
                                                  textAlign: "right",
                                                  marginTop: "5px",
                                                }}
                                              >
                                                {modal_data.notification_filter !==
                                                  "nested" ? (
                                                  <button
                                                    type="button"
                                                    style={{
                                                      borderRadius: "40px",
                                                      background: "#d03940",
                                                      color: "white",
                                                      lineHeight: "1",
                                                    }}
                                                    className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                                    onClick={() => {
                                                      set_modal_data({
                                                        ...modal_data,
                                                        keyword_entered:
                                                          val.channel_name !==
                                                            "None"
                                                            ? val.channel_name
                                                            : val.link !==
                                                              "None"
                                                              ? val.link
                                                              : val.channel_id,
                                                      });
                                                      set_channel_name_clicked(
                                                        val.channel_name,
                                                      );
                                                    }}
                                                  >
                                                    Add
                                                  </button>
                                                ) : modal_data.channel_name &&
                                                  modal_data.channel_name.includes(
                                                    val.channel_name,
                                                  ) === false ? (
                                                  <button
                                                    type="button"
                                                    style={{
                                                      borderRadius: "40px",
                                                      background: "#d03940",
                                                      color: "white",
                                                      lineHeight: "1",
                                                    }}
                                                    className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                                    onClick={() =>
                                                      add_channels_list(
                                                        val.channel_name,
                                                      )
                                                    }
                                                  >
                                                    Add
                                                  </button>
                                                ) : (
                                                  <button
                                                    type="button"
                                                    disabled
                                                    style={{
                                                      borderRadius: "40px",
                                                      background: "#4cabd9",
                                                      color: "white",
                                                      lineHeight: "1",
                                                    }}
                                                    className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                                  >
                                                    Added
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        ))}
                                    </ul>
                                  </>
                                )}
                              </div>
                            ) : null}

                            {modal_data.notification_type === "channel" &&
                              modal_data.notification_filter === "nested" ? (
                              <div>
                                <FormLabel
                                  id="demo-controlled-radio-buttons-group"
                                  className="label__title"
                                >
                                  Search
                                </FormLabel>
                                <br />
                                <input
                                  type="text"
                                  className="global_input_box w-75"
                                  name="keyword"
                                  onChange={(e) => {
                                    handleChange(e);
                                    set_modal_data({
                                      ...modal_data,
                                      keyword_entered: e.target.value,
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  value={modal_data.keyword_entered}
                                />
                              </div>
                            ) : null}

                            <div>
                              <FormLabel
                                id="demo-controlled-radio-buttons-group"
                                className="label__title"
                              >
                                Tag the Alert
                              </FormLabel>
                              <br />
                              <input
                                type="text"
                                className="global_input_box w-75"
                                name="tag_alert"
                                onChange={(e) => {
                                  handleChange(e);
                                  get_tag_name_list(e);
                                }}
                                onBlur={handleBlur}
                                value={modal_data.tag_name}
                              />
                            </div>

                            {/* list of previous tag name */}
                            {show_tag_list_div === true ? (
                              <div className="autocom-box w-80 mt-3">
                                {stat_loading ? (
                                  <RollerAnimation />
                                ) : tag_name_list !== null &&
                                  tag_name_list.length === 0 ? (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      fontSize: "larger",
                                      color: "white",
                                      marginTop: "10%",
                                    }}
                                  >
                                    No Data to display
                                  </div>
                                ) : (
                                  <>
                                    <h4
                                      style={{
                                        paddingLeft: "1rem",
                                        marginLeft: "15px",
                                        marginTop: "5px",
                                        fontSize: "18px",
                                        color: "whitesmoke",
                                      }}
                                    >
                                      List Of Tag Names
                                    </h4>
                                    <ul>
                                      {tag_name_list !== null &&
                                        tag_name_list.map((val, id) => (
                                          <li key={id}>
                                            <div className="row">
                                              <div
                                                className="col-md-8"
                                                style={{ textAlign: "left" }}
                                              >
                                                {" "}
                                                <LabelIcon fontSize="large" />
                                                <span
                                                  className="channels_list_map"
                                                  style={{
                                                    marginLeft: "10px",
                                                    color: "#dcf3ff",
                                                  }}
                                                >
                                                  {val}
                                                </span>
                                              </div>
                                              <div
                                                className="col-md-4"
                                                style={{
                                                  textAlign: "right",
                                                  marginTop: "5px",
                                                }}
                                              >
                                                <button
                                                  type="button"
                                                  style={{
                                                    borderRadius: "40px",
                                                    background: "#d03940",
                                                    color: "white",
                                                    lineHeight: "1",
                                                  }}
                                                  className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                                  onClick={() => {
                                                    set_modal_data({
                                                      ...modal_data,
                                                      tag_name: val,
                                                    });
                                                    set_show_tag_list_div(
                                                      false,
                                                    );
                                                  }}
                                                >
                                                  Add
                                                </button>
                                              </div>
                                            </div>
                                          </li>
                                        ))}
                                    </ul>
                                  </>
                                )}
                              </div>
                            ) : null}

                            <FormControl sx={{ mt: 1 }}>
                              <FormLabel
                                id="demo-controlled-radio-buttons-group"
                                className="label__title"
                              >
                                Interval Type
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={interval_type}
                                onChange={(event) => {
                                  set_interval_type(event.target.value);
                                  set_interval_number(
                                    event.target.value === "minutes" ? 5 : 1,
                                  );
                                }}
                              >
                                {modal_data &&
                                  modal_data.notification_type !== "breach" && (
                                    <FormControlLabel
                                      value="day"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Day"
                                    />
                                  )}
                                <FormControlLabel
                                  value="week"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="Week"
                                />
                                {modal_data &&
                                  modal_data.notification_type !== "breach" &&
                                  modal_data.notification_type !== "stealer_logs" &&
                                  modal_data.notification_type !==
                                  "brand_protection" && (
                                    <FormControlLabel
                                      value="minutes"
                                      control={
                                        <Radio
                                          disabled={handle_disable_radio_btn()}
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label={
                                        <Badge
                                          color="secondary"
                                          badgeContent="premium"
                                        >
                                          <span>Minutes</span>
                                        </Badge>
                                      }
                                    />
                                  )}
                              </RadioGroup>
                            </FormControl>

                            {modal_data.notification_type !== "breach" &&
                              modal_data.notification_type !== "channel" &&
                              modal_data.notification_type !== "tag" &&
                              modal_data.notification_type !== "stealer_logs" &&
                              modal_data.notification_type !==
                              "brand_protection" && (
                                <FormControl>
                                  <FormLabel
                                    id="demo-controlled-radio-buttons-group"
                                    className="label__title"
                                  >
                                    Filters
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    row
                                    name="controlled-radio-buttons-group"
                                    value={notification_filters_modal}
                                    onChange={(e) =>
                                      set_notificaton_filters_modal(
                                        e.target.value,
                                      )
                                    }
                                  >
                                    <FormControlLabel
                                      value="Default"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Default"
                                    />
                                    <FormControlLabel
                                      value="regex"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Regex"
                                    />
                                    <FormControlLabel
                                      value="logical"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Logical"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              )}
                            {modal_data.notification_type === "breach" && (
                              <FormControl>
                                <FormLabel
                                  id="demo-controlled-radio-buttons-group"
                                  className="label__title"
                                >
                                  Filters
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  row
                                  name="controlled-radio-buttons-group"
                                  value={notification_filters_modal}
                                  onChange={(e) =>
                                    set_notificaton_filters_modal(
                                      e.target.value,
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    value="domain"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Domain"
                                  />
                                  <FormControlLabel
                                    value="phone"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Phone"
                                  />
                                  <FormControlLabel
                                    value="name"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Name"
                                  />
                                  <FormControlLabel
                                    value="email"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Email"
                                  />
                                  <FormControlLabel
                                    value="username"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Username"
                                  />
                                  <FormControlLabel
                                    value="ip"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="IP"
                                  />
                                  <FormControlLabel
                                    value="password"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Password"
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}

                            {modal_data.notification_type ===
                              "stealer_logs" && (
                                <FormControl>
                                  <FormLabel
                                    id="demo-controlled-radio-buttons-group"
                                    className="label__title"
                                  >
                                    Filters
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    row
                                    value={notification_filters_modal}
                                    onChange={(e) =>
                                      set_notificaton_filters_modal(
                                        e.target.value,
                                      )
                                    }
                                  >
                                    <FormControlLabel
                                      value="email"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Username/Email"
                                    />
                                    <FormControlLabel
                                      value="domain"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Domain"
                                    />
                                    <FormControlLabel
                                      value="password"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Password"
                                    />
                                    <FormControlLabel
                                      value="country_code"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Country Code"
                                    />
                                    <FormControlLabel
                                      value="ip_address"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="IP"
                                    />
                                    <FormControlLabel
                                      value="zip"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Zip"
                                    />
                                    <FormControlLabel
                                      value="location"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Location"
                                    />
                                    <FormControlLabel
                                      value="device_id"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="MID/HWID/GUID"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              )}
                            <p
                              className="card-text "
                              style={
                                users.dark_theme
                                  ? {
                                    fontWeight: 900,
                                    color: "var(--headingColor)",
                                  }
                                  : {
                                    fontWeight: 900,
                                    color: "var(--headingColor)",
                                  }
                              }
                            >
                              {" "}
                              Interval/Duration
                            </p>
                            <div className="row">
                              <div className="col text-left">
                                {modal_data !== null ? (
                                  <div style={wrapperStyle}>
                                    <Slider
                                      min={interval_type === "minutes" ? 5 : 1}
                                      max={
                                        interval_type === "minutes"
                                          ? 1439
                                          : interval_type === "week"
                                            ? 4
                                            : 7
                                      }
                                      value={interval_number}
                                      handle={handle}
                                      onChange={(e) => {
                                        set_interval_number(e);
                                      }}
                                    />
                                    <p
                                      className="txtslider"
                                      style={
                                        users.dark_theme
                                          ? {
                                            color: "#fff",
                                            fontWeight: 500,
                                            marginLeft: "80px",
                                          }
                                          : {
                                            color: "#5e5873",
                                            fontWeight: 500,
                                            marginLeft: "80px",
                                          }
                                      }
                                    >
                                      Interval:{" "}
                                      {interval_type === "minutes"
                                        ? interval_number >= 60
                                          ? `${Math.floor(
                                            Number(interval_number) / 60,
                                          )} hr ${Number(interval_number) % 60
                                          } mins`
                                          : `${interval_number} mins`
                                        : interval_number}
                                    </p>
                                  </div>
                                ) : null}
                              </div>
                              {modal_data.notification_type ===
                                "stealer_logs" && (
                                  <FormControl>
                                    <FormLabel
                                      id="demo-controlled-radio-buttons-group"
                                      className="label__title"
                                    >
                                      Category
                                    </FormLabel>
                                    <RadioGroup
                                      aria-labelledby="demo-controlled-radio-buttons-group"
                                      row
                                      name="controlled-radio-buttons-group"
                                      value={modal_data.stealer_category}
                                      onChange={(e) =>
                                        set_modal_data((prev) => ({
                                          ...prev,
                                          stealer_category: e.target.value,
                                        }))
                                      }
                                      sx={{ columnGap: 2 }}
                                    >
                                      <FormControlLabel
                                        value={"hacking"}
                                        control={
                                          <Radio
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="Hacking"
                                      />
                                      <FormControlLabel
                                        value={"pedophiles"}
                                        control={
                                          <Radio
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="Pedophiles"
                                      />
                                      <FormControlLabel
                                        value={"russian defense"}
                                        control={
                                          <Radio
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="Russian Defense"
                                      />
                                      <FormControlLabel
                                        value={"terrorism"}
                                        control={
                                          <Radio
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="Terrorism"
                                      />
                                      <FormControlLabel
                                        value={"off"}
                                        control={
                                          <Radio
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="OFF"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                )}
                              {modal_data &&
                                modal_data.notification_type !== "channel" &&
                                modal_data.notification_filter === "Default" &&
                                modal_data.notification_type !== "breach" &&
                                modal_data.notification_type !==
                                "brand_protection" ? (
                                <FormControl>
                                  <FormLabel
                                    id="demo-controlled-radio-buttons-group"
                                    className="label__title"
                                  >
                                    Keyword
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={keyword_modal}
                                    onChange={handleChangeKeywordModal}
                                  >
                                    <FormControlLabel
                                      value="contains"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Contains"
                                    />
                                    <FormControlLabel
                                      value="exact"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Exact"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              ) : null}

                              {modal_data &&
                                modal_data.notification_type ===
                                "stealer_logs" ? (
                                <FormControl>
                                  <FormLabel
                                    id="demo-controlled-radio-buttons-group"
                                    className="label__title"
                                  >
                                    Keyword
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={keyword_modal}
                                    onChange={handleChangeKeywordModal}
                                  >
                                    <FormControlLabel
                                      value="contains"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Contains"
                                    />
                                    <FormControlLabel
                                      value="exact"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Exact"
                                    />
                                    <FormControlLabel
                                      value="regex"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Regex"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              ) : null}

                              {handle_session_get("Acc_typ") ===
                                "PAID_CUSTOMER" ||
                                handle_session_get("User") === "administrator" ? (
                                <FormControl>
                                  <FormLabel
                                    id="demo-controlled-radio-buttons-group"
                                    className="label__title"
                                  >
                                    Alert Type
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={alert_type_modal}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "custom_endpoint" &&
                                        end_point === null
                                      ) {
                                        handle_warning_message(
                                          "Please add endpoint first",
                                        );
                                        return;
                                      }
                                      if (
                                        e.target.value === "multiple_email" &&
                                        (multiple_emails_list.emails.length ===
                                          0 ||
                                          multiple_emails_list.emails.length ===
                                          1)
                                      ) {
                                        handle_warning_message(
                                          "Please add some emails first",
                                        );
                                        return;
                                      }
                                      set_alert_type_modal(e.target.value);
                                      if (
                                        e.target.value === "custom_endpoint"
                                      ) {
                                        set_modal_data({
                                          ...modal_data,
                                          alert_file: "csv",
                                        });
                                      }
                                      if (e.target.value === "multiple_email") {
                                        if (!modal_data.email_type) {
                                          set_modal_data((prev) => ({
                                            ...prev,
                                            email_type: "cc",
                                          }));
                                        }
                                      }
                                    }}
                                  >
                                    <FormControlLabel
                                      value="email"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="email"
                                    />
                                    <FormControlLabel
                                      value="custom_endpoint"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Endpoint"
                                    />
                                    <FormControlLabel
                                      value="multiple_email"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="Multiple Emails"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              ) : null}

                              {alert_type_modal === "multiple_email" ? (
                                <FormControl>
                                  <FormLabel
                                    id="demo-controlled-radio-buttons-group"
                                    className="label__title"
                                  >
                                    Emails
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    row
                                    name="controlled-radio-buttons-group"
                                    value={modal_data.email_type}
                                    onChange={(e) => {
                                      set_modal_data((prev) => ({
                                        ...prev,
                                        email_type: e.target.value,
                                      }));
                                    }}
                                  >
                                    <FormControlLabel
                                      value="cc"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="CC"
                                    />
                                    <FormControlLabel
                                      value="bcc"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="BCC"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              ) : null}

                              {/* Alert file type for Update */}
                              {modal_data.notification_type !== "breach" &&
                                modal_data.notification_type !== "tag" &&
                                modal_data.notification_type !== "stealer_logs" &&
                                (alert_type_modal === "email" ||
                                  alert_type_modal === "multiple_email") ? (
                                <FormControl>
                                  <FormLabel
                                    id="demo-controlled-radio-buttons-group"
                                    className="label__title"
                                  >
                                    File Type
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    row
                                    name="controlled-radio-buttons-group"
                                    value={
                                      modal_data.alert_file
                                        ? modal_data.alert_file
                                        : "csv"
                                    }
                                    onChange={(e) => {
                                      set_modal_data({
                                        ...modal_data,
                                        alert_file: e.target.value,
                                      });
                                    }}
                                  >
                                    <FormControlLabel
                                      value="csv"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="CSV"
                                    />
                                    <FormControlLabel
                                      value="html"
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label="HTML"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              ) : null}

                              {/* Alert Post no for update */}
                              {modal_data.notification_type !== "breach" &&
                                modal_data.notification_type !== "tag" &&
                                modal_data.notification_type !==
                                "brand_protection" &&
                                (alert_type_modal === "email" ||
                                  alert_type_modal === "multiple_email") ? (
                                modal_data.alert_file &&
                                  modal_data.alert_file === "html" ? (
                                  <FormControl>
                                    <FormLabel
                                      id="demo-controlled-radio-buttons-group"
                                      className="label__title"
                                    >
                                      Post
                                    </FormLabel>
                                    <RadioGroup
                                      aria-labelledby="demo-controlled-radio-buttons-group"
                                      row
                                      name="controlled-radio-buttons-group"
                                      value={
                                        modal_data.no_of_posts === 0
                                          ? modal_data.no_of_posts
                                          : modal_data.no_of_posts
                                            ? modal_data.no_of_posts
                                            : modal_data.notification_type ===
                                              "keyword" ||
                                              modal_data.notification_type ===
                                              "file" ||
                                              modal_data.notification_type ===
                                              "file_extension"
                                              ? 3
                                              : 5
                                      }
                                      onChange={(e) => {
                                        set_modal_data({
                                          ...modal_data,
                                          no_of_posts: e.target.value,
                                        });
                                      }}
                                    >
                                      <FormControlLabel
                                        value={0}
                                        control={
                                          <Radio
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label={0}
                                      />
                                      <FormControlLabel
                                        value={1}
                                        control={
                                          <Radio
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label={1}
                                      />
                                      <FormControlLabel
                                        value={
                                          modal_data.notification_type ===
                                            "keyword" ||
                                            modal_data.notification_type ===
                                            "file" ||
                                            modal_data.notification_type ===
                                            "file_extension"
                                            ? 2
                                            : 5
                                        }
                                        control={
                                          <Radio
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          modal_data.notification_type ===
                                            "keyword" ||
                                            modal_data.notification_type ===
                                            "file" ||
                                            modal_data.notification_type ===
                                            "file_extension"
                                            ? 2
                                            : 5
                                        }
                                      />
                                      <FormControlLabel
                                        value={
                                          modal_data.notification_type ===
                                            "keyword" ||
                                            modal_data.notification_type ===
                                            "file" ||
                                            modal_data.notification_type ===
                                            "file_extension"
                                            ? 3
                                            : 10
                                        }
                                        control={
                                          <Radio
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          modal_data.notification_type ===
                                            "keyword" ||
                                            modal_data.notification_type ===
                                            "file" ||
                                            modal_data.notification_type ===
                                            "file_extension"
                                            ? 3
                                            : 10
                                        }
                                      />
                                      <FormControlLabel
                                        value={
                                          modal_data.notification_type ===
                                            "keyword" ||
                                            modal_data.notification_type ===
                                            "file" ||
                                            modal_data.notification_type ===
                                            "file_extension"
                                            ? 4
                                            : 15
                                        }
                                        control={
                                          <Radio
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          modal_data.notification_type ===
                                            "keyword" ||
                                            modal_data.notification_type ===
                                            "file" ||
                                            modal_data.notification_type ===
                                            "file_extension"
                                            ? 4
                                            : 15
                                        }
                                      />
                                      <FormControlLabel
                                        value={
                                          modal_data.notification_type ===
                                            "keyword" ||
                                            modal_data.notification_type ===
                                            "file" ||
                                            modal_data.notification_type ===
                                            "file_extension"
                                            ? 5
                                            : 20
                                        }
                                        control={
                                          <Radio
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          modal_data.notification_type ===
                                            "keyword" ||
                                            modal_data.notification_type ===
                                            "file" ||
                                            modal_data.notification_type ===
                                            "file_extension"
                                            ? 5
                                            : 20
                                        }
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                ) : null
                              ) : null}

                              {/* Tag alert update filters */}
                              {modal_data &&
                                modal_data.notification_type === "tag"
                                ? (modal_data.notification_filter ===
                                  "bank_card" ||
                                  modal_data.notification_filter ===
                                  "bin") && (
                                  <FormControl>
                                    <FormLabel
                                      id="demo-controlled-radio-buttons-group"
                                      className="label__title"
                                    >
                                      Exp Year
                                    </FormLabel>
                                    <Box
                                      className="filter__category_modal "
                                      id="scroll_filter"
                                    >
                                      {exp_year_options.map((item, id) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              value={item}
                                              onChange={
                                                handle_change_exp_year
                                              }
                                              checked={modal_data.sub_value.exp_year.includes(
                                                item,
                                              )}
                                              size="small"
                                              sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                  color: "#28cab3",
                                                },
                                              }}
                                            />
                                          }
                                          label={item}
                                          key={id}
                                        />
                                      ))}

                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value="all"
                                            onChange={handle_change_exp_year}
                                            checked={
                                              modal_data.sub_value.exp_year
                                                .length ===
                                              exp_year_options.length
                                            }
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="All"
                                      />
                                    </Box>
                                  </FormControl>
                                )
                                : null}

                              {modal_data &&
                                modal_data.notification_type === "tag"
                                ? (modal_data.notification_filter ===
                                  "bank_card" ||
                                  modal_data.notification_filter ===
                                  "bin") && (
                                  <FormControl>
                                    <FormLabel
                                      id="demo-controlled-radio-buttons-group"
                                      className="label__title"
                                    >
                                      Exp Month
                                    </FormLabel>
                                    <Box
                                      className="filter__category_modal "
                                      id="scroll_filter"
                                    >
                                      {exp_month_options.map((item, id) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              value={item}
                                              onChange={
                                                handle_change_exp_month
                                              }
                                              checked={modal_data.sub_value.exp_month.includes(
                                                item,
                                              )}
                                              size="small"
                                              sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                  color: "#28cab3",
                                                },
                                              }}
                                            />
                                          }
                                          label={item}
                                          key={id}
                                        />
                                      ))}

                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value="all"
                                            onChange={handle_change_exp_month}
                                            checked={
                                              modal_data.sub_value.exp_month
                                                .length ===
                                              exp_month_options.length
                                            }
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="All"
                                      />
                                    </Box>
                                  </FormControl>
                                )
                                : null}

                              {modal_data &&
                                modal_data.notification_type === "tag"
                                ? (modal_data.notification_filter ===
                                  "bank_card" ||
                                  modal_data.notification_filter ===
                                  "bin") && (
                                  <FormControl>
                                    <FormLabel
                                      id="demo-controlled-radio-buttons-group"
                                      className="label__title"
                                    >
                                      Card Type
                                    </FormLabel>
                                    <Box
                                      className="filter__category_modal "
                                      id="scroll_filter"
                                    >
                                      {card_type_options.map((item, id) => {
                                        let split_card_name = "";
                                        if (
                                          item ===
                                          "universal_air_travel_plan_card"
                                        ) {
                                          split_card_name = "UATP";
                                        } else if (item === "jcb_card") {
                                          split_card_name = "JCB";
                                        } else if (
                                          [
                                            "amex_card",
                                            "mastercard",
                                            "visa_card",
                                          ].includes(item) === false
                                        ) {
                                          split_card_name = item.replaceAll(
                                            "card",
                                            "",
                                          );
                                        } else {
                                          split_card_name = item;
                                        }
                                        split_card_name =
                                          split_card_name.replaceAll(
                                            "_",
                                            " ",
                                          );
                                        return (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                value={item}
                                                onChange={
                                                  handle_change_card_type
                                                }
                                                checked={modal_data.sub_value.bank_card_type.includes(
                                                  item,
                                                )}
                                                size="small"
                                                sx={{
                                                  color: "white",
                                                  "&.Mui-checked": {
                                                    color: "#28cab3",
                                                  },
                                                }}
                                              />
                                            }
                                            label={
                                              <span className="card_name">
                                                {split_card_name}
                                              </span>
                                            }
                                            key={id}
                                          />
                                        );
                                      })}

                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value="all"
                                            onChange={handle_change_card_type}
                                            checked={
                                              modal_data.sub_value
                                                .bank_card_type.length ===
                                              card_type_options.length
                                            }
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="All"
                                      />
                                    </Box>
                                  </FormControl>
                                )
                                : null}

                              {modal_data &&
                                modal_data.notification_type === "tag"
                                ? modal_data.notification_filter ===
                                "email" && (
                                  <FormControl>
                                    <FormLabel
                                      id="demo-controlled-radio-buttons-group"
                                      className="label__title"
                                    >
                                      Email Provider
                                    </FormLabel>
                                    <Box
                                      className="filter__category_modal "
                                      id="scroll_filter"
                                    >
                                      {email_options.map((item, id) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              value={item}
                                              onChange={
                                                handle_change_email_provider
                                              }
                                              checked={modal_data.sub_value.email.includes(
                                                item,
                                              )}
                                              size="small"
                                              sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                  color: "#28cab3",
                                                },
                                              }}
                                            />
                                          }
                                          label={
                                            <span className="card_name">
                                              {item}
                                            </span>
                                          }
                                          key={id}
                                        />
                                      ))}

                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value="all"
                                            onChange={
                                              handle_change_email_provider
                                            }
                                            checked={
                                              modal_data.sub_value.email
                                                .length ===
                                              email_options.length
                                            }
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="All"
                                      />
                                    </Box>
                                  </FormControl>
                                )
                                : null}

                              {modal_data &&
                                modal_data.notification_type === "tag"
                                ? modal_data.notification_filter ===
                                "crypto" && (
                                  <FormControl>
                                    <FormLabel
                                      id="demo-controlled-radio-buttons-group"
                                      className="label__title"
                                    >
                                      Crypto Provider
                                    </FormLabel>
                                    <Box
                                      className="filter__category_modal "
                                      id="scroll_filter"
                                    >
                                      {crypto_options.map((item, id) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              value={item}
                                              onChange={
                                                handle_change_crypto_provider
                                              }
                                              checked={modal_data.sub_value.crypto_type.includes(
                                                item,
                                              )}
                                              size="small"
                                              sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                  color: "#28cab3",
                                                },
                                              }}
                                            />
                                          }
                                          label={
                                            <span className="card_name">
                                              {item}
                                            </span>
                                          }
                                          key={id}
                                        />
                                      ))}

                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value="all"
                                            onChange={
                                              handle_change_crypto_provider
                                            }
                                            checked={
                                              modal_data.sub_value.crypto_type
                                                .length ===
                                              crypto_options.length
                                            }
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="All"
                                      />
                                    </Box>
                                  </FormControl>
                                )
                                : null}

                              {/* Category in Modal for updating */}
                              {modal_data &&
                                modal_data.notification_type !== "channel" &&
                                modal_data.notification_type !== "breach" &&
                                modal_data.notification_type !==
                                "brand_protection" &&
                                modal_data.notification_type !==
                                "stealer_logs" ? (
                                <Categories
                                  postCategory={postCategory_modal}
                                  setPostCategory={setPostCategory_modal}
                                  container_type={"row"}
                                />
                              ) : null}

                              {modal_data.notification_type === "keyword" &&
                                modal_data.lang_obj &&
                                modal_data.lang_obj !== "None" &&
                                modal_data.lang_obj.length > 0 ? (
                                <Stack>
                                  <span
                                    className="card-text "
                                    style={
                                      users.dark_theme
                                        ? { fontWeight: 900, color: "#fff" }
                                        : {
                                          fontWeight: 900,
                                          color: "#5e5873",
                                        }
                                    }
                                  >
                                    {" "}
                                    Select Languages
                                  </span>
                                  <button
                                    className="load_more_btn"
                                    style={{ width: "25%", margin: 0 }}
                                    onClick={() =>
                                      set_open_translate_modal(true)
                                    }
                                  >
                                    Languages
                                  </button>
                                </Stack>
                              ) : null}
                            </div>
                            <Stack direction="row" justifyContent="center">
                              <button
                                type="submit"
                                style={{
                                  marginTop: "20px",
                                }}
                                data-dismiss="modal"
                                className="button_global_style"
                                onClick={(e) => {
                                  e.preventDefault();
                                  post_data_modal(
                                    modal_data.keyword_entered,
                                    modal_data.notification_type,
                                    modal_data.regex_status,
                                    modal_data.id,
                                    modal_data.channel_name,
                                  );
                                }}
                              >
                                Update
                              </button>
                            </Stack>
                          </form>
                        ) : null
                      }
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MOdal for Channel update */}
      <div
        className="modal fade"
        id="help_logical"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="help_logical"
        aria-hidden="true"
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog" role="document">
          <div
            className={
              users.dark_theme
                ? "modal-content dk-mode"
                : "modal-content bg-white"
            }
            style={{ top: "51px", width: "580px" }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="userModalLongTitle">
                Guide for logical query
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <ClearIcon sx={{ color: "#fb1616" }} />
              </button>
            </div>
            <div className="modal-body">
              <ul className="logical_guidance">
                <li>
                  Logical keyword AND/OR should be between search queries.
                </li>
                <li>Only one conditional bracket is allowed</li>
                <li>AND / OR operator are only allowed on logical feature </li>
              </ul>
              <div>
                Example :{" "}
                <span className="logical_guide_example">
                  test1 and test2 or (test3 and test4)
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for the helpline of the LOGICAL in notification */}
      <div
        className="modal fade"
        id="help_logical"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="help_logical"
        aria-hidden="true"
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog" role="document">
          <div
            className={
              users.dark_theme
                ? "modal-content dk-mode"
                : "modal-content bg-white"
            }
            style={{ top: "51px", width: "580px" }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="userModalLongTitle">
                Guide for logical query
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <ClearIcon sx={{ color: "#fb1616" }} />
              </button>
            </div>
            <div className="modal-body">
              <ul className="logical_guidance">
                <li>
                  Logical keyword AND/OR should be between search queries.
                </li>
                <li>Only one conditional bracket is allowed</li>
                <li>AND / OR operator are only allowed on logical feature </li>
              </ul>
              <div>
                Example :{" "}
                <span className="logical_guide_example">
                  test1 and test2 or (test3 and test4)
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="showdeletebutton"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="showdeletebutton"
        aria-hidden="true"
        style={{ background: "rgba(29, 40, 53, .95)" }}
      >
        <div className="modal-dialog modal-confirm">
          <div className="modal-content dk-mode">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100">Are you sure?</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                <CancelIcon
                  sx={{ color: "#ff1f1f ", cursor: "pointer", m: 0.5 }}
                />
              </button>
            </div>
            <div className="modal-body">
              <p>
                Do you really want to delete these records? This process cannot
                be undone.
              </p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  data_delete();
                  document.getElementById("showdeletebutton").className =
                    "modal fade";
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {open_email_alert ? (
        <EmailAlert
          open_email_alert={open_email_alert}
          set_open_email_alert={set_open_email_alert}
          end_point={end_point}
          endpoint_loading={endpoint_loading}
          get_endpoint={get_endpoint}
          header_obj={header_obj}
          multiple_emails_list={multiple_emails_list}
          set_multiple_emails_list={set_multiple_emails_list}
          get_multiple_emails={get_multiple_emails}
        />
      ) : null}
      {open_channel_logical_alert_modal === true ? (
        <ChannelLogicalAlert
          open_channel_logical_alert_modal={open_channel_logical_alert_modal}
          set_open_channel_logical_alert_modal={
            set_open_channel_logical_alert_modal
          }
          keyword={keyword}
          setKeyword={setKeyword}
          interval_type_filter={interval_type_filter}
          set_interval_type_filter={set_interval_type_filter}
          interval_number_filter={interval_number_filter}
          set_interval_number_filter={set_interval_number_filter}
          alert_type={alert_type}
          set_alert_type={set_alert_type}
          channels_list={channels_list}
          set_channels_list={set_channels_list}
          post_data={post_data}
          alert_file_type={alert_file_type}
          set_alert_file_type={set_alert_file_type}
          alert_post_no={alert_post_no}
          set_alert_post_no={set_alert_post_no}
          end_point={end_point}
          multiple_emails_list={multiple_emails_list}
          multiple_email_type={multiple_email_type}
          set_multiple_email_type={set_multiple_email_type}
          set_open_email_alert={set_open_email_alert}
          alert_status="user_alert"
        />
      ) : null}
      {open_translate === true ? (
        <TranslateApi
          open_translate={open_translate}
          set_open_translate={set_open_translate}
          language_array={language_array}
          set_language_array={set_language_array}
          checked_exclude={checked_exclude}
          set_checked_exclude={set_checked_exclude}
        />
      ) : null}
      {open_translate_modal === true ? (
        <TranslateApiUpdate
          open_translate={open_translate_modal}
          set_open_translate={set_open_translate_modal}
          language_array={language_array_modal}
          set_language_array={set_language_array_modal}
          checked_exclude={checked_exclude_modal}
          set_checked_exclude={set_checked_exclude_modal}
          modal_data={modal_data}
          set_modal_data={set_modal_data}
          select_top_lang={select_top_lang_modal}
          set_select_top_lang={set_select_top_lang_modal}
        />
      ) : null}
      {open_brand_protection_modal === true ? (
        <BulkModal
          open_bulk_modal={open_brand_protection_modal}
          set_open_bulk_modal={set_open_brand_protection_modal}
          post_data={post_data}
          brand_protection_data={brand_protection_data}
          alert_status="user_alert"
        />
      ) : null}
    </section>
  );
}

Customernotification.propTypes = {
  users: PropTypes.object,
};
const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, { change_inv_func })(
  Customernotification,
);
