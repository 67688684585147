import PropTypes from "prop-types";
import React, { useState, useRef, memo } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import {
  date_to_iso,
  options_post_category,
} from "../../../Assets/globalfunctions";
import { handle_time_format } from "./companyglobalfunc";
import DialogAlert from "../modalContainer/DialogAlert";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_warning_message } from "../../../utils/ResponseMessage/response_message";
import "../../../css/Dashboard/company_dashboard.css";

function ModalCompanyFilterDate({
  stats_name,
  set_stats_state,
  chart_type,
  setting,
  tag_name_obj,
  open_modal,
  set_open_modal,
}) {
  const token = handle_session_get("Token");
  const {
    date_type,
    set_date_type,
    date_from,
    set_date_from,
    date_to,
    set_date_to,
    hourly_date,
    set_hourly_date,
    postCategory,
    setPostCategory,
  } = setting;
  // Calendar part
  const date_start_ref = useRef();
  const date_to_ref = useRef();
  const [open_dialog, set_open_dialog] = useState(false);
  const user_name_session = handle_session_get("admin_status");

  const update_chart_data = (
    qtext,
    interval,
    start_date,
    end_date,
    chart,
    categories,
  ) => {
    let isSubmitBlocked = false;
    if (chart === "product") {
      if (postCategory.length === 0) {
        handle_warning_message("Please select atleast a category");
        return;
      }
    }
    set_stats_state((prev) => ({
      ...prev,
      [stats_name]: { ...prev[stats_name], loading_chart: true, error: "" },
    }));

    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      const data = {
        qtext,
        selectCategory: chart === "product" ? categories : null,
        interval_type: interval,
        start_date:
          interval === "hourly"
            ? date_to_iso(
                hourly_date,
                start_date,
                start_date,
                end_date,
                "start_time",
              )
            : start_date,
        end_date:
          interval === "hourly"
            ? date_to_iso(
                hourly_date,
                end_date,
                start_date,
                end_date,
                "end_time",
              )
            : end_date,
      };
      if (chart !== "product") {
        delete data.selectCategory;
      }
      axios
        .post(
          chart === "product"
            ? "https://api.recordedtelegram.com/user_pref_stats"
            : chart === "domain"
            ? "https://api.recordedtelegram.com/user_domain_stats"
            : chart === "tag"
            ? "https://api.recordedtelegram.com/user_tag_stats"
            : "https://api.recordedtelegram.com/user_channel_stats",
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          const all_res = res.data.statistics;
          const categories_arr_custom_channel = [];
          const categories_obj =
            chart === "product" || chart === "channel"
              ? all_res
              : Object.fromEntries(Object.entries(all_res).slice(0, 5));
          const restructuredData =
            chart !== "channel"
              ? categories_obj[Object.keys(categories_obj)[0]].map(
                  (item, index) => {
                    const newObj = {
                      name:
                        chart !== "domain"
                          ? handle_time_format(
                              categories_obj[Object.keys(categories_obj)[0]][
                                index
                              ].date,
                            )
                          : handle_time_format(
                              categories_obj[Object.keys(categories_obj)[0]][
                                index
                              ].key_as_string,
                            ),
                    };

                    for (const val in categories_obj) {
                      newObj[val] =
                        chart !== "domain"
                          ? categories_obj[val][index].value
                          : categories_obj[val][index].doc_count;
                    }
                    return newObj;
                  },
                )
              : categories_obj.map((item) => {
                  const newObj = {
                    name: handle_time_format(item.date),
                    [stats_name]: item.value,
                  };
                  categories_arr_custom_channel.push(item);
                  return newObj;
                });

          const categories_channel_obj = {};
          let categories_channel_arr = [];
          if (chart === "product") {
            for (const val in all_res) {
              categories_channel_obj[val] = all_res[val].map(
                (item) => item.channel,
              );
            }
          } else if (chart === "tag") {
            categories_channel_arr = categories_obj[
              Object.keys(categories_obj)[0]
            ].map((item, index) => {
              const newObj = {};
              for (const val in categories_obj) {
                newObj[val] = {
                  channel_name: val,
                  total_messages: categories_obj[val][index].messages.length,
                  messages: categories_obj[val][index].messages,
                  date: item.date,
                };
              }
              return newObj;
            });
          }
          if (chart === "product") {
            set_stats_state((prev) => ({
              ...prev,
              [stats_name]: {
                ...prev[stats_name],
                data: restructuredData,
                categories_channel: categories_channel_obj,
                loading_chart: false,
              },
            }));
          } else if (chart === "domain") {
            set_stats_state((prev) => ({
              ...prev,
              [stats_name]: {
                ...prev[stats_name],
                data: restructuredData,
                loading_chart: false,
              },
            }));
          } else if (chart === "tag") {
            set_stats_state((prev) => ({
              ...prev,
              [stats_name]: {
                ...prev[stats_name],
                data: restructuredData,
                categories_channel: categories_channel_arr,
                loading_chart: false,
              },
            }));
          } else {
            set_stats_state((prev) => ({
              ...prev,
              [stats_name]: {
                ...prev[stats_name],
                data: restructuredData,
                categories_channel: categories_arr_custom_channel,
                loading_chart: false,
              },
            }));
          }
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_stats_state((prev) => ({
            ...prev,
            [stats_name]: {
              ...prev[stats_name],
              error: err.response.data.errormsg,
              loading_chart: false,
            },
          }));
          isSubmitBlocked = false;
        });
    }
  };

  const handle_disable_start_date = (filter_date, end_date) => {
    try {
      if (filter_date === "hourly") return null;
      if (end_date === "None") return null;
      const clicked_date = new Date(end_date);
      const clonedDate = new Date(clicked_date);
      switch (filter_date) {
        case "week":
          clonedDate.setDate(clicked_date.getDate() - 7 * 12);
          break;
        case "month":
          clonedDate.setMonth(clicked_date.getMonth() - 12);
          break;
        case "daily":
          clonedDate.setDate(clicked_date.getDate() - 30);
          break;
        default:
          break;
      }
      return clonedDate.toISOString().slice(0, 10);
    } catch {
      return null;
    }
  };
  const handle_disable_end_date = (filter_date, start_date) => {
    try {
      if (filter_date === "hourly") return null;
      if (start_date === "None") return new Date().toISOString().slice(0, 10);
      const clicked_date = new Date(start_date);
      const clonedDate = new Date(clicked_date);
      switch (filter_date) {
        case "week":
          clonedDate.setDate(clicked_date.getDate() + 7 * 12);
          break;
        case "month":
          clonedDate.setMonth(clicked_date.getMonth() + 12);
          break;
        case "daily":
          clonedDate.setDate(clicked_date.getDate() + 30);
          break;
        default:
          break;
      }
      return clonedDate.toISOString().slice(0, 10);
    } catch {
      return null;
    }
  };

  const handleChangePostCategory = (event) => {
    const session_categories = handle_session_get("enabled_categories");
    let session_category_array = [];
    if (session_categories !== "all") {
      const array_data = session_categories.split(",");
      session_category_array = array_data;
    }
    const { value } = event.target;
    if (user_name_session === "super_admin" || user_name_session === "admin") {
      if (value === "all") {
        setPostCategory(
          postCategory.length === options_post_category.length
            ? []
            : options_post_category,
        );
      } else {
        const list_post_category = [...postCategory];
        const index_post_category = list_post_category.indexOf(value);
        index_post_category === -1
          ? list_post_category.push(value)
          : list_post_category.splice(index_post_category, 1);
        setPostCategory(list_post_category);
      }
    } else if (session_categories === "all") {
      if (value === "all") {
        setPostCategory(
          postCategory.length === options_post_category.length
            ? []
            : options_post_category,
        );
      } else {
        const list_post_category = [...postCategory];
        const index_post_category = list_post_category.indexOf(value);
        index_post_category === -1
          ? list_post_category.push(value)
          : list_post_category.splice(index_post_category, 1);
        setPostCategory(list_post_category);
      }
    } else if (value === "all") {
      setPostCategory(
        postCategory.length === session_category_array.length
          ? []
          : session_category_array,
      );
    } else if (session_category_array.includes(value)) {
      const list_post_category = [...postCategory];
      const index_post_category = list_post_category.indexOf(value);
      index_post_category === -1
        ? list_post_category.push(value)
        : list_post_category.splice(index_post_category, 1);
      setPostCategory(list_post_category);
    } else {
      set_open_dialog(true);
    }
  };

  return (
    <div>
      <Modal
        open={open_modal}
        onClose={() => set_open_modal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="company_dash_filter_date_modal">
          <div className="modal_admin_close">
            <CancelIcon
              onClick={() => set_open_modal(false)}
              fontSize="medium"
              sx={{ color: "#eb0000 ", cursor: "pointer" }}
            />
          </div>
          <Box className="company_dash_date_modal_desc">
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Filter Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                row
                name="controlled-radio-buttons-group"
                value={date_type}
                onChange={(e) => set_date_type(e.target.value)}
                sx={{ columnGap: 2 }}
              >
                <FormControlLabel
                  value="hourly"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Hourly"
                />
                <FormControlLabel
                  value="daily"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Daily"
                />
                <FormControlLabel
                  value="week"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Weekly"
                />
                <FormControlLabel
                  value="month"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Monthly"
                />
              </RadioGroup>
            </FormControl>
            {date_type === "hourly" ? (
              <>
                <div className="dateRangePicker_filter_date_modal">
                  <h6>Date</h6>
                  <div className="dateRangePicker_input_filter_date_modal">
                    <input
                      type="date"
                      value={hourly_date}
                      onChange={(event) =>
                        set_hourly_date(
                          event.target.value ? event.target.value : "None",
                        )
                      }
                    />
                  </div>
                </div>
                <div className="dateRangePicker_filter_date_modal mt-3">
                  <h6>Time</h6>
                  <div className="dateRangePicker_input_filter_date_modal">
                    <input
                      type="text"
                      placeholder="start"
                      value={date_from !== "None" ? date_from : "start"}
                      ref={date_start_ref}
                      onClick={() => (date_start_ref.current.type = "time")}
                      onChange={(event) => {
                        const selectedTime = event.target.value;
                        if (date_to !== "None") {
                          if (selectedTime <= date_to) {
                            set_date_from(selectedTime);
                          } else {
                            set_date_from("None");
                          }
                        } else {
                          set_date_from(selectedTime);
                        }
                      }}
                      max={date_to !== "None" ? date_to : null}
                    />
                    <Box
                      sx={{
                        mx: 1,
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      to{" "}
                    </Box>
                    <input
                      type="text"
                      placeholder="end"
                      value={date_to !== "None" ? date_to : "end"}
                      ref={date_to_ref}
                      onClick={() => (date_to_ref.current.type = "time")}
                      onChange={(event) => {
                        const selectedTime = event.target.value;
                        if (date_from !== "None") {
                          if (selectedTime >= date_from) {
                            set_date_to(selectedTime);
                          } else {
                            set_date_to("None");
                          }
                        } else {
                          set_date_to(selectedTime);
                        }
                      }}
                      min={date_from !== "None" ? date_from : null}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="dateRangePicker_filter_date_modal">
                <h6>Date</h6>
                <div className="dateRangePicker_input_filter_date_modal">
                  <input
                    type="text"
                    placeholder="start"
                    value={date_from !== "None" ? date_from : "start"}
                    ref={date_start_ref}
                    onClick={() => (date_start_ref.current.type = "date")}
                    onChange={(event) =>
                      set_date_from(
                        event.target.value ? event.target.value : "None",
                      )
                    }
                    max={
                      date_to !== "None"
                        ? date_to
                        : new Date().toISOString().slice(0, 10)
                    }
                    min={handle_disable_start_date(date_type, date_to)}
                  />
                  <Box
                    sx={{
                      mx: 1,
                      color: "white",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    to{" "}
                  </Box>
                  <input
                    type="text"
                    placeholder="end"
                    value={date_to !== "None" ? date_to : "end"}
                    ref={date_to_ref}
                    onClick={() => (date_to_ref.current.type = "date")}
                    onChange={(event) =>
                      set_date_to(
                        event.target.value ? event.target.value : "None",
                      )
                    }
                    min={date_from !== "None" ? date_from : null}
                    max={handle_disable_end_date(date_type, date_from)}
                  />
                </div>
              </div>
            )}
            {chart_type === "product" ? (
              <Box
                className="company_dash_update_category_modal_content"
                sx={{ mt: 2 }}
              >
                <FormControl>
                  <FormLabel className="modal_title">Category</FormLabel>
                  <Box className="company_dash_update_category_modal_label">
                    {options_post_category.length > 0
                      ? options_post_category.map((item, id) => {
                          let channel_name = "";
                          try {
                            if (item === "telegram2") {
                              channel_name = "hacking";
                            } else if (item === "extremepolitical2") {
                              channel_name = "political";
                            } else if (item === "arab") {
                              channel_name = "arabic";
                            } else {
                              channel_name = item;
                            }
                            channel_name = channel_name.replaceAll("_", " ");
                          } catch {
                            channel_name = item;
                          }
                          if (item !== "overall") {
                            return (
                              <FormControlLabel
                                key={id}
                                control={
                                  <Checkbox
                                    value={item}
                                    onChange={handleChangePostCategory}
                                    checked={postCategory.includes(item)}
                                    size="medium"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "#28cab3",
                                      },
                                    }}
                                  />
                                }
                                label={channel_name}
                              />
                            );
                          }
                          return null;
                        })
                      : null}
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="all"
                          onChange={handleChangePostCategory}
                          checked={
                            options_post_category.length > 0 &&
                            postCategory.length === options_post_category.length
                          }
                          size="medium"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="All"
                    />
                  </Box>
                </FormControl>
              </Box>
            ) : null}
          </Box>
          <button
            onClick={() => {
              update_chart_data(
                chart_type !== "tag"
                  ? stats_name
                  : { [tag_name_obj[stats_name]]: stats_name },
                date_type,
                date_from,
                date_to,
                chart_type,
                postCategory,
              );
              set_open_modal(false);
            }}
            className="button_global_style mb-2"
          >
            Update
          </button>
          <DialogAlert
            open_dialog={open_dialog}
            set_open_dialog={set_open_dialog}
            show_filter_dialog
          />
        </Box>
      </Modal>
    </div>
  );
}

ModalCompanyFilterDate.propTypes = {
  chart_type: PropTypes.string,
  open_modal: PropTypes.bool,
  set_open_modal: PropTypes.func,
  set_stats_state: PropTypes.func,
  setting: PropTypes.object,
  stats_name: PropTypes.string,
  tag_name_obj: PropTypes.object,
};

export default memo(ModalCompanyFilterDate);
