import {
  SEARCH_FILTER_RADIO,
  USER_SEARCHED_DATA,
  PAGINATION_VALUE,
  ARRAY_LOGICAL,
  STORE_ARRAY,
  STORE_URL_LOCATION,
} from "./filterTypes";

export const handle_change_radio = (name, value) => ({
  type: SEARCH_FILTER_RADIO,
  name,
  value,
});

export const store_fetched_data = (data) => ({
  type: USER_SEARCHED_DATA,
  data,
});

export const handle_pagination_value = (value) => ({
  type: PAGINATION_VALUE,
  value,
});
export const handle_array_logical = (arr) => ({
  type: ARRAY_LOGICAL,
  array: arr,
});

export const handle_store_array = (name, arr) => ({
  type: STORE_ARRAY,
  name,
  array: arr,
});
export const handle_store_url_location = (url) => ({
  type: STORE_URL_LOCATION,
  url,
});
