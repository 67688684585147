/* eslint-disable no-undef */
import React, { useRef, useContext, memo } from "react";
import { filter_context } from "../searchbar/Searchbar";
import { MapContainer, TileLayer, Tooltip, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { leaflet_countries_color } from "../../../utils/Globals/global_variables";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_error_message } from "../../../utils/ResponseMessage/response_message";
import "../../../css/geolocation/geolocation.css";
function Geolocation() {
  const mapRef = useRef(null);
  const handleMapReady = (mapInstance) => {
    setTimeout(() => {
      if (mapInstance) {
        mapRef.current = mapInstance;
      }
    });
  };
  return (
    <div className="geolocation_map_container">
      <MapContainer
        center={[51.505, -0.09]}
        zoom={2}
        minZoom={2}
        style={{
          height: "100vh",
          width: "100%",
          background: "#1d404a",
        }}
        zoomControl={false}
        ref={mapRef}
        doubleClickZoom={false}
        scrollWheelZoom={true}
        whenReady={handleMapReady}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          noWrap={true}
          lang="en"
        />
        <LocationMarker />
      </MapContainer>
    </div>
  );
}

function LocationMarker() {
  const {
    stealer_logs_location,
    set_stealer_filters,
    search_value,
    set_typed_value,
    set_search_value,
    search_sender,
  } = useContext(filter_context)["stealer_filters"];
  const handleMarkerClick = (item) => {
    const counry_code_status = handle_session_get("country_code_access")
    const countries_key = counry_code_status==="all"?"all":JSON.parse(counry_code_status);
    if((countries_key !=="all" && countries_key.includes(item.key.toUpperCase())===false)){
      handle_error_message("sorry, this country is restricted to the user. Please contact your service provider.")
      return
    }
    set_stealer_filters("country_code");
    set_typed_value(item.key.toUpperCase());
    setTimeout(() => {
      if (item.key === search_value) {
        search_sender();
      } else {
        set_search_value(item.key);
      }
    }, 100);
  };
  const per_capital_array = stealer_logs_location["map_array"].map(
    (item) => item["doc_count"] / item["population"],
  );
  const max_value = Math.max(...per_capital_array);
  const min_value = Math.min(...per_capital_array);
  const newMin = 1;
  const newMax = 100;

  function scaleToRange(value, min, max, newMin, newMax) {
    return Math.round(
      ((value - min) / (max - min)) * (newMax - newMin) + newMin,
    );
  }

  return (
    <>
      {stealer_logs_location["map_array"].length > 0 &&
        stealer_logs_location["map_array"].map((item, index) => {
          const getFlagImageUrl =
            item.key === "AE"
              ? `${process.env.PUBLIC_URL}/static/images/AE.png`
              : `https://flagcdn.com/w20/${item["key"].toLowerCase()}.png`;
          let background_color = "";
          const intensity = scaleToRange(
            item["doc_count"] / item["population"],
            min_value,
            max_value,
            newMin,
            newMax,
          );
          background_color =
            leaflet_countries_color[intensity] ?? leaflet_countries_color[11];
          const per_capital_info = parseFloat(
            ((item["doc_count"] / item["population"]) * 1000).toFixed(6),
          );
          return (
            <>
              <GeoJSON
                data={item.location}
                style={{
                  fillColor: background_color,
                  weight: 1,
                  opacity: 1,
                  color: "#1d2835f2",
                  fillOpacity: 0.7,
                }}
                onEachFeature={(feature, layer) => {
                  layer.on("dblclick", () => handleMarkerClick(item));
                }}
                key={index}
              >
                <Tooltip sticky>
                  <div className="leaflet_marker_tooltip_box">
                    <div className="leaflet_flags_tooltip">
                      <img
                        width="20"
                        height="20"
                        src={getFlagImageUrl}
                        style={{ objectFit: "contain" }}
                        alt="flag"
                      />
                      <p style={{ color: "#dcf3ff" }}>{item.country_name}</p>
                    </div>
                    <p className="leaflet_flags_tooltip_count">
                      Total stealers :{" "}
                      <span style={{ color: "#dcf3ff", fontWeight: "600" }}>
                        {item["doc_count"]}
                      </span>
                    </p>
                    <p className="leaflet_flags_tooltip_count">
                      Total Population :{" "}
                      <span style={{ color: "#dcf3ff", fontWeight: "600" }}>
                        {item["population"]}
                      </span>
                    </p>
                    <p className="leaflet_flags_tooltip_count">
                      Per-capita (x 1000):{" "}
                      <span style={{ color: "#dcf3ff", fontWeight: "600" }}>
                        {per_capital_info}
                      </span>
                    </p>
                  </div>
                </Tooltip>
              </GeoJSON>
            </>
          );
        })}
    </>
  );
}
export default memo(Geolocation);
