/* eslint-disable no-undef */
import PropTypes from "prop-types";
import React, { useState, memo } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import TableViewIcon from "@mui/icons-material/TableView";
// eslint-disable-next-line no-unused-vars
import { Tooltip as tooltip_box, Grid } from "@mui/material";
import { RoundAnimationLoader } from "../loader/Loader";
import ModalCompanyAdminTable from "./ModalCompanyAdminTable";
import CompanyParametersFillModal from "./CompanyParametersFillModal";
import CompanyStatsNavbar from "../Navbar/CompanyStatsNavbar";
import Sidenav from "../Navbar/Sidenav";
import DashboardChart from "./DashboardChart";
import AdminChannelsList from "./AdminChannelsList";
import { styles_background_img } from "../../../utils/Globals/global_functions";
import "../../../css/Dashboard/company_dashboard.css";

function CompanyStats({
  product_stats,
  set_product_stats,
  domain_stats,
  set_domain_stats,
  tag_stats,
  set_tag_stats,
  tag_name_obj,
  channel_stats,
  set_channel_stats,
  product_status,
  domain_status,
  tag_status,
  channel_status,
  get_chart_data_func,
  company_pref_data,
}) {
  const [open_company_admin_table, set_open_company_admin_table] =
    useState(false);
  const [open_fill_param_modal, set_open_fill_param_modal] = useState(false);
  const [show_sidenav, set_show_sidenav] = useState(false);

  // const CustomTooltip = ({ active, payload, label }) => {
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="company_dash_domain_custom_tooltip">
  //         <p>{`${label}`}</p>
  //         <p>Tags Stats : <span style={{ color: "#28CAB3" }}>{payload[0].value}</span></p>
  //         <p>Stealer Stats : <span style={{ color: "#28CAB3" }} >{payload[1].value}</span></p>
  //       </div>
  //     );
  //   }
  //   return null;
  // };
  const user_name_session = localStorage.getItem("admin_status");
  return (
    <Grid container>
      {user_name_session === "super_admin" || user_name_session === "admin" ? (
        <Grid
          item
          xs={show_sidenav === true ? 0.5 : 0}
          sm={show_sidenav === true ? 0.5 : 0}
        >
          {show_sidenav ? <Sidenav /> : null}
        </Grid>
      ) : null}
      <Grid
        item
        xs={show_sidenav === true ? 11.5 : 12}
        sm={show_sidenav === true ? 11.5 : 12}
      >
        <CompanyStatsNavbar
          show_sidenav={show_sidenav}
          set_show_sidenav={set_show_sidenav}
        />
        <div
          className="company_stats_main_container"
          style={styles_background_img}
        >
          <AdminChannelsList />
          <div className="company_stats_product_container">
            <h5 className="company_stats_chart_heading">Product Stats</h5>
            {product_status.loading === false ? (
              product_status.error ||
              Object.keys(product_stats).length === 0 ? (
                <NoData
                  text={
                    product_status.error
                      ? product_status.error
                      : "No data to display"
                  }
                />
              ) : (
                <div className="company_stats_chart_parent_div">
                  {Object.keys(product_stats).length > 0 &&
                    Object.keys(product_stats).map((item, index) => (
                      <DashboardChart
                        chart_stats={product_stats}
                        set_chart_stats={set_product_stats}
                        item={item}
                        index={index}
                        company_pref_data={company_pref_data}
                        chart_type={"product"}
                        key={index}
                      />
                    ))}
                </div>
              )
            ) : (
              <RoundAnimationLoader />
            )}
          </div>
          <div className="comp_dash_divider_container">
            <div className="comp_dash_divider_top" />
            {/* <div class="comp_dash_divider_bottom"></div> */}
          </div>
          <div className="company_stats_domain_container">
            <h5 className="company_stats_chart_heading">Domain Stats</h5>
            {domain_status.loading === false ? (
              domain_status.error || Object.keys(domain_stats).length === 0 ? (
                <NoData
                  text={
                    domain_status.error
                      ? domain_status.error
                      : "No data to display"
                  }
                />
              ) : (
                <div className="company_stats_chart_parent_div">
                  {Object.keys(domain_stats).map((item, index) => (
                    <DashboardChart
                      chart_stats={domain_stats}
                      set_chart_stats={set_domain_stats}
                      item={item}
                      index={index}
                      company_pref_data={company_pref_data}
                      chart_type={"domain"}
                      key={index}
                    />
                  ))}
                </div>
              )
            ) : (
              <RoundAnimationLoader />
            )}
          </div>
          <div className="comp_dash_divider_container">
            <div className="comp_dash_divider_top" />
            {/* <div class="comp_dash_divider_bottom"></div> */}
          </div>
          <div className="company_stats_domain_container">
            <h5 className="company_stats_chart_heading">Tag Stats</h5>
            {tag_status.loading === false ? (
              tag_status.error || Object.keys(tag_stats).length === 0 ? (
                <NoData
                  text={
                    tag_status.error ? tag_status.error : "No data to display"
                  }
                />
              ) : (
                <div className="company_stats_chart_parent_div">
                  {Object.keys(tag_stats).map((item, index) => (
                    <DashboardChart
                      chart_stats={tag_stats}
                      set_chart_stats={set_tag_stats}
                      item={item}
                      index={index}
                      tag_name_obj={tag_name_obj}
                      company_pref_data={company_pref_data}
                      key={index}
                      chart_type={"tag"}
                    />
                  ))}
                </div>
              )
            ) : (
              <RoundAnimationLoader />
            )}
          </div>
          <div className="comp_dash_divider_container">
            <div className="comp_dash_divider_top" />
            {/* <div class="comp_dash_divider_bottom"></div> */}
          </div>
          {/* Channel Chart */}
          <div className="company_stats_domain_container">
            <h5 className="company_stats_chart_heading">Channel Stats</h5>
            {channel_status.loading === false ? (
              channel_status.error ||
              Object.keys(channel_stats).length === 0 ? (
                <NoData
                  text={
                    channel_status.error
                      ? channel_status.error
                      : "No data to display"
                  }
                />
              ) : (
                <div className="company_stats_chart_parent_div">
                  {Object.keys(channel_stats).map((item, index) => (
                    <DashboardChart
                      chart_stats={channel_stats}
                      set_chart_stats={set_channel_stats}
                      item={item}
                      index={index}
                      company_pref_data={company_pref_data}
                      chart_type={"channel"}
                      key={index}
                    />
                  ))}
                </div>
              )
            ) : (
              <RoundAnimationLoader />
            )}
          </div>

          {open_company_admin_table === true ? (
            <ModalCompanyAdminTable
              open_modal={open_company_admin_table}
              set_open_modal={set_open_company_admin_table}
            />
          ) : null}
          {open_fill_param_modal === true ? (
            <CompanyParametersFillModal
              open_fill_param_modal={open_fill_param_modal}
              set_open_fill_param_modal={set_open_fill_param_modal}
              fill_status="update"
              get_chart_data_func={get_chart_data_func}
            />
          ) : null}

          <div className="company_stats_btns_div company_stats_graph_div">
            {user_name_session === "company_admin" ||
            user_name_session === "super_admin" ? (
              <button
                className="company_admin_table_btn"
                onClick={() => set_open_company_admin_table(true)}
              >
                <tooltip_box title="Open company admin table">
                  <TableViewIcon
                    fontSize="large"
                    sx={{ color: "whitesmoke" }}
                  />
                </tooltip_box>{" "}
              </button>
            ) : null}
            <button
              className="company_admin_table_btn"
              onClick={() => set_open_fill_param_modal(true)}
            >
              <tooltip_box title="Update company parameters">
                <SettingsIcon fontSize="large" sx={{ color: "whitesmoke" }} />
              </tooltip_box>{" "}
            </button>
            <div className="card_arrow">
              <div className="card_arrow_top_left" />
              <div className="card_arrow_top_right" />
              <div className="card_arrow_bottom_left" />
              <div className="card_arrow_bottom_right" />
            </div>
          </div>
        </div>
        {/* Lodading modal animation */}
        <div
          className="modal fade"
          id="downloadmodal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="downloadmodal"
          aria-hidden="true"
          style={{ background: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-confirm"
            style={{ background: "#2b3c46", marginTop: "13rem" }}
          >
            <div className="modal-content dk-mode">
              <div className="modal-header flex-column">
                <h4
                  className="modal-title w-100"
                  id="model_title"
                  style={{ fontSize: "18px", color: "#359daf" }}
                >
                  Please wait
                </h4>
              </div>
              <div className="modal-body" style={{ marginTop: "50px" }}>
                <div className="load-wrapper" style={{ marginLeft: "200px" }}>
                  <div
                    className="load-circle"
                    style={{ background: "#359daf" }}
                  />
                  <div
                    className="load-circle"
                    style={{ background: "#359daf" }}
                  />
                  <div
                    className="load-circle"
                    style={{ background: "#359daf" }}
                  >
                    {" "}
                  </div>
                  <div className="load-shadow" />
                  <div className="load-shadow" />
                  <div className="load-shadow" />
                  <span style={{ left: "2%", color: "#359daf" }}>
                    Deleting...
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

CompanyStats.propTypes = {
  company_pref_data: PropTypes.array,
  domain_stats: PropTypes.object,
  domain_status: PropTypes.object,
  get_chart_data_func: PropTypes.func,
  product_stats: PropTypes.object,
  product_status: PropTypes.object,
  set_domain_stats: PropTypes.func,
  set_product_stats: PropTypes.func,
  set_tag_stats: PropTypes.func,
  channel_stats: PropTypes.object,
  set_channel_stats: PropTypes.func,
  tag_name_obj: PropTypes.object,
  tag_stats: PropTypes.object,
  tag_status: PropTypes.object,
  channel_status: PropTypes.object,
};
export function NoData({ text }) {
  return (
    <div
      className="company_stats_graph_div"
      style={{
        height: "220px",
        width: "25%",
        margin: "auto",
        marginTop: "1rem",
      }}
    >
      <div className="card-body" style={{ height: "200px" }}>
        <h5 style={{ marginTop: "20px", color: "white" }}>{text}</h5>
      </div>
      <div className="card_arrow">
        <div className="card_arrow_top_left" />
        <div className="card_arrow_top_right" />
        <div className="card_arrow_bottom_left" />
        <div className="card_arrow_bottom_right" />
      </div>
    </div>
  );
}

NoData.propTypes = {
  text: PropTypes.string,
};

export default memo(CompanyStats);
