import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Formik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { RollerAnimation } from "../loader/Loader";
import { filter_context } from "../searchbar/Searchbar";
import Datepicker from "../sections/DatePicker/Datepicker";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import "../../../css/Modal/modal.css";

function NestedTagSearch({ open_nested_tag_modal, set_open_nested_tag_modal }) {
  const {
    card_filters,
    exp_year,
    set_exp_year,
    exp_year_options,
    exp_month,
    set_exp_month,
    exp_month_options,
    card_type,
    set_card_type,
    card_type_options,
    crypto_provider,
    set_crypto_provider,
    crypto_options,
    email_provider,
    set_email_provider,
    email_options,
    card_date_from,
    set_card_date_from,
    card_date_to,
    set_card_date_to,
    display_type_tag,
    set_display_type_tag,
    max_tag,
    set_max_tag,
    channels_list,
    set_channels_list,
    set_typed_value,
    search_value,
    search_sender,
    set_search_value,
  } = useContext(filter_context)["tag_filters"];
  const isAllselectedExpYear =
    exp_year_options.length > 0 && exp_year.length === exp_year_options.length;
  const isAllselectedExpMonth =
    exp_month_options.length > 0 &&
    exp_month.length === exp_month_options.length;
  const isAllselectedCardType =
    card_type_options.length > 0 &&
    card_type.length === card_type_options.length;

  const isAllselectedCryptoProvider =
    crypto_options.length > 0 &&
    crypto_provider.length === crypto_options.length;
  const isAllselectedEmailProvider =
    email_options.length > 0 && email_provider.length === email_options.length;

  const [search_val, set_search_val] = useState("");
  const [search_data, set_search_data] = useState(null); // Get the list of channels
  const [stat_loading, set_stat_loading] = useState(false);
  const [search_error, set_search_error] = useState(null);

  const val_change = (e) => {
    set_search_val(e.target.value);
    const token = localStorage.getItem("Token");
    if (e.target.value.length > 2) {
      set_stat_loading(true);
      const data = { qtext: e.target.value };
      axios
        .post("https://api.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          set_search_data(res.data);
          set_stat_loading(false);
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_stat_loading(false);
          set_search_error(err.response.data.errormsg);
        });
    } else {
      set_search_data(null);
    }
  };

  const add_channels_list = (item) => {
    if (channels_list.includes(item) === false) {
      set_channels_list([...channels_list, item]);
    } else {
      set_channels_list(channels_list);
    }
    set_search_val("");
  };

  const handle_delete_channels = (clicked) => {
    const filter_channels = channels_list.filter((item) => item !== clicked);
    set_channels_list(filter_channels);
  };
  const channels_list_chips = channels_list.map((item, index) => (
    <Chip
      label={item}
      onDelete={() => handle_delete_channels(item)}
      color="success"
      key={index}
    />
  ));
  const handle_change_exp_year = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_exp_year(
        exp_year.length === exp_year_options.length ? [] : exp_year_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_year = [...exp_year];
      const index_exp_year = list_exp_year.indexOf(num_value);
      index_exp_year === -1
        ? list_exp_year.push(num_value)
        : list_exp_year.splice(index_exp_year, 1);
      set_exp_year(list_exp_year);
    }
  };
  const handle_change_exp_month = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_exp_month(
        exp_month.length === exp_month_options.length ? [] : exp_month_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_month = [...exp_month];
      const index_exp_month = list_exp_month.indexOf(num_value);
      index_exp_month === -1
        ? list_exp_month.push(num_value)
        : list_exp_month.splice(index_exp_month, 1);
      set_exp_month(list_exp_month);
    }
  };
  const handle_change_card_type = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_card_type(
        card_type.length === card_type_options.length ? [] : card_type_options,
      );
    } else {
      const list_card_type = [...card_type];
      const index_card_type = list_card_type.indexOf(value);
      index_card_type === -1
        ? list_card_type.push(value)
        : list_card_type.splice(index_card_type, 1);
      set_card_type(list_card_type);
    }
  };

  const handle_change_crypto_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_crypto_provider(
        crypto_provider.length === crypto_options.length ? [] : crypto_options,
      );
    } else {
      const list_crypto_provider = [...crypto_provider];
      const index_crypto_provider = list_crypto_provider.indexOf(value);
      index_crypto_provider === -1
        ? list_crypto_provider.push(value)
        : list_crypto_provider.splice(index_crypto_provider, 1);
      set_crypto_provider(list_crypto_provider);
    }
  };
  const handle_change_email_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_email_provider(
        email_provider.length === email_options.length ? [] : email_options,
      );
    } else {
      const list_email_provider = [...email_provider];
      const index_email_provider = list_email_provider.indexOf(value);
      index_email_provider === -1
        ? list_email_provider.push(value)
        : list_email_provider.splice(index_email_provider, 1);
      set_email_provider(list_email_provider);
    }
  };

  return (
    <div>
      <Modal
        open={open_nested_tag_modal}
        onClose={() => set_open_nested_tag_modal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="container_modal_nested_channel container_modal_nested_tag scroll-custom"
          id="container_modal_nested_channel"
        >
          <div className="modal_email_alert_close">
            <button
              className="modal-heading-closeBtn"
              onClick={() => set_open_nested_tag_modal(false)}
            >
              <CancelIcon sx={{ color: "#eb0000 ", cursor: "pointer" }} />
            </button>
          </div>
          <div className="modal_nested_channel_desc">
            <Formik
              initialValues={{ channel_name: "", search_word: "" }}
              validate={(values) => {
                const errors = {};

                if (values.search_word.length === 0) {
                  errors.search_word = "";
                }
                if (channels_list.length === 0) {
                  errors.channel_name = "";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  set_open_nested_tag_modal(false);

                  set_typed_value(values.search_word.trim());
                  if (values.search_word.trim() === search_value) {
                    search_sender();
                  } else {
                    set_search_value(values.search_word.trim());
                  }
                }, 400);
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="nested_input_div">
                    <label htmlFor="nested_channel_name">Channels/Groups</label>
                    <input
                      name="channel_name"
                      className="nested_channel_name"
                      id="nested_channel_name"
                      onChange={(e) => {
                        handleChange(e);
                        val_change(e);
                      }}
                      onBlur={handleBlur}
                      value={search_val}
                      type="search"
                      placeholder="add channels/groups"
                      autoComplete="off"
                    />
                  </div>
                  <div className="channels_list_chips">
                    {channels_list_chips}
                  </div>

                  {/* list of channels */}
                  {search_val.length > 2 ? (
                    <div
                      className="autocom-box  mt-2 mx-auto"
                      style={{ width: "70%" }}
                    >
                      {stat_loading ? (
                        <RollerAnimation />
                      ) : search_error !== null ? (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "larger",
                            color: "white",
                            marginTop: "10%",
                          }}
                        >
                          {search_error}
                        </div>
                      ) : search_data !== null && search_data.length === 0 ? (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "larger",
                            color: "white",
                            marginTop: "10%",
                          }}
                        >
                          No Data to display
                        </div>
                      ) : (
                        <>
                          <h4
                            style={{
                              paddingLeft: "1rem",
                              marginLeft: "15px",
                              marginTop: "5px",
                              fontSize: "18px",
                              color: "whitesmoke",
                            }}
                          >
                            List Of Channels
                          </h4>
                          <ul>
                            {search_data !== null &&
                              search_data.map((val, id) => (
                                <li key={id}>
                                  <div className="row">
                                    <div
                                      className="col-md-8"
                                      style={{ textAlign: "left" }}
                                    >
                                      {" "}
                                      <img
                                        style={{
                                          width: "36px",
                                          height: "36px",
                                          padding: "2px",
                                          border: "2px solid #f4f4f4",
                                        }}
                                        className="img-fluid rounded-circle"
                                        src={
                                          val.channel_id !== "None"
                                            ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                        }
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src =
                                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                        }}
                                        alt="User"
                                      />
                                      <span
                                        className="channels_list_map"
                                        style={{
                                          marginLeft: "10px",
                                          color: "#dcf3ff",
                                        }}
                                      >
                                        {val.channel_name}
                                      </span>
                                    </div>
                                    <div
                                      className="col-md-4"
                                      style={{
                                        textAlign: "right",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {channels_list.includes(
                                        val.channel_name,
                                      ) === false ? (
                                        <button
                                          type="button"
                                          style={{
                                            borderRadius: "40px",
                                            background: "#d03940",
                                            color: "white",
                                            lineHeight: "1",
                                          }}
                                          className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                          onClick={() =>
                                            add_channels_list(val.channel_name)
                                          }
                                        >
                                          Add
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          disabled
                                          style={{
                                            borderRadius: "40px",
                                            background: "#4cabd9",
                                            color: "white",
                                            lineHeight: "1",
                                          }}
                                          className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                        >
                                          Added
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </>
                      )}
                    </div>
                  ) : null}
                  <div className="nested_input_div">
                    <label htmlFor="nested_search_word">Search</label>
                    <input
                      name="search_word"
                      className="nested_search_word"
                      id="nested_search_word"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.search_word}
                      placeholder="type to search"
                      autoComplete="off"
                    />
                    <p className="channel_search_note">
                      <b>Note</b> : Please enter query inside / / to search
                      regex
                    </p>
                  </div>
                  <h5
                    style={{
                      color: "var(--headingColor)",
                      marginBottom: 0,
                      marginTop: "4px",
                    }}
                  >
                    Filters
                  </h5>
                  <div
                    style={{
                      borderBottom: "0.3px solid #f5f5f55e",
                      width: "80%",
                    }}
                  />
                  <div className="nested_channel_filters">
                    {(card_filters === "bank_card" ||
                      card_filters === "bin") && (
                      <FormControl>
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Exp Year
                        </FormLabel>
                        <Box
                          className="filter__category filter_category_tag_nested "
                          id="scroll_filter"
                        >
                          <FormGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                          >
                            {exp_year_options.map((item, id) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={item}
                                    onChange={handle_change_exp_year}
                                    checked={exp_year.includes(item)}
                                    size="small"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "#28cab3",
                                      },
                                    }}
                                  />
                                }
                                label={item}
                                key={{ id }}
                              />
                            ))}

                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="all"
                                  onChange={handle_change_exp_year}
                                  checked={isAllselectedExpYear}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label="All"
                            />
                          </FormGroup>
                        </Box>
                      </FormControl>
                    )}
                    {(card_filters === "bank_card" ||
                      card_filters === "bin") && (
                      <FormControl>
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Exp Month
                        </FormLabel>
                        <Box
                          className="filter__category filter_category_tag_nested "
                          id="scroll_filter"
                        >
                          <FormGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                          >
                            {exp_month_options.map((item, id) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={item}
                                    onChange={handle_change_exp_month}
                                    checked={exp_month.includes(item)}
                                    size="small"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "#28cab3",
                                      },
                                    }}
                                  />
                                }
                                label={item}
                                key={id}
                              />
                            ))}

                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="all"
                                  onChange={handle_change_exp_month}
                                  checked={isAllselectedExpMonth}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label="All"
                            />
                          </FormGroup>
                        </Box>
                      </FormControl>
                    )}

                    {(card_filters === "bank_card" ||
                      card_filters === "bin") && (
                      <FormControl>
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Card Type
                        </FormLabel>
                        <Box
                          className="filter__category filter_category_tag_nested "
                          id="scroll_filter"
                        >
                          <FormGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                          >
                            {card_type_options.map((item, index) => {
                              const split_card_name = item.replaceAll("_", " ");
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      value={item}
                                      onChange={handle_change_card_type}
                                      checked={card_type.includes(item)}
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <span className="card_name">
                                      {split_card_name}
                                    </span>
                                  }
                                  key={index}
                                />
                              );
                            })}

                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="all"
                                  onChange={handle_change_card_type}
                                  checked={isAllselectedCardType}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label="All"
                            />
                          </FormGroup>
                        </Box>
                      </FormControl>
                    )}

                    {card_filters === "crypto" && (
                      <FormControl>
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Crypto Provider
                        </FormLabel>
                        <Box
                          className="filter__category filter_category_tag_nested "
                          id="scroll_filter"
                        >
                          <FormGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                          >
                            {crypto_options.map((item, index) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={item}
                                    onChange={handle_change_crypto_provider}
                                    checked={crypto_provider.includes(item)}
                                    size="small"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "#28cab3",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <span className="card_name">{item}</span>
                                }
                                key={index}
                              />
                            ))}

                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="all"
                                  onChange={handle_change_crypto_provider}
                                  checked={isAllselectedCryptoProvider}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label="All"
                            />
                          </FormGroup>
                        </Box>
                      </FormControl>
                    )}
                    {card_filters === "email" && (
                      <FormControl>
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Email Provider
                        </FormLabel>
                        <Box
                          className="filter__category filter_category_tag_nested "
                          id="scroll_filter"
                        >
                          <FormGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                          >
                            {email_options.map((item, id) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={item}
                                    onChange={handle_change_email_provider}
                                    checked={email_provider.includes(item)}
                                    size="small"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "#28cab3",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <span className="card_name">{item}</span>
                                }
                                key={id}
                              />
                            ))}

                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="all"
                                  onChange={handle_change_email_provider}
                                  checked={isAllselectedEmailProvider}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label="All"
                            />
                          </FormGroup>
                        </Box>
                      </FormControl>
                    )}

                    <FormControl>
                      <div className="dateRangePicker">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title"
                        >
                          Uploaded Date
                        </FormLabel>
                        <Datepicker
                          date_from={card_date_from}
                          set_date_from={set_card_date_from}
                          date_to={card_date_to}
                          set_date_to={set_card_date_to}
                        />
                      </div>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Display
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={display_type_tag}
                        onChange={(event) =>
                          set_display_type_tag(event.target.value)
                        }
                      >
                        <FormControlLabel
                          value="table"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Table"
                        />
                        <FormControlLabel
                          value="json"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="JSON"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Max Result
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={max_tag}
                        onChange={(e) => set_max_tag(e.target.value)}
                        sx={{ columnGap: 2 }}
                      >
                        <FormControlLabel
                          value={100}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={100}
                        />

                        <FormControlLabel
                          value={1000}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={1000}
                        />
                        <FormControlLabel
                          value={5000}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={5000}
                        />
                        <FormControlLabel
                          value={10000}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={10000}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="button_global_style"
                  >
                    Search
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
NestedTagSearch.propTypes = {
  open_nested_tag_modal: PropTypes.bool,
  set_open_nested_tag_modal: PropTypes.func,
};

export default NestedTagSearch;
