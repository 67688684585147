import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import { Stack, Tooltip as tooltip_box } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import MaximizeIcon from "@mui/icons-material/Maximize";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { ChartTooltipChannels, ChartTooltipPosts } from "./ChartTooltip";
import { RoundAnimationLoader, ErrorMsg } from "../loader/Loader";
import {
  handle_maximize_div,
  handle_minimize_div,
  handle_determine_maximize_minimize_chart,
  handle_show_maximize_minimize_btn,
  compareDates,
  handle_detect_company_pref_id,
} from "./companyglobalfunc";
import {
  categories_line_chart_color,
  tag_line_color,
  domain_line_chart_color,
} from "../../../utils/Globals/global_variables";
import ModalCompanyFilterDate from "./ModalCompanyFilterDate";
import { DeleteConfirmBox } from "../modalContainer/DialogAlert";
import { chips_background_color } from "../../../utils/Globals/global_variables";
import { store_stats_localstorage } from "./companyglobalfunc";
import { rename_all_categories_name } from "../../../utils/Globals/global_functions";
import "../../../css/Dashboard/company_dashboard.css";

function DashboardChart({
  chart_stats,
  set_chart_stats,
  item,
  index,
  company_pref_data,
  tag_name_obj,
  chart_type,
}) {
  const [open_filter_date_modal, set_open_filter_date_modal] = useState(false);
  const [open_delete_confirm, set_open_delete_confirm] = useState(false);
  const [company_pref_id, set_company_pref_id] = useState(null);
  const user_name_session = localStorage.getItem("admin_status");
  const [date_type, set_date_type] = useState("daily");
  const [date_from, set_date_from] = useState("None");
  const [date_to, set_date_to] = useState("None");
  const [hourly_date, set_hourly_date] = useState("None");
  const [showAll, setShowAll] = useState(false);
  const filter_categories =
    chart_type === "product"
      ? Object.keys(chart_stats[item].data[0]).filter(
          (item) => item !== "name" && item !== "overall",
        )
      : [];
  const [postCategory, setPostCategory] = useState(filter_categories || []);

  useEffect(() => {
    set_date_from("None");
    set_date_to("None");
  }, [date_type]);

  const handleMouseHover = (key, x, y, index, item) => {
    const data = chart_stats[item].data[index];
    const keyName = Object.keys(data).find((name) => data[name] === key);
    const hovered_category_channels = [
      ...chart_stats[item].categories_channel[keyName][index],
    ];
    set_chart_stats((prev) => ({
      ...prev,
      [item]: {
        ...prev[item],
        channel_details: hovered_category_channels,
        hovered_category: `${keyName}$@${key}`,
        show_channel: true,
      },
    }));
  };

  const handleMouseHoverTag = (key, x, y, index, item) => {
    const data = chart_stats[item].data[index];
    const keyName = Object.keys(data).find((name) => data[name] === key);
    const hovered_category_channels =
      chart_stats[item].categories_channel[index];
    const value_array = Object.keys(hovered_category_channels).map(
      (item) => hovered_category_channels[item].total_messages,
    );
    const sum_value = value_array.reduce((sum, a) => sum + a, 0);
    set_chart_stats((prev) => ({
      ...prev,
      [item]: {
        ...prev[item],
        channel_details: hovered_category_channels,
        hovered_category: `${chart_stats[item].categories_channel[index][keyName].date}$@${sum_value}`,
        show_channel: true,
      },
    }));
  };
  const handleMouseHoverChannel = (key, x, y, index, item) => {
    const hovered_category_channels =
      chart_stats[item].categories_channel[index]["messages"];
    set_chart_stats((prev) => ({
      ...prev,
      [item]: {
        ...prev[item],
        channel_details: hovered_category_channels,
        hovered_category: `${
          chart_stats[item].categories_channel[index].date
        }$@${
          Array.isArray(hovered_category_channels)
            ? hovered_category_channels.length
            : 0
        }`,
        show_channel: true,
      },
    }));
  };
  const setting = {
    date_type,
    set_date_type,
    date_from,
    set_date_from,
    date_to,
    set_date_to,
    hourly_date,
    set_hourly_date,
    postCategory,
    setPostCategory,
  };
  return (
    <div
      id={`${chart_type}_${item}_company_stats_chart`}
      className={handle_determine_maximize_minimize_chart(
        chart_stats[item].data,
        "parent",
        chart_type,
        item,
      )}
      key={index}
    >
      <div className="company_stats_graph_div">
        <div>
          <div className="comp_stats_heading">
            <p>
              {item}{" "}
              <span style={{ color: "#28CAB3", fontSize: "small" }}>
                {chart_stats[item].pref_type === "company_preference"
                  ? "(Company Preference)"
                  : null}
              </span>
            </p>
            <Stack direction="row" alignItems="center" columnGap={2}>
              <tooltip_box
                title="Expand"
                id={`${chart_type}_${item}_company_stats_chart_max_btn`}
                className={
                  handle_show_maximize_minimize_btn(
                    chart_stats[item].data,
                    chart_type,
                    item,
                  ) === "maximize"
                    ? "comp_dash_btn_show"
                    : "comp_dash_btn_hidden"
                }
              >
                <button
                  className="comp_dash_update_categories_btn"
                  onClick={() => {
                    handle_maximize_div(
                      `${chart_type}_${item}_company_stats_chart`,
                      `${chart_type}_${item}_comp_stats_chart`,
                    );
                    store_stats_localstorage(chart_type, item, "max");
                  }}
                >
                  <ZoomOutMapIcon sx={{ color: "rgb(144, 202, 249)" }} />
                </button>
              </tooltip_box>
              <tooltip_box
                title="Shrink"
                id={`${chart_type}_${item}_company_stats_chart_min_btn`}
                className={
                  handle_show_maximize_minimize_btn(
                    chart_stats[item].data,
                    chart_type,
                    item,
                  ) === "minimize"
                    ? "comp_dash_btn_show"
                    : "comp_dash_btn_hidden"
                }
              >
                <button
                  className="comp_dash_update_categories_btn"
                  onClick={() => {
                    handle_minimize_div(
                      `${chart_type}_${item}_company_stats_chart`,
                      `${chart_type}_${item}_comp_stats_chart`,
                    );
                    store_stats_localstorage(chart_type, item, "min");
                  }}
                >
                  <MaximizeIcon sx={{ color: "rgb(144, 202, 249)", mt: 2 }} />
                </button>
              </tooltip_box>
              <tooltip_box title="Update">
                <button
                  className="comp_dash_update_categories_btn"
                  onClick={() => {
                    set_open_filter_date_modal(true);
                  }}
                >
                  <SettingsIcon sx={{ color: "rgb(144, 202, 249)" }} />
                </button>
              </tooltip_box>
              {chart_stats[item].pref_type === "company_preference" ? (
                user_name_session === "company_admin" ||
                user_name_session === "super_admin" ? (
                  <tooltip_box title="Delete">
                    <button
                      className="comp_dash_update_categories_btn"
                      onClick={() => {
                        set_open_delete_confirm(true);
                        handle_detect_company_pref_id(
                          item,
                          chart_type,
                          company_pref_data,
                          set_company_pref_id,
                        );
                      }}
                    >
                      <DeleteForeverIcon sx={{ color: "#e51549" }} />
                    </button>
                  </tooltip_box>
                ) : null
              ) : (
                <tooltip_box title="Delete">
                  <button
                    className="comp_dash_update_categories_btn"
                    onClick={() => {
                      set_open_delete_confirm(true);
                      set_company_pref_id(null);
                    }}
                  >
                    <DeleteForeverIcon sx={{ color: "#e51549" }} />
                  </button>
                </tooltip_box>
              )}
            </Stack>
          </div>
          <p
            className="chart_date_type_name"
            style={{
              border: `2px solid ${
                chips_background_color[
                  `dashboard_${compareDates(
                    chart_stats[item].data[0].name,
                    chart_stats[item].data[1].name,
                  )}`
                ]
              }`,
              color: `${
                chips_background_color[
                  `dashboard_${compareDates(
                    chart_stats[item].data[0].name,
                    chart_stats[item].data[1].name,
                  )}`
                ]
              }`,
            }}
          >
            {compareDates(
              chart_stats[item].data[0].name,
              chart_stats[item].data[1].name,
            )}
          </p>
          <div
            id={`${chart_type}_${item}_comp_stats_chart`}
            className={handle_determine_maximize_minimize_chart(
              chart_stats[item].data,
              "child",
              chart_type,
              item,
            )}
          >
            {chart_stats[item].loading_chart === true ? (
              <RoundAnimationLoader />
            ) : chart_stats[item].error ? (
              <ErrorMsg error={chart_stats[item].error} />
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={chart_stats[item].data}
                  margin={{
                    top: 5,
                    right: 10,
                    left: 0,
                    bottom: 5,
                  }}
                  isAnimationActive={false}
                >
                  <CartesianGrid
                    strokeDasharray="0"
                    fillOpacity={0.1}
                    strokeWidth={0.2}
                  />
                  <XAxis
                    dataKey="name"
                    interval={0}
                    tick={{ fontSize: 10, fill: "white" }}
                    angle={-45}
                    textAnchor="end"
                    height={50}
                  />
                  <YAxis
                    tick={{
                      fontSize: 10,
                      fill: "white",
                    }}
                  />
                  {chart_type !== "channel" ? (
                    chart_type === "product" ? (
                      <Legend
                        content={RenderLegend}
                        showAll={showAll}
                        setShowAll={setShowAll}
                      />
                    ) : (
                      <Legend
                        iconType="circle"
                        width="100%"
                        wrapperStyle={{ marginTop: "20px" }}
                      />
                    )
                  ) : null}
                  {chart_type === "domain" ? (
                    <Tooltip
                      cursor={false}
                      position={{ x: 20, y: 15 }}
                      contentStyle={{
                        background: "#0C151A",
                        border: "none",
                        textAlign: "start",
                      }}
                      isAnimationActive
                    />
                  ) : null}

                  {chart_stats[item].data &&
                    Object.keys(chart_stats[item].data[0]).map((val, ind) => {
                      if (val === "name") {
                        return null;
                      }
                      return (
                        <Line
                          type="monotone"
                          dataKey={val}
                          stroke={
                            chart_type === "product"
                              ? categories_line_chart_color[val]
                              : chart_type === "tag"
                              ? tag_line_color[ind]
                              : domain_line_chart_color[val]
                          }
                          activeDot={{ r: 8 }}
                          key={ind}
                        >
                          {chart_type !== "domain" ? (
                            <LabelList
                              dataKey={val}
                              position="top"
                              content={({ x, y, value, index }) => (
                                <circle
                                  cx={x}
                                  cy={y}
                                  r={3}
                                  fill={
                                    chart_type === "product"
                                      ? categories_line_chart_color[val]
                                      : tag_line_color[ind]
                                  }
                                  onMouseEnter={() =>
                                    chart_type === "product"
                                      ? handleMouseHover(
                                          value,
                                          x,
                                          y,
                                          index,
                                          item,
                                        )
                                      : chart_type === "tag"
                                      ? handleMouseHoverTag(
                                          value,
                                          x,
                                          y,
                                          index,
                                          item,
                                        )
                                      : handleMouseHoverChannel(
                                          value,
                                          x,
                                          y,
                                          index,
                                          item,
                                        )
                                  }
                                  // eslint-disable-next-line react/no-unknown-property
                                  isAnimationActive
                                />
                              )}
                            />
                          ) : null}
                        </Line>
                      );
                    })}
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
        <div className="card_arrow">
          <div className="card_arrow_top_left" />
          <div className="card_arrow_top_right" />
          <div className="card_arrow_bottom_left" />
          <div className="card_arrow_bottom_right" />
        </div>
        {chart_stats[item].show_channel === true &&
        Object.keys(chart_stats[item].channel_details).length > 0 ? (
          <ChartTooltipChannels
            chart={item}
            chart_stats={chart_stats}
            set_chart_stats={set_chart_stats}
            chart_type={chart_type}
          />
        ) : null}
        {chart_stats[item].show_message === true &&
        chart_stats[item].message_details.hovered_messages.length > 0 ? (
          <ChartTooltipPosts
            chart={item}
            chart_stats={chart_stats}
            set_chart_stats={set_chart_stats}
            chart_type={chart_type}
          />
        ) : null}
      </div>
      {open_filter_date_modal === true ? (
        <ModalCompanyFilterDate
          stats_name={item}
          set_stats_state={set_chart_stats}
          chart_type={chart_type}
          setting={setting}
          tag_name_obj={tag_name_obj}
          open_modal={open_filter_date_modal}
          set_open_modal={set_open_filter_date_modal}
        />
      ) : null}
      {open_delete_confirm === true ? (
        <DeleteConfirmBox
          open_modal={open_delete_confirm}
          set_open_modal={set_open_delete_confirm}
          company_pref_id={company_pref_id}
          chart_type={chart_type}
          stats_name={item}
          tag_name_obj={tag_name_obj}
        />
      ) : null}
    </div>
  );
}

DashboardChart.propTypes = {
  company_pref_data: PropTypes.any,
  index: PropTypes.number,
  item: PropTypes.string,
  chart_stats: PropTypes.object,
  set_chart_stats: PropTypes.func,
  chart_type: PropTypes.string,
  tag_name_obj: PropTypes.object,
};

const RenderLegend = ({ payload, showAll, setShowAll }) => {
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="custom_legend_container" style={{ paddingTop: "10px" }}>
      <div
        className="custom_legend_div"
        style={{ height: showAll === true ? "100px" : "fit-content" }}
      >
        {showAll
          ? payload.map((entry, index) => {
              let line_chart_color =
                categories_line_chart_color[entry.value] ?? "#AA8F65";
              return (
                <div key={`item-${index}`} className="custom_legend_item">
                  <p
                    className="custom_legend_circle"
                    style={{ background: line_chart_color }}
                  />
                  <p className="custom_legend_name">
                    {rename_all_categories_name(entry.value)}
                  </p>
                </div>
              );
            })
          : payload.slice(0, 6).map((entry, index) => {
              let line_chart_color =
                categories_line_chart_color[entry.value] ?? "#AA8F65";
              return (
                <div key={`item-${index}`} className="custom_legend_item">
                  <p
                    className="custom_legend_circle"
                    style={{ background: line_chart_color }}
                  />
                  <p className="custom_legend_name">
                    {rename_all_categories_name(entry.value)}
                  </p>
                </div>
              );
            })}

        <button className="custom_legend_button" onClick={toggleShowAll}>
          {showAll ? (
            <tooltip_box title="show less">
              <ExpandLessIcon fontSize="large" />
            </tooltip_box>
          ) : (
            <tooltip_box title="show more">
              <ExpandMoreIcon fontSize="large" />
            </tooltip_box>
          )}
        </button>
      </div>
    </div>
  );
};
RenderLegend.propTypes = {
  payload: PropTypes.array,
  showAll: PropTypes.bool,
  setShowAll: PropTypes.func,
};
DashboardChart.propTypes = {
  company_pref_data: PropTypes.any,
  index: PropTypes.number,
  item: PropTypes.string,
  chart_stats: PropTypes.object,
  set_chart_stats: PropTypes.func,
  chart_type: PropTypes.string,
  tag_name_obj: PropTypes.object,
};

export default DashboardChart;
