import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Formik } from "formik";
import CancelIcon from "@mui/icons-material/Cancel";
import { Stack } from "@mui/material";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import {
  response_message_custom,
  handle_error_message,
  handle_warning_message,
} from "../../../utils/ResponseMessage/response_message";

function AdminBulkActDeact({
  open_admin_bulk,
  set_open_admin_bulk,
  all_user_data,
}) {
  const [bulk_input, set_bulk_input] = useState("");
  const [activate, set_activate] = useState(null);

  useEffect(() => {
    if (open_admin_bulk === true) {
      set_bulk_input("");
    }
  }, [open_admin_bulk]);

  const activate_deactivate_emails = (auth) => {
    document.getElementById("downloadmodal").className = "modal fade show";
    document.getElementById("downloadmodal").style.display = "block";
    const token = localStorage.getItem("Token");
    const split_emails = bulk_input.split("\n").map((item) => item.trim());
    const data = { emails: split_emails, auth };
    axios
      .post(
        "https://api.recordedtelegram.com/v2/admin/bulkaccountdeactivation",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        const text = auth === "True" ? "activated" : "deactivated";
        response_message_custom(
          `Emails ${split_emails.toString()} ${text}`,
          "success",
        );
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
        setTimeout(() => all_user_data(), 1000);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
      });
  };

  const activate_btns_onclick = (status) => {
    if (bulk_input.length === 0) {
      handle_warning_message("Please enter emails first");
      return;
    }
    set_activate(status);
    set_open_admin_bulk(false);
  };

  return (
    <>
      <Modal
        open={open_admin_bulk}
        onClose={() => set_open_admin_bulk(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="bulk_search_modal">
          <Stack alignItems="flex-end">
            <CancelIcon
              onClick={() => set_open_admin_bulk(false)}
              fontSize="small"
              sx={{ color: "red ", cursor: "pointer", m: 0.5 }}
            />
          </Stack>
          <h5>Please enter emails</h5>
          <Formik
            initialValues={{ bulk_search: "" }}
            validate={(values) => {
              const errors = {};

              if (values.bulk_search.length === 0) {
                errors.bulk_search = "";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                // set_bulk_input(JSON.stringify(values.bulk_search, null, 2))
                setSubmitting(false);
                set_open_admin_bulk(false);
              }, 400);
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <textarea
                  name="bulk_search"
                  className="bulk_search_textarea"
                  rows="10"
                  cols="20"
                  onChange={(e) => {
                    handleChange(e);
                    set_bulk_input(e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={bulk_input}
                  placeholder="example:&#10;test1@gmail.com&#10;test2@gmail.com&#10;test3@gmail.com"
                />
                <Stack direction="row" columnGap={1} justifyContent="center">
                  <button
                    className="bulk_search_submit"
                    data-toggle="modal"
                    data-target={
                      bulk_input.length > 0 ? "#activate_deactivate_user" : ""
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      activate_btns_onclick(true);
                    }}
                  >
                    Activate
                  </button>
                  <button
                    className="bulk_search_submit admin_bulk_deactivate_btn"
                    data-toggle="modal"
                    data-target={
                      bulk_input.length > 0 ? "#activate_deactivate_user" : ""
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      activate_btns_onclick(false);
                    }}
                  >
                    Deactivate
                  </button>
                </Stack>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
      {/* Modal for loading please wait */}
      <div
        className="modal fade"
        id="downloadmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="downloadmodal"
        aria-hidden="true"
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div
          className="modal-dialog modal-confirm"
          style={{ background: "#2b3c46", marginTop: "13rem" }}
        >
          <div className="modal-content dk-mode">
            <div className="modal-header flex-column">
              <h4
                className="modal-title w-100"
                style={{ fontSize: "18px", color: "#359daf" }}
              >
                Please wait,
              </h4>
            </div>
            <div className="modal-body" style={{ marginTop: "50px" }}>
              <div className="load-wrapper" style={{ marginLeft: "200px" }}>
                <div
                  className="load-circle"
                  style={{ background: "#359daf" }}
                />
                <div
                  className="load-circle"
                  style={{ background: "#359daf" }}
                />
                <div className="load-circle" style={{ background: "#359daf" }}>
                  {" "}
                </div>
                <div className="load-shadow" />
                <div className="load-shadow" />
                <div className="load-shadow" />
                <span style={{ left: "2%", color: "#359daf" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      {/* Modal for the reseting feature of the user */}
      <div
        className="modal fade"
        id="activate_deactivate_user"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="activate_deactivate_user"
        aria-hidden="true"
        style={{ background: "rgba(29, 40, 53, .95)" }}
      >
        <div className="modal-dialog modal-confirm">
          <div className="modal-content" style={{ background: "#2b3c46" }}>
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100" style={{ color: "white" }}>
                Are you sure?
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
                style={{ color: "white" }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p style={{ color: "whitesmoke" }}>
                {`Do you really want to ${
                  activate === true ? "activate" : "deactivate"
                } these records? This process cannot be undone.`}
              </p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-info"
                data-dismiss="modal"
              >
                Cancel
              </button>
              {activate !== null ? (
                activate === true ? (
                  <button
                    type="button"
                    className="btn btn-info"
                    style={{ background: "green" }}
                    data-dismiss="modal"
                    onClick={(e) => {
                      e.preventDefault();
                      activate_deactivate_emails("True");
                      document.getElementById("reset_user").className =
                        "modal fade";
                    }}
                  >
                    Activate
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={(e) => {
                      e.preventDefault();
                      activate_deactivate_emails("False");
                      document.getElementById("reset_user").className =
                        "modal fade";
                    }}
                  >
                    Deactivate
                  </button>
                )
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
AdminBulkActDeact.propTypes = {
  open_admin_bulk: PropTypes.bool,
  set_open_admin_bulk: PropTypes.func,
  all_user_data: PropTypes.func,
};

export default AdminBulkActDeact;
