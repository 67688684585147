// NOTE : Disable enlint for the process declaration
/* eslint-disable no-undef */
export const styles_background_img = {
  "--borders-url": `url(${process.env.PUBLIC_URL}/static/images/pattern.png)`,
  "--logo-url": `url(${process.env.PUBLIC_URL}/static/images/bg4.jpeg)`,
};

export const filter_logical_keyword = (query) => {
  try {
    const new_sub = /\s{2,}/g;
    const double_space_remover = query.replaceAll(new_sub, " ");
    const open_braket_space_remover = double_space_remover.replace(
      /\([^\w+]/g,
      "(",
    );
    const close_braket_space_remover = open_braket_space_remover.replace(
      /[^\w+]\)/g,
      ")",
    );
    const remove_bracket_open = close_braket_space_remover.replaceAll("(", "");
    const remove_bracket_close = remove_bracket_open.replaceAll(")", "");
    const remove_quatation = remove_bracket_close
      .replaceAll('"', "")
      .replaceAll("'", "");
    const split_keyword = remove_quatation.split(
      /\s\b(and|or|not|AND|OR|NOT)\b\s/i,
    );
    const filter_keyword_array = split_keyword.filter((item) => {
      return (
        item.toLowerCase() !== "or" &&
        item.toLowerCase() !== "and" &&
        item.toLowerCase() !== "not"
      );
    });
    const remove_space = filter_keyword_array.filter((item) => item.length > 0);
    const remove_dublicate_items = [...new Set(remove_space)];
    return remove_dublicate_items;
  } catch {
    return query.split(" ");
  }
};

export const rename_all_categories_name = (category) => {
  let category_name = "";
  try {
    switch (category) {
      case "telegram2":
        category_name = "Hacking";
        break;
      case "extremepolitical2":
        category_name = "Political";
        break;
      case "extremepolitical":
        category_name = "Political";
        break;
      case "criminal_activities":
        category_name = "Criminal";
        break;
      case "religion_spirituality":
        category_name = "Religious";
        break;
      case "information_technology":
        category_name = "IT";
        break;
      case "pharma_drugs":
        category_name = "Pharma";
        break;
      case "betting_gambling":
        category_name = "Gambling";
        break;
      case "blogs_vlogs":
        category_name = "Blogs";
        break;
      case "science_index":
        category_name = "Science";
        break;
      case "books_comics":
        category_name = "Books";
        break;
      case "fashion_beauty":
        category_name = "Fashion";
        break;
      case "design_arch":
        category_name = "Design";
        break;
      case "humor_entertainment":
        category_name = "Humor";
        break;
      case "culture_events":
        category_name = "Culture";
        break;
      case "adult_content":
        category_name = "Adult";
        break;
      case "marketing_and_ads":
        category_name = "Marketing";
        break;
      case "sports_and_activities":
        category_name = "Sports";
        break;
      case "cyber_security":
        category_name = "Cyber";
        break;
      case "arab":
        category_name = "arabic";
        break;
      default:
        category_name = category;
        break;
    }
    category_name =
      category_name !== "IT"
        ? category_name
            .replaceAll("_", " ")
            .split(" ")
            .map(function (word) {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            })
            .join(" ")
        : "IT";
  } catch {
    category_name = category;
  }
  return category_name;
};

export const handleOpenNewTab = (url_route, e = null) => {
  if (e) {
    e.preventDefault();
  }
  window.open(url_route, "_blank");
};
export const detect_channel_group_type = (typ) => {
  if (typ !== undefined) {
    const channel_type = typ === "True" ? "Group" : "Channel";
    return channel_type;
  }
  return null;
};

export const tag_crypto_auto_search = (
  nav_search,
  category,
  tag_type,
  search_query,
) => {
  if (
    nav_search === "card_search" &&
    category.length === 1 &&
    tag_type === "crypto" &&
    !search_query
  ) {
    return true;
  } else {
    return false;
  }
};
export const stealer_logs_auto_search = (
  nav_search,
  category,
  search_query,
) => {
  if (
    nav_search === "stealer_logs_search" &&
    category !== "off" &&
    !search_query
  ) {
    return true;
  } else {
    return false;
  }
};
export const stealer_logs_auto_notification = (
  filter,
  category,
  search_query,
) => {
  if (
    filter === "stealer_logs" &&
    category !== "off" &&
    category !== null &&
    !search_query
  ) {
    return true;
  } else {
    return false;
  }
};
export const txt_remove_underscore = (text) => {
  try {
    if (text.includes("_")) {
      const filter_text = text.replaceAll("_", " ");
      return filter_text;
    } else return text;
  } catch {
    return text;
  }
};

export const check_author_channel_or_user = (item) => {
  if (item.is_group === "True" && item.author) {
    const author_info =
      item.author_info === "channel/group"
        ? {
            author_type: "channel/group",
            channel:
              item.author?.channel ||
              item.author?.channel_link ||
              item.author?.channel_id,
            navigate_type: item.author.channel
              ? "channel name"
              : item.author.channel_link
              ? "link"
              : "id",
          }
        : {
            author_type: "user",
            user:
              item.author?.username && item.author.username !== "None"
                ? item.author.username
                : item.author?.userfullname &&
                  item.author.userfullname !== "None"
                ? item.author.userfullname
                : item.author?.userid,
            user_id: item.author.userid,
            navigate_type:
              (item.author?.username && item.author.username !== "None") ||
              (item.author?.userfullname && item.author.userfullname !== "None")
                ? "name"
                : "id",
          };
    return author_info;
  }
};

export const handle_filter_brand_protection_keyword = (query) => {
  if (query.includes(",")) {
    const split_query = query.split(",");
    const filter_query = split_query.filter((item) => item.length > 0);
    const sanitize_query = filter_query.map((item) =>
      item.trim().toLowerCase(),
    );
    const join_query = [...new Set(sanitize_query)].join("\n");
    return join_query;
  }
  return query;
};

export const handle_validation_brand_protection_query = (
  inserted_array,
  query,
  seperator,
) => {
  if (inserted_array.length >= 10) {
    return "You have already inserted 10 queries. Please deleted some of them to add new.";
  } else if (query.includes(",") || query.includes("\n")) {
    const split_query = query.split(seperator === "comma" ? "," : "\n");
    const filter_query = split_query.filter((item) => item.length > 0);
    if (
      inserted_array.length > 0 &&
      filter_query.length > 10 - inserted_array.length
    ) {
      return `You have already inserted ${
        inserted_array.length
      } queries.You can only add ${10 - inserted_array.length} new queries.`;
    } else if (filter_query.length > 10) {
      return "You can only insert a maximum of 10 queries at a time.";
    } else {
      return "submit form";
    }
  } else {
    return "submit form";
  }
};

export const sort_cloumn_datewise = (v1, v2) => new Date(v1) - new Date(v2);
