import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import axios from "axios";
import Stack from "@mui/material/Stack";
import {
  get_data,
  inv_data,
  update_user_type,
  updt_pass,
  updt_email,
  logout,
} from "../actions/userAction";
import UserHomeV2 from "../TableUi/components/home/UserHomeV2";
import {
  handle_session_get,
  handle_clear_localStorage,
} from "../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../utils/Validation/Token/token_checker";
import {
  handle_error_message,
  response_message_custom,
} from "../utils/ResponseMessage/response_message";
import { styles_background_img } from "../utils/Globals/global_functions";
import TransparentContainer from "../TableUi/components/sections/Charts/TransparentContainer";
import { options_post_category } from "../Assets/globalfunctions";
import { rename_all_categories_name } from "../utils/Globals/global_functions";
import BorderColorIcon from "@mui/icons-material/BorderColor";
function Userprofile({
  users,
  get_data,
  inv_data,
  update_user_type,
  updt_email,
  logout,
}) {
  const token = handle_session_get("Token");
  const [email, set_email] = useState("");
  const [username, set_username] = useState("");
  const [password, set_password] = useState("");
  const [message, set_message] = useState("");
  const [edit_user, set_edit_user] = useState("");
  const session_cagetories = handle_session_get("enabled_categories");
  const rename_categories = session_cagetories
    ? session_cagetories !== "all"
      ? session_cagetories
          .split(",")
          .map((item) => rename_all_categories_name(item))
      : options_post_category.map((item) => rename_all_categories_name(item))
    : [];
  const filter_session_categories = [...rename_categories];
  const company_name = handle_session_get("company_status") || "None";
  const updt_pass_indv = (e, password) => {
    e.preventDefault();
    const data = { id: users.userid, newpassword: password };
    axios
      .post("https://api.recordedtelegram.com/v2/updateself", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const all_arr = Object.keys(response.data);

        if (all_arr.includes("Success")) {
          set_message("Password");

          update_user_type(false);
          document.getElementById("showdeletebutton").className =
            "modal fade show";
          document.getElementById("showdeletebutton").style.display = "block";
        } else {
          handle_error_message(response.data.errormsg);
        }
      })
      .catch((error) => {
        const token_expire_status = handle_token_expire(error.response.data);
        if (token_expire_status === true) return;
        response_message_custom(error.response.data.errormsg, "error");
      });
  };

  const updt_username = (e, username) => {
    e.preventDefault();
    const data = { id: users.userid, new_username: username };
    axios
      .post("https://api.recordedtelegram.com/v2/update_username", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const all_arr = Object.keys(response.data);

        if (all_arr.includes("Success")) {
          set_message("Username");

          update_user_type(false);

          document.getElementById("showdeletebutton").className =
            "modal fade show";
          document.getElementById("showdeletebutton").style.display = "block";
        } else {
          handle_error_message(response.data.errormsg);
        }
      })
      .catch((error) => {
        const token_expire_status = handle_token_expire(error.response.data);
        if (token_expire_status === true) return;
        response_message_custom(error.response.data.errormsg, "error");
      });
  };
  useEffect(() => {
    get_data();
    inv_data();
  }, []);
  return (
    <div className="company_stats_main_container" style={styles_background_img}>
      <UserHomeV2 />
      <section id="boxes" className="">
        <div className="page-content page-container" id="page-content">
          <div className="padding">
            <div
              className="row container d-flex justify-content-center"
              style={{ marginTop: "130px", marginLeft: "8%" }}
            >
              <div className="col-xl-10 col-md-12">
                <div className="user-card-full">
                  <div className="row m-l-0 m-r-0">
                    <div className="col-sm-3 bg-c-lite-green user-profile">
                      <div className="card-block text-center text-white">
                        <div className="m-b-25">
                          {" "}
                          <img
                            src="https://img.icons8.com/bubbles/100/000000/user.png"
                            className="img-radius"
                            alt="User-Profile"
                          />{" "}
                        </div>
                        <h6
                          className="f-w-600"
                          style={{ textTransform: "capitalize" }}
                        >
                          {users.username}
                        </h6>

                        <h6 className="f-w-600">
                          {users.superadmin === "False" ? "User" : " Admin"}
                        </h6>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            update_user_type(true);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-9">
                      <TransparentContainer
                        container={
                          <div>
                            <form autoComplete="off">
                              <h6 className="m-b-20 p-b-5 f-w-600 userprofile_detail_text_heading">
                                Information
                              </h6>
                              <div className="row">
                                <div className="col-sm-6">
                                  <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="baseline"
                                    columnGap={1}
                                  >
                                    <p className="m-b-10 f-w-600">Username</p>
                                    {users.update_user &&
                                    edit_user !== "username" ? (
                                      <button
                                        type="button"
                                        className="edit_user_information_btn"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          set_edit_user("username");
                                        }}
                                      >
                                        <BorderColorIcon
                                          sx={{ color: "#ddca30" }}
                                        />
                                      </button>
                                    ) : null}
                                  </Stack>
                                  {edit_user === "username" ? (
                                    <div>
                                      <input
                                        type="text"
                                        name="username-profile"
                                        id="username-profile"
                                        className="global_input_box w-75"
                                        placeholder="Username"
                                        autoComplete="username-profile"
                                        value={username}
                                        required
                                        onChange={(e) => {
                                          set_username(e.target.value);
                                        }}
                                        style={{ margin: "0" }}
                                      />
                                      {username.length === 0 ? (
                                        <h6
                                          style={{
                                            margin: "5px 0 0 0",
                                            color: "red",
                                            fontSize: "small",
                                          }}
                                        >
                                          Username must not be empty
                                        </h6>
                                      ) : username.length < 3 ? (
                                        <h6
                                          style={{
                                            margin: "5px 0 0 0",
                                            color: "red",
                                            fontSize: "small",
                                          }}
                                        >
                                          Username must be longer than 2
                                          characters
                                        </h6>
                                      ) : /^[.A-Za-z0-9_-]*$/g.test(
                                          username,
                                        ) === false ? (
                                        <h6
                                          style={{
                                            margin: "5px 0 0 0",
                                            color: "red",
                                            fontSize: "small",
                                          }}
                                        >
                                          {`Username should only contain alphabetical
                                        characters, numbers and symbol as . - _'`}
                                        </h6>
                                      ) : null}
                                      {users.update_user ? (
                                        <button
                                          type="button"
                                          style={{
                                            marginLeft: "20px",
                                            padding: "3px 2rem",
                                            marginTop: "10px",
                                          }}
                                          disabled={
                                            !!(
                                              username.length < 3 ||
                                              /^[.A-Za-z0-9_-]*$/g.test(
                                                username,
                                              ) === false
                                            )
                                          }
                                          className="btn btn-danger"
                                          onClick={(e) => {
                                            updt_username(e, username);
                                          }}
                                        >
                                          Update
                                        </button>
                                      ) : null}
                                    </div>
                                  ) : (
                                    <h6 className="userprofile_detail_text">
                                      {users.username}
                                    </h6>
                                  )}
                                </div>
                                <div className="col-sm-6">
                                  <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="baseline"
                                    columnGap={1}
                                  >
                                    <p className="m-b-10 f-w-600">Email</p>
                                    {users.update_user &&
                                    edit_user !== "email" ? (
                                      <button
                                        type="button"
                                        className="edit_user_information_btn"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          set_edit_user("email");
                                        }}
                                      >
                                        <BorderColorIcon
                                          sx={{ color: "#ddca30" }}
                                        />
                                      </button>
                                    ) : null}
                                  </Stack>
                                  {edit_user === "email" ? (
                                    <div>
                                      <input
                                        type="email"
                                        name="email-profile"
                                        id="email-profile"
                                        className="global_input_box w-75"
                                        placeholder="Email here"
                                        autoComplete="email-profile"
                                        value={email}
                                        required
                                        onChange={(e) => {
                                          set_email(e.target.value);
                                        }}
                                        style={{ margin: "0" }}
                                      />
                                      {email.length === 0 ? (
                                        <h6
                                          style={{
                                            margin: "5px 0 0 0",
                                            color: "red",
                                            fontSize: "small",
                                          }}
                                        >
                                          Email must not be empty
                                        </h6>
                                      ) : null}
                                      <button
                                        type="button"
                                        disabled={email.length === 0}
                                        style={{
                                          marginLeft: "20px",
                                          padding: "3px 2rem",
                                          marginTop: "10px",
                                        }}
                                        className="btn btn-danger"
                                        onClick={(e) => {
                                          updt_email(e, email);
                                        }}
                                      >
                                        Update
                                      </button>
                                    </div>
                                  ) : (
                                    <h6 className="userprofile_detail_text">
                                      {users.email}
                                    </h6>
                                  )}
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="col-sm-6">
                                  <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="baseline"
                                    columnGap={1}
                                  >
                                    <p className="m-b-10 f-w-600">Password</p>
                                    {users.update_user &&
                                    edit_user !== "password" ? (
                                      <button
                                        type="button"
                                        className="edit_user_information_btn"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          set_edit_user("password");
                                        }}
                                      >
                                        <BorderColorIcon
                                          sx={{ color: "#ddca30" }}
                                        />
                                      </button>
                                    ) : null}
                                  </Stack>
                                  {edit_user === "password" ? (
                                    <div>
                                      <input
                                        type="password"
                                        name="new-password"
                                        id="new-password"
                                        className="global_input_box w-75"
                                        placeholder="Password"
                                        autoComplete="new-password"
                                        value={password}
                                        required
                                        onChange={(e) => {
                                          set_password(e.target.value);
                                        }}
                                        style={{ margin: "0" }}
                                      />
                                      {password.length === 0 ? (
                                        <h6
                                          style={{
                                            margin: "5px 0 0 0",
                                            color: "red",
                                            fontSize: "small",
                                          }}
                                        >
                                          Password must not be empty
                                        </h6>
                                      ) : password.length < 6 ||
                                        password.length > 30 ? (
                                        <h6
                                          style={{
                                            margin: "5px 0 0 0",
                                            color: "red",
                                            fontSize: "small",
                                          }}
                                        >
                                          Password must be between 6 to 30
                                          characters
                                        </h6>
                                      ) : /['"]+$/g.test(password) === true ? (
                                        <h6
                                          style={{
                                            margin: "5px 0 0 0",
                                            color: "red",
                                            fontSize: "small",
                                          }}
                                        >
                                          Password must not contain single quote
                                          or double quote
                                        </h6>
                                      ) : null}
                                      {users.update_user ? (
                                        <button
                                          type="button"
                                          style={{
                                            marginLeft: "20px",
                                            padding: "3px 2rem",
                                            marginTop: "10px",
                                          }}
                                          disabled={
                                            !!(
                                              password.length < 6 ||
                                              password.length > 30 ||
                                              /['"]+$/g.test(password) === true
                                            )
                                          }
                                          className="btn btn-danger"
                                          onClick={(e) => {
                                            updt_pass_indv(e, password);
                                          }}
                                        >
                                          Update
                                        </button>
                                      ) : null}
                                    </div>
                                  ) : (
                                    <h6 className="userprofile_detail_text">
                                      ********
                                    </h6>
                                  )}
                                </div>
                                <div className="col-sm-6">
                                  <p className="m-b-10 f-w-600">Tokens</p>
                                  <h6 className="userprofile_detail_text">
                                    {users.tokensgenerated}
                                  </h6>
                                </div>
                              </div>
                              <h6 className="m-b-20 m-t-40 p-b-5 f-w-00 userprofile_detail_text_heading">
                                Authorizations
                              </h6>
                              <div className="row">
                                <div className="col-sm-6">
                                  <p className="m-b-10 f-w-600">Status</p>
                                  <h6 className="userprofile_detail_text">
                                    Authorized
                                  </h6>
                                </div>
                                <div className="col-sm-6">
                                  <p className="m-b-10 f-w-600">Rate Limit</p>
                                  <h6 className="userprofile_detail_text">
                                    {users.ratelimit}
                                  </h6>
                                </div>
                              </div>
                              <div className="row mt-2">
                                <div className="col-sm-6">
                                  <p className="m-b-10 f-w-600">Account Type</p>
                                  <h6 className="userprofile_detail_text">
                                    {localStorage.getItem("Acc_typ") ===
                                    "PAID_CUSTOMER"
                                      ? "Paid"
                                      : "Trial"}
                                  </h6>
                                </div>
                                <div className="col-sm-6">
                                  <p className="m-b-10 f-w-600">Company Name</p>
                                  <h6 className="userprofile_detail_text">
                                    {company_name}
                                  </h6>
                                </div>
                              </div>
                              <div className="row mt-2">
                                <div className="col-sm-6">
                                  <p className="m-b-10 f-w-600">
                                    Categories Access
                                  </p>
                                  <p className="user_profile_categories_list">
                                    {filter_session_categories.join(`, `)}
                                  </p>
                                </div>
                              </div>
                            </form>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
      <div
        className="modal fade"
        id="showdeletebutton"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="showdeletebutton"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-confirm">
          <div
            className="modal-content"
            style={{ background: "rgba(29, 40, 53, .95)" }}
          >
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100 text-light">
                {message} Sucessfully Changed
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("showdeletebutton").style.display =
                    "none";
                  document.getElementById("showdeletebutton").className =
                    "modal fade";
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p>
                Your {message} had been sucessfully changed. Please Go To Login
                page to further use the services
              </p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();

                  logout();
                  sessionStorage.clear();
                  handle_clear_localStorage();
                  window.location.href = "/";
                  document.getElementById("showdeletebutton").className =
                    "modal fade";
                }}
              >
                LOGOUT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
Userprofile.propTypes = {
  users: PropTypes.object,
  get_data: PropTypes.func,
  inv_data: PropTypes.func,
  update_user_type: PropTypes.func,
  updt_email: PropTypes.func,
  logout: PropTypes.func,
};

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps, {
  get_data,
  inv_data,
  update_user_type,
  updt_pass,
  updt_email,
  logout,
})(Userprofile);
