import React, { useState } from "react";
import fileDownload from "js-file-download";
import PropTypes from "prop-types";
import axios from "axios";
import { Stack } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ClearIcon from "@mui/icons-material/Clear";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import {
  handle_error_message,
  handle_internal_server_error,
} from "../../../utils/ResponseMessage/response_message";
import { handle_disable_radio_btn } from "../../../utils/SessionStorage/handle_session";

function DownloadFileOptions({
  getSearchedData,
  file_id,
  post_no,
  search_table_type,
}) {
  // state for the download
  const [max_download, set_max_download] = useState(post_no);
  const [download_type, set_download_type] = useState("csv");
  // handle max download for download modal
  const handle_max_download = (event) => {
    set_max_download(event.target.value);
  };
  // Download file
  const file_download = (filename, file_type, no_of_posts) => {
    model_popup();
    const token = handle_session_get("Token");

    try {
      axios
        .post(
          `https://api.recordedtelegram.com/${
            search_table_type === "telegram" ? "posts" : "tags"
          }_csv_report`,
          {
            file_hash: filename,
            file_type,
            no_of_posts: Number(no_of_posts),
          },
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          const new_file_name = `${getSearchedData}_${new Date()}.${file_type}`;
          fileDownload(res.data, new_file_name);
          document.getElementById("downloadmodal").className = "modal fade";
          document.getElementById("downloadmodal").style.display = "none";
        })
        .catch((err) => {
          document.getElementById("downloadmodal").className = "modal fade";
          document.getElementById("downloadmodal").style.display = "none";
          const is_internal_server_err = handle_internal_server_error(
            err.response.status,
          );
          if (is_internal_server_err === true) return;
          const token_expire_status = handle_token_expire(
            err.response.data,
            err.response.status,
          );
          if (token_expire_status === true) return;
          let errorMessage = "";
          const reader = new FileReader();
          reader.onload = () => {
            errorMessage = reader.result;
            handle_error_message(JSON.parse(errorMessage).errormsg);
          };
          reader.readAsText(err.response.data);
        });
    } catch (err) {
      document.getElementById("downloadmodal").className = "modal fade";
      document.getElementById("downloadmodal").style.display = "none";
      handle_error_message("Sorry could not download file");
    }
  };
  const model_popup = () => {
    document.getElementById("downloadmodal").className = "modal fade show";
    document.getElementById("downloadmodal").style.display = "block";
  };
  return (
    <div>
      {file_id !== "None" ? (
        search_table_type === "telegram" ? (
          <Button data-toggle="modal" data-target="#download_individual_user">
            <Tooltip title="Download">
              <CloudDownloadIcon sx={{ cursor: "pointer", color: "#9bbcd1" }} />
            </Tooltip>
          </Button>
        ) : handle_disable_radio_btn() === false ? (
          <Button data-toggle="modal" data-target="#download_individual_user">
            <Badge
              color="secondary"
              badgeContent="premium"
              sx={{ textTransform: "lowercase" }}
            >
              <Tooltip title="Download">
                <CloudDownloadIcon
                  sx={{ cursor: "pointer", color: "#9bbcd1" }}
                />
              </Tooltip>
            </Badge>
          </Button>
        ) : (
          <Button>
            <Tooltip title="Download">
              <Badge
                color="secondary"
                badgeContent="premium"
                sx={{ textTransform: "lowercase" }}
              >
                <CloudDownloadIcon sx={{ color: "#9bbcd1" }} />
              </Badge>
            </Tooltip>
          </Button>
        )
      ) : null}
      {/* Modal pop up for the download options  */}
      <div
        className="modal fade"
        id="download_individual_user"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="download_individual_user"
        aria-hidden="true"
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content dk-mode"
            style={{ top: "51px", width: "650px", marginTop: "40%" }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="userModalLongTitle">
                Download Options
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <ClearIcon sx={{ color: "#fb1616" }} />
              </button>
            </div>
            <div className="modal-body">
              <Stack>
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Max
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={max_download}
                    onChange={handle_max_download}
                  >
                    <FormControlLabel
                      value={post_no}
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label={post_no}
                    />

                    {Number(post_no) !== 100 ? (
                      <FormControlLabel
                        value={100}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="100"
                      />
                    ) : null}
                    {Number(post_no) !== 1000 ? (
                      <FormControlLabel
                        value={1000}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="1000"
                      />
                    ) : null}

                    {Number(post_no) !== 10000 ? (
                      <FormControlLabel
                        value={10000}
                        control={
                          <Radio
                            disabled={handle_disable_radio_btn()}
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label={
                          <Badge color="secondary" badgeContent="premium">
                            <span>10,000</span>
                          </Badge>
                        }
                      />
                    ) : null}
                    {search_table_type === "telegram" ? (
                      <>
                        {Number(post_no) !== 15000 ? (
                          <FormControlLabel
                            value={15000}
                            control={
                              <Radio
                                disabled={handle_disable_radio_btn()}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={
                              <Badge color="secondary" badgeContent="premium">
                                <span>15,000</span>
                              </Badge>
                            }
                          />
                        ) : null}

                        {Number(post_no) !== 30000 ? (
                          <FormControlLabel
                            value={30000}
                            control={
                              <Radio
                                disabled={handle_disable_radio_btn()}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={
                              <Badge color="secondary" badgeContent="premium">
                                <span>30,000</span>
                              </Badge>
                            }
                          />
                        ) : null}

                        {Number(post_no) !== 50000 ? (
                          <FormControlLabel
                            value={50000}
                            control={
                              <Radio
                                disabled={handle_disable_radio_btn()}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={
                              <Badge color="secondary" badgeContent="premium">
                                <span>50,000</span>
                              </Badge>
                            }
                          />
                        ) : null}

                        {Number(post_no) !== 100000 ? (
                          <FormControlLabel
                            value={100000}
                            control={
                              <Radio
                                disabled={handle_disable_radio_btn()}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={
                              <Badge color="secondary" badgeContent="premium">
                                <span>100,000</span>
                              </Badge>
                            }
                          />
                        ) : null}
                      </>
                    ) : null}
                  </RadioGroup>
                </FormControl>

                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Download As
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={download_type}
                    onChange={(e) => {
                      set_download_type(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="csv"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="CSV"
                    />
                    {search_table_type === "telegram" ? (
                      <>
                        <FormControlLabel
                          value="html"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="HTML"
                        />
                        <FormControlLabel
                          value="json"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="JSON"
                        />
                      </>
                    ) : null}
                  </RadioGroup>
                </FormControl>
                <button
                  className="button_global_style"
                  onClick={() =>
                    file_download(file_id, download_type, max_download)
                  }
                  data-dismiss="modal"
                >
                  Download
                </button>
              </Stack>
            </div>
          </div>
        </div>
      </div>

      {/* download file */}
      <div
        className="modal fade"
        id="downloadmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="downloadmodal"
        aria-hidden="true"
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div
          className="modal-dialog modal-confirm"
          style={{ background: "#2b3c46", marginTop: "13rem" }}
        >
          <div className="modal-content dk-mode">
            <div className="modal-header flex-column">
              <h4
                className="modal-title w-100"
                style={{ fontSize: "18px", color: "#359daf" }}
              >
                Please wait for a moment...
              </h4>
            </div>
            <div className="modal-body" style={{ marginTop: "50px" }}>
              <div className="load-wrapper" style={{ marginLeft: "200px" }}>
                <div
                  className="load-circle"
                  style={{ background: "#359daf" }}
                />
                <div
                  className="load-circle"
                  style={{ background: "#359daf" }}
                />
                <div className="load-circle" style={{ background: "#359daf" }}>
                  {" "}
                </div>
                <div className="load-shadow" />
                <div className="load-shadow" />
                <div className="load-shadow" />
                <span style={{ left: "2%", color: "#359daf" }}>
                  Downloading
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
DownloadFileOptions.propTypes = {
  getSearchedData: PropTypes.string,
  file_id: PropTypes.string,
  post_no: PropTypes.string,
  search_table_type: PropTypes.string,
};

export function BreachdownloadOptions({ getSearchedData, file_id }) {
  const token = handle_session_get("Token");
  const file_download = (filename, file_type) => {
    model_popup();
    axios
      .post(
        "https://api.recordedtelegram.com/v2/breach_csv_report",
        { file_hash: filename, file_type },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        const new_file_name = `${getSearchedData}_${new Date()}.${file_type}`;
        fileDownload(res.data, new_file_name);
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
      })
      .catch((err) => {
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
        const is_internal_server_err = handle_internal_server_error(
          err.response.status,
        );
        if (is_internal_server_err === true) return;
        const token_expire_status = handle_token_expire(
          err.response.data,
          err.response.status,
        );
        if (token_expire_status === true) return;
        let errorMessage = "";
        const reader = new FileReader();
        reader.onload = () => {
          errorMessage = reader.result;
          handle_error_message(JSON.parse(errorMessage).errormsg);
        };
        reader.readAsText(err.response.data);
      });
  };
  const model_popup = () => {
    document.getElementById("downloadmodal").className = "modal fade show";
    document.getElementById("downloadmodal").style.display = "block";
  };

  // Download menu function
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {file_id !== "None" ? (
        <div>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Tooltip title="Download">
              <CloudDownloadIcon sx={{ cursor: "pointer", color: "#9bbcd1" }} />
            </Tooltip>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                file_download(file_id, "html");
                handleClose();
              }}
            >
              Download as HTML
            </MenuItem>
            <MenuItem
              onClick={() => {
                file_download(file_id, "json");
                handleClose();
              }}
            >
              Download as JSON
            </MenuItem>
            <MenuItem
              onClick={() => {
                file_download(file_id, "csv");
                handleClose();
              }}
            >
              Download as CSV
            </MenuItem>
          </Menu>
        </div>
      ) : null}

      {/* download file Loader*/}
      <div
        className="modal fade"
        id="downloadmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="downloadmodal"
        aria-hidden="true"
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div
          className="modal-dialog modal-confirm"
          style={{ background: "#2b3c46", marginTop: "13rem" }}
        >
          <div className="modal-content dk-mode">
            <div className="modal-header flex-column">
              <h4
                className="modal-title w-100"
                style={{ fontSize: "18px", color: "#359daf" }}
              >
                Please wait for a moment...
              </h4>
            </div>
            <div className="modal-body" style={{ marginTop: "50px" }}>
              <div className="load-wrapper" style={{ marginLeft: "200px" }}>
                <div
                  className="load-circle"
                  style={{ background: "#359daf" }}
                />
                <div
                  className="load-circle"
                  style={{ background: "#359daf" }}
                />
                <div className="load-circle" style={{ background: "#359daf" }}>
                  {" "}
                </div>
                <div className="load-shadow" />
                <div className="load-shadow" />
                <div className="load-shadow" />
                <span style={{ left: "2%", color: "#359daf" }}>
                  Downloading
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
BreachdownloadOptions.propTypes = {
  getSearchedData: PropTypes.string,
  file_id: PropTypes.string,
};

export default DownloadFileOptions;
