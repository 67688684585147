import React, { useState, useRef, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Filters from "../filters/Filters";
import FiltersBreach from "../filters/FiltersBreach";
import BlockIcon from "@mui/icons-material/Block";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import CancelIcon from "@mui/icons-material/Cancel";
import TranslateIcon from "@mui/icons-material/Translate";
import GridViewIcon from "@mui/icons-material/GridView";
import ClearIcon from "@mui/icons-material/Clear";
import HelpIcon from "@mui/icons-material/Help";
import { GlobalStyles, Stack } from "@mui/material";
import Chip from "@mui/material/Chip";
import DialogAlert from "../modalContainer/DialogAlert";
import TranslateApi from "../modalContainer/TranslateApi";
import NestedChannelSearch from "../modalContainer/NestedChannelSearch";
import NestedTagSearch from "../modalContainer/NestedTagSearch";
import StealerLogsMultipleSearch from "../stealerlogs/StealerLogsMultipleSearch";
import { handle_array_logical } from "../../../actions/filterAction";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  bank_card_validator,
  ip_address_validator,
  phone_validator,
  crypto_validator,
  email_validator,
  bin_validator,
} from "../../../utils/Validation/Tag/tag_validator";
import {
  options_post_category,
  handle_check_category_length,
} from "../../../Assets/globalfunctions";
import {
  tag_crypto_auto_search,
  stealer_logs_auto_search,
} from "../../../utils/Globals/global_functions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import for the bulk modal
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import StealerLogsFunction from "../stealerlogs/StealerLogsFunction";
import TagSearchFunction from "../tabledata/TagSearchFunction";
import UserJsonFunction from "../display_json/UserJsonFunction";
import BreachDataFunction from "../breachdata/BreachDataFunction";
import ForumDataFunction from "../forumdata/ForumDataFunction";
import FiltersCard from "../filters/FiltersCard";
import TableDataFunction from "../tabledata/TableDataFunction";
import FiltersStealerLogs from "../filters/FiltersStealerLogs";
import {
  handle_session_get,
  handle_session_set,
} from "../../../utils/SessionStorage/handle_session";
import { get_stealer_location_detains } from "../../../utils/Fetch/fetchApi";
import { RollerAnimation } from "../loader/Loader";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import "../../../css/Search/searchbar.css";

/*
NOTE :
-> This component handles the search bar globally for all the searches comprising Telegram,
Breach, Forum, User, Tag and Stealer logs.
-> All the states needed to handle on parent components
are declared in this component and then passed to required 
child component as props.
*/
const filter_context = createContext();
function Searchbar({ searchTypeTab, stateChanger, setSelectedTab, session }) {
  const dispatch = useDispatch();

  // Alert pop for the darkweb
  const [open_dialog, set_open_dialog] = useState(false);

  const [open_bulk_modal, set_open_bulk_modal] = useState(false);
  const [open_nested_channel_modal, set_open_nested_channel_modal] =
    useState(false);
  const [open_nested_tag_modal, set_open_nested_tag_modal] = useState(false);

  const [regex_autosuggest_data, set_regex_autosuggest_data] = useState(null);
  const [stat_loading_regex, set_stats_loading_regex] = useState(false);
  const [show_auto_regex_dropdown, set_show_auto_regex_dropdown] =
    useState(false);

  // Modal for the Translate Api
  const [open_translate, set_open_translate] = useState(false);
  const [language_array, set_language_array] = useState([]);

  const [showFilters, setShowFilters] = useState(false);

  // telegram state handle by the parent
  const [teleSearch, setTeleSearch] = useState("post");
  const [userFilter, setUserFilter] = useState("all");
  const [postSearch, setPostSearch] = useState("None");
  const [postSearchSub, setPostSearchSub] = useState("Channel/Group");
  const [keyword, setKeyword] = useState("contains");
  const [postCategory, setPostCategory] = useState([]);
  const [multimedia, setMultimedia] = useState("disabled");
  const [dataDisplay, setDataDisplay] = useState("grid");
  const [dataOrder, setDataOrder] = useState("desc");
  const [postNo, setPostNo] = useState(100);
  const [date_from, set_date_from] = useState("None");
  const [date_to, set_date_to] = useState("None");

  const [dateInput, setDateInput] = useState(["None", "None"]);

  const [telegramOption_post, setTelegramOption_post] = useState("None");
  const [telegramOption_user, setTelegramOption_user] = useState("username");
  const [telegramOption_channel, setTelegramOption_channel] =
    useState("conv_name");
  const [user_group, set_user_group] = useState("group_channel_name");
  const [search_value, set_search_value] = useState("");
  const [typed_value, set_typed_value] = useState("");

  const [spamFilter, setSpamFilter] = useState("False");
  const [proximity, set_proximity] = useState(0);

  const new_tele = teleSearch;
  const input_ref = useRef();

  // Filter for the Breach
  const [breach_parameter, set_breach_parameter] = useState("domain");
  const [display_type_breach, set_display_type_breach] = useState("table");
  const [max_result_breach, set_max_result_breach] = useState(5000);
  const [breached_date, set_breached_date] = useState("False");

  // state for the translate switch
  const [checked_exclude, set_checked_exclude] = useState(false);

  // state for the nested_channel_search
  const [channels_list, set_channels_list] = useState([]);
  const [hybrid_channels, set_hybrid_channels] = useState({
    marked_channels: {
      company_preference: [],
      user_preference: [],
    },
    unmarked_channels: [],
  });
  const [nested_channel_regex, set_nested_channel_regex] = useState(false);

  // state for the nested_tag_search
  const [channels_list_tag, set_channels_list_tag] = useState([]);

  // Issue of action POP in filters
  const [re_render, set_re_render] = useState(null);
  const [re_render_table, set_re_render_table] = useState(false);

  // Filters for the card search form the parent
  const [card_filters, set_card_filters] = useState("bank_card");
  const [post_category_tag, set_post_category_tag] = useState([]);
  const [display_type_tag, set_display_type_tag] = useState("table");

  // sub filter for the bank card
  const [bank_card_option, set_bank_card_option] = useState("card_no");

  const exp_year_options = [
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
  ];
  const [exp_year, set_exp_year] = useState(exp_year_options);

  const exp_month_options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [exp_month, set_exp_month] = useState(exp_month_options);

  const tag_source_options = ["telegram", "biden_cash_shop"];
  const [tag_source, set_tag_source] = useState(tag_source_options);

  const crypto_options = ["bitcoin", "ethereum", "monero", "tron", "xrp"];
  const [crypto_provider, set_crypto_provider] = useState(crypto_options);

  const email_options = [
    "gmail",
    "outlook",
    "aol",
    "yahoo",
    "icloud",
    "proton",
    "zoho",
    "yandex",
    "gmx",
  ];
  const [email_provider, set_email_provider] = useState(email_options);

  const [card_date_from, set_card_date_from] = useState("None");
  const [card_date_to, set_card_date_to] = useState("None");

  const card_type_options = [
    "amex_card",
    "bc_global",
    "carte_blanche_card",
    "diners_club_card",
    "insta_payment_card",
    "jcb_card",
    "korean_local_card",
    "laser_card",
    "maestro_card",
    "mastercard",
    "solo_card",
    "switch_card",
    "visa_master_card",
    "union_pay_card",
    "visa_card",
    "interpayment_card",
    "dankort_card",
    "universal_air_travel_plan_card",
  ];

  const [card_type, set_card_type] = useState(card_type_options);
  const [max_tag, set_max_tag] = useState(100);
  const [tag_filter_type, set_tag_filter_type] = useState("default");

  // Filters for the stealer Logs form the parent
  const [stealer_filters, set_stealer_filters] = useState("email");

  // Filter for the Stealer logs , On FiltersStealerLogs.js component
  const [display_type_stealer, set_display_type_stealer] = useState("table");
  const [max_result_stealer, set_max_result_stealer] = useState(100);
  const [search_type_stealer, set_search_type_stealer] = useState("contains");
  const [stealer_date_from, set_stealer_date_from] = useState("None");
  const [stealer_date_to, set_stealer_date_to] = useState("None");
  const [stealer_date_log, set_stealer_date_log] = useState(false);
  const [stealer_auto_fields, set_stealer_auto_fields] = useState(true);
  const [stealer_logs_location, set_stealer_logs_location] = useState({
    map_array: [],
    index_data: {},
  });
  const [post_category_stealer, set_post_category_stealer] = useState("off");
  // Filters for the stealer Logs multiple search modal
  const [stealer_multiple_obj, set_stealer_multiple_obj] = useState({});
  const [stealer_filter_type, set_stealer_filter_type] = useState("default");
  const [
    open_stealer_logs_multiple_search,
    set_open_stealer_logs_multiple_search,
  ] = useState(false);

  useEffect(() => {
    if (stealer_filter_type === "individual") {
      set_open_stealer_logs_multiple_search(true);
    }
  }, [stealer_filter_type]);

  useEffect(() => {
    const session_categories = handle_session_get("enabled_categories");
    if (session_categories !== "all") {
      const array_data = session_categories.split(",");
      setPostCategory(array_data);
      set_post_category_tag(array_data);
    } else {
      setPostCategory(options_post_category);
      set_post_category_tag(options_post_category);
    }
    get_stealer_location_detains(set_stealer_logs_location);
    set_re_render(false);
  }, []);

  useEffect(() => {
    if (postSearch === "bulk") {
      set_open_bulk_modal(true);
    } else if (postSearch === "individual") {
      set_open_nested_channel_modal(true);
    }
  }, [postSearch]);

  useEffect(() => {
    set_typed_value("");
  }, [
    searchTypeTab,
    postSearch,
    card_filters,
    stealer_filter_type,
    tag_filter_type,
  ]);
  useEffect(() => {
    if (tag_filter_type === "individual") {
      set_open_nested_tag_modal(true);
    }
  }, [tag_filter_type]);

  useEffect(() => {
    if (search_value.length > 0) {
      search_sender();
    }
  }, [search_value]);

  const handleSearchFocus = () => {
    setSelectedTab(searchTypeTab);
  };
  const childRef = useRef();
  const forum_ref = useRef();
  const breach_ref = useRef();
  const user_search_ref = useRef();
  const tag_search_ref = useRef();
  const stealer_logs_ref = useRef();

  // Get state of fetched data from redux
  const all_filters_state = useSelector((state) => state.filter_reducer);
  useEffect(() => {
    const session_categories = handle_session_get("enabled_categories");
    const api_status = JSON.parse(localStorage.getItem("fetch_mode_5"));
    const url_product_name = api_status && api_status.chart_name;
    const url_channel_list = api_status && api_status.channel_list;
    if (re_render === false && api_status) {
      if (session_categories) {
        if (all_filters_state.user_searched_data.length === 0) {
          if (postCategory.length > 0) {
            if (url_product_name && url_channel_list) {
              const filters_array = {
                searchedData: url_product_name,
                new_tele: "post",
                date_data: ["None", "None"],
                telegramOption_post: "None",
                telegramOption_user: "username",
                user_group: "group_channel_name",
                userFilter: "all",
                postSearch: "None",
                postSearchSub: "Channel/Group",
                keyword: "contains",
                postCategory,
                multimedia: "disabled",
                postNo: 100,
                dataDisplay: "grid",
                dataOrder: "desc",
                spamFilter: "False",
                proximity: 0,
                language_array: [],
                checked_exclude: false,
                telegramOption_channel: "conv_name",
                channels_list: [url_channel_list],
              };
              handle_session_set(
                "filters_array",
                JSON.stringify(filters_array),
              );
              childRef.current(
                url_product_name,
                new_tele,
                dateInput,
                telegramOption_post,
                telegramOption_user,
                user_group,
                userFilter,
                "individual",
                postSearchSub,
                keyword,
                postCategory,
                multimedia,
                postNo,
                dataDisplay,
                dataOrder,
                spamFilter,
                proximity,
                language_array,
                checked_exclude,
                telegramOption_channel,
                [url_channel_list],
              );
              stateChanger("telegram");
              setSelectedTab("telegram");
              setTimeout(() => localStorage.removeItem("fetch_mode_5"));
            }
          }
        } else return;
      } else return;
    }
  }, [re_render]);

  const search_sender = () => {
    const filters_array = {
      searchedData: search_value.trim(),
      new_tele: teleSearch,
      date_data: dateInput,
      telegramOption_post,
      telegramOption_user,
      user_group,
      userFilter,
      postSearch,
      postSearchSub,
      keyword,
      postCategory,
      multimedia,
      postNo,
      dataDisplay,
      dataOrder,
      spamFilter,
      proximity,
      language_array,
      checked_exclude,
      telegramOption_channel,
      channels_list,
    };
    const filters_array_tag = {
      searchedData:
        tag_crypto_auto_search(
          searchTypeTab,
          post_category_tag,
          card_filters,
          typed_value,
        ) === true
          ? ""
          : search_value.trim(),
      card_filters,
      exp_year,
      exp_month,
      card_type,
      crypto_provider,
      email_provider,
      card_date_from,
      card_date_to,
      max_tag,
      tag_source,
      tag_filter_type,
      channels_list_tag,
      display_type_tag,
      post_category_tag,
    };
    const filters_array_stealer = {
      searchedData:
        stealer_logs_auto_search(
          searchTypeTab,
          post_category_stealer,
          typed_value,
        ) === true
          ? ""
          : search_value.trim(),
      stealer_filters,
      search_type_stealer,
      display_type_stealer,
      max_result_stealer,
      stealer_date_from,
      stealer_date_to,
      stealer_filter_type,
      stealer_multiple_obj,
      stealer_date_log,
      stealer_auto_fields,
      post_category_stealer,
    };
    if (searchTypeTab === "telegram") {
      handle_session_set("filters_array", JSON.stringify(filters_array));
    } else if (searchTypeTab === "card_search") {
      handle_session_set(
        "filters_array_tag",
        JSON.stringify(filters_array_tag),
      );
    } else if (searchTypeTab === "stealer_logs_search") {
      handle_session_set(
        "filters_array_stealer",
        JSON.stringify(filters_array_stealer),
      );
    }
    searchTypeTab === "telegram"
      ? childRef.current(
        search_value,
        new_tele,
        dateInput,
        telegramOption_post,
        telegramOption_user,
        user_group,
        userFilter,
        postSearch,
        postSearchSub,
        keyword,
        postCategory,
        multimedia,
        postNo,
        dataDisplay,
        dataOrder,
        spamFilter,
        proximity,
        language_array,
        checked_exclude,
        telegramOption_channel,
        channels_list,
      )
      : searchTypeTab === "forum"
        ? forum_ref.current(search_value)
        : searchTypeTab === "breach"
          ? breach_ref.current(
            search_value,
            breach_parameter,
            display_type_breach,
            max_result_breach,
            breached_date,
          )
          : searchTypeTab === "user_search"
            ? user_search_ref.current(search_value)
            : searchTypeTab === "card_search"
              ? tag_search_ref.current(
                tag_crypto_auto_search(
                  searchTypeTab,
                  post_category_tag,
                  card_filters,
                  typed_value,
                ) === true
                  ? ""
                  : search_value,
                card_filters,
                exp_year,
                exp_month,
                card_type,
                crypto_provider,
                email_provider,
                card_date_from,
                card_date_to,
                max_tag,
                tag_source,
                tag_filter_type,
                channels_list_tag,
                display_type_tag,
                post_category_tag,
              )
              : stealer_logs_ref.current(
                stealer_logs_auto_search(
                  searchTypeTab,
                  post_category_stealer,
                  typed_value,
                ) === true
                  ? ""
                  : search_value,
                stealer_filters,
                search_type_stealer,
                display_type_stealer,
                max_result_stealer,
                stealer_date_from,
                stealer_date_to,
                stealer_filter_type,
                stealer_multiple_obj,
                stealer_date_log,
                stealer_auto_fields,
                post_category_stealer,
              );
  };

  const filter_json = (value) => {
    try {
      if (value.length > 0) {
        const new_value = value
          .replaceAll("\\n", " OR ")
          .replaceAll("\n", " OR ");
        const text = JSON.parse(new_value);

        return text;
      }
    } catch {
      return "";
    }
  };

  const handleClickSearchbar = () => {
    if (
      (teleSearch === "file" || teleSearch === "post") &&
      postSearch === "bulk" &&
      searchTypeTab === "telegram"
    ) {
      set_open_bulk_modal(true);
    } else if (
      (teleSearch === "file" || teleSearch === "post") &&
      postSearch === "individual" &&
      searchTypeTab === "telegram"
    ) {
      set_open_nested_channel_modal(true);
    } else if (
      searchTypeTab === "card_search" &&
      tag_filter_type === "individual"
    ) {
      set_open_nested_tag_modal(true);
    } else if (
      searchTypeTab === "stealer_logs_search" &&
      stealer_filter_type === "individual"
    ) {
      set_open_stealer_logs_multiple_search(true);
    }
    if (
      (teleSearch === "post" || teleSearch === "file") &&
      postSearch === "regex" &&
      show_auto_regex_dropdown === false
    ) {
      set_show_auto_regex_dropdown(true);
      get_auto_suggest_regex("", teleSearch);
    }
  };

  const handle_change_regex_search = (event) => {
    set_typed_value(event.target.value);
    if (searchTypeTab === "telegram") {
      if (
        (teleSearch === "post" || teleSearch === "file") &&
        (postSearch === "bulk" || postSearch === "individual")
      ) {
        set_typed_value("");
      }
      if (
        (teleSearch === "post" || teleSearch === "file") &&
        postSearch === "regex"
      ) {
        set_show_auto_regex_dropdown(true);
        get_auto_suggest_regex(event.target.value, teleSearch);
      }
    } else if (searchTypeTab === "card_search") {
      if (tag_filter_type === "individual") {
        set_typed_value("");
      }
    } else if (searchTypeTab === "stealer_logs_search") {
      if (stealer_filter_type === "individual") {
        set_typed_value("");
      }
    }
  };

  // Auto suggestion regex
  const get_auto_suggest_regex = (query, filter) => {
    set_stats_loading_regex(true);
    const token = handle_session_get("Token");
    const data = {
      regex_query: query.length === 0 ? [] : query,
      regex_filter: filter,
    };
    axios
      .post("https://api.recordedtelegram.com/regex_auto", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        set_regex_autosuggest_data(res.data);
        set_stats_loading_regex(false);
      })

      .catch((error) => {
        const token_expire_status = handle_token_expire(error.response.data);
        if (token_expire_status === true) return;
        set_stats_loading_regex(false);
      });
  };

  // Search regex from the regex auto suggestion
  const search_regex = (regex_query) => {
    if (postSearch === "regex") {
      const conv_data = alert_validator(regex_query);
      const remove_bracket_open = conv_data.replaceAll("(", "");
      const remove_bracket_close = remove_bracket_open.replaceAll(")", "");
      const remove_quatation = remove_bracket_close
        .replaceAll('"', "")
        .replaceAll("'", "");
      logical_checker(
        remove_quatation.split(/\s\b(and|or|not|AND|OR|NOT)\b\s/i),
      );
    }
    set_typed_value(regex_query);
    set_search_value(regex_query);
    set_show_auto_regex_dropdown(false);
  };

  const handle_delete_tag = (clicked) => {
    const filter_channels = channels_list_tag.filter(
      (item) => item !== clicked,
    );
    set_channels_list_tag(filter_channels);
  };
  const channels_list_chips = channels_list.map((item, index) => (
    <Chip label={item} color="success" size="small" key={index} />
  ));
  const channels_list_chips_tag = channels_list_tag.map((item, index) => (
    <Chip
      label={item}
      onDelete={() => handle_delete_tag(item)}
      color="success"
      size="small"
      key={index}
    />
  ));

  // Create ArrayLogical function
  const alert_validator = (txt) => {
    const new_sub = /\s{2,}/g;
    const double_space_remover = txt.replaceAll(new_sub, " ");
    const open_braket_space_remover = double_space_remover.replace(
      /\([^\w+]/g,
      "(",
    );
    const close_braket_space_remover = open_braket_space_remover.replace(
      /[^\w+]\)/g,
      ")",
    );
    return close_braket_space_remover;
  };

  const logical_checker = (arr) => {
    const new_arr = arr
      .filter(
        (item) =>
          item.toLowerCase() !== "or" &&
          item.toLowerCase() !== "and" &&
          item.toLowerCase() !== "not",
      )
      .map((value) => value.trim());
    dispatch(handle_array_logical(new_arr));
    return true;
  };

  const Filters_obj_main = {
    telegram_filters: {
      showFilters,
      setShowFilters,
      teleSearch,
      setTeleSearch,
      telegramOption_user,
      setTelegramOption_user,
      user_group,
      set_user_group,
      telegramOption_post,
      setTelegramOption_post,
      telegramOption_channel,
      setTelegramOption_channel,
      userFilter,
      setUserFilter,
      postSearch,
      setPostSearch,
      postSearchSub,
      setPostSearchSub,
      keyword,
      setKeyword,
      postCategory,
      setPostCategory,
      multimedia,
      setMultimedia,
      dateInput,
      setDateInput,
      date_from,
      set_date_from,
      date_to,
      set_date_to,
      postNo,
      setPostNo,
      dataDisplay,
      setDataDisplay,
      dataOrder,
      setDataOrder,
      spamFilter,
      setSpamFilter,
      proximity,
      set_proximity,
      re_render,
      set_re_render,
      channels_list,
      set_channels_list,
      hybrid_channels,
      set_hybrid_channels,
      set_typed_value,
      nested_channel_regex,
      set_nested_channel_regex,
      search_sender,
      search_value,
      set_search_value,
    },
    breach_filters: {
      setShowFilters,
      display_type_breach,
      set_display_type_breach,
      max_result_breach,
      set_max_result_breach,
      breached_date,
      set_breached_date,
    },
    tag_filters: {
      setShowFilters,
      post_category_tag,
      set_post_category_tag,
      card_filters,
      set_card_filters,
      bank_card_option,
      set_bank_card_option,
      exp_year,
      set_exp_year,
      exp_year_options,
      exp_month,
      set_exp_month,
      exp_month_options,
      card_type,
      set_card_type,
      card_type_options,
      tag_source,
      set_tag_source,
      tag_filter_type,
      set_tag_filter_type,
      tag_source_options,
      crypto_provider,
      set_crypto_provider,
      crypto_options,
      email_provider,
      set_email_provider,
      email_options,
      card_date_from,
      set_card_date_from,
      card_date_to,
      set_card_date_to,
      display_type_tag,
      set_display_type_tag,
      max_tag,
      set_max_tag,
      channels_list: channels_list_tag,
      set_channels_list: set_channels_list_tag,
      set_typed_value,
      search_value,
      search_sender,
      set_search_value,
    },
    stealer_filters: {
      showFilters,
      setShowFilters,
      display_type_stealer,
      set_display_type_stealer,
      max_result_stealer,
      set_max_result_stealer,
      search_type_stealer,
      set_search_type_stealer,
      stealer_date_from,
      set_stealer_date_from,
      stealer_date_to,
      set_stealer_date_to,
      stealer_filter_type,
      set_stealer_filter_type,
      stealer_date_log,
      set_stealer_date_log,
      stealer_auto_fields,
      set_stealer_auto_fields,
      stealer_multiple_obj,
      set_stealer_multiple_obj,
      search_sender,
      stealer_logs_location,
      stealer_filters,
      set_stealer_filters,
      search_value,
      set_search_value,
      set_typed_value,
      post_category_stealer,
      set_post_category_stealer,
      is_auto_search: stealer_logs_auto_search(
        searchTypeTab,
        post_category_stealer,
        typed_value,
      ),
    },
  };

  return (
    <filter_context.Provider value={Filters_obj_main}>
      <GlobalStyles
        styles={{
          ".MuiDataGrid-menuList": {
            backgroundColor: "#394e5a",
          },
          ".MuiMenu-list ": {
            backgroundColor: "#394e5a",
          },
          ".MuiDataGrid-menuList li": {
            color: "#dcf3ff",
          },
          ".MuiMenu-list li": {
            color: "#dcf3ff",
          },
          ".MuiPaginationItem-root": {
            color: "#9bbcd1",
            backgroundColor: "#1e2a31",
          },
        }}
      />

      <div className="searchbar__main">
        <Formik
          initialValues={{ input_search: "" }}
          validate={(values) => {
            const errors = {};
            if (
              tag_crypto_auto_search(
                searchTypeTab,
                post_category_tag,
                card_filters,
                typed_value,
              ) === true ||
              stealer_logs_auto_search(
                searchTypeTab,
                post_category_stealer,
                typed_value,
              ) === true
            ) {
              return;
            } else if (postSearch === "regex") {
              if (typed_value.length === 0) {
                errors.input_search = "";
              }
            } else if (
              searchTypeTab === "stealer_logs_search" &&
              stealer_filters === "country_code" &&
              typed_value.length > 0
            ) {
              return;
            } else if (!values.input_search || typed_value.length === 0) {
              errors.input_search = "";
            }

            const isRegExp = (string) => {
              try {
                new RegExp(string);
              } catch (e) {
                return false;
              }
              return true;
            };
            if (postSearch === "regex") {
              if (isRegExp(typed_value) === false) {
                errors.input_search = "Please enter valid Regular expression";
              }
            }

            if (searchTypeTab === "user_search") {
              const usernameRegex = /^[a-zA-Z0-9.@_-]+$/;
              if (values.input_search.length < 3) {
                errors.input_search = "Username must have atleast 3 characters";
              } else if (usernameRegex.test(values.input_search) === false) {
                errors.input_search =
                  "Special characters are not allowed except @ _ . - and space should not be present";
              }
            }

            if (searchTypeTab === "telegram") {
              if (
                teleSearch === "channel" &&
                telegramOption_channel === "link" &&
                postSearch === "None"
              ) {
                if (
                  values.input_search.trim().startsWith("https://t.me/") ===
                  false
                ) {
                  errors.input_search =
                    "Please follow the format 'https://t.me/...' while searching profile through link";
                }
              } else if (
                (teleSearch === "post" || teleSearch === "file") &&
                postSearch === "logical" &&
                language_array.length > 0
              ) {
                const conv_data = alert_validator(values.input_search);
                const remove_bracket_open = conv_data.replaceAll("(", "");
                const remove_bracket_close = remove_bracket_open.replaceAll(
                  ")",
                  "",
                );
                const remove_quatation = remove_bracket_close
                  .replaceAll('"', "")
                  .replaceAll("'", "");
                const new_arr = remove_quatation
                  .split(/\s\b(and|or|not|AND|OR|NOT)\b\s/)
                  .filter((item) => {
                    return (
                      item.toLowerCase() !== "or" &&
                      item.toLowerCase() !== "and" &&
                      item.toLowerCase() !== "not"
                    );
                  });
                if (new_arr.length > 10) {
                  errors.input_search =
                    "You can enter up to 10 keywords when enabling translation on logical search";
                }
              }
            }
            if (searchTypeTab === "card_search") {
              if (card_filters === "bank_card") {
                const error_msg = bank_card_validator(values.input_search);
                if (error_msg) {
                  errors.input_search = error_msg;
                }
              } else if (card_filters === "ip_address") {
                const error_msg = ip_address_validator(values.input_search);
                if (error_msg) {
                  errors.input_search = error_msg;
                }
              } else if (card_filters === "phone") {
                const error_msg = phone_validator(values.input_search);
                if (error_msg) {
                  errors.input_search = error_msg;
                }
              } else if (card_filters === "crypto") {
                const error_msg = crypto_validator(values.input_search);
                if (error_msg) {
                  errors.input_search = error_msg;
                }
              } else if (card_filters === "email") {
                const error_msg = email_validator(values.input_search);
                if (error_msg) {
                  errors.input_search = error_msg;
                }
              } else if (card_filters === "bin") {
                const error_msg = bin_validator(values.input_search);
                if (error_msg) {
                  errors.input_search = error_msg;
                }
              }
            }
            if (searchTypeTab === "stealer_logs_search") {
              if (stealer_filters === "ip_address" && search_type_stealer !=="regex") {
                const error_msg = ip_address_validator(values.input_search);
                if (error_msg) {
                  errors.input_search = error_msg;
                }
              } 
            }

            return errors;
          }}
          onSubmit={(values) => {
            session();
            // Set all filters to default value when breach and forum is searched
            if (
              searchTypeTab === "breach" ||
              searchTypeTab === "forum" ||
              searchTypeTab === "user_search"
            ) {
              sessionStorage.removeItem("filters_array");
              sessionStorage.removeItem("Data");
              setTeleSearch("post");
              setUserFilter("all");
              setPostSearch("None");
              setPostSearchSub("Channel/Group");
              setKeyword("contains");
              setMultimedia("disabled");
              setDataDisplay("grid");
              setDataOrder("desc");
              setPostNo(100);
              setDateInput(["None", "None"]);
              setTelegramOption_post("None");
              setTelegramOption_user("username");
              set_user_group("group_channel_name");
              const session_categories =
                handle_session_get("enabled_categories");
              if (session_categories !== "all") {
                const array_data = session_categories.split(",");
                setPostCategory(array_data);
              } else {
                setPostCategory(options_post_category);
              }
            }
            if (postSearch === "logical" || postSearch === "regex") {
              const conv_data = alert_validator(values.input_search);
              const remove_bracket_open = conv_data.replaceAll("(", "");
              const remove_bracket_close = remove_bracket_open.replaceAll(
                ")",
                "",
              );
              const remove_quatation = remove_bracket_close
                .replaceAll('"', "")
                .replaceAll("'", "");
              logical_checker(
                remove_quatation.split(/\s\b(and|or|not|AND|OR|NOT)\b\s/i),
              );
            }
            if (
              tag_crypto_auto_search(
                searchTypeTab,
                post_category_tag,
                card_filters,
                typed_value,
              ) === true ||
              stealer_logs_auto_search(
                searchTypeTab,
                post_category_stealer,
                typed_value,
              ) === true
            ) {
              search_sender();
              return;
            }
            if (
              (teleSearch === "post" || teleSearch === "file") &&
              postSearch === "regex"
            ) {
              set_show_auto_regex_dropdown(false);
              if (search_value === typed_value.trim()) {
                search_sender();
              } else {
                set_search_value(typed_value.trim());
              }
              return;
            }
            if (
              searchTypeTab === "stealer_logs_search" &&
              stealer_filter_type === "default" &&
              stealer_filters === "country_code" &&
              typed_value.length > 0
            ) {
              if (search_value === typed_value.trim()) {
                search_sender();
              } else {
                set_search_value(values.input_search.trim());
              }
              return;
            }

            if (values.input_search.length > 1) {
              if (search_value === values.input_search.trim()) {
                search_sender();
              } else {
                set_search_value(values.input_search.trim());
              }
            }
          }}
        >
          {({ errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="main_container_searchbar">
                <div
                  className="input_dropdown_main"
                  id="input_dropdown_main_id"
                >
                  {searchTypeTab === "card_search" ? (
                    <div className="dropdown_searchbar_card">
                      <FormControl
                        sx={{
                          minWidth: 120,
                          borderRight: "1px solid rgba(231, 228, 228, 0.39)",
                          mt: 0.5,
                        }}
                        size="small"
                      >
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={card_filters}
                          onChange={(e) => {
                            set_card_filters(e.target.value);
                            if (
                              e.target.value !== "bank_card" &&
                              e.target.value !== "bin"
                            ) {
                              set_tag_source(["telegram"]);
                            }
                          }}
                          sx={{ width: "100%", mt: 1 }}
                          disableUnderline
                          variant="standard"
                        >
                          <MenuItem value="bank_card">Bank card</MenuItem>
                          <MenuItem value="email">Email</MenuItem>
                          <MenuItem value="ip_address">Ip address</MenuItem>
                          <MenuItem value="crypto">Crypto</MenuItem>
                          <MenuItem value="domain">Domain</MenuItem>
                          <MenuItem value="phone">Phone</MenuItem>
                          <MenuItem value="bin">Bin</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  ) : searchTypeTab === "stealer_logs_search" ? (
                    <div className="dropdown_searchbar_card">
                      <FormControl
                        sx={{
                          minWidth: 150,
                          borderRight: "1px solid rgba(231, 228, 228, 0.39)",
                          mt: 0.5,
                        }}
                        size="small"
                      >
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={stealer_filters}
                          onChange={(e) => {
                            set_stealer_filters(e.target.value);
                          }}
                          sx={{ width: "100%", mt: 1 }}
                          disableUnderline
                          variant="standard"
                        >
                          <MenuItem value="email">Username/Email</MenuItem>
                          <MenuItem value="domain">Domain</MenuItem>
                          <MenuItem value="password">Password</MenuItem>
                          <MenuItem value="country_code">Country Code</MenuItem>
                          <MenuItem value="ip_address">IP</MenuItem>
                          <MenuItem value="zip">Zip code</MenuItem>
                          <MenuItem value="location">Location</MenuItem>
                          <MenuItem value="device_id">MID/HWID/GUID</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  ) : searchTypeTab === "breach" ? (
                    <div className="dropdown_searchbar_card">
                      <FormControl
                        sx={{
                          minWidth: 120,
                          borderRight: "1px solid rgba(231, 228, 228, 0.39)",
                          mt: 0.5,
                        }}
                        size="small"
                      >
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={breach_parameter}
                          onChange={(e) => {
                            set_breach_parameter(e.target.value);
                          }}
                          sx={{ width: "100%", mt: 1 }}
                          disableUnderline
                          variant="standard"
                        >
                          <MenuItem value="domain">Domain</MenuItem>
                          <MenuItem value="phone">Phone</MenuItem>
                          <MenuItem value="name">Name</MenuItem>
                          <MenuItem value="email">Email</MenuItem>
                          <MenuItem value="username">Username</MenuItem>
                          <MenuItem value="ip">IP Address</MenuItem>
                          <MenuItem value="password">Password</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  ) : null}
                  <div className="searchbar" id="searchbar">
                    <Stack direction="row" sx={{ width: "100%" }}>
                      <input
                        id="search_bar"
                        ref={input_ref}
                        type="text"
                        name="input_search"
                        autoComplete="off"
                        className={
                          tag_crypto_auto_search(
                            searchTypeTab,
                            post_category_tag,
                            card_filters,
                            typed_value,
                          ) === true
                            ? "searchbar__input searchbar__input_crypto_placeholder"
                            : "searchbar__input"
                        }
                        onFocus={handleSearchFocus}
                        onChange={(event) => {
                          handleChange(event);
                          handle_change_regex_search(event);
                        }}
                        onClick={() => handleClickSearchbar()}
                        onBlur={handleBlur}
                        value={typed_value}
                        placeholder={
                          searchTypeTab === "telegram" &&
                            teleSearch === "post" &&
                            postSearch === "logical"
                            ? "e.g: test1 and test2 or (test3 and test4)"
                            : searchTypeTab === "telegram" &&
                              handle_check_category_length(postCategory) ===
                              true &&
                              teleSearch !== "user"
                              ? `You are searching Telegram ${teleSearch} in all category `
                              : searchTypeTab === "telegram" &&
                                teleSearch === "user"
                                ? "You are searching Telegram User"
                                : searchTypeTab === "telegram" &&
                                  handle_check_category_length(postCategory) ===
                                  false &&
                                  teleSearch !== "user"
                                  ? `You are searching Telegram ${teleSearch} in  category ${postCategory} `
                                  : searchTypeTab === "user_search"
                                    ? "Enter username"
                                    : searchTypeTab === "forum"
                                      ? "You are searching forum"
                                      : searchTypeTab === "card_search" &&
                                        tag_crypto_auto_search(
                                          searchTypeTab,
                                          post_category_tag,
                                          card_filters,
                                          typed_value,
                                        ) === true
                                        ? "Type your query or press enter for all wallets"
                                        : searchTypeTab === "card_search" &&
                                          tag_crypto_auto_search(
                                            searchTypeTab,
                                            post_category_tag,
                                            card_filters,
                                            typed_value,
                                          ) === false
                                          ? "You are searching tag"
                                          : searchTypeTab === "stealer_logs_search" &&
                                            stealer_logs_auto_search(
                                              searchTypeTab,
                                              post_category_stealer,
                                              typed_value,
                                            ) === true
                                            ? "Type your query or press enter for all stealers"
                                            : searchTypeTab === "stealer_logs_search" &&
                                              stealer_logs_auto_search(
                                                searchTypeTab,
                                                post_category_stealer,
                                                typed_value,
                                              ) === false
                                              ? "Search ..."
                                              : searchTypeTab !== "breach_2"
                                                ? breach_parameter === "domain"
                                                  ? "e.g. hackforum.net"
                                                  : breach_parameter === "phone"
                                                    ? "e.g. 9843789087"
                                                    : breach_parameter === "name"
                                                      ? "e.g. Adrian shah"
                                                      : breach_parameter === "email"
                                                        ? "e.g. test09@gmail.com"
                                                        : breach_parameter === "username"
                                                          ? "e.g. adrian79 "
                                                          : breach_parameter === "ip"
                                                            ? "e.g. 192.168.123.34"
                                                            : "e.g. password123"
                                                : "You are searching dark web"
                        }
                      />
                      <input
                        type="submit"
                        value=""
                        style={{ display: "none" }}
                      />
                    </Stack>
                    <div className="searchbar-btngroup globalheight">
                      {teleSearch === "post" && postSearch === "logical" ? (
                        <span data-toggle="modal" data-target="#help_logical">
                          <Tooltip title="Logical Input Guidance">
                            <HelpIcon
                              sx={{
                                color: "rgb(56 211 175)",
                                m: 0.5,
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                        </span>
                      ) : null}
                      {searchTypeTab === "telegram" &&
                        (teleSearch === "post" || teleSearch === "file") &&
                        (postSearch === "None" || postSearch === "logical") ? (
                        <Tooltip title="choose language">
                          <TranslateIcon
                            onClick={() => set_open_translate(true)}
                            sx={{
                              color: "rgb(56 211 175)",
                              mr: 0.5,
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      ) : null}
                    </div>
                  </div>
                </div>
                {(searchTypeTab === "telegram" ||
                  searchTypeTab === "breach" ||
                  searchTypeTab === "card_search" ||
                  searchTypeTab === "stealer_logs_search") && (
                    <button
                      type="button"
                      onClick={() => {
                        setShowFilters(!showFilters);
                      }}
                      className="searchbar-btn searchbar-btn-main"
                    >
                      {" "}
                      <Tooltip title="Filters">
                        <GridViewIcon sx={{ color: "#dcf3ff" }} />
                      </Tooltip>{" "}
                    </button>
                  )}

                {(searchTypeTab === "telegram" ||
                  searchTypeTab === "breach" ||
                  searchTypeTab === "card_search" ||
                  searchTypeTab === "stealer_logs_search") && (
                    <div>
                      <Link to="/notification" style={{ width: "0px" }}>
                        <Tooltip title="Create Alerts">
                          <AddAlertIcon sx={{ color: "#dcf3ff" }} />
                        </Tooltip>
                      </Link>
                    </div>
                  )}

                {searchTypeTab === "telegram" && (
                  <div>
                    <Link to="/block-channel" style={{ width: "0px" }}>
                      <Tooltip title="Block channel/group">
                        <BlockIcon sx={{ color: "#dcf3ff" }} />
                      </Tooltip>
                    </Link>
                  </div>
                )}

                {searchTypeTab === "telegram" && (
                  <div>
                    <Link to="/channel-preference" style={{ width: "0px" }}>
                      <Tooltip title="Custom preference">
                        <DisplaySettingsIcon sx={{ color: "#dcf3ff" }} />
                      </Tooltip>
                    </Link>
                  </div>
                )}
              </div>

              {showFilters === false &&
                errors.input_search &&
                touched.input_search &&
                (postSearch === "logical" ||
                  postSearch === "regex" ||
                  postSearch === "None") &&
                searchTypeTab === "telegram" &&
                typed_value.length > 0 ? (
                <div className="user_search_validation">
                  {errors.input_search}
                </div>
              ) : null}
              {errors.input_search &&
                touched.input_search &&
                searchTypeTab === "user_search" &&
                typed_value.length > 0 ? (
                <div className="user_search_validation">
                  {errors.input_search}
                </div>
              ) : null}
              {showFilters === false &&
                errors.input_search &&
                touched.input_search &&
                searchTypeTab === "card_search" &&
                typed_value.length > 0 ? (
                <div className="user_search_validation">
                  {errors.input_search}
                </div>
              ) : null}
              {showFilters === false &&
                errors.input_search &&
                touched.input_search &&
                searchTypeTab === "stealer_logs_search" &&
                typed_value.length > 0 ? (
                <div className="user_search_validation">
                  {errors.input_search}
                </div>
              ) : null}

              {showFilters === false &&
                !errors.input_search &&
                searchTypeTab === "card_search" ? (
                <div className="user_search_validation">
                  Please enter query inside / / to search regex
                </div>
              ) : null}
              {searchTypeTab === "telegram" &&
                channels_list.length > 0 &&
                showFilters === false &&
                (teleSearch === "post" || teleSearch === "file") &&
                show_auto_regex_dropdown === false &&
                postSearch === "individual" ? (
                <div style={{ marginTop: "1rem" }}>{channels_list_chips}</div>
              ) : null}
              {searchTypeTab === "card_search" &&
                channels_list_tag.length > 0 &&
                showFilters === false &&
                tag_filter_type === "individual" &&
                show_auto_regex_dropdown === false ? (
                <div style={{ marginTop: "1rem" }}>
                  {channels_list_chips_tag}
                </div>
              ) : null}

              {/* Auto suggestion for regex */}
              {searchTypeTab === "telegram" &&
                postSearch === "regex" &&
                showFilters === false &&
                (teleSearch === "post" || teleSearch === "file") &&
                show_auto_regex_dropdown === true &&
                regex_autosuggest_data !== null &&
                regex_autosuggest_data.length > 0 ? (
                <ClickAwayListener
                  onClickAway={() => set_show_auto_regex_dropdown(false)}
                >
                  <div
                    className="autocom-box regex_auto_suggestion_box mt-2"
                    style={{ marginLeft: "10%", position: "absolute" }}
                  >
                    {stat_loading_regex ? (
                      <RollerAnimation />
                    ) : (
                      <div>
                        <Stack direction="row" justifyContent="space-between">
                          <h4
                            style={{
                              paddingLeft: "1rem",
                              marginLeft: "15px",
                              marginTop: "5px",
                              fontSize: "18px",
                              color: "whitesmoke",
                            }}
                          >
                            List Of Query
                          </h4>
                          <CancelIcon
                            fontSize="small"
                            onClick={() => set_show_auto_regex_dropdown(false)}
                            sx={{ color: "red", cursor: "pointer" }}
                          />
                        </Stack>

                        <ul>
                          {regex_autosuggest_data !== null &&
                            regex_autosuggest_data.map((val, id) => (
                              <li key={id}>
                                <div
                                  className="row"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => search_regex(val.regex_query)}
                                >
                                  <div
                                    className="col-md-8"
                                    style={{ textAlign: "left" }}
                                  >
                                    <span
                                      className="channels_list_map"
                                      style={{
                                        marginLeft: "10px",
                                        color: "#dcf3ff",
                                      }}
                                    >
                                      {val.regex_query}
                                    </span>
                                  </div>
                                  <div
                                    className="col-md-4"
                                    style={{
                                      textAlign: "right",
                                      marginTop: "5px",
                                      color: "#C0C0C0",
                                    }}
                                  >
                                    {val.regex_name}
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </ClickAwayListener>
              ) : null}
            </form>
          )}
        </Formik>
        <div className="display__table">
          {searchTypeTab === "telegram" ? (
            <TableDataFunction
              childRef={childRef}
              re_render_table={re_render_table}
              set_re_render_table={set_re_render_table}
            />
          ) : searchTypeTab === "forum" ? (
            <ForumDataFunction forum_ref={forum_ref} />
          ) : searchTypeTab === "breach" ? (
            <BreachDataFunction breach_ref={breach_ref} />
          ) : searchTypeTab === "user_search" ? (
            <UserJsonFunction user_search_ref={user_search_ref} />
          ) : searchTypeTab === "card_search" ? (
            <TagSearchFunction tag_search_ref={tag_search_ref} />
          ) : (
            <StealerLogsFunction stealer_logs_ref={stealer_logs_ref} />
          )}
        </div>
      </div>

      {showFilters ? (
        searchTypeTab === "telegram" ? (
          <Filters />
        ) : searchTypeTab === "breach" ? (
          <FiltersBreach />
        ) : searchTypeTab === "card_search" ? (
          <FiltersCard />
        ) : searchTypeTab === "stealer_logs_search" ? (
          <FiltersStealerLogs />
        ) : null
      ) : null}

      <DialogAlert
        open_dialog={open_dialog}
        set_open_dialog={set_open_dialog}
      />

      {/* modal for the bulk search */}
      {open_bulk_modal === true && (
        <Modal
          open={open_bulk_modal}
          onClose={() => set_open_bulk_modal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="bulk_search_modal">
            <Stack alignItems="flex-end">
              <CancelIcon
                onClick={() => set_open_bulk_modal(false)}
                fontSize="small"
                sx={{ color: "red ", cursor: "pointer", m: 0.5 }}
              />
            </Stack>
            <h6>Search in bulk</h6>
            <Formik
              initialValues={{ bulk_search: "" }}
              validate={(values) => {
                const errors = {};

                if (values.bulk_search.length === 0) {
                  errors.bulk_search = "";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  // alert(JSON.stringify(values, null, 2));
                  const current_value = filter_json(
                    JSON.stringify(values.bulk_search.trim(), null, 2),
                  );
                  set_typed_value(current_value);
                  setSubmitting(false);
                  set_open_bulk_modal(false);
                  if (values.bulk_search.trim() === search_value) {
                    search_sender();
                  } else {
                    set_search_value(values.bulk_search.trim());
                  }
                }, 400);
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <textarea
                    name="bulk_search"
                    className="bulk_search_textarea"
                    rows="10"
                    cols="20"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bulk_search}
                    placeholder="example:&#10;searchterm1&#10;searchterm2&#10;searchterm3"
                  />
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="button_global_style"
                  >
                    Search
                  </button>
                </form>
              )}
            </Formik>
          </Box>
        </Modal>
      )}

      {/* Modal for the helpline of the LOGICAL in notification */}

      <div
        className="modal fade"
        id="help_logical"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="help_logical"
        aria-hidden="true"
        style={{ background: "rgba(29, 40, 53, .95)" }}
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content dk-mode"
            style={{ top: "51px", width: "580px" }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="userModalLongTitle">
                Guide for logical query
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <ClearIcon sx={{ color: "#fb1616" }} />
              </button>
            </div>
            <div className="modal-body">
              <ul className="logical_guidance">
                <li>
                  Logical keyword AND/OR should be between search queries.
                </li>
                <li>Multiple conditional brackets are allowed</li>
                <li>AND / OR operator are only allowed on Post search </li>
              </ul>
              <div>
                Example :{" "}
                <span className="logical_guide_example">
                  test1 and test2 or (test3 and test4)
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {open_translate === true ? (
        <TranslateApi
          open_translate={open_translate}
          set_open_translate={set_open_translate}
          language_array={language_array}
          set_language_array={set_language_array}
          checked_exclude={checked_exclude}
          set_checked_exclude={set_checked_exclude}
          teleSearch={teleSearch}
          postSearch={postSearch}
        />
      ) : null}
      {open_nested_channel_modal === true ? (
        <NestedChannelSearch
          open_nested_channel_modal={open_nested_channel_modal}
          set_open_nested_channel_modal={set_open_nested_channel_modal}
        />
      ) : null}
      {open_nested_tag_modal === true ? (
        <NestedTagSearch
          open_nested_tag_modal={open_nested_tag_modal}
          set_open_nested_tag_modal={set_open_nested_tag_modal}
        />
      ) : null}
      {open_stealer_logs_multiple_search === true ? (
        <StealerLogsMultipleSearch
          open_modal={open_stealer_logs_multiple_search}
          set_open_modal={set_open_stealer_logs_multiple_search}
        />
      ) : null}
      <ToastContainer />
    </filter_context.Provider>
  );
}
Searchbar.propTypes = {
  searchTypeTab: PropTypes.string,
  stateChanger: PropTypes.func,
  setSelectedTab: PropTypes.func,
  session: PropTypes.func,
};

export default Searchbar;
export { filter_context };
