import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import UserSearchTable from "../tabledata/UserSearchTable";
import "react-toastify/dist/ReactToastify.css";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";

function UserJsonFunction({ user_search_ref }) {
  const [user_search_data, set_user_search_data] = useState([]);
  const [error_status, set_error_status] = useState(false);
  const [user_search_error, set_user_search_error] = useState();
  const [show_table, set_show_table] = useState(false);
  const [getSearchedData, setGetSearchedData] = useState();

  // Paginaton Value set to 1 when form submitted
  const [paginationValue, setPaginationValue] = useState(1);

  useEffect(() => {
    user_search_ref.current = get_user_data;
  }, []);

  let isSubmitBlocked = false;

  const get_user_data = (searchedData) => {
    setPaginationValue(1);
    document.getElementById("downloadmodal").className = "modal fade show";
    document.getElementById("downloadmodal").style.display = "block";
    setGetSearchedData(searchedData);
    set_show_table(false);
    const token = localStorage.getItem("Token");
    const data = { username: searchedData };
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .post("https://api.recordedtelegram.com/get_username_scanner", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const all_data = res.data.data;
          all_data.sort((a, b) =>
            a.status === "Available" && b.status === "Unavailable"
              ? -1
              : b.status === "Available" && a.status === "Unavailable"
              ? 1
              : 0,
          );
          set_show_table(true);
          set_user_search_data(all_data);
          set_error_status(false);
          document.getElementById("downloadmodal").className = "modal fade";
          document.getElementById("downloadmodal").style.display = "none";
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_error_status(true);
          set_show_table(true);
          set_user_search_error(err.response.data.errormsg);
          document.getElementById("downloadmodal").className = "modal fade";
          document.getElementById("downloadmodal").style.display = "none";
          isSubmitBlocked = false;
        });
    }
  };
  return (
    <>
      <div>
        {/* Lodading modal animation */}
        <div
          className="modal fade"
          id="downloadmodal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="downloadmodal"
          aria-hidden="true"
          style={{ background: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-confirm"
            style={{ background: "#2b3c46", marginTop: "13rem" }}
          >
            <div className="modal-content dk-mode">
              <div className="modal-header flex-column">
                <h4
                  className="modal-title w-100"
                  id="model_title"
                  style={{ fontSize: "18px", color: "#359daf" }}
                >
                  Please wait
                </h4>
              </div>
              <div className="modal-body" style={{ marginTop: "50px" }}>
                <div className="load-wrapper" style={{ marginLeft: "200px" }}>
                  <div
                    className="load-circle"
                    style={{ background: "#359daf" }}
                  />
                  <div
                    className="load-circle"
                    style={{ background: "#359daf" }}
                  />
                  <div
                    className="load-circle"
                    style={{ background: "#359daf" }}
                  >
                    {" "}
                  </div>
                  <div className="load-shadow" />
                  <div className="load-shadow" />
                  <div className="load-shadow" />
                  <span style={{ left: "2%", color: "#359daf" }}>
                    Searching...
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {show_table === true ? (
          error_status === true ? (
            <div className="main__table no_data">{user_search_error}</div>
          ) : user_search_data.includes(
              "No results. Please try again after some time.",
            ) ? (
            <div className="main__table no_data">No data to display</div>
          ) : user_search_data.length === 0 ? (
            <div className="main__table no_data">No data to display</div>
          ) : (
            <UserSearchTable
              Data={user_search_data}
              getSearchedData={getSearchedData}
              paginationValue={paginationValue}
              setPaginationValue={setPaginationValue}
            />
          )
        ) : null}
      </div>
      <ToastContainer />
    </>
  );
}

UserJsonFunction.propTypes = {
  user_search_ref: PropTypes.object,
};

export default UserJsonFunction;
