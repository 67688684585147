import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import axios from "axios";
import Adminsidenav from "./Adminsidenav";
import Adminmainnav from "./Adminmainnav";
import { handle_session_get } from "../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../utils/Validation/Token/token_checker";
import JsonEditor from "../../TableUi/components/display_json/JsonEditor";
import { ThreeCircles } from "react-loader-spinner";
import "../../css/Admin/admin.css";

function AdminSearchUser() {
  const [user_data, set_user_data] = useState({
    data: {},
    loading: false,
    error: "",
  });
  const handle_search_user = (search_value) => {
    set_user_data((prev) => ({ ...prev, loading: true }));
    const token = handle_session_get("Token");
    axios
      .post(
        "https://api.recordedtelegram.com/external_user_search",
        { qtext: search_value },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        set_user_data({ data: res.data.data, loading: false, error: "" });
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_user_data((prev) => ({
          ...prev,
          loading: false,
          error: err.response.data.errormsg,
        }));
      });
  };
  return (
    <div className="wrapper">
      <Adminsidenav />
      <div id="content" className="active">
        <section>
          <Adminmainnav />
          <div className="container" style={{ position: "relative" }}>
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-lg-7 mb-5">
                <Formik
                  initialValues={{ user_search: "" }}
                  validationSchema={Yup.object().shape({
                    user_search: Yup.string()
                      .min(3, "Please enter at least 3 characters")
                      .matches(
                        /^[\w\s]+$/,
                        "Please enter only alphanumeric characters",
                      )
                      .required("Please enter username"),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      handle_search_user(values.user_search);
                      setSubmitting(false);
                    });
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <div
                        className="  rounded rounded-pill  shadow-sm mb-4"
                        style={{
                          border: ".5px solid #1FCECB",
                          padding: "0.4rem",
                        }}
                      >
                        <div className="input-group">
                          <input
                            id="srch_tab"
                            onBlur={handleBlur}
                            type="search"
                            placeholder="Search here ..."
                            name="user_search"
                            value={values.user_search}
                            onChange={handleChange}
                            className="form-control border-0 new-dk admin_user_search_input"
                          />
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                      <ErrorMessage
                        component="div"
                        name="user_search"
                        style={{ color: "#c13b3b" }}
                      />
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="admin_user_search_json">
            {user_data.loading === true ? (
              <div className="spinner" style={{ height: "30vh" }}>
                <ThreeCircles
                  color="#28cab3"
                  height={60}
                  width={60}
                  ariaLabel="three-circles-rotating"
                />
              </div>
            ) : user_data.error ? (
              <div className="no_data">{user_data.error}</div>
            ) : Object.keys(user_data.data).length > 0 ? (
              <JsonEditor
                Data={user_data.data}
                json_display_type={"admin_user_search"}
              />
            ) : null}
          </div>
        </section>
      </div>
    </div>
  );
}

export default AdminSearchUser;
