/* eslint-disable no-useless-escape */
import dayjs from "dayjs";
// List of all categories
export const options_post_category = [
  "hacking",
  "extremepolitical",
  "financials",
  "criminal_activities",
  "religion_spirituality",
  "information_technology",
  "pharma_drugs",
  "cyber_security",
  "betting_gambling",
  "adult_content",
  "blogs_vlogs",
  "education",
  "science_index",
  "movies",
  "travelling",
  "gaming",
  "music",
  "lifestyle",
  "books_comics",
  "fashion_beauty",
  "design_arch",
  "humor_entertainment",
  "culture_events",
  "weapons",
  "wildlife",
  "marketing_and_ads",
  "sports_and_activities",
  "arab",
  "uzbek",
  "burmese",
  "thai",
  "french",
  "russian",
  "indonesian",
  "others",
];
export const handle_check_category_length = (category) => {
  const all_categories = [
    "hacking",
    "extremepolitical",
    "financials",
    "criminal_activities",
    "religion_spirituality",
    "information_technology",
    "pharma_drugs",
    "cyber_security",
    "betting_gambling",
    "adult_content",
    "blogs_vlogs",
    "education",
    "science_index",
    "movies",
    "travelling",
    "gaming",
    "music",
    "lifestyle",
    "books_comics",
    "fashion_beauty",
    "design_arch",
    "humor_entertainment",
    "culture_events",
    "weapons",
    "wildlife",
    "marketing_and_ads",
    "sports_and_activities",
    "arab",
    "uzbek",
    "burmese",
    "thai",
    "french",
    "russian",
  "indonesian",
    "others",
  ];
  if (Array.isArray(category)) {
    if (category.length === all_categories.length) {
      return true;
    }
    return false;
  }
  return true;
};

// After clicking on the link , new tap is open
export const link_website = (link) => {
  window.open(
    link,
    "_blank",
    "location=yes,height=700,width=700,scrollbars=yes,status=yes",
  );
};
// Note :create link for the crypto
export const crypto_link_tag = (crypto, provider) => {
  let url_text = null;
  if (provider === "bitcoin" || provider === "ethereum") {
    url_text = `https://www.blockchain.com/explorer/addresses/${
      provider === "bitcoin" ? "btc" : "eth"
    }/${crypto}`;
  } else if (provider == "tron") {
    url_text = `https://tronscan.org/#/address/${crypto}`;
  } else if (provider == "xrp") {
    url_text = `https://livenet.xrpl.org/accounts/${crypto}`;
  }
  return url_text;
};
// Check file name and file ext
export const check_filename = (file, file_type) => {
  if (file_type === "filename") {
    try {
      if (file) {
        if (
          file !== "None" &&
          file.includes("SIZE_EXCEEDED_NOT_DOWNLOADED") === false
        ) {
          return true;
        }
        return false;
      }
    } catch {
      return false;
    }
  } else if (file_type === "fileext") {
    try {
      if (file) {
        if (
          file === "None" ||
          file.includes("SIZE_EXCEEDED_NOT_DOWNLOADED") === true
        ) {
          return true;
        }
        return false;
      }
    } catch {
      return false;
    }
  }
};

// function for the date
export const date_fm = (date_str) => {
  if (date_str) {
    let new_dt = date_str.split("T");
    let sp_dt = new_dt[0].split("-");
    let new_sp_month = `0${parseInt(sp_dt[1]) - 1}`;
    const date = new Date(sp_dt[0], new_sp_month, sp_dt[2]); // 2009-11-10
    const month = date.toLocaleString("default", { month: "long" });
    let mod_month = month.substring(0, 3);
    let ret_dt = `${mod_month}-${sp_dt[2]}-${sp_dt[0]}`;
    return ret_dt;
  } else {
    return null;
  }
};
export const date_time_tooltip = (dt) => {
  if (dt) {
    if (dt !== "None" && dt !== null) {
      try {
        let new_dt = dt.split("T");
        let sp_dt = new_dt[0].split("-");
        let new_sp_month = `0${parseInt(sp_dt[1]) - 1}`;
        const date = new Date(sp_dt[0], new_sp_month, sp_dt[2]); // 2009-11-10
        const month = date.toLocaleString("default", { month: "long" });
        let mod_month = month.substring(0, 3);
        let ret_dt = `${mod_month}-${sp_dt[2]}-${sp_dt[0]}`;
        const time = new Date(dt).toLocaleTimeString();
        const final_value = `${ret_dt} / ${time}`;
        return final_value;
      } catch (err) {
        return dt;
      }
    }
  }
};
// Subscribers count function
export const participants = (value) => {
  try {
    if (value !== "null" && value !== null) {
      let new_data = value;
      let new_value = null;
      const data_object = {};
      try {
        // eslint-disable-next-line react/prop-types
        const conv_data = value.replaceAll("'", '"');
        new_data = JSON.parse(conv_data);
      } catch {
        //Do nothing
      }
      if (typeof new_data === "object") {
        new_value = new_data[0].subscribers;
        data_object.subs = new_value;
        data_object.photos = Object.prototype.hasOwnProperty.call(
          new_data[0],
          "photos",
        )
          ? new_data[0].photos
          : "None";
        data_object.files = Object.prototype.hasOwnProperty.call(
          new_data[0],
          "files",
        )
          ? new_data[0].files
          : "None";
        data_object.links = Object.prototype.hasOwnProperty.call(
          new_data[0],
          "links",
        )
          ? new_data[0].links
          : "None";

        return data_object;
      }
      new_value = value;
      data_object.subs = new_value;
      data_object.photos = "None";
      data_object.files = "None";
      data_object.links = "None";

      return data_object;
    }
    const data_object = {};

    data_object.subs = "None";
    data_object.photos = "None";
    data_object.files = "None";
    data_object.links = "None";

    return data_object;
  } catch {
    const data_object = {};
    data_object.subs = "None";
    data_object.photos = "None";
    data_object.files = "None";
    data_object.links = "None";

    return data_object;
  }
};

export const date_fm_str = (dateString, interval) => {
  if (dateString !== undefined) {
    try {
      const date = new Date(dateString);
      //Note: Format the date to "dd-MMM-yyyy" format
      const day = date.getDate().toString().padStart(2, "0");
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = monthNames[monthIndex];
      const formattedDate =
        interval === "yearly"
          ? `${year}`
          : interval === "monthly"
          ? `${month}-${year}`
          : `${day}-${month}`;
      return formattedDate;
    } catch {
      return dateString;
    }
  } else {
    return null;
  }
};

export const handle_tooltip_date = (dateString, interval, posts) => {
  try {
    if (dateString) {
      const date = new Date(dateString);
      //Note: Format the date to "dd-MMM-yyyy" format
      const day = date.getDate().toString().padStart(2, "0");
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[monthIndex];
      if (interval === "daily") {
        if (dateString.includes("T")) {
          const santize_date = `${dateString.split(".")[0]}.000Z`;
          const date = new Date(santize_date);
          return date.toString().split("GMT")[0];
        }
      }
      return `${posts} Posts on ${month} ${day}, ${year}`;
    }
    return "None";
  } catch {
    return "None";
  }
};
export const format_date_mui_to_input = (date) => {
  if (date) {
    const year = dayjs(date).year();
    const month = Number(dayjs(date).month() + 1);
    const day = Number(dayjs(date).date());
    const filter_month = month < 10 ? `0${month}` : month;
    const filter_day = day < 10 ? `0${day}` : day;
    return `${year}-${filter_month}-${filter_day}`;
  } else {
    return "None";
  }
};

export const check_product_local_storage = (product_name, all_category_obj) => {
  const user_name = localStorage.getItem("User");
  try {
    const product_obj = {};
    const local_storage_obj = JSON.parse(
      localStorage.getItem(`${user_name}_product_category`),
    );
    if (local_storage_obj && Object.keys(local_storage_obj).length > 0) {
      const product_obj_keys = Object.keys(local_storage_obj);
      if (product_obj_keys.includes(product_name) === true) {
        local_storage_obj[product_name].forEach((item) => {
          product_obj[item] = all_category_obj[item];
        });
        return product_obj;
      }
      return Object.fromEntries(Object.entries(all_category_obj).slice(0, 5));
    }
    return Object.fromEntries(Object.entries(all_category_obj).slice(0, 5));
  } catch {
    return Object.fromEntries(Object.entries(all_category_obj).slice(0, 5));
  }
};

export const date_to_iso = (
  dateStr,
  timeStr,
  start_date,
  end_date,
  time_type,
) => {
  try {
    if (dateStr === "None") return "None";
    if (dateStr !== "None" && start_date === "None" && end_date === "None") {
      if (time_type === "end_time") {
        return `${dateStr}T23:59:00`;
      }
      return "None";
    }
    if (timeStr === "None") {
      return "None";
    }

    const combinedDateTime = `${dateStr}T${timeStr}:00`;
    return combinedDateTime;
  } catch {
    const combinedDateTime = `${dateStr}:00`;
    return combinedDateTime;
  }
};
