import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import axios from "axios";
import { FaCog, FaInfoCircle, FaStarOfLife, FaTrashAlt } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import "rc-slider/assets/index.css";
import GridViewIcon from "@mui/icons-material/GridView";
import Tooltip from "@mui/material/Tooltip";
// import radio for the modal part
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CancelIcon from "@mui/icons-material/Cancel";
import { RollerAnimation } from "../TableUi/components/loader/Loader";
import { handle_session_get } from "../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../utils/Validation/Token/token_checker";
import TransparentContainer from "../TableUi/components/sections/Charts/TransparentContainer";
import {
  handle_success_message,
  handle_error_message,
  handle_warning_message,
} from "../utils/ResponseMessage/response_message";
import { chips_background_color } from "../utils/Globals/global_variables";
function BlockList() {
  const token = handle_session_get("Token");
  const [search_val, set_search_val] = useState("");
  const [search_data, set_search_data] = useState([]); // Get the list of channels
  const [stat_loading, set_stat_loading] = useState(false);
  const [block_type, set_block_type] = useState("both");
  const [channel_data, set_channel_data] = useState([]);
  const [alert_data, set_alert_data] = useState([]);
  const [all_data, set_all_data] = useState([]);
  const [delete_id, set_delete_id] = useState(null);
  const [modal_data, set_modal_data] = useState(null);
  const [search_field_error, set_search_field_error] = useState(null);

  const [block_type_update, set_block_type_update] = useState("search");
  useEffect(() => {
    toast.configure();
    data_getter();
  }, []);

  // Function onSubmit
  const post_channel = (val, search_type) => {
    const data = {
      block_status: block_type,
      search_type,
      qtext: val,
    };

    axios
      .post("https://api.recordedtelegram.com/v2/add_blocklist", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        handle_success_message("Added Sucessfully");
        setTimeout(() => {
          data_getter();
        });
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message("Addition operation encountered an error");
      });
  };

  const post_channel_modal = (val, search_type) => {
    const data = {
      block_status: block_type_update,
      search_type,
      qtext: val,
    };

    axios
      .post("https://api.recordedtelegram.com/v2/add_blocklist", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        handle_success_message("Added Sucessfully");
        setTimeout(() => {
          data_getter();
        });
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message("Addition operation encountered an error");
      });
  };

  const data_getter = () => {
    axios
      .get("https://api.recordedtelegram.com/v2/get_blocklist", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const filter_channel_name = res.data.map((item) => item.channel_name);
        set_all_data(filter_channel_name);

        const new_channel_data = res.data.filter((item) => {
          if (item.block_status === "both" || item.block_status === "search") {
            return item;
          }
          return null;
        });
        set_channel_data(new_channel_data);
        const new_alert_data = res.data.filter((item) => {
          if (item.block_status === "both" || item.block_status === "alert") {
            return item;
          }
          return null;
        });
        set_alert_data(new_alert_data);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
      });
  };

  const data_delete = () => {
    const data = { hash_id: delete_id };
    axios
      .post("https://api.recordedtelegram.com/v2/delete_channel_block", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setTimeout(() => {
          data_getter();
        }, 1000);
        handle_warning_message("Deleted Sucessfully");
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message("Deletion Failed");
      });
  };

  const val_change = (e) => {
    set_search_val(e.target.value);
    if (e.target.value.length > 2) {
      set_stat_loading(true);
      const data = { qtext: e.target.value };
      axios
        .post("https://api.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          set_search_data(res.data);
          set_stat_loading(false);
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_stat_loading(false);
          set_search_field_error(err.response.data.errormsg);
        });
    }
  };
  return (
    <section>
      <div
        className="container-fluid dk-mode"
        style={{ marginTop: "50px", position: "relative", height: "100vh" }}
      >
        <div className="row mb-1 " style={{ justifyContent: "center" }}>
          <div className="col-sm-8">
            <Formik
              initialValues={{ search: "" }}
              validate={(values) => {
                const errors = {};
                const { search } = values;
                if (search.length < 2) {
                  errors.search =
                    "search keyword must be longer than 1 character";
                }

                return errors;
              }}
              onSubmit={() => {}}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div
                    className="d-flex align-items-baseline"
                    style={{ columnGap: "10px" }}
                  >
                    <div
                      className="rounded input-group rounded-pill  shadow-sm mb-1 block_channel_input_field notification_search_box"
                      style={{
                        padding: "0.4rem",
                        width: "100%",
                      }}
                    >
                      <input
                        id="srch_tab"
                        type="search"
                        placeholder="Enter channel"
                        name="search"
                        value={values.name}
                        onChange={(e) => {
                          handleChange(e);
                          val_change(e);
                        }}
                        onBlur={handleBlur}
                        className="form-control border-0 new-dk"
                      />
                    </div>
                    <button
                      type="button"
                      className="searchbar-btn searchbar-btn-main"
                      data-toggle="modal"
                      data-target="#blocklistfilters"
                    >
                      {" "}
                      <Tooltip title="Filters">
                        <GridViewIcon sx={{ color: "#dcf3ff" }} />
                      </Tooltip>{" "}
                    </button>
                  </div>
                  {errors.search && touched.search ? (
                    <div className="user_search_validation">
                      {errors.search}
                    </div>
                  ) : null}
                  {search_val.length < 2 &&
                  !errors.search &&
                  !touched.search ? (
                    <span style={{ color: "#fff", fontSize: "14px" }}>
                      {" "}
                      <IconContext.Provider
                        value={{ size: 18, color: "#f15e5e" }}
                      >
                        <FaInfoCircle />
                      </IconContext.Provider>{" "}
                      Please type in channel/group name to blacklist for
                      search/alert
                    </span>
                  ) : null}
                </form>
              )}
            </Formik>
          </div>

          {/* list of channels */}
          {search_val.length > 2 ? (
            <div
              className="autocom-box channel_list_container mt-3"
              style={{ width: "62%" }}
            >
              {stat_loading ? (
                <RollerAnimation />
              ) : search_field_error !== null ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "larger",
                    color: "white",
                    marginTop: "10%",
                  }}
                >
                  {search_field_error}
                </div>
              ) : search_data !== null && search_data.length === 0 ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "larger",
                    color: "white",
                    marginTop: "10%",
                  }}
                >
                  No data to display
                </div>
              ) : (
                <>
                  <h4
                    style={{
                      paddingLeft: "1rem",
                      marginLeft: "15px",
                      marginTop: "5px",
                      fontSize: "18px",
                      color: "whitesmoke",
                    }}
                  >
                    List Of Channels
                  </h4>
                  <ul>
                    {search_data !== null &&
                      search_data.map((val, index) => (
                        <li key={index}>
                          <div className="row">
                            <div
                              className="col-md-8"
                              style={{ textAlign: "left" }}
                            >
                              {" "}
                              <img
                                style={{
                                  width: "36px",
                                  height: "36px",
                                  padding: "2px",
                                  border: "2px solid #f4f4f4",
                                }}
                                className="img-fluid rounded-circle"
                                src={
                                  val.channel_id !== "None"
                                    ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                }}
                                alt="User"
                              />
                              <span
                                className="channels_list_map"
                                style={{ marginLeft: "10px", color: "#dcf3ff" }}
                              >
                                {val.channel_name}
                              </span>
                            </div>
                            {all_data.indexOf(val.channel_name) === -1 ? (
                              <div
                                className="col-md-4"
                                style={{ textAlign: "right", marginTop: "5px" }}
                              >
                                <button
                                  style={{
                                    borderRadius: "40px",
                                    background: "#d03940",
                                    color: "white",
                                    lineHeight: "1",
                                  }}
                                  className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                  onClick={() => {
                                    post_channel(
                                      val.channel_name !== "None" &&
                                        val.channel_name !== null
                                        ? val.channel_name
                                        : val.link !== "None" &&
                                          val.link !== null
                                        ? val.link
                                        : val.channel_id,
                                      val.channel_name !== "None" &&
                                        val.channel_name !== null
                                        ? "channel name"
                                        : val.link !== "None" &&
                                          val.link !== null
                                        ? "link"
                                        : "id",
                                    );
                                    set_search_val("");
                                  }}
                                >
                                  Block
                                </button>
                              </div>
                            ) : (
                              <div
                                className="col-md-4"
                                style={{ textAlign: "right", marginTop: "5px" }}
                              >
                                <button
                                  disabled
                                  style={{
                                    borderRadius: "40px",
                                    background: "#d03940",
                                    color: "white",
                                    lineHeight: "1",
                                  }}
                                  className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                >
                                  Blocked
                                </button>
                              </div>
                            )}
                          </div>
                        </li>
                      ))}
                  </ul>
                </>
              )}
            </div>
          ) : null}
        </div>
        <div
          className="position-absolute "
          style={{ marginLeft: "1%", width: "95%" }}
        >
          <div
            className="row"
            style={{ justifyContent: "center", marginTop: "16px" }}
          >
            <div className="col-lg-8">
              <TransparentContainer
                container={
                  <div>
                    <div className="card-header ">
                      <h5 className="stats_title_color">Search Block List</h5>
                    </div>
                    <div
                      className="card-body  scroll-custom"
                      style={{ height: "45vh" }}
                    >
                      {channel_data.length > 0
                        ? channel_data.map((val, index) => (
                            <div className="file-tab" key={index}>
                              <div className="media">
                                <div className="media-aside mr-1 align-self-start">
                                  <i
                                    className="file-icon lbg-0"
                                    style={{
                                      fontFamily: "none",
                                      fontSize: "30px",
                                      color: "white",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        size: 18,
                                        marginTop: "10px",
                                        marginLeft: "12px",
                                        color: "white",
                                      }}
                                    >
                                      {val.channel_name ? (
                                        val.channel_name[0]
                                      ) : (
                                        <FaStarOfLife />
                                      )}
                                    </IconContext.Provider>
                                  </i>
                                </div>
                                <div className="media-body my-auto ">
                                  <div className="file-name ">
                                    <span style={{ marginLeft: "10px" }}>
                                      {val.channel_name}
                                    </span>
                                    <span
                                      style={{
                                        border: `2px solid ${chips_background_color["notification_type"]}`,
                                        color:
                                          chips_background_color[
                                            "notification_type"
                                          ],
                                      }}
                                      className="filters-text"
                                    >
                                      {val.block_status
                                        ? val.block_status
                                        : null}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <Tooltip title="Update">
                                  <span
                                    data-toggle="modal"
                                    data-target="#blocklistfilters_update"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      set_modal_data(val);
                                      set_block_type_update(val.block_status);
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        size: 28,
                                        marginTop: "10px",
                                        color: "rgb(6, 110, 128)",
                                      }}
                                    >
                                      <FaCog />
                                    </IconContext.Provider>
                                  </span>
                                </Tooltip>

                                <Tooltip title="Delete">
                                  <span
                                    style={{
                                      marginLeft: "25px",
                                      cursor: "pointer",
                                    }}
                                    data-toggle="modal"
                                    data-target="#showdeletebutton"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      set_delete_id(val._id);
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        size: 28,
                                        marginTop: "10px",
                                        color: "rgb(217, 37, 80)",
                                      }}
                                    >
                                      <FaTrashAlt />
                                    </IconContext.Provider>
                                  </span>
                                </Tooltip>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
          <div
            className="row"
            style={{ justifyContent: "center", marginTop: "30px" }}
          >
            <div className="col-lg-8">
              <TransparentContainer
                container={
                  <div>
                    <div className="card-header ">
                      <h5 className="stats_title_color">Alert Block List</h5>
                    </div>
                    <div className="card-body">
                      {alert_data.length > 0
                        ? alert_data.map((val, index) => (
                            <div className="file-tab" key={index}>
                              <div className="media">
                                <div className="media-aside mr-1 align-self-start">
                                  <i
                                    className="file-icon lbg-0"
                                    style={{
                                      fontFamily: "none",
                                      fontSize: "30px",
                                      color: "white",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        size: 18,
                                        marginTop: "10px",
                                        marginLeft: "12px",
                                        color: "white",
                                      }}
                                    >
                                      {val.channel_name ? (
                                        val.channel_name[0]
                                      ) : (
                                        <FaStarOfLife />
                                      )}
                                    </IconContext.Provider>
                                  </i>
                                </div>
                                <div className="media-body my-auto ">
                                  <div className="file-name ">
                                    <span style={{ marginLeft: "10px" }}>
                                      {val.channel_name}
                                    </span>
                                    <span
                                      style={{
                                        border: `2px solid ${chips_background_color["notification_type"]}`,
                                        color:
                                          chips_background_color[
                                            "notification_type"
                                          ],
                                      }}
                                      className="filters-text"
                                    >
                                      {val.block_status
                                        ? val.block_status
                                        : null}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <Tooltip title="Update">
                                  <span
                                    data-toggle="modal"
                                    data-target="#blocklistfilters_update"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      set_modal_data(val);
                                      set_block_type_update(val.block_status);
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        size: 28,
                                        marginTop: "10px",
                                        color: "rgb(6, 110, 128)",
                                      }}
                                    >
                                      <FaCog />
                                    </IconContext.Provider>
                                  </span>
                                </Tooltip>

                                <Tooltip title="Delete">
                                  <span
                                    style={{
                                      marginLeft: "25px",
                                      cursor: "pointer",
                                    }}
                                    data-toggle="modal"
                                    data-target="#showdeletebutton"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      set_delete_id(val._id);
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        size: 28,
                                        marginTop: "10px",
                                        color: "rgb(217, 37, 80)",
                                      }}
                                    >
                                      <FaTrashAlt />
                                    </IconContext.Provider>
                                  </span>
                                </Tooltip>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* Modal of filters for selection */}
      <div
        className="modal fade"
        id="blocklistfilters"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="blocklistfilters"
        aria-hidden="true"
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content dk-mode"
            style={{ top: "51px", width: "580px" }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="userModalLongTitle">
                Block Filters
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <CancelIcon
                  sx={{ color: "#ff1f1f ", cursor: "pointer", m: 0.5 }}
                />
              </button>
            </div>
            <div className="modal-body">
              <div className="cardbox-item">
                <div className="row">
                  <div className="col text-left">
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Block Type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={block_type}
                        onChange={(event) => set_block_type(event.target.value)}
                      >
                        <FormControlLabel
                          value="search"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Search"
                        />
                        <FormControlLabel
                          value="alert"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Alert"
                        />
                        <FormControlLabel
                          value="both"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Both"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for filters for update */}
      <div
        className="modal fade"
        id="blocklistfilters_update"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="blocklistfilters_update"
        aria-hidden="true"
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog" role="document">
          <div
            className="modal-content dk-mode"
            style={{ top: "51px", width: "580px" }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="userModalLongTitle">
                Block Filters
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <CancelIcon
                  sx={{ color: "#ff1f1f ", cursor: "pointer", m: 0.5 }}
                />
              </button>
            </div>
            <div className="modal-body">
              <div className="cardbox-item">
                <div className="row">
                  <div className="col text-left">
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Block Type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={block_type_update}
                        onChange={(event) =>
                          set_block_type_update(event.target.value)
                        }
                      >
                        <FormControlLabel
                          value="search"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Search"
                        />
                        <FormControlLabel
                          value="alert"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Alert"
                        />
                        <FormControlLabel
                          value="both"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Both"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <button
                  style={{
                    marginTop: "20px",
                  }}
                  data-dismiss="modal"
                  className="button_global_style"
                  onClick={() =>
                    post_channel_modal(
                      modal_data.channel_name !== "None" &&
                        modal_data.channel_name !== null
                        ? modal_data.channel_name
                        : modal_data.link !== "None" && modal_data.link !== null
                        ? modal_data.link
                        : modal_data.channel_id,
                      modal_data.channel_name !== "None" &&
                        modal_data.channel_name !== null
                        ? "channel name"
                        : modal_data.link !== "None" && modal_data.link !== null
                        ? "link"
                        : "id",
                    )
                  }
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for delete */}
      <div
        className="modal fade"
        id="showdeletebutton"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="showdeletebutton"
        aria-hidden="true"
        style={{ background: "rgba(29, 40, 53, .95)" }}
      >
        <div
          className="modal-dialog modal-confirm"
          style={{ background: "white" }}
        >
          <div className="modal-content dk-mode">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100">Are you sure?</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p>
                Do you really want to delete these records? This process cannot
                be undone.
              </p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  data_delete();
                  document.getElementById("showdeletebutton").className =
                    "modal fade";
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </section>
  );
}

export default BlockList;
