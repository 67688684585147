import React, { useState, useEffect, memo } from "react";
import "../../../css/Dashboard/company_dashboard.css";
import TransparentContainer from "../sections/Charts/TransparentContainer";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import axios from "axios";
import ShowMoreText from "react-show-more-text";
import { Stack, Grid } from "@mui/material";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { date_fm } from "../../../Assets/globalfunctions";
import { RoundAnimationLoader } from "../loader/Loader";
import EditIcon from "@mui/icons-material/Edit";
import { Formik } from "formik";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";

function AdminChannelsList() {
  const [start_date, set_start_date] = useState();
  const [loading, set_loading] = useState(false);
  const [fetch_date, set_fetch_date] = useState();
  const [channels_display, set_channels_display] = useState([]);
  const [response_channels, set_response_channels] = useState([]);
  const [error_msg, set_error_msg] = useState("");
  const [search_type, set_search_type] = useState("telegram_channel");
  const [chart_title, set_chart_title] = useState({
    status: true,
    text: "",
    editicon: false,
    loading: false,
  });
  const [tooltip_box, set_tooltip_box] = useState({
    status: false,
    name: "",
    date: "",
  });
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (response_channels.length === 0)
        return () => clearInterval(intervalId);
      const first_item = response_channels[0];
      if (!first_item) return () => clearInterval(intervalId);
      set_channels_display((prev) => [first_item, ...prev]);
      set_response_channels((prev) => {
        const updatedChannels = [...prev];
        updatedChannels.shift();
        return updatedChannels;
      });
      div_appear();
    }, calculate_time(response_channels));

    return () => clearInterval(intervalId);
  }, [channels_display, response_channels]);
  const calculate_time = (channels) => {
    try {
      if (channels.length > 0) {
        const time_sec = Math.floor(18 / channels.length);
        const filter_time_sec = Number(time_sec * 1000);
        return time_sec > 0 ? filter_time_sec : 1000;
      } else {
        return 3000;
      }
    } catch {
      return 3000;
    }
  };
  useEffect(() => {
    handle_preset_channles();
  }, [search_type]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      handle_preset_channles(start_date);
    }, 20000);
    return () => clearInterval(intervalId);
  }, [start_date, fetch_date]);

  function getCurrentDateISOString(currentDate) {
    try {
      const isoString = `${currentDate.toISOString().slice(0, -5)}+00:00`;
      return isoString;
    } catch {
      return currentDate.isoString();
    }
  }
  const handle_preset_channles = (startdate = null) => {
    const token = handle_session_get("Token");
    if (!startdate) {
      set_loading(true);
    }
    const data = startdate
      ? {
          start_date: getCurrentDateISOString(startdate),
          search_filter: search_type,
        }
      : {
          search_filter: search_type,
        };
    axios
      .post("https://api.recordedtelegram.com/preset_channels", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        set_fetch_date(new Date());
        if (!start_date) {
          const all_response = res.data.data.table_result;
          const filter_message = all_response.filter(
            (item) => item.message && item.message.length > 0,
          );
          set_channels_display(filter_message);
          set_response_channels([]);
          set_start_date(new Date());
          set_loading(false);
          set_chart_title((prev) => ({
            ...prev,
            text: res.data.data.preference_title,
          }));
          if (filter_message.length === 0) {
            set_error_msg("No data to Display");
          } else {
            set_error_msg("");
          }
          return;
        }
        if (res.data.data.table_result.length > 0) {
          const all_response = res.data.data.table_result;
          const filter_message = all_response.filter(
            (item) => item.message && item.message.length > 0,
          );
          const uniqueSet = new Set(filter_message.map((item) => item.hash_id));
          const uniqueArray = Array.from(uniqueSet).map((hash_id) =>
            filter_message.find((item) => item.hash_id === hash_id),
          );
          const res_array = [...uniqueArray];
          set_start_date(new Date());
          set_channels_display((prev) => {
            const res_len = res_array.length;
            const first_item = res_array[0];
            const slice_array = prev.slice(0, prev.length - res_len - 1);
            return [first_item, ...slice_array];
          });
          div_appear();
          res_array.shift();
          set_response_channels(res_array);
          set_error_msg("");
        }
        return;
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_loading(false);
        if (!start_date) {
          set_error_msg(err.response.data.errormsg);
        }
      });
  };

  const handle_set_title = (title_name) => {
    const token = handle_session_get("Token");
    set_chart_title((prev) => ({ ...prev, loading: true }));
    const data = { preference_title: title_name };
    axios
      .post(
        "https://api.recordedtelegram.com/v2/update_preference_title",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        set_chart_title((prev) => ({
          ...prev,
          status: true,
          editicon: false,
          loading: false,
          text: res.data.data.title,
        }));
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_chart_title((prev) => ({
          ...prev,
          status: false,
          editicon: false,
          loading: false,
        }));
      });
  };
  function executeOnClick() {}
  const div_appear = () => {
    let fade_in = document.getElementById("animate_div_0");
    let opacity = 0;
    let fadeOut = setInterval(function () {
      if (opacity >= 1) {
        clearInterval(fadeOut);
      }
      fade_in.style.opacity = opacity;
      opacity += 0.1;
    }, 100);

    return () => clearInterval(fadeOut);
  };
  const utc_time = (date) => {
    try {
      const filter_date = date.split("T");
      const slice_time = filter_date[1].slice(0, 5);
      return slice_time;
    } catch {
      //  Do nothing
    }
  };

  return (
    <div className="admin_drug_channels_container">
      <TransparentContainer
        container={
          <div className="admin_drug_channels_desc_container">
            <div className="admin_drug_channels_desc_container_title_box">
              {chart_title.status === false ? (
                <Formik
                  initialValues={{ title: "" }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      handle_set_title(values.title);
                      setSubmitting(false);
                    }, 400);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container justifyContent="center">
                        <Grid item xs={10}>
                          <input
                            type="text"
                            name="title"
                            className="input_field_param input_field_dashboard_title"
                            placeholder="your Title"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                          />
                          <input
                            type="submit"
                            disabled={isSubmitting}
                            style={{ display: "none" }}
                          />
                        </Grid>
                        <Grid xs={1}>
                          {chart_title.loading === true ? (
                            <CircularProgress sx={{ color: "#28cab3" }} />
                          ) : null}
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              ) : (
                <h3
                  className="admin_drug_channels_desc_container_title"
                  onMouseEnter={() =>
                    set_chart_title((prev) => ({ ...prev, editicon: true }))
                  }
                >
                  {chart_title.text}
                </h3>
              )}
              {chart_title.text &&
              chart_title.status === true &&
              chart_title.editicon === true ? (
                <button
                  className="admin_drug_channels_desc_container_title_edit_btn"
                  onClick={() =>
                    set_chart_title((prev) => ({ ...prev, status: false }))
                  }
                >
                  <Tooltip title="Edit your title">
                    <EditIcon style={{ color: "white" }} />
                  </Tooltip>{" "}
                </button>
              ) : null}
            </div>
            <Stack
              direction="row"
              alignItems="center"
              alignContent="center"
              justifyContent="flex-end"
              columnGap={1}
              sx={{ width: "95%" }}
            >
              <button
                onClick={() => {
                  set_search_type("telegram_channel");
                  set_fetch_date(null);
                  set_start_date(null);
                }}
                disabled={search_type === "telegram_channel"}
                className={
                  search_type === "telegram_channel"
                    ? "dashboard_filter_btn dashboard_filter_btn_activated"
                    : "dashboard_filter_btn dashboard_filter_btn_deactivated"
                }
              >
                Channel
              </button>
              <div className="dashboard_filter_btn_border" />
              <button
                onClick={() => {
                  set_search_type("telegram_user");
                  set_fetch_date(null);
                  set_start_date(null);
                }}
                disabled={search_type === "telegram_user"}
                className={
                  search_type === "telegram_user"
                    ? "dashboard_filter_btn dashboard_filter_btn_activated"
                    : "dashboard_filter_btn dashboard_filter_btn_deactivated"
                }
              >
                User
              </button>
            </Stack>
            {loading === true ? (
              <RoundAnimationLoader />
            ) : error_msg ? (
              <h4 style={{ marginTop: "200px" }}>{error_msg}</h4>
            ) : (
              <div className="admin_drug_channels_desc scroll-custom">
                {channels_display.map((item, index) => {
                  return (
                    <div
                      className="admin_drug_channels_desc_message_container"
                      id={`animate_div_${index}`}
                      onMouseEnter={() =>
                        set_tooltip_box({
                          status: true,
                          name:
                            search_type === "telegram_channel"
                              ? item.conv_name
                              : item.username && item.username !== "None"
                              ? item.username
                              : item.userfullname &&
                                item.userfullname !== "None"
                              ? item.userfullname
                              : item.id,
                          date: item.date,
                        })
                      }
                      onMouseLeave={() =>
                        set_tooltip_box({ status: false, name: "", date: "" })
                      }
                      key={index}
                    >
                      <p className="admin_drug_channels_desc_message">
                        <ShowMoreText
                          lines={3}
                          more="Show more"
                          less="Show less"
                          className="content-css"
                          anchorClass="my-anchor-css-class"
                          onClick={executeOnClick}
                          expanded={false}
                        >
                          {item.message}
                        </ShowMoreText>
                      </p>
                    </div>
                  );
                })}
                {tooltip_box["status"] === true ? (
                  <ClickAwayListener
                    onClickAway={() =>
                      set_tooltip_box({ status: false, name: "", date: "" })
                    }
                  >
                    <div className="admin_drug_channels_desc_message_fixed">
                      <p className="admin_drug_channels_desc_message_fixed_channel">
                        {tooltip_box["name"]}
                      </p>
                      <p className="admin_drug_channels_desc_message_fixed_date">{`${date_fm(
                        tooltip_box["date"],
                      )} , ${utc_time(tooltip_box["date"])} UTC`}</p>
                    </div>
                  </ClickAwayListener>
                ) : null}
              </div>
            )}
          </div>
        }
      />
    </div>
  );
}

export default memo(AdminChannelsList);
