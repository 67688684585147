import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  handleOpenNewTab,
  check_author_channel_or_user,
} from "../../../../utils/Globals/global_functions";
import { participants } from "../../../../Assets/globalfunctions";
import {
  check_filename,
  date_time_tooltip,
} from "../../../../Assets/globalfunctions";
import { Link } from "react-router-dom";
function ChannelSeeMoreInfo({ item }) {
  const [seemore, set_seemore] = useState(false);
  const message = item.message ? item.message : "";
  const filter_message = seemore ? message : message.slice(0, 500);
  const file_link_adder = (links, msgid) => {
    if (links !== null && links !== "None") {
      if (links.includes("t.me")) {
        const new_link = links.replace("s/", "");
        const open_link = `${new_link}/${msgid}`;

        window.open(
          open_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      } else {
        const new_link = `https://t.me/${links}/${msgid}`;
        window.open(
          new_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      }
    }
  };
  const author_info = check_author_channel_or_user(item);
  return (
    <div className="channel_see_more_card_container">
      <div className="flex_first_child_heading">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="baseline" columnGap={1}>
            <h5 className="flex_child_tweet_heading_text">
              <span
                onClick={(e) =>
                  handleOpenNewTab(
                    `/page?page=${item.conv_name}&type=channel name`,
                    e,
                  )
                }
                className="group_channel_profile_link_text"
              >
                {item.conv_name}
              </span>
              <span
                style={{
                  color: "#fdff32",
                }}
              >
                ({item.is_group === "True" ? "Group" : "Channel"})
              </span>
            </h5>
            {message.length > 500 ? (
              <Stack direction="row" alignItems="center" columnGap={1}>
                <button
                  className="flex_child_seemore_less_btn"
                  onClick={() => set_seemore((prev) => !prev)}
                >
                  {seemore ? "Show less post" : "Show full post"}
                </button>
                {item.is_group === "True" && (
                  <KeyboardDoubleArrowRightIcon fontSize="small" />
                )}
              </Stack>
            ) : null}
            {item.is_group === "True" && item.id !== "None" ? (
              <Link
                to={
                  author_info.author_type === "user"
                    ? `/users?page=${author_info.user_id}`
                    : `/page?page=${author_info.channel}&type=${author_info.navigate_type}`
                }
              >
                <p className="author_link" style={{ marginBottom: 0 }}>
                  {author_info.author_type === "user"
                    ? `Author ${
                        author_info.navigate_type === "id" ? "ID" : ""
                      }  : ${author_info.user}`
                    : `${
                        author_info.navigate_type === "channel name"
                          ? "channel name"
                          : "channel" + " " + author_info.navigate_type
                      } : ${author_info.channel}`}
                </p>
              </Link>
            ) : null}
          </Stack>
          <Stack direction="row" alignItems="baseline" columnGap={1}>
            <Stack direction="row" columnGap={1}>
              <UpdateIcon fontSize="small" sx={{ color: "#999" }} />
              <p className="icon_text">{date_time_tooltip(item.date)}</p>
            </Stack>
            {item.views ? (
              <Stack direction="row" columnGap={1}>
                <RemoveRedEyeIcon fontSize="small" sx={{ color: "#999" }} />
                <p className="icon_text">{item.views}</p>
              </Stack>
            ) : null}
            <Stack direction="row" columnGap={1}>
              <GroupsIcon fontSize="small" sx={{ color: "#999" }} />
              <p
                className="icon_text"
                style={{
                  width: "100%",
                  overflowWrap: "break-word",
                }}
              >
                {participants(item.participants_count).subs}
              </p>
            </Stack>
          </Stack>
        </Stack>
      </div>
      {item.filename !== "None" || item.fileext !== "None" ? (
        <button
          className="filter_file_link"
          disabled={!(item.link !== null && item.link !== "None")}
          onClick={() => file_link_adder(item.link, item.msgid)}
        >
          <Stack direction="row" alignItems="center" columnGap={0.5}>
            <InsertDriveFileIcon fontSize="smaller" />
            <span className="link_filename_modal">
              {check_filename(item.filename, "filename") === true
                ? item.filename
                : "No File Name"}
              {check_filename(item.filename, "fileext") === true
                ? `(${item.fileext})`
                : null}
            </span>
          </Stack>
        </button>
      ) : null}

      {item.message ? (
        <div className="flex_first_child_body_message">{filter_message}</div>
      ) : null}
    </div>
  );
}
ChannelSeeMoreInfo.propTypes = {
  item: PropTypes.object,
};

export default memo(ChannelSeeMoreInfo);
