import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import ForumData from "./ForumData";
function ForumDataFunction({ forum_ref }) {
  const [forum_data, set_forum_data] = useState([]);
  const [showTable, setShowTable] = useState(null);
  const [get_searched_data_forum, set_get_searched_data_forum] = useState("");

  // Paginaton Value set to 1 when form submitted
  const [paginationValue, setPaginationValue] = useState(1);
  const [total_db_data, set_total_db_data] = useState();
  const [forum_rate_limit, set_forum_rate_limit] = useState();
  const [error_status, set_error_status] = useState(false);
  const [forum_error, set_forum_error] = useState();

  useEffect(() => {
    forum_ref.current = get_forum_data;
  }, []);

  let isSubmitBlocked = false;

  const get_forum_data = useCallback(
    (searchedData) => {
      set_get_searched_data_forum(searchedData);
      setPaginationValue(1);
      setShowTable(false);
      const token = localStorage.getItem("Token");
      const data = { qtext: searchedData };
      if (!isSubmitBlocked) {
        isSubmitBlocked = true;
        axios
          .post("https://api.recordedtelegram.com/v2/forums_search", data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            set_forum_data(res.data.data);
            set_total_db_data(res.data.total_db_data);
            set_forum_rate_limit(res.data.forums_ratelimit);
            setShowTable(true);
            isSubmitBlocked = false;
            if (res.data.data.length < 1) {
              setShowTable(true);
              set_error_status(true);
              set_forum_error("No data to display");
            } else {
              set_error_status(false);
            }
          })
          .catch((err) => {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            setShowTable(true);
            set_error_status(true);
            set_forum_error(err.response.data.errormsg);
            isSubmitBlocked = false;
          });
      }
    },
    [get_searched_data_forum, forum_data],
  );

  return (
    <div>
      {showTable ? (
        error_status === true ? (
          <div className="main__table no_data">{forum_error}</div>
        ) : forum_data.includes(
            "No results. Please try again after some time.",
          ) ? (
          <div className="main__table no_data">No data to display</div>
        ) : (
          <div>
            <ForumData
              forum_data={forum_data}
              paginationValue={paginationValue}
              setPaginationValue={setPaginationValue}
              get_searched_data_forum={get_searched_data_forum}
              total_db_data={total_db_data}
              forum_rate_limit={forum_rate_limit}
            />
          </div>
        )
      ) : (
        showTable !== null && (
          <div className="spinner">
            <ThreeCircles
              color="#28cab3"
              height={60}
              width={60}
              ariaLabel="three-circles-rotating"
            />
          </div>
        )
      )}

      <ToastContainer />
    </div>
  );
}

ForumDataFunction.propTypes = {
  forum_ref: PropTypes.object,
};

export default ForumDataFunction;
